import React, {useEffect, useState} from 'react';
import API from "../../common/AxoisClient";
import {Auth} from "../../Auth/Auth";
import {AxiosResponse} from "axios";
import {NOTIFICATION_TYPE, showNotification, successNotification} from "../../common/helpers";
import Lang from "../../common/lang/Lang";
import {translationTypesModel} from "../signup/Schema";
import {EditBasicInfoState} from "./State";
import Swal from "sweetalert2";

declare let window:any;

  export const TranslatorService = (props:any) => {

      const [TranslatorService, setTranslatorService] = useState(EditBasicInfoState);
      const translationTypeDescription = [Lang.telephone_translation_description, Lang.physical_translation_description, Lang.video_translation_description, Lang.telephone_besked_description, Lang.document_translation_description];
      useEffect(() => {
          getTranslationServicesData();
      }, []);

      function getTranslationServicesData() {
          API.get(`translator-data/${Auth.userInfo.id}`).then((res: AxiosResponse) => {
              setTranslatorService({...TranslatorService, transTypes:res.data.translationTypes});
              ($('[data-toggle="tooltip"]') as any).tooltip();
          });
      }

      function handleChange(event:React.ChangeEvent<any>){
          const checkValue = event.target.value;
          const checkStatus = event.target.checked;
          let transTypesObject = TranslatorService.transTypes;

          let i, atleastOneTranslation = 0;
              for (i = 0; i < transTypesObject.length; i++) {
                  if (transTypesObject[i].ischecked == 1) {
                      atleastOneTranslation += 1;
                  }
              }
          if(event.target.checked === true){
              atleastOneTranslation += 2;
          }

          let confirm_text = Lang.translation_service_confirm;
          Swal.fire({
              title: Lang.is_sure,text: confirm_text,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: Lang.ok, cancelButtonText: Lang.cancel
          }).then((result:any) => {
              if (result.value) {
                  if(atleastOneTranslation>1){
                      if(checkStatus === true) {
                          transTypesObject[checkValue].ischecked = 1;
                      } else {
                          transTypesObject[checkValue].ischecked = 0;
                      }
                      setTranslatorService({...TranslatorService, transTypes:transTypesObject});
                      API.post(`profile/update-translation-types`, TranslatorService).then((res: AxiosResponse) => {
                          if(res.data.status==true){
                              successNotification(Lang.translation_service_update);
                          } else if(res.data.status==false){
                              showNotification(NOTIFICATION_TYPE.ERROR, res.data.message);
                          }
                      })
                  } else {
                      showNotification(NOTIFICATION_TYPE.ERROR, Lang.atleastOneTranslation);
                  }
              }
          })
      }

  return(
          <div className="translation_ser mb-40">
              <div className="translationService customRadioGroup">
                  {TranslatorService.transTypes &&
                      TranslatorService.transTypes.map((item: translationTypesModel, index: number) => {
                          return (
                              <div className="radioCustom" key={item.id} data-toggle='tooltip' data-placement='bottom' title={translationTypeDescription[index]}>
                                  <input type="checkbox" className="custom-control-radio" id={`tService${item.id}`} checked={item.ischecked>0} onChange={handleChange} name="translation_type_id" value={index}/>
                                  <label className="custom-control-radio2" htmlFor={`tService${item.id}`}>
                                      <span className={`tService${item.id}`} style={{backgroundImage:  `url(${item.icon})`}} />
                                      <span>{item.title} </span>
                                      <i className="iconCheck fas fa-check-circle"/>
                                  </label>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      )
};
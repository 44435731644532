import { Subject } from 'rxjs';
import {CallType} from "../calling/Config";

const subject = new Subject();
export const CallingServices = {
  createVideoCall: (state:any) => subject.next({call: CallType.VIDEO, ...state}),
  createAudioCall: (state:any) => subject.next({call: CallType.AUDIO, ...state}),
  logout: () => subject.next({call: '3'}),
  init: () => subject.next({call: '4'}),
  getCall: () => subject.asObservable()
};


import React, {useEffect, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {Auth, getUserInfo} from "../../Auth/Auth";
import _ from "lodash";
import API from "../../common/AxoisClient";
import {Modal} from "react-bootstrap";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import {EditBasicInfoState} from "./State";
import {getCountries, getCountryList, getLanguageList} from "../../services";
import {getMapContent, GooglePlace} from "../../common/GooglePlace";
import {AxiosResponse} from "axios";
import {successNotification} from "../../common/helpers";
import {EditBasicInfoValidations} from "./Validations";
import {FormLayout, Submit} from "../../common";
declare let window:any;

    export const TBasicInfo = (props: any) => {

        const user:any = getUserInfo();
        const [EditBasicInfoPopup, setEditBasicInfoPopup] = useState({show:false});
        const [EditBasicInfo, setEditBasicInfo] = useState(EditBasicInfoState);
        const [originCountries,  setOriginCountries] = useState();

        const transTypes = props.translator_data.translation_types;

        useEffect(() => {
            getCountries().then(countries => {
                setOriginCountries(countries);
            });

        }, []);

        function popup(event:any, mode:boolean) {
            setEditBasicInfo(EditBasicInfoState);
            if(mode === true) {
                setTimeout(function(){
                    EditBasicInfoValidations();
                    $('.modal').removeAttr("tabindex");
                }, 1000);
                API.get(`translator-data/${Auth.userInfo.id}`).then((res: AxiosResponse) => {
                    const getTranslatorData = res.data.translatorData;
                    const transTypes = res.data.translationTypes;
                    const getuserData = res.data.userData;
                    setTimeout(function(){
                        setEditBasicInfo({...EditBasicInfo, name: getuserData.name, email: getuserData.email, country_code: getuserData.country_code, mobile_no: getuserData.mobile_no, address: getuserData.address, social_no: getTranslatorData.social_no, driving_license: getTranslatorData.driving_license, own_vehicle: getTranslatorData.own_vehicle, is_certified: getTranslatorData.is_certified, police_verified: getTranslatorData.police_verified, origin_country: getTranslatorData.origin_country, verification_number: getTranslatorData.verification_number, zip_code:getuserData.zip_code, city:getuserData.city, lat:getuserData.lat, lng:getuserData.lng, transTypes:res.data.translationTypes, gender: getuserData.gender, about: getTranslatorData.about, worked_with_ministry: getTranslatorData.worked_with_ministry});
                    }, 100);
                });
            }
            setEditBasicInfoPopup({...EditBasicInfoPopup, show: mode});

        }

        function googleGetAddress(place:any){
            const {address, city, lat, lng, zip_code} =  getMapContent(place);
            setEditBasicInfo(prevState => ({...prevState, address: address, zip_code: zip_code, city: city, lat: lat, lng: lng}));
        }

        function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
            event.preventDefault();
            if($("#edit_basicinfo_form").valid()) {
                API.post(`profile/update-basic-info`, EditBasicInfo).then((res: AxiosResponse) => {
                    let TanslatorHeaderData = localStorage.getItem('TanslatorHeaderData');
                    let TheaderData = (TanslatorHeaderData!=null)?JSON.parse(TanslatorHeaderData):'';
                    var TranslatorHeaderData = {"profile_image" : TheaderData.profile_image, "name" : JSON.parse(res.config.data).name};
                    localStorage.setItem('TanslatorHeaderData',  JSON.stringify(TranslatorHeaderData));

                    successNotification(Lang.basic_info_success);
                    setEditBasicInfoPopup({...EditBasicInfoPopup, show: false});
                    props.refreshData();
                })
            }
        }

        function handleChange(event:React.ChangeEvent<any>){
            if(event.target.checked === true) {
                const checkValue = event.target.value;
                let transTypesObject = EditBasicInfo.transTypes;
                transTypesObject[checkValue].ischecked = 1;
                setEditBasicInfo({...EditBasicInfo, transTypes:transTypesObject});
            } else {
                const checkValue = event.target.value;
                let transTypesObject = EditBasicInfo.transTypes;
                transTypesObject[checkValue].ischecked = 0;
                setEditBasicInfo({...EditBasicInfo, transTypes:transTypesObject});
            }
        }

        const hideCPR = (cpr_number: any) => {
            let cpr_string = (cpr_number || '').toString();
            if (cpr_string.length > 6) {
                let shown_cpr = cpr_string.slice(0, 6);
                let hidden_cpr = '*'.repeat(cpr_string.length - 6);
                cpr_string = shown_cpr.concat(hidden_cpr);
            }
            return cpr_string;
        }
        
    return (
        <div id="profileTab1" className="tab-pane active">

            <div className="row">
                <div className="col-md-12">
                    <div className="edit-commen">
                        <button type="button" className="ico-btn" data-toggle="modal"
                                data-target="#makeComplaint" onClick={(e) => popup(e,true)}><i
                            className="fas fa-pencil-alt"/></button><span className={'editLabel'}>{Lang.edit}</span>
                        <Modal show={EditBasicInfoPopup.show} size="lg" className="customModal" aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <h1 className="title"><i className="far fa-edit"/> {Lang.updt_basic_info_title}</h1>
                                        <button type="button" className="close"
                                                data-dismiss="modal" onClick={(e) => popup(e,false)}>&times;</button>
                                    </Modal.Title>
                                </Modal.Header>
                            {EditBasicInfo.name == '' &&
                                <div className="dataLoad">{Lang.please_wait}</div>
                            }
                            {EditBasicInfo.name!='' &&
                                <Modal.Body>
                                    <FormLayout id="edit_basicinfo_form" onSubmit={handleSubmit}  autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">{Lang.name} <span className={'required'}>*</span></label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="name"
                                                           placeholder={Lang.name_placeholder} value={EditBasicInfo.name} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, name: e.target.value})}} name={'name'}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="address">{Lang.address} <span className={'required'}>*</span></label>
                                                    <GooglePlace defaultValue={EditBasicInfo.address} onChooseAddress={googleGetAddress} name={'address'} className="form-control" placeholder={Lang.address_placeholder} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="address">{Lang.zip_code}</label>
                                                    <input type="text" className="form-control input"
                                                           id="zip_code" name="zip_code" onChange={(e) => {setEditBasicInfo({...EditBasicInfo, zip_code: e.target.value})}} placeholder={Lang.zip_code_placeholder} value={EditBasicInfo.zip_code} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">{Lang.social_security_no} <span className="required">*</span></label>
                                                    <input id="social_no" name="social_no" type="text" className="form-control validate" value={EditBasicInfo.social_no} disabled={!_.isEmpty(EditBasicInfo.social_no)} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, social_no: e.target.value})}}
                                                           placeholder={Lang.social_placeholder} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">{Lang.driver_license} <span className="required">*</span></label>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" className="custom-control-input"
                                                                   id={'customRadio12'} name={'driving_license'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, driving_license: 1})}} checked={EditBasicInfo.driving_license===1} value={1}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customRadio12">{Lang.yes}</label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" className="custom-control-input"
                                                                   id={'customRadio13'} name={'driving_license'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, driving_license: 0})}} value={0} checked={EditBasicInfo.driving_license===0}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customRadio13">{Lang.no}</label>
                                                        </div>
                                                </div>
                                            </div>
                                            { EditBasicInfo.driving_license==1 &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="email">{Lang.vehicle} <span className="required">*</span></label>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" className="custom-control-input"
                                                                   id={'customRadio3'} name={'own_vehicle'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, own_vehicle: 1})}} checked={EditBasicInfo.own_vehicle===1} value={1}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customRadio3">{Lang.yes}</label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" className="custom-control-input"
                                                                   id={'customRadio4'} name={'own_vehicle'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, own_vehicle: 0})}} value={0} checked={EditBasicInfo.own_vehicle===0}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customRadio4">{Lang.no}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">{Lang.police_confirmed} <span className="required">*</span></label>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio7'} name={'police_verified'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, police_verified: 1})}} checked={EditBasicInfo.police_verified===1} value={1}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio7">{Lang.yes}</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio8'} name={'police_verified'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, police_verified: 0})}} value={0} checked={EditBasicInfo.police_verified===0}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio8">{Lang.no}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {EditBasicInfo.police_verified == 1 &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="email">{Lang.verification_number} <span className="required">*</span></label>
                                                        <input id="verification_number" name="verification_number" type="text" className="form-control validate" value={EditBasicInfo.verification_number} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, verification_number: e.target.value})}}
                                                               placeholder={Lang.verification_number_placeholder} />
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">{Lang.gender_title} <span className="required">*</span></label>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio14'} name={'gender'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, gender: 1})}} checked={EditBasicInfo.gender==1} value={1}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio14">{Lang.male}</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio15'} name={'gender'} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, gender: 2})}} value={2} checked={EditBasicInfo.gender==2}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio15">{Lang.female}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{Lang.country_of_origin} <span className={'required'}>*</span></label>
                                                    <Select2Wrapper value={EditBasicInfo.origin_country} onChange={(e) => {setEditBasicInfo({...EditBasicInfo, origin_country: e.target.value})}}
                                                        id={'origin_country'} name={'origin_country'}
                                                        data={{
                                                            placeholder: Lang.country_of_origin_placeholder,
                                                            data: originCountries,
                                                            templateResult: CountryFlags,
                                                            templateSelection: CountryFlags,
                                                        }}>
                                                        <option value={''}>{Lang.country_placeholder}</option>
                                                    </Select2Wrapper>
                                                </div>
                                            </div>

                                        </div>
                                        {/* Worked with ministry in the past field */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '1rem 0' }}>
                                            <label style={{marginBottom: 0, textAlign: 'start', fontSize: '.87rem'}}><span style={{ fontWeight: 'bolder' }}>Har du erfaring med at tolke for Udlændinge- og Integrationsministeriet </span><i>(herunder, Flygtningenævnet, Hjemrejsestyrelsen, Udlændingestyrelsen, SIRI og Udlændingenævnet)</i> ?</label>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="worked_with_ministry" id="worked_with_ministry_true" value="true" checked={EditBasicInfo.worked_with_ministry} onChange={() => setEditBasicInfo({...EditBasicInfo, worked_with_ministry: true })} />
                                                    <label className="form-check-label" htmlFor="worked_with_ministry_true">{Lang.yes}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="worked_with_ministry" id="worked_with_ministry_false" value="false" checked={!EditBasicInfo.worked_with_ministry} onChange={() => setEditBasicInfo({ ...EditBasicInfo, worked_with_ministry: false })} />
                                                    <label className="form-check-label" htmlFor="worked_with_ministry_false">{Lang.no}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 btnSet text-center">
                                            <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.submit}/>
                                        </div>
                                    </FormLayout>

                                </Modal.Body>
                            }
                                <Modal.Footer>
                                </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </div>
            <ul className="infoList basic_information row">
                { !_.isEmpty(props.translator_data.zip_code) &&
                    <li className='col-md-4 col-sm-6'>
                        <span className="label"><i className="fas fa-envelope-open-text colorPink"/>{Lang.zip_code}</span>
                        <div className="dataSet">{props.translator_data.zip_code}</div>
                    </li>
                }
                <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className="fas fa-shield-alt colorPink"/>{Lang.social_security_no}</span>
                    <div className="dataSet">{hideCPR(props.translator_data.social_no)}</div>
                </li>
                <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className="fas fa-id-card colorPink"/>{Lang.driver_license}</span>
                    <div className="dataSet">{props.translator_data.driving_license===0?'No':'Yes'}</div>
                </li>
                { props.translator_data.police_verified===1 &&
                    <li className='col-md-4 col-sm-6'>
                        <span className="label"><i className="fas fa-user-check colorYellow"/>{Lang.verification_number}</span>
                        <div className="dataSet">{props.translator_data.verification_number}</div>
                    </li>
                }
                { props.translator_data.driving_license===1 &&
                    <li className='col-md-4 col-sm-6'>
                        <span className="label"><i className="fas fa-car-alt colorYellow"/>{Lang.vehicle}</span>
                        <div className="dataSet">{props.translator_data.own_vehicle===0?'No':'Yes'}</div>
                    </li>
                }
                <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className="fas fa-male colorYellow"/>{Lang.police_confirmed}</span>
                    <div className="dataSet">{props.translator_data.police_verified===0?'No':'Yes'} </div>
                </li>
                <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className="fas fa-flag colorBlue"/>{Lang.country_of_origin}</span>
                    <div className="dataSet">{props.translator_data.country_name}</div>
                </li>
                {/* Gender */}
                <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className={`fa ${props.user_data.gender === '1'? 'fa-male colorBlue' : 'fa-female colorPink'}`}/>{Lang.gender_title}</span>
                    <div className="dataSet">{props.user_data.gender === '1'? Lang.male : Lang.female}</div>
                </li>
            </ul>

        </div>

    )
}
import React, { useEffect, useState } from "react";
import _, { isEmpty } from "lodash";
import { RouteComponentProps } from "react-router";
import { Auth, getUserInfo } from "../../Auth/Auth";
import { TBasicInfo } from "./TBasicInfo";
import { TBankInfo } from "./TBankInfo";
import { TChangePassword } from "./ChangePassword";
import { TLeaveInfo } from "./TLeaveInfo";
import Cropper from "cropperjs";
import API, { setFormSubmition } from "../../common/AxoisClient";
import { TContractDisplay } from "./TContractDisplay";
import { AxiosResponse } from "axios";
import {
  EditEmailState,
  EditPhoneFormState,
  GetLanguagesState,
  OTPExpiryState,
  TContractDisplayState,
  TempEmailState,
  TimerCountState,
} from "./State";
import {
  BankInfoFormState,
  RegisterFormState,
  SignTranslationState,
  ViewBasicInfoFormState,
} from "../signup/State";
import Lang from "../../common/lang/Lang";
import { Footer, Header } from "../../partial";
import { TranslatorService } from "./TranslatorService";
import { ErrorNotification, showNotification, successNotification } from "../../common/helpers";
import { TDocumentUpload } from "./TDocumentUpload";
import { TAddLanguage } from "./TAddLanguage";
import { LanguageGetFormSchema } from "./Schema";
import { EditEmailValidations, EditMobileNoValidations } from "./Validations";
import { TCropProfileImage } from "./TCropProfileImage";
import { CountryFlags, Select2Wrapper } from "../../common/Select2";
import { getCountryList } from "../../services";
import { FormLayout, Icons, Submit } from "../../common";
import { Link } from "react-router-dom";
import { RegisterFormSchema, ViewBasicInfoFormSchema } from "../signup/Schema";
import style from "./TranslatorProfile.module.scss";
declare let window: any;
var cropper: any;

export function TranslatorProfile(props: RouteComponentProps) {
  const [transBasicInfo, setTransBasicInfo] = useState(ViewBasicInfoFormState);
  const [transBankInfo, setTransBankInfo] = useState(BankInfoFormState);
  const [transContractData, setTransContractData] = useState(TContractDisplayState);
  const [UserInfo, setUserInfo] = useState(RegisterFormState);
  const [SignTranslation, setSignTranslation] = useState(SignTranslationState);
  const [transLanguage, setTransLanguage] = useState(GetLanguagesState);
  const [EditEmail, setEditEmail] = useState(EditEmailState);
  const [country, setCountry] = useState();
  const [editMobileNo, setEditMobileNo] = useState(EditPhoneFormState);
  const [timerCountDown, setTimerCountDown] = useState(TimerCountState);
  const [OTPExpiryTime, setOTPExpiryTime] = useState(OTPExpiryState);
  const [tempEmailData, setTempEmailData] = useState(TempEmailState);
  const [profilePopupMsg, setProfilePopupMsg] = useState(0);
  const [feedback, setFeedback] = useState({
    averageRating: 0,
    noOfReviews: 0,
  });

  const TabsItems = [
    {
      label: Lang.basic_info_page_title,
      class: "fas fa-user-edit",
      aclass: "nav-link active",
      tabID: "#profileTab1",
    },
    {
      label: Lang.bank_info_page_title,
      class: "fas fa-university",
      aclass: "nav-link",
      tabID: "#profileTab2",
    },
    {
      label: Lang.availability_page_title,
      class: "fas fa-user-cog",
      aclass: "nav-link",
      tabID: "#profileTab3",
    },
    {
      label: Lang.change_password,
      class: "fas fa-unlock-alt",
      aclass: "nav-link",
      tabID: "#profileTab4",
    },
  ];

  useEffect(() => {
    document.title = Lang.profile + " - " + Lang.vitolker_translator;
    getProfileData();
    EditEmailValidations();
    EditMobileNoValidations();
  }, []);

  function getProfileData() {
    API.get(`translator-data/${Auth.userInfo.id}`).then((res: AxiosResponse) => {
      getCountryList().then((country) => {
        setCountry(country);
      });
      const getTranslatorData = res.data.translatorData;
      const getuserData: any = res.data.userData;
      const getTranslationType: any = res.data.translationTypes;
      const gettranslatorLanguage: any = res.data.translatorLanguage;
      const getTempEmailData = res.data.tempEmailData;
      setFeedback({
        ...feedback,
        averageRating: res.data.calculateAverageRating,
        noOfReviews: res.data.calculateReview,
      });
      getTranslatorData.send_contract === 1
        ? setTransContractData({
            ...transContractData,
            contractDisplay: true,
            keyboard: false,
            backdrop: "static",
            translatorContractContent: res.data.translatorContract.content,
            registrationStep: getTranslatorData.registration_step,
          })
        : setTransContractData({
            ...transContractData,
            contractDisplay: false,
            keyboard: true,
            registrationStep: getTranslatorData.registration_step,
            backdrop: "",
          });

      setTransBasicInfo({
        ...transBasicInfo,
        social_no: getTranslatorData.social_no,
        driving_license: getTranslatorData.driving_license,
        verification_number: getTranslatorData.verification_number,
        own_vehicle: getTranslatorData.own_vehicle,
        is_certified: getTranslatorData.is_certified,
        police_verified: getTranslatorData.police_verified,
        push_agreement: getTranslatorData.push_agreement,
        zip_code: getuserData.zip_code,
        country_name: getTranslatorData.country.name,
        translation_types: getTranslationType,
        resume: getTranslatorData.resume,
        criminal_record: getTranslatorData.criminal_record,
        children_certificate: getTranslatorData.children_certificate,
      });

      setTransBankInfo({
        ...transBankInfo,
        bank_registration_no: getTranslatorData.bank_registration_no,
        account_no: getTranslatorData.account_no,
        iban: getTranslatorData.iban,
        swift: getTranslatorData.swift,
        bank_name: getTranslatorData.bank_name,
        account_holder_name: getTranslatorData.account_holder_name,
      });

      setUserInfo({
        ...UserInfo,
        name: getuserData.name,
        email: getuserData.email,
        address: getuserData.address,
        country_code: getuserData.country_code,
        mobile_no: getuserData.mobile_no,
        gender: getuserData.gender,
        profile_image: getuserData.profile_image,
        profile_image_small: getuserData.profile_image_small,
        profile_image_medium: getuserData.profile_image_medium,
        default_img_placeholder: getuserData.default_img_placeholder,
        email_verified_at: getuserData.email_verified_at,
      });

      setEditEmail({ ...EditEmail, email: getuserData.email });
      setEditMobileNo({
        ...editMobileNo,
        country_code: getuserData.country_code,
        mobile_no: getuserData.mobile_no,
      });

      setTransLanguage({
        ...transLanguage,
        transLanguageData: gettranslatorLanguage,
      });

      setSignTranslation({
        ...SignTranslation,
        is_sign_lang_translation: getTranslatorData.is_sign_lang_translation,
        total_hours: res.data.totalHours,
        total_compelete_jobs: res.data.totalCompleteJobs,
        tempEmailExists: res.data.tempEmailExists,
      });

      if (!_.isEmpty(getTempEmailData[0])) {
        setTempEmailData({
          ...tempEmailData,
          user_id: getTempEmailData[0].user_id,
          email: getTempEmailData[0].email,
          status: getTempEmailData[0].status,
        });
      }
      ($('[data-toggle="tooltip"]') as any).tooltip();
      setTimeout(function () {
        let mainObj: any = $(".translator_average_rating");
        if (mainObj.length > 0) {
          mainObj.each(function (index: any, item: any) {
            let obj: any = $(item);
            let objRating = obj.attr("data-rating");
            objRating = objRating != "null" ? objRating : 0;
            obj.rateYo({
              starWidth: "20px",
              spacing: "4px",
              rating: res.data.calculateAverageRating[0].translatorRating,
              readOnly: true,
            });
          });
        }
      }, 100);
    });
  }

  const uploadProfilePic = async (
    e: React.ChangeEvent<HTMLElement>,
    callback?: (doc: string) => void
  ) => {
    $("#updateProfilePictureOfUser").show();
    $("#ProfilePicturePleaseWait").hide();
    setProfilePopupMsg(0);
    setFormSubmition(true);
    const target: any = e.target;

    var fileData: any = target.files[0];
    var reader: any = new FileReader();
    if (fileData && fileData.type && fileData.type.startsWith("image/")) {
      ($("#editTProfileImagePopup") as any).modal("show");
      $("#uploadedImage1").css({ opacity: "0" });
      reader.addEventListener(
        "load",
        function () {
          $("#uploadedImage1").attr("src", reader.result);
          setTimeout(function () {
            $("#uploadedImage1").css({ opacity: "1" });
            const image: any = document.getElementById("uploadedImage1");
            if (!_.isEmpty(cropper)) {
              cropper.destroy();
            }
            image.src = reader.result;
            cropper = new Cropper(image, {
              aspectRatio: 50 / 50,
              crop(event) {},
            });
          }, 500);
        },
        false
      );
      if (fileData) {
        reader.readAsDataURL(fileData);
      }
    }

    $("body").on("click", "#updateProfilePictureOfUser", function () {
      $("#updateProfilePictureOfUser").hide();
      $("#ProfilePicturePleaseWait").show();
      let imgData = cropper.getCroppedCanvas().toDataURL("image/png");
      let formData = new FormData();
      formData.append("profile_image", imgData);
      formData.append("document_name", "profile_image");
      if (profilePopupMsg === 0) {
        API.post("document-info", formData).then((res) => {
          if (res.data.type === "profile_image") {
            API.post(`profile/update-profile-image`, {
              filename: res.data.filename,
              user_id: Auth.userInfo.id,
            }).then((response: AxiosResponse) => {
              setProfilePopupMsg(1);
              showNotification("success", Lang.profile_image_success);
              getProfileData();
              var TranslatorHeaderData = {
                profile_image: response.data.userData.profile_image,
                profile_image_small: response.data.userData.profile_image_small,
                name: response.data.userData.name,
                uniqueid: res.data.uniqid,
              };
              localStorage.setItem("TanslatorHeaderData", JSON.stringify(TranslatorHeaderData));
              ($("#editTProfileImagePopup") as any).modal("hide");
            });
          }
        });
      }
    });
  };

  function handleChange(event: React.ChangeEvent<any>) {
    const checkValue = event.target.value;
    setSignTranslation({
      ...SignTranslation,
      is_sign_lang_translation: checkValue,
    });
    API.post(`profile/update-sign-translation`, {
      is_sign_lang_translation: checkValue,
    }).then((res: AxiosResponse) => {
      successNotification(Lang.translation_service_update);
    });
  }

  function handleEditEmailSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    if ($("#edit_email_form").valid()) {
      API.post(`profile/edit-email`, EditEmail).then((res: AxiosResponse) => {
        if (res.data.status === true) {
          successNotification(Lang.verifying_email_address);
          getProfileData();
          setEditEmail(EditEmailState);
          $("#email").val("");
          ($("#edit-email") as any).modal("hide");
        } else if (res.data.status === false) {
          ErrorNotification(Lang.already_email_change_req);
        }
      });
    }
  }

  function startTimer(duration: any) {
    var start = Date.now(),
      diff,
      minutes,
      seconds;
    function timer() {
      diff = duration - (((Date.now() - start) / 1000) | 0);
      if (diff == 0) {
        setTimerCountDown({ ...timerCountDown, timer_count: "155" });
        clearInterval(refreshIntervalId);
        return;
      }
      minutes = (diff / 60) | 0;
      seconds = diff % 60 | 0;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setTimerCountDown({
        ...timerCountDown,
        timer_count: minutes + ":" + seconds,
      });
      if (diff <= 0) {
        start = Date.now() + 1000;
      }
    }
    var refreshIntervalId = setInterval(timer, 1000);
  }

  function sendOTP(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    let startDate = new Date();
    let letterNumber = /^[0-9]+$/;
    if (
      editMobileNo.mobile_no.length > 7 &&
      editMobileNo.mobile_no.match(letterNumber) &&
      editMobileNo.country_code != "" &&
      $("#edit_mobile_no").valid()
    ) {
      API.post(`profile/edit-mobile-send-otp`, editMobileNo).then((res: AxiosResponse) => {
        setEditMobileNo({
          ...editMobileNo,
          otp: res.data.translator_phone_number_otp_enc,
          send_otp_text: "Resend OTP",
          translator_phone_number: res.data.translatorPhoneNumber,
        });
        setTimerCountDown({ ...timerCountDown, timer_count: "01:00" });
        setOTPExpiryTime({ ...OTPExpiryTime, start_time: startDate });
        successNotification(Lang.otp_sent_msg);
        startTimer(60 * 1);
      });
    }
  }

  function handleEditMobileNoChange(event: React.ChangeEvent<any>) {
    const checkValue = event.target.value;
    setEditMobileNo({ ...editMobileNo, country_code: checkValue });
  }

  function checkOTPEditMobile(event: any, type: string) {
    let get_start_time = OTPExpiryTime.start_time;
    let get_end_time = new Date();
    var seconds = (get_end_time.getTime() - get_start_time.getTime()) / 1000;
    if (seconds < 300) {
      API.post(`profile/edit-mobile-check-otp`, editMobileNo).then((res: AxiosResponse) => {
        if (type === "check_otp" && res.data.verified_phone_number === "0") {
          ErrorNotification("Invalid OTP");
        } else if (type === "check_otp" && res.data.verified_phone_number === "1") {
          getProfileData();
          setEditMobileNo(EditPhoneFormState);
          ($("#edit-mobile-no") as any).modal("hide");
        }
      });
    } else {
      ErrorNotification(Lang.otp_expire);
    }
  }

  const user: any = getUserInfo();
  return (
    <div className="bgMain">
      <div className="wrapper">
        <Header {...props} />
        <section className="mainContent">
          {/* Application under review banner */}
          {user.status !== 1 && !transContractData.contractDisplay && (
            <ApplicationUnderReviewBanner />
          )}
          {/* Contract banner */}
          {transContractData.contractDisplay && <ContractBanner />}
          {/* GTEP test banner */}
          {localStorage.getItem("send_online_test") === "1" && <OnlineTestBanner />}
          {/* Missing info warning banner */}
          <MissingInfoBanner trans_basic_info={transBasicInfo} user_basic_info={UserInfo} />
          {/* start of content */}
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="boxWhite heightProfile profileBoxInfo boxShadow">
                  <div className="profile_box">
                    <div className="text-center">
                      <div
                        className="imgbox"
                        style={{
                          backgroundColor: UserInfo.profile_image_medium.includes(
                            "user-placeholder"
                          )
                            ? "#EA868F60"
                            : undefined,
                        }}
                      >
                        <img
                          src={
                            UserInfo.profile_image_medium.includes("user-placeholder")
                              ? UserInfo.default_img_placeholder
                              : UserInfo.profile_image_medium
                          }
                          alt="vt-img"
                          id="current_profile_pic"
                        />
                        <div
                          className="overlay_img"
                          style={{
                            backgroundColor: UserInfo.profile_image_medium.includes(
                              "user-placeholder"
                            )
                              ? "#EA868F60"
                              : undefined,
                          }}
                        >
                          <div className="input-file-container">
                            <input
                              accept="image/*"
                              onChange={(e) => uploadProfilePic(e)}
                              className="input-file-trigger"
                              type="file"
                              name="profile_image"
                              id="profile_pic"
                            />
                            <label
                              id="change_avatar"
                              htmlFor="profile_pic"
                              className="input-file-trigger"
                            >
                              <span>
                                {!_.isUndefined(UserInfo.profile_image_medium) &&
                                  UserInfo.profile_image_medium.indexOf("user-placeholder") ===
                                    -1 && <i className="fas fa-pencil-alt" aria-hidden="true" />}
                                {!_.isUndefined(UserInfo.profile_image_medium) &&
                                  UserInfo.profile_image_medium.indexOf("user-placeholder") >
                                    -1 && <i className="fa fa-camera" aria-hidden="true" />}
                              </span>
                            </label>
                          </div>
                          <p className="file-return" />
                        </div>
                      </div>
                      <div className="namebox">
                        <span>{UserInfo.name}</span>
                        <span>
                          {feedback.averageRating == 0 ? "--" : feedback.averageRating}{" "}
                          <i className="fa fa-star" style={{ color: "green", fontSize: "20px" }} />
                        </span>
                        <br />
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          {feedback.noOfReviews} {Lang.review_title}
                        </span>
                        {/*<div className={'rating translator_average_rating'} />*/}
                      </div>
                    </div>
                  </div>

                  <ul className="infoList">
                    <li className={"editMore"}>
                      <span className="label">
                        <i className="far fa-envelope colorPink" /> {Lang.email_address}
                      </span>
                      <div className="dataSet">
                        <p className={"dataSetEmail"}>
                          {SignTranslation.tempEmailExists === 0
                            ? UserInfo.email
                            : tempEmailData.email}
                        </p>
                        <div className="edit-commen">
                          <button
                            type="button"
                            className="ico-btn"
                            data-toggle="modal"
                            data-target="#edit-email"
                          >
                            <i className="fas fa-pencil-alt" />
                          </button>
                          <span className={"editLabel"}>{Lang.edit}</span>
                        </div>
                        {SignTranslation.tempEmailExists > 0 && (
                          <span className={"badge badge-warning"}>{Lang.unverified}</span>
                        )}
                        {UserInfo.email_verified_at == null && (
                          <span className={"badge badge-warning"}>{Lang.unverified}</span>
                        )}
                      </div>
                    </li>
                    <li className={"editMore"}>
                      <span className="label">
                        <i className="fas fa-phone-volume colorBlue" /> {Lang.mobile}
                      </span>
                      <div className="dataSet">
                        <p className={"dataSetEmail"}>
                          (+{UserInfo.country_code}) {UserInfo.mobile_no}
                        </p>
                        <div className="edit-commen">
                          <button
                            type="button"
                            className="ico-btn"
                            data-toggle="modal"
                            data-target="#edit-mobile-no"
                          >
                            <i className="fas fa-pencil-alt" />
                          </button>
                          <span className={"editLabel"}>{Lang.edit}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <span className="label">
                        <i className="fas fa-map-marker-alt colorYellow" /> {Lang.current_location}
                      </span>
                      <div className="dataSet">
                        <p className={"dataSetEmail"}>{UserInfo.address}</p>
                      </div>
                    </li>
                  </ul>
                  <ul className="hrs">
                    <li>
                      <span className="label">
                        <i className="far fa-clock" /> {SignTranslation.total_hours} {Lang.hrs}
                      </span>
                    </li>
                    <li>
                      <span className="label">
                        <i className="far fa-check-circle" /> {SignTranslation.total_compelete_jobs}{" "}
                        {Lang.vt_trans_lang_78_job_completed}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="boxWhite heightProfile boxShadow">
                  <div className="profileContent">
                    <ul className="nav nav-tabs">
                      {TabsItems.map((item, index: number) => {
                        const bankWithNoSwiftOrIban =
                          item.label === Lang.bank_info_page_title &&
                          (isEmpty(transBankInfo.swift) || isEmpty(transBankInfo.iban));
                        return (
                          <li
                            className={`nav-item ${
                              bankWithNoSwiftOrIban ? style["title-pulse"] : ""
                            }`}
                            key={index}
                          >
                            <a className={item.aclass} data-toggle="tab" href={item.tabID}>
                              <i className={item.class} /> <span>{item.label}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="tab-content">
                      <TBasicInfo
                        refreshData={() => {
                          getProfileData();
                        }}
                        translator_data={transBasicInfo}
                        user_data={UserInfo}
                      />
                      <TBankInfo
                        refreshData={() => {
                          getProfileData();
                        }}
                        translator_bank_info={transBankInfo}
                      />
                      <TLeaveInfo />
                      <TChangePassword />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal customModal fade" id="edit-email">
              <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="title m">
                      <i className="fas fa-pencil-alt" />
                      {Lang.edit} {Lang.email}
                    </h1>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <FormLayout
                      id="edit_email_form"
                      onSubmit={handleEditEmailSubmit}
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>{Lang.email}</label>
                            <input
                              id="email"
                              type="text"
                              placeholder={Lang.email_placeholder}
                              className="form-control"
                              name="email"
                              onChange={(e) => {
                                setEditEmail({
                                  ...EditEmail,
                                  email: e.target.value,
                                });
                              }}
                              value={EditEmail.email}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 btnSet text-center">
                        <Submit
                          className={"btn btn-lg btn-green hvr-rectangle-out"}
                          label={Lang.submit}
                        />
                      </div>
                    </FormLayout>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal customModal fade" id="edit-mobile-no">
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="title m">
                      <i className="fas fa-key" /> {Lang.edit_phone_number_title}
                    </h1>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="edit_mobile_no" onSubmit={sendOTP} autoComplete="off">
                      <div className="row form-group">
                        <div className="col-md-4">
                          <Select2Wrapper
                            value={editMobileNo.country_code}
                            onChange={handleEditMobileNoChange}
                            id={"country_code"}
                            name={"country_code"}
                            data={{
                              placeholder: Lang.country_placeholder,
                              data: country,
                              templateResult: CountryFlags,
                              templateSelection: CountryFlags,
                            }}
                          >
                            <option value={""}>{Lang.country_placeholder}</option>
                          </Select2Wrapper>
                        </div>
                        <div className="col-md-8">
                          <input
                            id="mobile_no"
                            type="text"
                            placeholder={Lang.mobile_placeholder}
                            className="phone_no form-control"
                            name="mobile_no"
                            value={editMobileNo.mobile_no}
                            onChange={(e) => {
                              setEditMobileNo({
                                ...editMobileNo,
                                mobile_no: e.target.value,
                              });
                            }}
                          />

                          {editMobileNo.send_otp_text === "Send OTP" &&
                            (UserInfo.country_code !== editMobileNo.country_code ||
                              UserInfo.mobile_no !== editMobileNo.mobile_no) && (
                              <button
                                type={"submit"}
                                className="btn btn-secondary senOtpBtn"
                                id="VerifyOtpBtn"
                              >
                                {editMobileNo.send_otp_text}
                              </button>
                            )}
                          {editMobileNo.send_otp_text === "Resend OTP" &&
                            timerCountDown.timer_count === "155" && (
                              <button
                                type={"submit"}
                                className="btn btn-secondary senOtpBtn"
                                id="VerifyOtpBtn"
                              >
                                {editMobileNo.send_otp_text}{" "}
                              </button>
                            )}
                          {editMobileNo.send_otp_text === "Resend OTP" &&
                            timerCountDown.timer_count !== "155" && (
                              <button
                                type={"button"}
                                className="btn btn-secondary senOtpBtn"
                                id="VerifyOtpBtn"
                              >
                                {timerCountDown.timer_count}
                              </button>
                            )}
                        </div>
                      </div>
                    </form>
                    {editMobileNo.send_otp_text === "Resend OTP" && (
                      <>
                        <div className="row form-group">
                          <div className="col-md-12">
                            <label className={"labelDefault"}>{Lang.enter_otp_number}</label>
                            <input
                              id="translator_otp"
                              type="text"
                              placeholder="Enter OTP"
                              className="phone_no form-control"
                              name="translator_otp"
                              onChange={(e) => {
                                setEditMobileNo({
                                  ...editMobileNo,
                                  translator_otp: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        {editMobileNo.translator_otp !== "" && (
                          <div className="mt-4 btnSet text-center">
                            <button
                              type={"button"}
                              onClick={(e) => checkOTPEditMobile(e, "check_otp")}
                              className="btn btn-green btn-xl hvr-rectangle-out"
                            >
                              {Lang.submit}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="statisticsPage mt-50">
              <div className="row align-items-center mobiCenter mb-22">
                <div className=" col-sm-12">
                  <h1 className="title">{Lang.vt_trans_lang_76_title_onprofile}</h1>
                </div>
              </div>
              <div className="boxWhite boxShadow chartPieJet">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <h2 className="title mb-22">
                          <img
                            src={window.PUBLIC_URL + "theme/images/translationIco.png"}
                            alt="vt-img"
                            title=""
                            width="25"
                          />{" "}
                          {Lang.vt_trans_lang_75_service_title}
                        </h2>
                        <TranslatorService translator_data={transBasicInfo} />
                      </div>
                      {/*<div className="col-xl-4 col-lg-12">
                                            <div className="interpreterBox customRadioGroup signInterpreterLang">
                                                <h2 className="title mb-22">{Lang.do_you_sign_translation}</h2>
                                                <div className="radioCustom">
                                                    <input type="radio" className="custom-control-radio"
                                                           id={'translationColumnYes'} name={'is_sign_lang_translation'} onChange={handleChange} checked={SignTranslation.is_sign_lang_translation==1} value={1}/>
                                                    <label className="custom-control-radio2"
                                                           htmlFor="translationColumnYes">
                                                        <span className="interpreterYes"/>
                                                        <span>{Lang.yes} </span>
                                                        <i className="iconCheck fas fa-check-circle"/>
                                                    </label>
                                                </div>
                                                <div className="radioCustom">
                                                    <input type="radio" className="custom-control-radio"
                                                           id={'translationColumnNo'} name={'is_sign_lang_translation'} onChange={handleChange} checked={SignTranslation.is_sign_lang_translation==0} value={0}/>
                                                    <label className="custom-control-radio2"
                                                           htmlFor="translationColumnNo">
                                                        <span className="interpreterNo"/>
                                                        <span>{Lang.no}</span>
                                                        <i className="iconCheck fas fa-check-circle"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>*/}
                    </div>

                    <div className="row">
                      {transLanguage.transLanguageData &&
                        transLanguage.transLanguageData.map(
                          (item: LanguageGetFormSchema, index: number) => {
                            return (
                              <div className="col-md-12 col-lg-6" key={index}>
                                <div
                                  className={
                                    item.status === 2 || item.status === 3
                                      ? "dashbox addlist unverfiedBox"
                                      : "dashbox addlist"
                                  }
                                >
                                  <h3>{Lang.language}</h3>
                                  <div className="invitation basicin">
                                    <div className={"row"}>
                                      <div className={"col-lg-10 col-sm-12"}>
                                        <div className="colData basic">
                                          <div className={"row align-items-center"}>
                                            <div className={"col-5 col-sm-5 col-xl-5"}>
                                              <label className={"labelDesign"}>
                                                {Lang.from}{" "}
                                                {item.mother_tongue == "1" ? (
                                                  <span
                                                    className={"motherTongu"}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={Lang.mother_tongue}
                                                  >
                                                    <img src="theme/images/motherTongue.png" />
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </label>
                                              <p className="lang">
                                                <span className="img-cricle">
                                                  {item.from_language.countries !== undefined && (
                                                    <img
                                                      src={
                                                        item.from_language.countries.iso !==
                                                        undefined
                                                          ? window.PUBLIC_URL +
                                                            "/flags/" +
                                                            item.from_language.countries.iso.toLowerCase() +
                                                            ".png"
                                                          : ""
                                                      }
                                                    />
                                                  )}
                                                </span>

                                                {item.from_language.language_name}
                                                {!_.isEmpty(item.from_accent) && (
                                                  <b>[{item.from_accent}]</b>
                                                )}
                                              </p>
                                            </div>
                                            <div className={"col-1 col-sm-2 col-xl-2"}>
                                              <span className="exchangeIcon">
                                                <i className="fas fa-exchange-alt" />
                                              </span>
                                            </div>
                                            <div className={"col-5 col-sm-5 col-xl-5"}>
                                              <label className={"labelDesign"}>
                                                {Lang.to}{" "}
                                                {item.mother_tongue == "2" ? (
                                                  <span
                                                    className={"motherTongu"}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={Lang.mother_tongue}
                                                  >
                                                    <img src="theme/images/motherTongue.png" />
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </label>

                                              <p className="lang">
                                                <span className="img-cricle">
                                                  {item &&
                                                    item.to_language &&
                                                    item.to_language.countries &&
                                                    item.to_language.countries !== undefined && (
                                                      <img
                                                        src={
                                                          item.to_language.countries.iso !==
                                                          undefined
                                                            ? window.PUBLIC_URL +
                                                              "/flags/" +
                                                              item.to_language.countries.iso.toLowerCase() +
                                                              ".png"
                                                            : ""
                                                        }
                                                      />
                                                    )}
                                                </span>{" "}
                                                {item.to_language.language_name}
                                                {!_.isEmpty(item.from_accent) && (
                                                  <b>[{item.to_accent}]</b>
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={"col-sm-12 mt-3"}>
                                        <div className={"row"}>
                                          {item.how_much_hours > 0 && (
                                            <div className={"col-sm-4"}>
                                              <div className="right">
                                                <label>
                                                  <i className="far fa-clock" />{" "}
                                                  {item.how_much_hours} {Lang.working_hours}
                                                </label>
                                              </div>
                                            </div>
                                          )}
                                          {item.is_certificate === 1 && (
                                            <>
                                              <div className={"col-sm-4"}>
                                                <label>
                                                  <i className="fas fa-award" /> {Lang.level}{" "}
                                                  {item.level}
                                                </label>
                                              </div>
                                              <div className={"col-sm-4"}>
                                                <div className="left">
                                                  <label>
                                                    <i className="far fa-file-alt" />{" "}
                                                    <a href={item.certificate} target={"_blank"}>
                                                      {" "}
                                                      {Lang.certificate}
                                                    </a>
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {item.status === 2 && (
                                        <div className={"StatusLang"}>
                                          <span className={"badge badge-warning unverified"}>
                                            {Lang.unverified}
                                          </span>
                                        </div>
                                      )}
                                      {item.status === 3 && (
                                        <div className={"StatusLang"}>
                                          <span
                                            className={"badge badge-danger unverified langRejected"}
                                          >
                                            {Lang.rejected}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>

                <div className=" mt-50">
                  <div className="row align-items-center mb-22">
                    <div className=" col-sm-12">
                      <h1 className="title "> {Lang.vt_trans_lang_74_browse_tit}</h1>
                    </div>
                  </div>
                </div>
                {/* The three document view/upload */}
                <TDocumentUpload />
              </div>
            </div>
          </div>
        </section>
        <TContractDisplay
          show={transContractData.contractDisplay}
          onHide={() =>
            setTransContractData({
              ...transContractData,
              contractDisplay: false,
            })
          }
          translator_contract={transContractData}
          backdrop="static"
          keyboard={false}
        />

        <Footer />

        <TCropProfileImage />
        {UserInfo.address == "" && (
          <>
            <div className="bg_load" />
            <div className="wrap" />
          </>
        )}
      </div>
    </div>
  );
}

interface MissingInfoProps {
  trans_basic_info: ViewBasicInfoFormSchema;
  user_basic_info: RegisterFormSchema;
}

const MissingInfoBanner = (props: MissingInfoProps) => {
  const { trans_basic_info, user_basic_info } = props;
  const {
    driving_license,
    own_vehicle,
    police_verified,
    verification_number,
    resume,
    criminal_record,
  } = trans_basic_info;
  const { profile_image } = user_basic_info;
  const [missing, success] = ["fas fa-times", "fas fa-check"];
  // We only need to check for the CV, criminal record and profile picture now
  const all_missing = !resume && !criminal_record && profile_image.includes("placeholder");
  // const all_missing = !driving_license && !police_verified && !resume && !criminal_record && profile_image.includes('placeholder');
  const all_present = criminal_record && resume && !profile_image.includes("placeholder");
  // const all_present = driving_license && ( driving_license !== 1 || own_vehicle ) && police_verified && ( police_verified !== 1 || verification_number ) && resume && !profile_image.includes('placeholder');
  return !all_present ? (
    <div className="container">
      <div
        className={`row danger_banner ${
          all_present ? "success_bg" : all_missing ? "empty_bg" : "missing_bg"
        }`}
      >
        <h3 className="missing_title">
          <i className="fas fa-user" />
          &ensp;Din profil er næsten færdig
        </h3>
        <div className="missing_docs">
          <div>
            <h5>Profiloplysninger</h5>
            <ul>
              {/* Profile Picture */}
              <li
                className={!profile_image.includes("placeholder") ? "success_info" : "missing_info"}
              >
                <i className={!profile_image.includes("placeholder") ? success : missing} />
                &nbsp;
                {Lang.profile_pic}
              </li>
              {/* Driving License */}
              {/* <li className={driving_license ? 'success_info' : 'missing_info'}>
                <i className={driving_license ? success : missing} />&nbsp;
                Kørekort
              </li> */}
              {/* Own Vehicle */}
              {/* {
                driving_license === 1 && (
                  <li className={own_vehicle ? 'success_info' : 'missing_info'}>
                    <i className={own_vehicle ? success : missing} />&nbsp;
                    Køretøj
                  </li>
                )
              } */}
              {/* Police Verified */}
              {/* <li className={police_verified ? 'success_info' : 'missing_info'}>
                <i className={police_verified ? success : missing} />&nbsp;
                Rigspolitiets tolkeliste
              </li> */}
              {/* Verification Number */}
              {/* {
                police_verified === 1 && (
                  <li className={verification_number ? 'success_info' : 'missing_info'}>
                    <i className={verification_number ? success : missing} />&nbsp;
                    Det nationale politiets tolke liste nummer
                  </li>
                )
              } */}
            </ul>
          </div>
          <div>
            <h5>Dokumenter</h5>
            <ul>
              {/* Resume */}
              <li className={resume ? "success_info" : "missing_info"}>
                <i className={resume ? success : missing} />
                &nbsp; CV
              </li>
              {/* Criminal Record */}
              <li className={criminal_record ? "success_info" : "missing_info"}>
                <i className={criminal_record ? success : missing} />
                &nbsp; Straffeattest
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const ApplicationUnderReviewBanner = () => {
  return (
    <>
      <div className="container underReview">
        <div className="boxWhite boxShadow mb-5">
          <div className="row">
            <div className="col-width1">
              <svg
                style={{
                  margin: "auto",
                  background: "#fff",
                  display: "block",
                  float: "left",
                }}
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 100 100"
                height="auto"
                width="54px"
              >
                <defs>
                  <clipPath id="ldio-x4hvcro74bn-cp">
                    <rect x="0" y="0" width="100" height="50">
                      <animate
                        attributeName="y"
                        repeatCount="indefinite"
                        dur="2.564102564102564s"
                        calcMode="spline"
                        values="0;50;0;0;0"
                        keyTimes="0;0.4;0.5;0.9;1"
                        keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                      ></animate>
                      <animate
                        attributeName="height"
                        repeatCount="indefinite"
                        dur="2.564102564102564s"
                        calcMode="spline"
                        values="50;0;0;50;50"
                        keyTimes="0;0.4;0.5;0.9;1"
                        keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                      ></animate>
                    </rect>
                    <rect x="0" y="50" width="100" height="50">
                      <animate
                        attributeName="y"
                        repeatCount="indefinite"
                        dur="2.564102564102564s"
                        calcMode="spline"
                        values="100;50;50;50;50"
                        keyTimes="0;0.4;0.5;0.9;1"
                        keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                      ></animate>
                      <animate
                        attributeName="height"
                        repeatCount="indefinite"
                        dur="2.564102564102564s"
                        calcMode="spline"
                        values="0;50;50;0;0"
                        keyTimes="0;0.4;0.5;0.9;1"
                        keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                      ></animate>
                    </rect>
                  </clipPath>
                </defs>
                <g transform="translate(50 50)">
                  <g transform="scale(0.9)">
                    <g transform="translate(-50 -50)">
                      <g>
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          dur="2.564102564102564s"
                          repeatCount="indefinite"
                          values="0 50 50;0 50 50;180 50 50;180 50 50;360 50 50"
                          keyTimes="0;0.4;0.5;0.9;1"
                        ></animateTransform>
                        <path
                          clip-path="url(#ldio-x4hvcro74bn-cp)"
                          fill="#3AD8DA"
                          d="M54.864 50L54.864 50c0-1.291 0.689-2.412 1.671-2.729c9.624-3.107 17.154-12.911 19.347-25.296 c0.681-3.844-1.698-7.475-4.791-7.475H28.908c-3.093 0-5.472 3.631-4.791 7.475c2.194 12.385 9.723 22.189 19.347 25.296 c0.982 0.317 1.671 1.438 1.671 2.729v0c0 1.291-0.689 2.412-1.671 2.729C33.84 55.836 26.311 65.64 24.117 78.025 c-0.681 3.844 1.698 7.475 4.791 7.475h42.184c3.093 0 5.472-3.631 4.791-7.475C73.689 65.64 66.16 55.836 56.536 52.729 C55.553 52.412 54.864 51.291 54.864 50z"
                        ></path>
                        <path
                          fill="#299798"
                          d="M81 81.5h-2.724l0.091-0.578c0.178-1.122 0.17-2.243-0.022-3.333C76.013 64.42 68.103 54.033 57.703 50.483l-0.339-0.116 v-0.715l0.339-0.135c10.399-3.552 18.31-13.938 20.642-27.107c0.192-1.089 0.2-2.211 0.022-3.333L78.276 18.5H81 c2.481 0 4.5-2.019 4.5-4.5S83.481 9.5 81 9.5H19c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5h2.724l-0.092 0.578 c-0.178 1.122-0.17 2.243 0.023 3.333c2.333 13.168 10.242 23.555 20.642 27.107l0.338 0.116v0.715l-0.338 0.135 c-10.4 3.551-18.31 13.938-20.642 27.106c-0.193 1.09-0.201 2.211-0.023 3.333l0.092 0.578H19c-2.481 0-4.5 2.019-4.5 4.5 s2.019 4.5 4.5 4.5h62c2.481 0 4.5-2.019 4.5-4.5S83.481 81.5 81 81.5z M73.14 81.191L73.012 81.5H26.988l-0.128-0.309 c-0.244-0.588-0.491-1.538-0.28-2.729c2.014-11.375 8.944-20.542 17.654-23.354c2.035-0.658 3.402-2.711 3.402-5.108 c0-2.398-1.368-4.451-3.403-5.108c-8.71-2.812-15.639-11.979-17.653-23.353c-0.211-1.191 0.036-2.143 0.281-2.731l0.128-0.308 h46.024l0.128 0.308c0.244 0.589 0.492 1.541 0.281 2.731c-2.015 11.375-8.944 20.541-17.654 23.353 c-2.035 0.658-3.402 2.71-3.402 5.108c0 2.397 1.368 4.45 3.403 5.108c8.71 2.812 15.64 11.979 17.653 23.354 C73.632 79.651 73.384 80.604 73.14 81.191z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="col-width2">
              <h2> {Lang.profile_under_review} </h2>
              <p>{Lang.profile_under_review_content}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContractBanner = () => {
  return (
    <>
      <div className="container underReview">
        <div className="boxWhite boxShadow mb-5">
          <div className="row">
            <div className="col-width1">
              <svg
                id="Layer_1"
                enable-background="new 0 0 128 128"
                viewBox="0 0 128 128"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
                height=""
              >
                <path
                  d="m118.496 34.843-.918-.608 2.068-3.121c.848-1.279 1.146-2.811.842-4.314-.305-1.504-1.177-2.799-2.457-3.646-1.278-.847-2.81-1.146-4.314-.841s-2.798 1.177-3.646 2.456l-2.62 3.954-10.087 15.22-3.337 5.036v-33.9c0-2.754-2.24-4.995-4.994-4.995h-72.64c-2.754 0-4.995 2.241-4.995 4.995v102.448c0 2.421 1.97 4.391 4.391 4.391h71.929c3.479 0 6.31-2.831 6.31-6.31v-45.836l12.913-19.484 9.535-14.387.918.609c.459.305.585.926.281 1.386l-12.618 19.039-2.601 2.085c-.431.346-.5.975-.155 1.406.198.247.488.375.781.375.22 0 .44-.072.625-.22l2.723-2.183c.081-.065.151-.142.208-.228l12.701-19.17c.914-1.378.535-3.244-.843-4.157z"
                  fill="#E6E7E8"
                ></path>
                <path
                  d="m83.716 116.916h-71.929c-1.873 0-3.39-1.518-3.39-3.391v-102.447c0-2.206 1.788-3.994 3.994-3.994h72.64c2.206 0 3.994 1.788 3.994 3.994v100.528c.001 2.933-2.377 5.31-5.309 5.31z"
                  fill="#D4FBFF"
                ></path>
                <path
                  d="m85.031 7.084h-72.64c-2.206 0-3.994 1.788-3.994 3.994v3c0-2.206 1.788-3.994 3.994-3.994h72.64c2.206 0 3.994 1.788 3.994 3.994v-3c0-2.206-1.788-3.994-3.994-3.994z"
                  fill="#fff"
                ></path>
                <path
                  d="m68.015 54.553h38.823v9.487h-38.823z"
                  fill="#6DC9F7"
                  transform="matrix(.552 -.834 .834 .552 -10.297 99.415)"
                ></path>
                <path
                  d="m98.45 26.375h9.487v18.259h-9.487z"
                  fill="#FA759E"
                  transform="matrix(.834 .552 -.552 .834 36.789 -51.098)"
                ></path>
                <path
                  d="m112.192 30.515-7.908-5.241 2.62-3.954c1.447-2.184 4.391-2.781 6.575-1.334 2.184 1.447 2.781 4.391 1.334 6.575z"
                  fill="#FFD7E5"
                ></path>
                <path d="m70.044 85.526 2.705-12.669 7.908 5.241z" fill="#FA759E"></path>
                <g fill="#3A2C60">
                  <path d="m89.025 83.615c-.552 0-1 .448-1 1v9.097c0 .552.448 1 1 1s1-.448 1-1v-9.097c0-.552-.447-1-1-1z"></path>
                  <path d="m37.418 29.137h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m37.418 43.484h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m37.418 57.831h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m77.356 98.873h-39.938c-.552 0-1 .448-1 1s.448 1 1 1h39.938c.552 0 1-.448 1-1s-.447-1-1-1z"></path>
                  <path d="m30.274 24.704c0-1.385-1.126-2.511-2.511-2.511h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511zm-2 6.866c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m30.274 53.398c0-1.385-1.126-2.511-2.511-2.511h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511zm-2 6.866c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m27.763 79.582h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511v-6.866c0-1.385-1.127-2.511-2.511-2.511zm.511 9.377c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m114.496 30.843-.918-.608 2.068-3.121c.848-1.279 1.146-2.811.842-4.314-.305-1.504-1.177-2.799-2.457-3.646-1.278-.847-2.81-1.146-4.314-.841s-2.798 1.177-3.646 2.456l-2.62 3.954-10.087 15.22-3.337 5.036v-33.9c0-2.754-2.24-4.995-4.994-4.995h-72.64c-2.754 0-4.995 2.241-4.995 4.995v102.448c0 2.421 1.97 4.391 4.391 4.391h71.929c3.479 0 6.31-2.831 6.31-6.31v-12.585c0-.552-.448-1-1-1s-1 .448-1 1v12.583c0 2.376-1.933 4.31-4.31 4.31h-71.931c-1.318 0-2.391-1.072-2.391-2.391v-102.447c0-1.651 1.343-2.995 2.995-2.995h72.64c1.651 0 2.994 1.343 2.994 2.995v36.918l-14.7 22.183h-35.907c-.552 0-1 .448-1 1s.448 1 1 1h34.581l-.083.125c-.008.011-.008.025-.015.036-.058.095-.106.196-.13.307l-2.537 11.878h-31.816c-.552 0-1 .448-1 1s.448 1 1 1h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-4.14l8.014-5.609c.093-.065.168-.149.233-.239.008-.011.02-.017.028-.028l6.534-9.86v10.335c0 .552.448 1 1 1s1-.448 1-1v-13.352l12.913-19.484 9.535-14.387.918.609c.459.305.585.926.281 1.386l-12.618 19.038-2.601 2.085c-.431.346-.5.975-.155 1.406.198.247.488.375.781.375.22 0 .44-.072.625-.22l2.723-2.183c.081-.065.151-.142.208-.228l12.704-19.169c.914-1.378.535-3.244-.843-4.157zm-6.759-8.97c1.141-1.719 3.469-2.192 5.189-1.052.833.552 1.402 1.396 1.601 2.376s.004 1.979-.548 2.812l-2.068 3.12-6.241-4.136zm-36.184 61.377 1.868-8.748 5.46 3.619zm8.823-6.539-6.241-4.136 20.342-30.694 6.241 4.136zm21.447-32.361-6.241-4.136 8.982-13.553 6.241 4.136z"></path>
                </g>
                <path
                  d="m23.405 31.016c-.406 0-.795-.165-1.078-.457l-4.235-4.379c-.576-.596-.56-1.545.035-2.121.596-.576 1.546-.56 2.121.035l3.102 3.207 6.525-7.455c.545-.624 1.493-.688 2.117-.141.624.546.687 1.493.141 2.117l-7.599 8.682c-.276.316-.672.501-1.092.512-.012 0-.025 0-.037 0z"
                  fill="#FA759E"
                ></path>
                <path
                  d="m23.405 59.71c-.406 0-.795-.165-1.078-.457l-4.235-4.379c-.576-.596-.56-1.545.035-2.121.596-.576 1.546-.56 2.121.035l3.102 3.207 6.525-7.455c.545-.624 1.493-.687 2.117-.141s.687 1.493.141 2.117l-7.599 8.682c-.276.316-.672.501-1.092.512-.012 0-.025 0-.037 0z"
                  fill="#FA759E"
                ></path>
              </svg>
            </div>
            <div className="col-width2">
              <h2> {Lang.contract_send} </h2>
              <p>{Lang.contract_content} </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OnlineTestBanner = () => {
  return (
    <>
      <div className="container underReview">
        <div className="boxWhite boxShadow mb-5">
          <div className="row">
            <div className="col-width1">
              <svg
                id="Layer_1"
                enable-background="new 0 0 128 128"
                viewBox="0 0 128 128"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
                height=""
              >
                <path
                  d="m118.496 34.843-.918-.608 2.068-3.121c.848-1.279 1.146-2.811.842-4.314-.305-1.504-1.177-2.799-2.457-3.646-1.278-.847-2.81-1.146-4.314-.841s-2.798 1.177-3.646 2.456l-2.62 3.954-10.087 15.22-3.337 5.036v-33.9c0-2.754-2.24-4.995-4.994-4.995h-72.64c-2.754 0-4.995 2.241-4.995 4.995v102.448c0 2.421 1.97 4.391 4.391 4.391h71.929c3.479 0 6.31-2.831 6.31-6.31v-45.836l12.913-19.484 9.535-14.387.918.609c.459.305.585.926.281 1.386l-12.618 19.039-2.601 2.085c-.431.346-.5.975-.155 1.406.198.247.488.375.781.375.22 0 .44-.072.625-.22l2.723-2.183c.081-.065.151-.142.208-.228l12.701-19.17c.914-1.378.535-3.244-.843-4.157z"
                  fill="#E6E7E8"
                ></path>
                <path
                  d="m83.716 116.916h-71.929c-1.873 0-3.39-1.518-3.39-3.391v-102.447c0-2.206 1.788-3.994 3.994-3.994h72.64c2.206 0 3.994 1.788 3.994 3.994v100.528c.001 2.933-2.377 5.31-5.309 5.31z"
                  fill="#D4FBFF"
                ></path>
                <path
                  d="m85.031 7.084h-72.64c-2.206 0-3.994 1.788-3.994 3.994v3c0-2.206 1.788-3.994 3.994-3.994h72.64c2.206 0 3.994 1.788 3.994 3.994v-3c0-2.206-1.788-3.994-3.994-3.994z"
                  fill="#fff"
                ></path>
                <path
                  d="m68.015 54.553h38.823v9.487h-38.823z"
                  fill="#6DC9F7"
                  transform="matrix(.552 -.834 .834 .552 -10.297 99.415)"
                ></path>
                <path
                  d="m98.45 26.375h9.487v18.259h-9.487z"
                  fill="#FA759E"
                  transform="matrix(.834 .552 -.552 .834 36.789 -51.098)"
                ></path>
                <path
                  d="m112.192 30.515-7.908-5.241 2.62-3.954c1.447-2.184 4.391-2.781 6.575-1.334 2.184 1.447 2.781 4.391 1.334 6.575z"
                  fill="#FFD7E5"
                ></path>
                <path d="m70.044 85.526 2.705-12.669 7.908 5.241z" fill="#FA759E"></path>
                <g fill="#3A2C60">
                  <path d="m89.025 83.615c-.552 0-1 .448-1 1v9.097c0 .552.448 1 1 1s1-.448 1-1v-9.097c0-.552-.447-1-1-1z"></path>
                  <path d="m37.418 29.137h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m37.418 43.484h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m37.418 57.831h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-39.938c-.552 0-1 .448-1 1s.448 1 1 1z"></path>
                  <path d="m77.356 98.873h-39.938c-.552 0-1 .448-1 1s.448 1 1 1h39.938c.552 0 1-.448 1-1s-.447-1-1-1z"></path>
                  <path d="m30.274 24.704c0-1.385-1.126-2.511-2.511-2.511h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511zm-2 6.866c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m30.274 53.398c0-1.385-1.126-2.511-2.511-2.511h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511zm-2 6.866c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m27.763 79.582h-6.866c-1.385 0-2.511 1.126-2.511 2.511v6.866c0 1.385 1.126 2.511 2.511 2.511h6.866c1.385 0 2.511-1.126 2.511-2.511v-6.866c0-1.385-1.127-2.511-2.511-2.511zm.511 9.377c0 .282-.229.511-.511.511h-6.866c-.282 0-.511-.229-.511-.511v-6.866c0-.282.229-.511.511-.511h6.866c.282 0 .511.229.511.511z"></path>
                  <path d="m114.496 30.843-.918-.608 2.068-3.121c.848-1.279 1.146-2.811.842-4.314-.305-1.504-1.177-2.799-2.457-3.646-1.278-.847-2.81-1.146-4.314-.841s-2.798 1.177-3.646 2.456l-2.62 3.954-10.087 15.22-3.337 5.036v-33.9c0-2.754-2.24-4.995-4.994-4.995h-72.64c-2.754 0-4.995 2.241-4.995 4.995v102.448c0 2.421 1.97 4.391 4.391 4.391h71.929c3.479 0 6.31-2.831 6.31-6.31v-12.585c0-.552-.448-1-1-1s-1 .448-1 1v12.583c0 2.376-1.933 4.31-4.31 4.31h-71.931c-1.318 0-2.391-1.072-2.391-2.391v-102.447c0-1.651 1.343-2.995 2.995-2.995h72.64c1.651 0 2.994 1.343 2.994 2.995v36.918l-14.7 22.183h-35.907c-.552 0-1 .448-1 1s.448 1 1 1h34.581l-.083.125c-.008.011-.008.025-.015.036-.058.095-.106.196-.13.307l-2.537 11.878h-31.816c-.552 0-1 .448-1 1s.448 1 1 1h39.938c.552 0 1-.448 1-1s-.448-1-1-1h-4.14l8.014-5.609c.093-.065.168-.149.233-.239.008-.011.02-.017.028-.028l6.534-9.86v10.335c0 .552.448 1 1 1s1-.448 1-1v-13.352l12.913-19.484 9.535-14.387.918.609c.459.305.585.926.281 1.386l-12.618 19.038-2.601 2.085c-.431.346-.5.975-.155 1.406.198.247.488.375.781.375.22 0 .44-.072.625-.22l2.723-2.183c.081-.065.151-.142.208-.228l12.704-19.169c.914-1.378.535-3.244-.843-4.157zm-6.759-8.97c1.141-1.719 3.469-2.192 5.189-1.052.833.552 1.402 1.396 1.601 2.376s.004 1.979-.548 2.812l-2.068 3.12-6.241-4.136zm-36.184 61.377 1.868-8.748 5.46 3.619zm8.823-6.539-6.241-4.136 20.342-30.694 6.241 4.136zm21.447-32.361-6.241-4.136 8.982-13.553 6.241 4.136z"></path>
                </g>
                <path
                  d="m23.405 31.016c-.406 0-.795-.165-1.078-.457l-4.235-4.379c-.576-.596-.56-1.545.035-2.121.596-.576 1.546-.56 2.121.035l3.102 3.207 6.525-7.455c.545-.624 1.493-.688 2.117-.141.624.546.687 1.493.141 2.117l-7.599 8.682c-.276.316-.672.501-1.092.512-.012 0-.025 0-.037 0z"
                  fill="#FA759E"
                ></path>
                <path
                  d="m23.405 59.71c-.406 0-.795-.165-1.078-.457l-4.235-4.379c-.576-.596-.56-1.545.035-2.121.596-.576 1.546-.56 2.121.035l3.102 3.207 6.525-7.455c.545-.624 1.493-.687 2.117-.141s.687 1.493.141 2.117l-7.599 8.682c-.276.316-.672.501-1.092.512-.012 0-.025 0-.037 0z"
                  fill="#FA759E"
                ></path>
              </svg>
            </div>
            <div className="col-width2">
              <h2> {Lang.online_test_assigned} </h2>
              <p>
                {Lang.online_test_assigned_content}{" "}
                <Link to={"/online-test"}>{Lang.click_here}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

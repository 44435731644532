import React from 'react';
import {PasswordLayout} from "../layout";
import {ForgotPasswordForm} from "../form/password";

export function ForgotPassword () {

  return(
    <PasswordLayout>
      <div className="forgot_password_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
                <div className="boxin">
                  <ForgotPasswordForm/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </PasswordLayout>
  )
}
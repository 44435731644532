import React, { useState} from 'react';
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {Submit} from "../common";
const moment =  require ("moment");
declare let window: any;

export function Footer () {

  const [termsAndCondition,  setTermsAndCondition] = useState({html:''});

  function getPrivacyPolicy(event:any) {
    API.get(`get-terms-and-conditions`).then((res: AxiosResponse) => {
      const terms_and_conditions = res.data.terms_and_conditions;
      setTermsAndCondition({...termsAndCondition, html: terms_and_conditions.setting_value});
    });
  }

  return(
      <>

        <footer className="mainFooter">

        <div className="footer_section">

          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-lg-4">
                <ul className="footer_right footer_left">
                  <li><a href='javascript:' onClick={(e) => getPrivacyPolicy(e)} data-toggle="modal" data-target="#privacy-policy">{Lang.privacy_policy}</a></li>
                  <li>|</li>
                  <li>{Lang.we_love_data_regulation}</li>
                </ul>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="storeMobLink">
                  <span className="findOnUsText mr-2 text-center">{Lang.vt_trans_lang_40_findUson}</span>
                  <ul className="BtnApp">
                    <li>
                      <a href="javascript:;"><img src={`${window.PUBLIC_URL}/theme/images/googlePlay.png`} alt="vt-img" title=""/></a>
                    </li>
                    <li>
                      <a href="javascript:;"><img src={`${window.PUBLIC_URL}/theme/images/appStore.png`} alt="vt-img" title=""/></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-12 col-lg-4">
                <div className="footer_right">
                  <p>{Lang.all_rights_reserved} &copy; {moment().year()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>

        <div className="modal customModal fade" id="privacy-policy" >
          <div className="modal-dialog  modal-bigger">
            <div className="modal-content">

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" style={{textAlign: 'right'}}>×
                </button>
              </div>
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12">
                  <div className="modal-body">
                    <div className="headArea text-center">
                      <img src="theme/images/vectorPrivacy.png" alt="" title=""/>
                      <div className="NewTitle">
                        <h4><span>{Lang.term_and_condition}</span></h4>
                      </div>
                    </div>
                    <div className="scrollbar-inner termsScroll">
                      <span dangerouslySetInnerHTML={{__html: termsAndCondition.html}}/>
                    </div>
                  </div>
                  <div className="button mt-5 text-center sign_up_form_page">
                    <Submit className="btn btn-md btn-green hvr-rectangle-out" data-dismiss="modal"
                            label={Lang.ok}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
  )
}
import React, {useEffect, useRef} from "react";
import 'dropzone/dist/dropzone.css'
import {base_url} from "../common/AxoisClient";
import {Auth} from "../Auth/Auth";
import {ErrorNotification, showNotification} from "../common/helpers";
import Lang from "../common/lang/Lang";
import {Error422, Error422Custom} from "../common";
import _ from 'lodash';
const Dropzone = require('dropzone');


export const Document = (props: any) => {
  const ele = useRef(null);

  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      $els.empty();
      let myDropzone = new Dropzone($el, {
        dictDefaultMessage: Lang.doc_upload_title,
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        addRemoveLinks: true,
        url: base_url + "api/translator/v1/orders/document-upload",
        autoProcessQueue: true,
        uploadMultiple: true,
        paramName: 'translated_document',
        acceptedFiles:"image/*,application/pdf,.doc,.docx,.xlsx,.xls,.tex,.odt,.txt,.png,.bmp,.pptx,.rtf,.ppt",
        params: function() {
          return {"document_name" : "translated_document"}
        },
        headers: {
          Authorization : `Bearer ${Auth.token}`,
          From: 'web'
        },
        successmultiple: function (file:any, res: any) {
          props.addDoc(res.filename);
        },
        error: function (file:any, res:any) {
          myDropzone.removeDoc(file);
          if(!_.isEmpty(res.errors)) {
            Error422Custom(res);
          } else {
            ErrorNotification(Lang.invalid_file);
          }
        }
      });
      myDropzone.on("removedfile", function(file : any) {
        props.removeDoc(file);
      });
    }
  }, []);

  return(
      <div className={'row'}>
        <div id={'drop_zone'} className={'dropzone'} ref={ele} style={{width:"100%"}}/>
      </div>
  )
}
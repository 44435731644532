import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from "../Auth/Auth";
import { HandleServerError } from "./HandleError";
import { SubmitButtonService } from "../services";
import { LoaderType, ShowLoader, StopLoader } from "./Loader";
import _ from "lodash";

let Mprogress: any = require("mprogress/mprogress.min");

let mprogress = new Mprogress();
declare var window: any;

//export const base_url = "http://192.168.1.4:8000/";
//export const base_url = "http://we-translate.local/";
export const base_url = "https://api.vitolker.com/";
// export const base_url = "http://api.beta-vitolker.com/";
// export const base_url = "http://127.0.0.1:8000/";

//export const base_url = "http://192.168.2.108/we-translate/public/";
//export const base_url = "http://172.20.10.11/we-translate/public/";

//export const base_url = "http://vitolker-api.local/";

const Axios = axios.create({
  baseURL: `${base_url}api/translator/v1/`,
});

let loader = LoaderType.COMMON;

export function SwitchLoader(state: LoaderType) {
  loader = state;
}

let formSubmition = false;

export function setFormSubmition(state: boolean) {
  formSubmition = state;
}

// Add a request interceptor
Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    ShowLoader(loader);
    /*if(_.isUndefined(config.headers['Authorization'])) { COMMENTED ON 02-Apr-2020 because loader was not working
    config.headers['Authorization'] = `Bearer ${Auth.token}`;
  }*/
    if (formSubmition) {
      SubmitButtonService.loadStart();
    }
    if (_.isUndefined(config.headers["Authorization"])) {
      config.headers["Authorization"] = `Bearer ${Auth.token}`;
    }
    //config.headers['Authorization'] = `Bearer ${Auth.token}`;
    config.headers["From"] = `web`;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor COMMENTED ON 19-Mar-2020
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    StopLoader(loader);
    mprogress.end();
    if (formSubmition) {
      SubmitButtonService.clearLoading();
      setFormSubmition(false);
    }
    return response;
  },
  (error: AxiosError) => {
    mprogress.end();
    if (formSubmition) {
      SubmitButtonService.clearLoading();
      setFormSubmition(false);
    }
    HandleServerError(error);
    return Promise.reject(error.response);
  }
);

export default Axios;

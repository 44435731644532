
export const CompleteOrderValidations = function () {
  $(document).ready(function () {

    $( "#complete_order" ).validate({
      rules: {
        order_to: {
          required: true
        },
        translation_of_phone_message: {
          required: {
            depends: function(element:any){
              if($('#translation_type_id_hidden').val()==5){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        translated_document: {
          required: {
            depends: function(element:any){
              if($('#translation_type_id_hidden').val()==6){
                return true;
              } else {
                return false;
              }
            }
          }
        },
      },
    });
  });
}
import React, {useEffect, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {Auth, getUserInfo} from "../../Auth/Auth";
import API, { base_url } from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {showNotification} from "../../common/helpers";
import {DocumentUploadState} from "./State";
declare let window:any;


    /* 
        Note: This typing is based on a response from the api so many properties are of type unknown, and there might be inaccuracies.
        Don't consider it as accurate therefore and be sure to do type checking and investigate/correct wrong types.
    */
    interface TranslatorCountry {
        id: number | null
        iso: string | null
        iso3: string | null
        name: string | null
        nick_name: string | null
        num_code: string | null
        phone_code: string | null
        priority: number | null
        string: number | null
    }
    interface TranslatorDataType {
        accept_contract: number | null
        account_no: string | null
        agreed_income: number | null
        bank_name: string | null
        bank_regestration_no: string | null
        comments: string | null
        company_address: string | null
        company_name: string | null
        company_type: string | null
        compensation_agreed: number | null
        contract_send_date: string | null
        contract_sign_date: string | null
        contract_signature: string | null
        country: TranslatorCountry
        created_at: string | null
        criminal_record: string | null
        criminal_record_filename: string | null
        current_employment: string | null
        cvr_number: string | number | null
        date_of_birth: string | null
        driving_license: number | null
        exp_scale: number | null
        extra_holiday_percentage: string | null
        fee_type: number | null
        highest_complete_edu: unknown | null
        holiday_charge_agreed: number | null
        hourly_pay: string | null
        how_many_km: unknown | null
        how_much_pay: unknown | null
        iban: unknown | null
        id: number | null
        income: unknown | null
        interview_scheduled_at: string | null
        is_certified: unknown | null
        is_doc_translation: number | null
        is_km_pay: number | null
        is_sign_lang_translation: number | null
        is_transport_pay: number | null
        is_vat_registered: unknown | null
        location: string | null
        min_km: unknown | null
        min_word: unknown | null
        motivation: string | null
        online_test_submit_date: string | null
        online_test_term: unknown | null
        origin_country: string | null
        own_vehicle: number | null
        per_km_rate: string | null
        per_word_rate: string | null
        physical_fix_minute: number | null
        physical_fix_rate: string | null
        physical_rate: unknown | null
        physical_sub_minute: number | null
        physical_sub_rate: string | null
        police_verified: number | null
        primary_income: unknown | null
        push_agreement: number | null
        registration_step: number | null
        resume: string | null
        resume_filename: string | null
        send_contract: number | null
        send_online_test: unknown | null
        social_no: string | null
        swift: unknown | null
        tax_country: unknown | null
        tel_message_fix_rate: string | null
        telephone_fix_minute: number | null
        telephone_fix_rate: string | null
        telephone_rate: unknown | null
        telephone_sub_minute: number | null
        telephone_sub_rate: string | null
        test_condition_accept: unknown | null
        title: unknown | null
        tolk_ratings: unknown | null
        training: unknown | null
        translator_calling: unknown | null
        translator_exam_given_date: unknown | null
        translator_type: number | null
        transport_fee_agreed: number | null
        transport_fee_fix_rate: string | null
        transport_fee_minute: unknown | null
        transport_fee_sub_minute: unknown | null
        transport_fee_sub_rate: string | null
        updated_at: string | null
        user_id: number | null
        vat_number: unknown | null
        verification_number: string | null
        video_fix_minute: number | null
        video_fix_rate: string | null
        video_rate: unknown | null
        video_sub_minute: number | null
        video_sub_rate: string | null
        contract_pdf_file: string | null
    }

    export const TDocumentUpload = (props:any) => {

        const user:any = getUserInfo();
        const [DocumentData, setDocumentData] = useState(DocumentUploadState);
        const [TranslatorData, setTranslatorData] = useState<TranslatorDataType | null>(null);
        const [ManualContractURL, setManualContractURL] = useState<string>('');

        function getTranslatorData(){
            API.get(`translator-data/${Auth.userInfo.id}`)
                .then((res: AxiosResponse) => {
                    const getTranslatorData = res.data.translatorData;
                    setTranslatorData(getTranslatorData);
                    // why a timeout?
                    setTimeout(function(){
                        setDocumentData({...DocumentData, resume: getTranslatorData.resume, criminal_record: getTranslatorData.criminal_record, children_certificate: getTranslatorData.children_certificate});
                    }, 500);
                    if (getTranslatorData)
                    return API.get(`get-contract/${Auth.userInfo.id}`, { responseType: 'arraybuffer' });
                }).then((res: AxiosResponse<ArrayBuffer> | undefined) => {
                    if (res) {
                        const dec = new TextDecoder();
                        const response_string = dec.decode(res.data);
                        if (response_string !== "\"Translator's Contract Doesnt Exist\"") {
                            const blob = new Blob([res.data], {type: 'application/pdf'});
                            const contract_url = URL.createObjectURL(blob);
                            setManualContractURL(contract_url);
                        }
                    }
                })
        }

        useEffect(() => {
            getTranslatorData();
        }, []);

        function handleUpload(e: any,document_type:string) {
            let formData = new FormData();
            const dFileName = (e.target.files && e.target.files[0] && e.target.files[0].name) ? e.target.files[0].name : '';
            formData.append(document_type, e.target.files[0]);
            formData.append('document_name', document_type);
            formData.append('orignal_filename', dFileName);
            API.post('profile/document-upload', formData).then((res) => {
                let document_type = res.data.document_type;
                let filename = res.data.filename;
                getTranslatorData();
                setDocumentData({...DocumentData, [document_type]: filename});
                showNotification("success", Lang.vt_trans_lang_91_document_success);
            });
        }
    return (
        <div className="document-upload mt-30 profile-document-upload">
            <div className="row  mb-22">
                {/* CONTRACT */}
                {
                    (TranslatorData !== null && (TranslatorData.send_contract === 2 || TranslatorData.contract_pdf_file)) ?
                    ( 
                        <div className="col-md-4 col-sm-12 text-center">
                            <div className="doc-box">
                                <img
                                    src={`${window.PUBLIC_URL}/theme/images/service5Gray.png`}
                                    alt="vt-img"
                                    style={{
                                        opacity: '0.6',
                                    }}
                                    />
                                <p>{Lang.contract} </p>
                                <div className="custom-file">
                                    <a href={TranslatorData.contract_pdf_file ? ManualContractURL : base_url + `storage/translator-contract/translator_contract_${TranslatorData.user_id}.pdf`} target={'_blank'} rel='noopener noreferrer'>
                                        <span className="btn btn-white hvr-rectangle-out browse-btn"> {Lang.view}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {/* CV */}
                <div className="col-md-4 col-sm-12 text-center">
                    <div
                        className="doc-box"
                        style={!DocumentData.resume ? {
                            borderColor: '#EA868F'
                        } : undefined}
                    >
                        <img src={`${window.PUBLIC_URL}/theme/images/cv.png`} alt="vt-img"/>
                        <p>{Lang.resume} </p>
                        <div className="custom-file">
                            {
                                DocumentData.resume && (
                                    <a href={DocumentData.resume} target={'_blank'}>
                                        <span className="btn btn-white hvr-rectangle-out browse-btn"> {Lang.view}</span>
                                    </a>
                                )
                            }
                            <span
                                className={`btn ${DocumentData.resume && 'btn-white'} hvr-rectangle-out browse-btn`}
                                style={!DocumentData.resume ? {
                                    border: 'none',
                                    backgroundColor: '#EA868F',
                                    color: 'black'
                                } : undefined}
                            >   {Lang.vt_trans_lang_73_browse}
                                <input
                                    type={'file'}
                                    onChange={(e) => handleUpload(e, 'resume')}
                                    name={'resume'}
                                    className="custom-file-input btn validate"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                {/*<div className="col-md-4 col-sm-12 text-center">
                    <div className="doc-box">
                        <img src={`${window.PUBLIC_URL}/theme/images/certificate.png`} alt="vt-img"/>
                        <p>{Lang.children_certificate} </p>
                        <div className="custom-file">
                            <a href={DocumentData.children_certificate} target={"_blank"}>
                                <span className="btn btn-white hvr-rectangle-out browse-btn"> {Lang.view}</span>
                            </a>
                            <span
                                className="btn btn-white hvr-rectangle-out browse-btn">{Lang.browse}<input
                                type={'file'}
                                className="custom-file-input btn validate"
                                onChange={(e) => handleUpload(e, 'children_certificate')}
                                name={'children_certificate'}/></span>
                        </div>
                    </div>
                </div>*/}
                {/* CRIMINAL RECORD */}
                <div className="col-md-4 col-sm-12 text-center">
                    <div
                        className="doc-box"
                        style={!DocumentData.criminal_record ? {
                            borderColor: '#EA868F'
                        } : undefined}
                    >
                        <img src={`${window.PUBLIC_URL}/theme/images/penalty-certificate.png`} alt="vt-img"/>
                        <p>{Lang.penalty_certificate} </p>
                        <div className="custom-file">
                            {
                                DocumentData.criminal_record && (
                                    <a href={DocumentData.criminal_record} target={"_blank"}>
                                        <span className="btn btn-white hvr-rectangle-out browse-btn"> {Lang.view}</span>
                                    </a>
                                )
                            }
                            <span
                                className={`btn ${DocumentData.criminal_record && 'btn-white' } hvr-rectangle-out browse-btn`}
                                style={!DocumentData.criminal_record ? {
                                    border: 'none',
                                    backgroundColor: '#EA868F',
                                    color: 'black'
                                } : undefined}
                            >   {Lang.vt_trans_lang_73_browse}
                                <input
                                    type={'file'}
                                    className="custom-file-input btn validate"
                                    onChange={(e) => handleUpload(e, 'criminal_record')}
                                    name={'penalty_certificate'}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {WeLearnModel} from "../model/WeLearn";
import {Link} from "react-router-dom";

export function WeLearn (props: any) {

    const [weLearn,  setWeLearn] = useState([] as Array<WeLearnModel>);
    const [loader, setLoader]    = useState({ 'isLoader': 0 });

    useEffect(() => {
        document.title = Lang.we_learn+' - '+Lang.vitolker_translator;;
        API.get(`we-learn`).then((res: AxiosResponse) => {
            setLoader({...loader, isLoader: 1 });
            setWeLearn(res.data);
        })
    },[]);
    $('body').addClass('blankVector');

  return(

      <div className="bgMain">
          {loader.isLoader == 0 &&
          <><div className="bg_load"></div>
              <div className="wrap"></div></>
          }
        <div className="wrapper">
            <Header {...props}/>
            <section className="mainContent">
                <div className="video-graphic">
                    <img className={'img-fluid'} src="theme/images/video-big.png" alt=""/>
                </div>

                <div className="container">
                    <div className="statisticsPage">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text-center mb-22">
                                <div className="video-heading">
                                    <h2>{Lang.we_learn_heading_first}<br/>{Lang.we_learn_heading_second} <span>{Lang.we_learn}</span> {Lang.we_learn_heading_third}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {weLearn.map((data:WeLearnModel, index: number) => {
                                return <div className="col-md-6 col-sm-12  col-lg-4 mb-30">
                                        <div className="boxShadow hover-vd hvr-grow-shadow">
                                            <div className="profile_box for-vd">
                                                <div className="imgbox">
                                                    <img src={data.video_thumbnail} alt="" className={'img-fluid'} style={{height:'200px'}} />
                                                </div>
                                                <Link to={`we-learn-detail/${data.id}`}>
                                                    <h4>{data.title}</h4>
                                                </Link>
                                                <p>{(data.description.length>130)?data.description.substr(0, 130)+'...':data.description}  <Link to={`we-learn-detail/${data.id}`}>{Lang.read_more}</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
          <Footer/>
        </div>
      </div>
  )
}
import {
  AddLeaveFormSchema,
  DocumentUploadSchema, EditEmailSchema, EditPhoneFormSchema,
  GetLanguagesStateSchema, GetLeavesSchema,
  LanguageAddFormSchema, OTPExpirySchema,
  TContractDisplaySchema, TempEmailSchema, TimerCountSchema
} from "./Schema";
import {RegisterFormSchema, BasicInfoFormSchema, TranslationTypeSchema} from "../signup/Schema";
import {BasicInfoFormState, RegisterFormState} from "../signup/State";
import {CountryModel} from "../../model/Country";


export const TContractDisplayState: TContractDisplaySchema = {
  contractDisplay: false,
  translatorContractContent: '',
  registrationStep: 0,
  keyboard: false,
  backdrop: '',
}

export interface EditBasicInfoSchema extends RegisterFormSchema,BasicInfoFormSchema {

}

export const DocumentUploadState: DocumentUploadSchema = {
  document_type:'',
  resume:'',
  children_certificate: '',
  criminal_record: ''
}

export const LanguageAddFormState: LanguageAddFormSchema = {
  from_language_id:'',
  to_language_id:'',
  from_accent:'',
  to_accent:'',
  is_certificate:0,
  how_much_hours:'',
  level:'',
  user_id: localStorage.getItem("login_user_id"),

}

export const AddLeaveFormState: AddLeaveFormSchema = {
  leave_type:1,
  from_date:'',
  to_date:'',
  from_time: '00:00',
  to_time: '23:59',
  reason:''
}

export const EditPhoneFormState: EditPhoneFormSchema = {
  country_code: '',
  mobile_no: '',
  translator_otp: '',
  translator_phone_number: '',
  final_phone_number: '',
  verified_phone_number: '',
  otp: '',
  timer_count_start: '0',
  send_otp_text: 'Send OTP'
}

export const TimerCountState: TimerCountSchema = {
  timer_count:'1:00'
}

export const OTPExpiryState: OTPExpirySchema = {
  start_time:''
}

export const GetLeavesState: GetLeavesSchema = {
  transLeavesData: [],
}

export const TempEmailState: TempEmailSchema = {
  transTempEmailData: [],
}

export const EditEmailState: EditEmailSchema = {
  email: '',
}

export const GetLanguagesState: GetLanguagesStateSchema = {
  transLanguageData: [],
}

export const EditBasicInfoState: EditBasicInfoSchema = {...RegisterFormState,...BasicInfoFormState}



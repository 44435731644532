import React, {useEffect, useState} from 'react';
import Lang from "../../../common/lang/Lang";
import API, {SwitchLoader} from "../../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {successNotification} from "../../../common/helpers";
import {FormLayout, Submit} from "../../../common";
import {ForgotPasswordValidations} from "../../../password/Validations";
import {InlineLoader} from "../../../common/InlineLoader";
import {LoaderType} from "../../../common/Loader";
import {Link} from "react-router-dom";
declare let window: any;

export function ForgotPasswordForm () {

  const [email, setEmail] = useState('');
  const [EmailExistsCheck, setEmailExistsCheck] = useState({'emailExistsReturn':1});

    useEffect(() => {
        document.title = Lang.forgot_password_title;
        ForgotPasswordValidations();
    }, []);

  function sendEmail(event: React.ChangeEvent<HTMLFormElement>){
    event.preventDefault();
      setEmailExistsCheck({...EmailExistsCheck, emailExistsReturn:1});
      if($("#forgot_password").valid() && EmailExistsCheck.emailExistsReturn===1) {
          API.post(`forgot-password`, {email: email}).then((res: AxiosResponse) => {
              successNotification(res.data);
              setEmail('');
          })
      }
  }

    function checkEmail(){
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(email)){
            SwitchLoader(LoaderType.INLINE);
            API.get(`check-email-exists-forgotpass`, {params: {email: email}}).then((res: AxiosResponse) => {
                if(res.data.status === 0){
                    setEmailExistsCheck({...EmailExistsCheck, emailExistsReturn:0});
                    setEmail('');
                } else {
                    setEmailExistsCheck({...EmailExistsCheck, emailExistsReturn:1});
                }
            })
        }
    }

  return(
      <section className="mainContent pageVerticalCenter">
          <div className="container">
              <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                      <div className="FormSite">
                          <FormLayout onSubmit={sendEmail} id={'forgot_password'} className="centerForm forgotForm" autoComplete="off">
                           <h1>{Lang.vt_trans_lang_41_forgotpw_title}</h1>
                              <p className="msgText">{Lang.vt_trans_lang_42_forgotpw_des}</p>
                              <div className="row">
                                  <div className="col-sm-8 offset-sm-2">
                                       <div className="form-group group mt-3 password">
                                          <label htmlFor="email">{Lang.email} <span className="required">*</span></label>
                                          <InlineLoader>
                                             <input type="email" name={'email'} className="form-control validate" placeholder={Lang.vt_trans_lang_43_example} value={email} onChange={(e) => { setEmail(e.target.value); setEmailExistsCheck({...EmailExistsCheck, emailExistsReturn:1}) } } onBlur={checkEmail}/>
                                          </InlineLoader>
                                          { EmailExistsCheck.emailExistsReturn === 0 &&
                                            <label id="email-exists-error" className={'error'} style={{color:'red'}}>{Lang.email_does_not_match}</label>
                                          }
                                       </div>
                                  </div>
                              </div>

                          <div className="mt-5 text-center">
                                <Submit className={'btn btn-md btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_44_forgotbutton}/>
                          </div>
                          <p className="msgText2">{Lang.do_you_remember_password} <Link to="/login">{Lang.try_logging}</Link></p>
                        </FormLayout>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}
import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";
import {Footer, Header} from "../partial";
import {OrderDataState} from "../dashboard/State";
import {AxiosResponse} from "axios";
import API from "../common/AxoisClient";
import moment from "moment";
import _ from "lodash";
import {DateTimeFormat, OrderStatus, OrderType} from "../model/Order";
import {ErrorNotification, getToLanguage, successNotification} from "../common/helpers";
import {OrderEditCommentModel} from "../model/OrderEditComment";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
declare let window: any;

export function OrdersDetailPage (props: any) {

    const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);
    const [show, setShow] = useState(false);
    const [invitationMessage, setInvitationMessage] = useState();

    useEffect(() => {
        document.title = Lang.order_detail+' - '+Lang.vitolker_translator;
        getOrderDetail(props.match.params.id);
    }, []);

    function getOrderDetail(order_id:number) { //res.data.orderData.rating
        API.post(`orders/order-detail`, {'order_id': props.match.params.id}).then((res: AxiosResponse) => {
            const translatorOrderDetail = res.data.orderData;
            if(_.isEmpty(translatorOrderDetail)){
                API.post(`orders/order-invitation-detail`, {'order_id': props.match.params.id}).then((res: AxiosResponse) => {
                    setInvitationMessage(res.data.message);
                });
            }
            setTimeout(function () {
                let mainObj: any = $('.custom-rating-detail');
                if (mainObj.length > 0) {
                    mainObj.each(function (index: any, item: any) {
                        let obj: any = $(item);
                        let objRating = obj.attr('data-rating');
                        objRating = objRating != 'null' ? objRating : 0;
                        obj.rateYo({
                            starWidth: "20px",
                            spacing: "4px",
                            rating: res.data.orderData[0].feedback.rating,
                            readOnly: true
                        });
                    })
                }
            }, 100);
            setTransOrderDetail({...transOrderDetail, orderData: translatorOrderDetail});
        });
    }

    function performAction(assignment_action: any, order_id: any) {
        API.post(`orders/order-action`,assignment_action).then((res:AxiosResponse) => {
            if(res.data.status===1){
                successNotification(Lang.accept_assignment);
            } else if(res.data.status===2){
                successNotification(Lang.reject_assignment);
            } else if(res.data.status===3){
                ErrorNotification(Lang.already_accepted_someone);
            } else if(res.data.status===4 || res.data.status===5){
                ErrorNotification(res.data.message);
            }
            getOrderDetail(order_id);
        })
    }

    function assignmentAction(event:any, order_id:any, action:string) {
        let assignment_action = {'order_id': order_id, 'action': action}
        if (action === 'reject') {
            let confirm_text = Lang.reject_assignment_confirmation;
            Swal.fire({
                title: Lang.is_sure,
                text: confirm_text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: Lang.ok,
                cancelButtonText: Lang.cancel
            }).then((result: any) => {
                if (result.value) {
                    performAction(assignment_action, order_id);
                }
            })
        } else {
            performAction(assignment_action, order_id);
        }
    }

    if(transOrderDetail['orderData'] && transOrderDetail['orderData'][0]!=undefined && transOrderDetail['orderData'][0].language_from.country) {
        return (
            <div className="bgMain">
                <div className="wrapper">
                    <Header {...props}/>
                    <section className="mainContent">
                        <div className="container">
                            <div className="myOrderPage orderDetailPage">
                                <div className="orderPreview">
                                    <h1 className="pageTitle text-center">{Lang.order_detail}</h1>
                                    <div className="blockStep">
                                        <div className="blockStepList">
                                            <h3 className="stripTitleNew">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <i className="far fa-file-alt"/> {Lang.order_detail} <span className={'orderID'}> #{transOrderDetail['orderData'][0].order_no}</span>
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                        <div className="colData"><span>{Lang.vt_trans_lang_56_invationdate} :</span>&nbsp;&nbsp;&nbsp;<span
                                                            className="timer"><p><i className="far fa-calendar-alt" aria-hidden="true"/> {moment(transOrderDetail['orderData'][0].created_at).format('dddd')}  {moment(transOrderDetail['orderData'][0].created_at).format('DD-MM-YYYY')}</p><p><i
                                                            className="far fa-clock" aria-hidden="true"> </i> {moment(transOrderDetail['orderData'][0].created_at).format('HH:mm')}</p></span></div>
                                                    </div>
                                                </div>
                                            </h3>

                                            <div className="row mt-4 mb-4">
                                                <div className="col-lg-12 col-xl-6">
                                                    <div className="row">
                                                        {transOrderDetail['orderData'][0].translate_by == undefined &&
                                                        <><div className="col-left">
                                                            <img src='/theme/images/user-placeholder.jpg'
                                                                 width="78" className="img-fluid rounded-circle"/>
                                                        </div>
                                                            <div className="col-right infoUserP">
                                                                <h4 className="Usertype">{Lang.translator}</h4>
                                                                <ul>
                                                                    {/*<li><i className="fas fa-sad-tear"/>
                                                                        {Lang.not_assigned}</li>*/}
                                                                </ul>
                                                            </div></>
                                                        }
                                                        {transOrderDetail['orderData'][0].translate_by!=undefined &&
                                                        <><div className="col-left">
                                                            <img
                                                            src={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.profile_image_small:''}
                                                            alt={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} width="78"
                                                            className="img-fluid rounded-circle"/>
                                                        </div>
                                                        <div className="col-right infoUserP">
                                                            <h4 className="Usertype">{Lang.vt_trans_lang_52_invationtranslator}</h4>
                                                            <ul>
                                                                <li><i className="far fa-user"/>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} <b>{(transOrderDetail['orderData'][0].translate_by!=undefined)?'('+transOrderDetail['orderData'][0].translate_by.user_code+')':''}</b></li>
                                                                <li><i className="far fa-envelope"/><a href={'mailto:'+((transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:'')}>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:''}</a></li>
                                                            </ul>
                                                            {/*{(!_.isEmpty(transOrderDetail['orderData'][0].order_files) && transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT && transOrderDetail['orderData'][0].order_files!==undefined) &&
                                                            <a target="_blank" href={transOrderDetail['orderData'][0].order_files[0].name} className="btn btn-outline-green btn-sm"><i className="fas fa-download"/>{Lang.translator_download_document}</a>
                                                            }*/}
                                                            {(transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT && (transOrderDetail['orderData'][0].status==OrderStatus.COMPLETED || transOrderDetail['orderData'][0].status==OrderStatus.UNDER_QA)) &&
                                                            <div className={'scrollbar-inner minHeight200'}>
                                                                <ul className={'document_upload_list'}>
                                                                    {
                                                                        transOrderDetail['orderData'][0].order_files &&
                                                                        transOrderDetail['orderData'][0].order_files.length > 0 &&
                                                                        transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                                                            if (orderFilesTranslator.type == 'complete') {
                                                                                return <li className={''}>

                                                                                    <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                                                                        <i className="far fa-file-alt"/>
                                                                                        <span>{orderFilesTranslator.file_name}</span>
                                                                                        <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                                                                    </Link>
                                                                                </li>
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </div>
                                                            }
                                                        </div></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 mobileSpace">
                                                    <div className="row">
                                                        <div className="col-left"><img
                                                            src={transOrderDetail['orderData'][0].customer.profile_image_small}
                                                            alt={transOrderDetail['orderData'][0].customer.name}  width="78"
                                                            className="img-fluid rounded-circle"/></div>
                                                        <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_54_invationCustomer}</h4>
                                                            <ul>
                                                                <li><i className="far fa-user"/>{transOrderDetail['orderData'][0].customer.name} <b>{'('+transOrderDetail['orderData'][0].customer.user_code+')'}</b></li>
                                                            </ul>
                                                            {/*{(!_.isEmpty(transOrderDetail['orderData'][0].document) && transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT) &&
                                                            <a target="_blank" href={transOrderDetail['orderData'][0].document}
                                                               className="btn btn-outline-green btn-sm"><i
                                                                className="fas fa-download"/>{Lang.customer_download_document}</a>
                                                            }*/}
                                                            {transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT &&
                                                            <div className={'scrollbar-inner minHeight200'}>
                                                                <ul className={'document_upload_list'}>
                                                                    {
                                                                        transOrderDetail['orderData'][0].order_files &&
                                                                        transOrderDetail['orderData'][0].order_files.length > 0 &&
                                                                        transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                                                            if (orderFilesTranslator.type == 'create') {
                                                                                return <li className={''}>
                                                                                    <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                                                                        <i className="far fa-file-alt"/>
                                                                                        <span>{orderFilesTranslator.file_name}</span>
                                                                                        <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                                                                    </Link>
                                                                                </li>
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
											<br/>
                                            <div className="profileDataRow">
                                                <div className="row">
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.order_no}.</span> #{transOrderDetail['orderData'][0].order_no}</div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.translation_type}</span>
                                                            <span data-toggle="tooltip" data-placement="left" title={transOrderDetail['orderData'][0].translation_type.title}
                                                                  className="d-inline">
 									<img src={transOrderDetail['orderData'][0].translation_type.small_icon} alt={transOrderDetail['orderData'][0].translation_type.title} width="25"/>
 								</span>
                                                            {transOrderDetail['orderData'][0].translation_type.title}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.language}</span>
                                                            <div className="language-section">
                                  <span>
                            <img src={(transOrderDetail['orderData'][0].language_from.country.iso!==undefined?window.PUBLIC_URL +'/flags/'+transOrderDetail['orderData'][0].language_from.country.iso.toLowerCase()+'.png':'')}/>{transOrderDetail['orderData'][0].language_from.language_name}
                                      <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span> {getToLanguage(transOrderDetail['orderData'][0])}
                          </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.vt_trans_lang_51_invationDate}</span>
                                                            <span className="timer">
 									<p><i className="far fa-calendar-alt" aria-hidden="true"/>{moment(transOrderDetail['orderData'][0].order_date).format('dddd')}  {moment(transOrderDetail['orderData'][0].order_date).format('DD-MM-YYYY')}</p>
 									<p><i className="far fa-clock" aria-hidden="true"> </i>{(transOrderDetail['orderData'][0].translation_type_id != OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id != OrderType.PHONE_MESSAGE) &&
                                    transOrderDetail['orderData'][0].order_from +' - ' } {transOrderDetail['orderData'][0].order_to}</p>
 									</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.vt_trans_lang_53_Institution}</span>{transOrderDetail['orderData'][0].company.name}</div>
                                                    </div>

                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData"><span>{Lang.department}</span>{(transOrderDetail['orderData'][0].department!=undefined)?transOrderDetail['orderData'][0].department.name:''}</div>
                                                    </div>

                                                    {transOrderDetail['orderData'][0].comment_for_translator && transOrderDetail['orderData'][0].comment_for_translator.length > 0 &&
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="colData">
                                                            <span>{Lang.vt_trans_lang_58_comment}</span>{transOrderDetail['orderData'][0].comment_for_translator}
                                                        </div>
                                                    </div>
                                                    }

                                                    {transOrderDetail['orderData'][0].meeting_address && transOrderDetail['orderData'][0].meeting_address.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                                                    <div className="col-sm-6">
                                                        <div className="colData"><span>{Lang.meeting_address}</span> <i
                                                            className="fas fa-map-marker-alt"/> {(transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) ? transOrderDetail['orderData'][0].meeting_address+', '+transOrderDetail['orderData'][0].meeting_zip_code+' '+transOrderDetail['orderData'][0].meeting_city : ''}
                                                        </div>
                                                    </div>
                                                    }
                                                    {transOrderDetail['orderData'][0].addition_info && transOrderDetail['orderData'][0].addition_info.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                                                    <div className="col-sm-6">
                                                        <div className="colData"><span>{Lang.addition_info}</span> <i
                                                            className="fas fa-map-marker-alt"/> {transOrderDetail['orderData'][0].addition_info}
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {!_.isEmpty(transOrderDetail['orderData'][0].tel_message) &&
                                            <div className="blockStepList custmorRow">
                                                <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.vt_trans_lang_57_telephoneMSG}</h3>
                                                <div className="profileDataRow">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="colData"> {transOrderDetail['orderData'][0].tel_message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {transOrderDetail['orderData'][0].order_files!=undefined && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE && !_.isEmpty(transOrderDetail['orderData'][0].order_files) &&
                                        <div className="blockStepList custmorRow">
                                            <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.telephone_translated_msg}</h3>
                                            <div className="profileDataRow">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="colData"> {transOrderDetail['orderData'][0].order_files[0].name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {transOrderDetail['orderData'][0].status !== OrderStatus.INVITATION_SENT &&
                                            <div className="blockStepList custmorRow">
                                                <h3 className="stripTitleNew"><i className="far fa-user"/> {Lang.vt_trans_lang_55_invationContact}</h3>
                                                <div className="profileDataRow">
                                                    <div className="row">
                                                        {
                                                            transOrderDetail['orderData'][0].contact_persons &&
                                                            transOrderDetail['orderData'][0].contact_persons.length > 0 &&
                                                            transOrderDetail['orderData'][0].contact_persons.map((contactPersons: any, index: number) => {
                                                                return <>

                                                                    <div className="col-sm-6">
                                                                        {index==0 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                                                        <h4 className="Usertype">{Lang.contact_first}</h4>
                                                                        }
                                                                        {index==1 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                                                        <h4 className="Usertype">{Lang.contact_second}</h4>
                                                                        }
                                                                        <div className="colData"><i className="far fa-user"/> {contactPersons.name}</div>
                                                                        { !_.isEmpty(contactPersons.email) &&
                                                                        <div className="colData"><i className="far fa-envelope"/> <a href={'mailto:'+(contactPersons.email)}>{contactPersons.email}</a>
                                                                        </div>
                                                                        }
                                                                        {!_.isEmpty(contactPersons.phone_no) &&
                                                                        <div className="colData"><i className="fas fa-mobile-alt"/> <a
                                                                            href={'tel:' + (contactPersons.phone_no)}>{contactPersons.phone_no}</a>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {transOrderDetail['orderData'][0].edit_request &&
                                        transOrderDetail['orderData'][0].edit_request.length > 0 &&
                                        <div className="blockStepList"><h3 className="stripTitleNew"><i
                                            className="fas fa-pencil-alt"/> {Lang.order_correction}</h3>
                                            <div className="kt-history">
                                                <div className="historybox">
                                                    {
                                                        transOrderDetail['orderData'][0].edit_request &&
                                                        transOrderDetail['orderData'][0].edit_request.length > 0 &&
                                                        transOrderDetail['orderData'][0].edit_request.map((editcomments: OrderEditCommentModel, index: number) => {
                                                            return <div className="kt-timeline-v2__item">
                                                                <div className="kt-timeline-v2__item-cricle"><i
                                                                    className="fa fa-genderless kt-font-danger"/></div>
                                                                <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                    <div className="colData"><span
                                                                        className="badge badge-info">{Lang.request_for_correction}</span></div>
                                                                    <div className="colData"><i className="far fa-calendar-alt"
                                                                                                aria-hidden="true"/> {moment(editcomments.created_at).format(DateTimeFormat.DATE)}&nbsp; <i
                                                                        className="far fa-clock" aria-hidden="true"> </i> {moment(editcomments.created_at).format(DateTimeFormat.TIME)}
                                                                    </div>
                                                                    <div className="colData">{editcomments.edit_order_message}</div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {transOrderDetail['orderData'][0].feedback && Number(transOrderDetail['orderData'][0].feedback.rating) >0 &&
                                        <div className="blockStepList">
                                            <h3 className="stripTitleNew"><i className="far fa-comment-alt"/> {Lang.order_feedback}</h3>
                                            <div className="profileDataRow">
                                                <div className="colData"><span>{Lang.rating} </span>
                                                    <div className={'rating custom-rating-detail'} data-rating={transOrderDetail['orderData'][0].feedback.rating?transOrderDetail['orderData'][0].feedback.rating:0}/>
                                                </div>
                                                {transOrderDetail['orderData'][0].feedback.message &&
                                                <div className="colData">
                                                    <span>{Lang.message}</span>{transOrderDetail['orderData'][0].feedback.message}
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        }

                                        {( transOrderDetail['orderData'][0].status != OrderStatus.COMPLETED && transOrderDetail['orderData'][0].status != OrderStatus.INVITATION_SENT && transOrderDetail['orderData'][0].status != OrderStatus.CANCELLED && transOrderDetail['orderData'][0].status != OrderStatus.UNDER_QA && transOrderDetail['orderData'][0].order_date != undefined && moment(transOrderDetail['orderData'][0].order_date).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") && moment(transOrderDetail['orderData'][0].order_date+' '+transOrderDetail['orderData'][0].order_from) <= moment() && transOrderDetail['orderData'][0].translation_type_id !== OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id !== OrderType.PHONE_MESSAGE) &&
                                        <div className="blockStepList button text-center">
                                            <Link className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail" to={`/complete-order/${transOrderDetail['orderData'][0].id}`}>{Lang.vt_trans_lang_59_submit}</Link>
                                        </div>
                                        }

                                        {
                                            ((transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT || transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE) && transOrderDetail['orderData'][0].status != OrderStatus.COMPLETED && transOrderDetail['orderData'][0].status != OrderStatus.INVITATION_SENT && transOrderDetail['orderData'][0].status != OrderStatus.CANCELLED && transOrderDetail['orderData'][0].status != OrderStatus.PAID && transOrderDetail['orderData'][0].status != OrderStatus.UNDER_QA ) &&

                                            <div className="blockStepList button text-center">
                                                <Link className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail" to={`/complete-order/${transOrderDetail['orderData'][0].id}`}>{Lang.vt_trans_lang_59_submit}</Link>
                                            </div>
                                        }

                                        {(transOrderDetail['orderData'][0].status == OrderStatus.INVITATION_SENT && moment(transOrderDetail['orderData'][0].order_date+' '+transOrderDetail['orderData'][0].order_to) >= moment()) &&
                                            <div className="col-lg-7 col-md-5 col-sm-12 mobileSpace20">
                                                <div className="btn_invitation">
                                                    <a className="btnStatusSuccess" href="javascript:void(0)"
                                                       onClick={(e) => assignmentAction(e, transOrderDetail['orderData'][0].id, 'accept')}>{Lang.accept}</a>
                                                    <a className="btnStatusDanger" href="javascript:void(0)"
                                                       onClick={(e) => assignmentAction(e, transOrderDetail['orderData'][0].id, 'reject')}>{Lang.reject}</a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        )

    } else {
        return (
            <div className="bgMain">
                <div className="wrapper">
                    <Header {...props}/>
                    <section className="mainContent">
                        <div className="container">
                            <div className="myOrderPage orderDetailPage">
                                <div className="orderPreview">
                                    <h5 className="text-center">{invitationMessage}</h5>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        )
    }
}

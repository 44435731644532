import Lang from "../../common/lang/Lang";
import { translateThisKey } from "../../common/helpers";

export const ChangePasswordValidations = function () {
  $(document).ready(function () {

    // let strongRegex:any = new RegExp("^.*(?=.{8,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]){1})(?=.*\\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$");
    let strongRegex:any = new RegExp("^.*(?=.{8,})((?=.*[a-z]){1}).*$");
    $.validator.addMethod("pwcheck", function(value:any) {
      $.validator.messages.pwcheck = Lang.strong_password_text;
	    $.validator.messages.equalTo = Lang.vt_trans_lang_100_cnpw;
      return strongRegex.test(value);
    });

    $( "#change_password" ).validate({
      rules: {
        current_password: "required",
        new_password: {
          minlength : 8,
          required: true,
          pwcheck:true,
        },
        confirm_password: {
          minlength : 8,
          required: true,
          equalTo: "#new_password",
          pwcheck:true,
        },
      },
    });

  });

  setTimeout(function () {
    ($('#new_password') as any).pwstrength({
      common: {
        minChar:8,
        maxChar:20,
        onScore: function (options:any, word:any, totalScoreCalculated:any) {
          ($('#new_password')as any).parents('.password').find('.progressBar_message ').show();
          let scoreData:number = 0;
          if (word.length<8) {
            scoreData += -(2.9)*word.length;
          }

          if (word.match(/[!@#$%^&*()\-_=+{};:,<.>]/)) {
            scoreData += (1.9)*(word.length/2);
          }else{
            scoreData += -(4.98)*(word.length/2);
          }

          if (word.match(/[0-9]/)) {
            scoreData += (1.9)*(word.length/2);
          }else{
            scoreData += -(4.98)*(word.length/2);
          }

          if (word.match(/[a-z]/)) {
            scoreData += (1.9)*word.length;
          }else{
            scoreData += -(4.98)*word.length;
          }

          if (word.match(/[A-Z]/)) {
            scoreData += (1.9)*word.length;
          } else {
            scoreData += -(4.98)*word.length;
          }

          if(scoreData > 100){
            scoreData = (scoreData-(2.38)*word.length)
          } else if(scoreData < 0){
            scoreData = (2.38)*word.length;
          }
          return scoreData;
        },
      },
      rules: {
        scores: {
          wordInvalidChar: -100,
          wordIsACommonPassword: -100,
          wordLetterNumberCharCombo: -2,
          wordLetterNumberCombo: -2,
          wordLowercase: -1,
          wordMaxLength: 2,
          wordMinLength: 1,
          wordNotEmail: -100,
          wordOneNumber: -3,
          wordOneSpecialChar: -3,
          wordRepetitions: -25,
          wordSequences: -20,
          wordSimilarToUsername: -100,
          wordThreeNumbers: -5,
          wordTwoCharacterClasses: -2,
          wordTwoSpecialChar: -5,
          wordUpperLowerCombo: -2,
          wordUppercase: -3,
        }
      },
      i18n:{
        t: function (key:string) {
            let result:string = translateThisKey(key);
            return result === key ? '' : result;
        }
      },
      ui:{
        container: "#pwd-container",
        viewports: {
          progress: "#passwordStrengthProgress",
          verdict: "#passwordStrengthVerdict"
        },
      }
    });
  },500);

}


export const EditBasicInfoValidations = function () {
  $(document).ready(function () {
    $( "#edit_basicinfo_form" ).validate({
      rules: {
        name: {
          required: true,
          minlength: 3,
          maxlength: 50,
        },
        email: "required",
        country_code: "required",
        mobile_no: "required",
        address: "required",
        social_no: "required",
        driving_license: "required",
        own_vehicle: {
          required: {
            depends: function(element:any){
              if($("#customRadio12").val()==1){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        police_verified: "required",
        verification_number: {
          required: {
            depends: function(element:any){
              if($("#customRadio7").val()==1){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        gender: "required",
        about: {
          maxlength: 500,
        },
      },
    });

  });
}



export const EditBankInfoValidations = function () {
  $(document).ready(function () {
    $( "#edit_bankinfo_form" ).validate({
      rules: {
        bank_registration_no: "required",
        account_no: "required",
        bank_name: "required",
        //iban: "required",
        account_holder_name: {
          required: true,
          minlength: 3,
          maxlength: 50,
        },
      },
    });

  });
}

export const AddLanguageValidations = function () {
  $(document).ready(function () {

    $.validator.addMethod("fromlang_valid",  (value: any, element: HTMLElement, params: any) =>{
      let flag = true;
      if(value === $('#to_language_id').val()){
        flag = false;
      }
      return flag;
    },Lang.fromlang_valid);

    $( "#add_language_form" ).validate({
      rules: {
        from_language_id: {
          required: true,
          fromlang_valid: true,
        },
        to_language_id: "required",
        mother_tongue: "required",
        /*from_accent: "required",
        to_accent: "required",
        how_much_hours: "required",
        is_certificate: "required",
        certificate: {
          required: {
            depends: function(element:any){
              if($("#customRadio").val()==1){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        level: {
          required: {
            depends: function(element:any){
              if($("#customRadio").val()==1){
                return true;
              } else {
                return false;
              }
            }
          }
        }*/
      },
    });

  });
}

export const EditEmailValidations = function () {
  $(document).ready(function () {
    $( "#edit_email_form" ).validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
    });

  });
}

export const EditMobileNoValidations = function () {
  $(document).ready(function () {
    $( "#edit_mobile_no" ).validate({
      rules: {
        country_code: {
          required: true
        },
        mobile_no: {
          required: true,
          minlength:8,
          number: true
        }
      },
    });

  });
}

export const LeaveFromValidations = function () {
  $(document).ready(function () {
    $( "#leave_info_form" ).validate({
      rules: {
        leave_type: {
          required: true
        },
        from_date: {
          required: true
        },
        to_date: {
          required: {
            depends: function(element:any){
              if($('#multiple_days').val()==3){
                return true;
              } else {
                return false;
              }
            }
          },
        },
        type_of_half_day: {
          required: {
            depends: function(element:any){
              if($('#half_day').val()==2){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        reason: {
          required: true
        }
      },
    });

  });
}

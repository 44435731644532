import React from 'react';
import _ from 'lodash';

export function NotificationsTitle (props: any) {

    return (
        <>
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='assign_translator') &&
            <span className="badge badge-primary">{props.notification_type.title}</span>
        }
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='order_complete') &&
            <span className="badge badge-success">{props.notification_type.title}</span>
        }
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='translator_send_invitation') &&
            <span className="badge badge-warning">{props.notification_type.title}</span>
        }
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='ONLINE_TEST') &&
            <span className="badge badge-info">{props.notification_type.title}</span>
        }
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='contract_send') &&
            <span className="badge badge-success">{props.notification_type.title}</span>
        }
        {(!_.isEmpty(props.notification_type.type) && props.notification_type.type=='UNDER_QA') &&
            <span className="badge badge-success">{props.notification_type.title}</span>
        }
        {(_.isEmpty(props.notification_type.type)) &&
            <span className="badge badge-primary">{props.notification_type.title}</span>
        }

    </>
    );
}

import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Lang from "../../common/lang/Lang";
import API from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {showNotification} from "../../common/helpers";
import {DocumentInfoFormState} from "./State";
import {DocumentValidations} from "./Validations";
import {Auth} from "../../Auth/Auth";
import {FormLayout, Submit} from "../../common";
import Cookies from "js-cookie";
import _ from 'lodash';
const moment =  require ("moment");
declare let window:any;

export function DocumentUpload(props: RouteComponentProps) {

    const [documentInfo, setDocumentInfo] = useState(DocumentInfoFormState);
    useEffect(() => {
        document.title = Lang.document_page_title+' - '+Lang.vitolker_translator;;
        ($('[data-toggle="tooltip"]') as any).tooltip();
        //DocumentValidations();
        API.get(`register/${Auth.userInfo.id}`).then((response: AxiosResponse) => {

            setDocumentInfo({...documentInfo, user_id: localStorage.getItem("login_user_id"), resume_display_filename: response.data.resume_filename, penalty_display_filename: response.data.criminal_record_filename, resume_file_name:response.data.resume, penalty_certificate_filename: response.data.criminal_record})
        });
        ($('.scrollbar-inner') as any).scrollbar();
        $(window).on('scroll', function() {
            let navHeight:number;
            navHeight = ($(window) as any).height() - 600;
            if (($(window) as any).scrollTop() > navHeight) {
                ($('.mainHeader') as any).addClass('navShadow');
            } else {
                ($('.mainHeader') as any).removeClass('navShadow');
            }
        });
    }, []);

    function tabChange(event: any,tab:string){
        API.post(`change-registration-step`, {'registration_step': tab}).then((res: AxiosResponse) => {
            localStorage.setItem('registration_step', '2');
            props.history.push('/bank-info');
        })
    }

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){

        event.preventDefault();
        if($("#document_form").valid()) {
            API.post(`document-info-submit`, documentInfo).then((res: AxiosResponse) => {
                showNotification("success", Lang.document_upload_success);
                localStorage.setItem('registration_step', '4');
                props.history.push('/profile');
            })
        } else {
            setTimeout(function() {
                $(".custom-file .error").removeAttr("for");
            }, 500);
        }
    }

    function cancelUpload(e: any,document_type:string) {
        let fileName = '';
        let doc_type = '';
        if(document_type === 'resume'){
             fileName = documentInfo.resume_file_name;
             doc_type = 'resume';
            setDocumentInfo({...documentInfo, resume_file_name: '', resume_display_filename:''});
        } else if(document_type === 'penalty_certificate'){
             fileName = documentInfo.penalty_certificate_filename;
             doc_type = 'criminal_record';
            setDocumentInfo({...documentInfo, penalty_certificate_filename: '', penalty_display_filename:''});
        } /*else if(document_type==='children_certificate'){
            setDocumentInfo({...documentInfo, child_certificate_filename: '', child_display_filename:''});
        }*/
        API.post('cancel-uploaded-document', {'document': doc_type, 'file_name': fileName}).then((res) => {
            //showNotification("success", Lang.document_upload_success);
        });
    }

    function handleUpload(e: any,document_type:string) {
        let formData = new FormData();
        let doc_type = (document_type == 'penalty_certificate')?'penalty_certificate':document_type;
        const dFileName = e.target.files[0].name;
        formData.append(document_type, e.target.files[0]);
        formData.append('document_name', doc_type);
        formData.append('orignal_filename', dFileName);
        API.post('document-info', formData).then((res) => {
            if(res.data.type === 'resume'){
                setDocumentInfo({...documentInfo, resume_file_name: res.data.filename, resume_display_filename:dFileName});
            } else if(res.data.type === 'children_certificate'){
                setDocumentInfo({...documentInfo, child_certificate_filename: res.data.filename, child_display_filename:dFileName});
            } else if(res.data.type === 'penalty_certificate'){
                setDocumentInfo({...documentInfo, penalty_certificate_filename: res.data.filename, penalty_display_filename:dFileName});
            }
        });
    }

    function Logout() {
        Cookies.set('remember', '', { expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        Cookies.set('remember_token', '', {expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        ($('.bs-tooltip-bottom') as any).removeClass('show');
        ($('.tooltip') as any).addClass('hide');
        Auth.signout();
        //window.location.href = '/login';
    }

    return(
        /*<div  className="bgCR">*/
        <div  className="bgMain">
            <div className="wrapper">
                <header className="mainHeader">
                    <div className="container">
                        <nav className="navbar">
                            <div className="col-6">
                                <Link to={'#'}><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/> </Link>
                            </div>
                            <div className="col-6 text-right">
                                <ul>
                                    <li>
                                        <a href={'#'} ><span data-toggle='tooltip' data-placement='bottom' data-original-title="Logout" onClick={() => Logout()}><i className="fas fa-power-off"/></span></a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
                <section className="mainContent processBarWOutHead">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12 documentUpload stepForm">
                                <div className="FormSite userinfoStep">
                                    <ul id="progressbar" className="stepListNav userTNav">
                                        <li className="active"/>
                                        <li className="active">
                                            <div className="step">
                                                <div className="step1"><i className="iconCheck fas fa-check-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="active">
                                            <div className="step">
                                                <div className="step2"><i className="iconCheck fas fa-check-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="step">
                                                <div className="step3"><i className="iconCheck fas fa-check-circle"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className={"boxWhite boxShadow"}>
                                        <h1 className={'title'}><i className="fas fa-file-alt"/>{Lang.vt_trans_lang_70_document_title}</h1>
                                        <FormLayout id="document_form" onSubmit={handleSubmit}  autoComplete="off">

                                        <div className="document-upload mt-30">
                                            <div className="row  mb-22">
                                                <div className="col-md-6 col-sm-12 text-center">
                                                    <div className="doc-box">
                                                        <img src={`${window.PUBLIC_URL}/theme/images/cv.png`} alt="vt-img"/>
                                                            <p>{Lang.resume}</p>
                                                            <div className="custom-file">
                                                                {!_.isEmpty(documentInfo.resume_display_filename) &&
                                                                    <span className="btn btn-white hvr-rectangle-out browse-btn fileNameBtn">
                                                                    {(documentInfo.resume_display_filename.length>20)?documentInfo.resume_display_filename.substr(0, 20)+'...':documentInfo.resume_display_filename}
                                                                    <button type="button" className="close closeBtnFile" onClick={(e) => cancelUpload(e, 'resume')} data-dismiss="modal">×</button>
                                                                    </span>
                                                                }
                                                                {_.isEmpty(documentInfo.resume_display_filename) &&

                                                                    <div className={'demo-section k-content customInputButton'}><input type={'file'} onChange={(e) => handleUpload(e, 'resume')} name={'resume'} id={'resumeUpload'} className="inputfile validate"/>
                                                                <label htmlFor={'resumeUpload'} className={'inputLabel'}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg>
                                                                    <span>{Lang.choose_a_file}</span></label></div>
                                                                }
                                                            </div>
                                                    </div>
                                                </div>
                                                {/*<div className="col-md-4 col-sm-12 text-center">
                                                    <div className="doc-box">
                                                        <img src={`${window.PUBLIC_URL}/theme/images/certificate.png`} alt="vt-img"/>
                                                            <p>{Lang.children_certificate}</p>
                                                            <div className="custom-file">
                                                                {documentInfo.child_display_filename !== '' &&
                                                                <span className="btn btn-white hvr-rectangle-out browse-btn fileNameBtn">
                                                                    {(documentInfo.child_display_filename.length>20)?documentInfo.child_display_filename.substr(0, 20)+'...':documentInfo.child_display_filename}
                                                                <button type="button" className="close closeBtnFile" onClick={(e) => cancelUpload(e, 'children_certificate')} data-dismiss="modal">×</button>
                                                                    </span>
                                                                }
                                                                {documentInfo.child_display_filename === '' &&

                                                                <div className={'demo-section k-content customInputButton'}><input
                                                                    type={'file'}
                                                                    onChange={(e) => handleUpload(e, 'children_certificate')} name={'children_certificate'} id={'children_certificate'} className="inputfile validate"/>
                                                                    <label htmlFor={'children_certificate'} className={'inputLabel'}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg>
                                                                    <span>{Lang.choose_a_file}</span></label></div>


                                                                }
                                                            </div>
                                                    </div>
                                                </div>*/}
                                                <div className="col-md-6 col-sm-12 text-center">
                                                    <div className="doc-box">
                                                        <img src={`${window.PUBLIC_URL}/theme/images/penalty-certificate.png`} alt="vt-img"/>
                                                            <p>{Lang.penalty_certificate}</p>
                                                            <div className="custom-file">
                                                                {!_.isEmpty(documentInfo.penalty_display_filename) &&
                                                                <span className="btn btn-white hvr-rectangle-out browse-btn fileNameBtn">
                                                                    {(documentInfo.penalty_display_filename.length>20)?documentInfo.penalty_display_filename.substr(0, 20)+'...':documentInfo.penalty_display_filename}
                                                                <button type="button" className="close closeBtnFile" onClick={(e) => cancelUpload(e, 'penalty_certificate')} data-dismiss="modal">×</button>
                                                                </span>
                                                                }
                                                                {_.isEmpty(documentInfo.penalty_display_filename) &&

                                                                <div className={'demo-section k-content customInputButton'}><input
                                                                    type={'file'}
                                                                    onChange={(e) => handleUpload(e, 'penalty_certificate')} name={'penalty_certificate'} id={'penalty_certificate'} className="inputfile validate"/>
                                                                    <label htmlFor={'penalty_certificate'} className={'inputLabel'}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg>
                                                                    <span>{Lang.choose_a_file}</span></label></div>
                                                                }
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-5 text-center">
                                            <button className="previous btn btn-default hvr-rectangle-out" type={'button'} onClick={(e) => tabChange(e,'2')}>{Lang.vt_trans_lang_48_documentBackbutton}</button> &nbsp;
                                            <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_90_save}/>
                                        </div>
                                        </FormLayout>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="mainFooter">
                    <div className="footer_section">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-12">
                                    <ul className="footer_right footer_left">
                                        <li>{Lang.we_love_data_regulation}</li>
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="storeMobLink">
                                        <span className="findOnUsText mr-2 text-center">{Lang.find_us_on}</span>
                                        <ul className="BtnApp">
                                            <li>
                                                <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/googlePlay.png`} alt="" title=""/></a>
                                            </li>
                                            <li>
                                                <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/appStore.png`} alt="" title=""/></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-12">
                                    <div className="footer_right">
                                        <p>{Lang.all_rights_reserved} &copy; {moment().year()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}
import React from 'react';
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import Lang from "../../common/lang/Lang";
import {Footer} from "../../partial";
declare let window:any;

export function ThankYou(props: RouteComponentProps) {
    const h1Title = () => {
        switch (props.location.pathname) {
            case "/thank-you":
                return Lang.vt_trans_lang_101_thank_you_registration;
            case "/account-active":
                return Lang.account_active_h1_title;
            default:
                return Lang.vt_trans_lang_16_welcome_text;
        }
    }

    const h5Title = () => {
        switch (props.location.pathname) {
            case "/thank-you":
                return (
                    <><h5>{Lang.vt_trans_lang_17_checkmail_text_2}</h5></>
                );
            default:
                return (
                    <></>
                );
        }
    }
    
    const paragraph = () => {
        switch (props.location.pathname) {
            case "/thank-you":
                return (
                    <><p>{Lang.vt_trans_lang_102_spam_folder}</p></>
                );
            case "/welcome":
                return (
                    <><p>{Lang.vt_trans_lang_18_thanks_emailverified}</p></>
                );
            case "/already-verified":
                return (
                    <><p>{Lang.already_email_verified}</p></>
                );
            case "/link-expired":
                return (
                    <><p>{Lang.email_link_expire}</p></>
                );
            case "/first-verify-email-address":
                return (
                    <><p>{Lang.first_verify_email}</p></>
                );
            case "/account-active":
                return (
                <>
                    <p>{Lang.account_active_next_steps_1}</p>
                </>
                );
            default:
                return "";
        }
    }
    
    return(
        <div className="bgMain">
            <div className="wrapper">
                <header className="mainHeader">
                    <div className="container">
                        <nav className="navbar">
                            <div className="col-6">
                                <a className="navbar-brand" href="#">
                                    <img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt="" title=""/>
                                </a>
                            </div>
                            <div className="col-6 text-right">
                            </div>
                        </nav>
                    </div>
                </header>
                <section className="mainContent pageVerticalCenter">
                    <div className="container">
                        <div className="PageContent text-center">
                            <div className="container">
                                <h1>
                                {h1Title()}
                                </h1>
                                {h5Title()}
                                {paragraph()}
                                <Link className="btn btn-green hvr-rectangle-out" to="/login">{Lang.login} <i
                                    className="fas fa-angle-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>

    )
}
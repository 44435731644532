import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";
import Cookies from 'js-cookie';
import {isMobile} from "react-device-detect";
import {getAppDownloadList} from "../services/AppDownloadService";
import _ from "lodash";
const moment =  require ("moment");

declare let window: any;

export function WebAppDownloadModal (props: any) {

    const [appDownloadData, setAppDownloadData] = useState<any>();

    useEffect(() => {

        getAppDownloadList().then(appDownloadData => {
            setAppDownloadData(appDownloadData.data);

            let days:number = appDownloadData.data.pop_again_shows_in_day;
            let cookieExpiryTime = moment(). add(days,'days').tz('Asia/Kolkata'). format('YYYY-MM-DD HH:mm');

            let curTime = moment.tz(new Date(cookieExpiryTime), 'Asia/Kolkata');
            curTime = curTime._i;

            let vitolker_app_model = Cookies.get('vitolker_app_model');

            if((vitolker_app_model=='undefined' || vitolker_app_model==undefined) && !isMobile){
                Cookies.set('vitolker_app_model', '1',{ expires :  curTime });
                ($('#webAppDownloadModal') as any).modal({
                    backdrop: 'static',
                    keyboard: true,
                    show:true
                });
            }
        });
    },[]);

  return (
      <>
          {!_.isEmpty(appDownloadData) &&
          <div className="modal fade" id="webAppDownloadModal">
              <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                      <div className="appBannerWeb">
                          <button type="button" className="close" data-dismiss="modal">×</button>
                          <div className="appWeb">
                              <div className="row align-items-center">
                                  <div className="col-lg-5 order-lg-2">
                                      <h3>{Lang.vt_trans_lang_34_popup_heading}</h3>
                                      <p>{Lang.vt_trans_lang_35_popup_des1}</p>
                                      <ul>
                                          <li>
                                              <a href={appDownloadData.ios_app_store_link}>
                                                  <img src="theme/images/downloadAppStore.png" alt="" title={Lang.app_store}
                                                       className="img-fluid"/>
                                              </a>
                                          </li>
                                          <li>
                                              <a href={appDownloadData.google_play_store_link}>
                                                  <img src="theme/images/downloadGooglePlay.png" alt="" title={Lang.google_play}
                                                       className="img-fluid"/>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                                  <div className="col-lg-7 order-lg-1">
                                      <img src="theme/images/downloadWebImg.png" alt="" className="img-fluid"/>
                                  </div>
                              </div>
                          </div>
                          <p className="popFooter">
                              <img src="theme/images/vitolkerIcon.png" alt="" className="img-fluid"/>{Lang.vitolker_with_love}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          }
      </>
  )
}
import React from 'react';
import {QuizStart} from "../online-test/QuizStart";

export function QuizStartTabs (props:any) {

    //console.log(props.match.params.id);

  return(
      <QuizStart quiz_id={props.match.params.id} from={'We Learn'}/>

  )
}
import React from 'react'
import { Icons } from '../../../common/Icon'

// styles
import sheet from './VideoLink.module.scss'

interface Props {
  video_meeting_link: string
}

const VideoLink = ({ video_meeting_link }: Props) => {

  return (
    <div className={sheet.link}>
      <button
        data-link={video_meeting_link}
        data-toggle="tooltip"
        data-placement="bottom"
        title="kopier link"
        className={`copy_link btnStatusPrimary showDetailsInfo ${sheet.copy}`}
      >
        <span className={sheet.copy_text}>
          <i className={Icons.link} />
        </span>
      </button>
      <a
        className="btnStatusPrimary showDetailsInfo mr-1"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Se videolink"
        target="blank"
        href={video_meeting_link}
        >
        <i className={Icons.external_link} /> 
      </a>
    </div>
  )
}

export default VideoLink

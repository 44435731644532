import React, {useState, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {CallStatus, CallType} from "../Config";
import {Ringing} from "./Ringing";
import _ from "lodash";
const moment = require("moment");



export function Connect(props: any) {

  const RingingWidth = 300;
  const ConnectWidth = 300;

  const [time, setTime] = useState('0:00');
  useEffect(() => {
    let interval:any;
    if(props.status === CallStatus.CONNECT){
      let counter = 0;
      interval = setInterval(() => {
        setTime(moment().hour(0).minute(0).second(counter++).format('HH : mm : ss'));
      }, 1000);
    }
    return () => clearInterval(interval);
  },[props.status])

  if(props.call_type === CallType.VIDEO){
    return (
      <Modal
        show={true}
        onHide={() => {}}
        dialogClassName="customModal videoModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <section className="videoPage">
          <div className="videoConference boxShadow">
            <div className="row">
              <div className="col-12">
                <div className="header">
                  <a href="#"><img className="fullscreenButton" src="theme/images/fullScreen_icon.png"/></a>
                  <h4 className="text-center">{time}</h4>
                </div>
              </div>
            </div>
            <div className="videoCallArea">
              {props.status === CallStatus.CONNECT &&
                <video  width="700" controls id={'remoteOpponentVideoElementId' } loop autoPlay={true}>
                  Your browser does not support the video tag.
                </video>
              }
              {props.status === CallStatus.RINGING &&
              <div className={"text-center outgoingVideo"}>
                <div className="profileIn profileEnimation"><img src="theme/images/john.png" alt="" title="" className="img-fluid" /></div>
                <div className="nameTitle">
                  <span> <strong>{_.capitalize(props.user.full_name)} </strong><br/> Ringing</span>
                </div>
              </div>
              }

              <div className="videoIn">

                <video height="140" id={'myVideoElementId' } muted loop autoPlay={true}>
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="btnsSet">
                {
                  props.status === CallStatus.CONNECT &&
                  <button type="button" className="btnWhite" onClick={props.onMute}>
                    {props.mute && <i className="fas fa-microphone-slash"/>}
                    {!props.mute && <i className="fas fa-microphone"/>}

                  </button>
                }
                <button type="button" className="btnRed" onClick={props.status === CallStatus.RINGING ? props.rejectCall : props.stopCall}>
                  <img src="theme/images/callClose.png"/>
                </button>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    )
  }


  if(props.call_type === CallType.AUDIO){
    return <Ringing {...props}/>
  }

  return null;
}
import React, {useRef, useEffect} from 'react';
import _ from 'lodash';
const moment = require("moment");

//https://www.malot.fr/bootstrap-datetimepicker/index.php
//https://gijgo.com/datepicker/example/bootstrap-4

export const datePicker = {format: 'dd-mm-yyyy',modal: true, header: true, type:'date',locale: 'da-da'};
export const timePicker = {format: 'HH:MM', modal: true, header: true, footer: true, type:'time', mode: '24hr'};
export const updatedTimePicker = { type: 'time', format: 'H:mm', minuteStep: 5,maxHours: 24,showMeridian: false, defaultTime: 'current', icons: {up: 'fa fa-chevron-up',down: 'fa fa-chevron-down'}};;



export interface DatetimePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: any
  onTimeChange?: any
}


export function DatetimePickerWrapper (props: DatetimePickerProps) {

  const datetime_picker = useRef(null);

  const options = {
    format: 'yyyy-mm-dd hh:ii',
    modal: true,
    header: true,
    //footer: true,
    change: function (e:any) {
      if(props.onChange !== undefined){
        props.onChange(e)
      }
    }
  };
  let newOptions = {...options, ...props.options}
  useEffect(() => {
    const $el = datetime_picker.current;
    if($el !== null ){
      if(newOptions.type === 'date'){
        ($($el) as any).datepicker(newOptions);
      }
      else if(newOptions.type === 'time'){
        ($($el) as any).timepicker(newOptions).on('changeTime.timepicker', (e: any) => (props.onChange as unknown as any)(e, $($el!)));
      } else {
        ($($el) as any).datetimepicker({...options, ...props.options});
      }
    }
  }, []);
  
  return(
    <input readOnly={true} type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref', ' '])} />
  )
}
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import Lang from "../common/lang/Lang";
import {Link} from "react-router-dom";
import { QuizModel, QuizQuestionModel, TranslatorQuizAnswersModel} from "../model";

export function OnlineTest (props: any) {

    const [onlineData,  setonlineTest]          = useState({} as QuizModel);
    const [questionAnswer,  setQuestionAnswer]  = useState([] as Array<QuizQuestionModel>);
    const [loader, setLoader]                   = useState({ 'isLoader': 0 });
    const [testAccept, setTestAccept]           = useState();

    useEffect(() => {
        document.title = Lang.online_test_heading+' - '+Lang.vitolker_translator;
        API.get(`online-test/2`).then((response: AxiosResponse) => {
            const onlineTestData = response.data[0];
			if(localStorage.getItem('send_online_test') === '2'){
                API.get(`online-tests/get-translator-test-answers`,{params:{'quiz_id' : response.data[0].id}}).then((res: AxiosResponse) => {
                    setTestAccept(res.data.test_accept.test_condition_accept);
                    setQuestionAnswer(res.data.answers);
                })
            }
            setonlineTest(onlineTestData);
            setLoader({...loader, isLoader: 1 });
        })
    },[]);

    function createMarkup(content: string) {
        return { __html: content };
    }

  return(

      <div className="bgMain">
        <div className="wrapper">
            <Header {...props}/>
            <section className="mainContent">
                <div className="container">
                    <div className="statisticsPage">
                        <div className="row align-items-center mb-20">
                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                                <h1 className="title"><i className="fas fa-file-alt"/>{Lang.online_test_heading}</h1>
                            </div>
                        </div>
                        {localStorage.getItem('send_online_test') === '1' &&
                            <div className="boxWhite boxShadow chartPieJet">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 text-center">
                                        <div className="online_testin">
                                            <h4>{onlineData.title}</h4>
                                            <p>{onlineData.description}</p>
												{onlineData.image !== null && onlineData.image !== '' &&
                                            <img src={onlineData.image} alt="vt-img"
                                                 style={{width: '560px', height: '465px'}}/>
												}
                                            <div className="online-test_btn">
                                                {/* <Link to={`online-test-start/${onlineData.id}`} */}
                                                {/* The route above is the old version of the route below, since there is only one online-quiz, we are using
                                                    the route below to obtain a clearer url  */}
                                                <Link to={`online-test-start`}
                                                      className="btn btn-green hvr-rectangle-out">{Lang.quiz_start_text}<i
                                                    className="fas fa-angle-right"/></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {localStorage.getItem('send_online_test') === '2' &&
                            <div className="boxWhite boxShadow chartPieJet quiz">
                                <div className="row">
                                    <div className="col-md-8 col-sm-12">
                                        <div className="left">
                                            <h4><b>{onlineData.title}</b></h4>
                                            <p>{onlineData.description}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="right">
                                            <img src={onlineData.image} alt="vt-img"
                                                 style={{width: '273px', height: '227px'}}/>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div id="accordion">
                                            <div className="accordion_heading">
                                                <h4>{Lang.your_answer}</h4>
                                            </div>
                                            {questionAnswer.map((data:QuizQuestionModel, index: number) => {

                                                return <><div className="card">
                                                    <div className="card-header" id="heading3">
                                                        <h5 className="">
                                                            <button className="btn btn-link collapsed"
                                                                    data-toggle="collapse" data-target={"#collapse"+index}
                                                                    aria-expanded="false" aria-controls="collapse3">
                                                                <span className="q-com">Q.</span><span dangerouslySetInnerHTML={ createMarkup(data.question) }/> <i className="fa" aria-hidden="false"/>
                                                            </button>
                                                        </h5>
                                                    </div>
                                                    <div id={"collapse"+index} className="collapse "
                                                         aria-labelledby="heading3" data-parent="#accordion">
                                                        <div className="card-body">
                                                        {
                                                            data.quiz_answers && data.quiz_answers.translator_answer.map((answerdata:TranslatorQuizAnswersModel, index: number) => {
                                                                return <p>{(answerdata.get_answer!==null)?answerdata.get_answer.options:answerdata.answer_id}</p>
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            })
                                            }
                                            {testAccept === 1 &&
                                                    <div>{Lang.online_test_confirm_text_first}</div>
                                            }
                                            {testAccept === 2 &&
                                            <div>{Lang.online_test_confirm_text_second}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
          <Footer/>
            {loader.isLoader == 0 &&
            <><div className="bg_load"/>
                <div className="wrap"/></>
            }
        </div>
      </div>
  )
}
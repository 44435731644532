import React, {useEffect, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {getUserInfo} from "../../Auth/Auth";
import {datePicker, DatetimePickerWrapper, timePicker, updatedTimePicker} from "../../common/DatetimePicker";
import {AddLeaveFormState, GetLeavesState} from "./State";
import API from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {ErrorNotification, successNotification} from "../../common/helpers";
import {LeaveFromValidations} from "./Validations";
import {LeaveGetSchema} from "./Schema";
import Swal from "sweetalert2";
import {FormLayout, Submit} from "../../common";
import {NoDataFound} from "../../no-data-found/NoDataFound";
import { cloneDeep, dropRight, set } from 'lodash';
const moment = require("moment");
declare let window:any;
export const removeSeconds = (time: string):string => dropRight(time.split(':')).join(':');
    export const TLeaveInfo = (props : any) => {
        const [leaveInfo, setLeaveInfo] = useState(AddLeaveFormState);
        const [getLeaveInfo, setGetLeaveInfo] = useState(GetLeavesState);
        const user:any = getUserInfo();


        useEffect(() => {
            LeaveFromValidations();
            getLeaveData();
        }, []);

        function resetForm(event:any) {
            event.preventDefault(); 
            setLeaveInfo({...leaveInfo, leave_type:1, from_date: '', to_date: '', from_time: '00:00', to_time: '23:59', reason:''});
        }

        function getLeaveData(){
            API.get(`leave-info`).then((res: AxiosResponse) => {
                const getTranslatorLeaves:any = res.data.translatorLeave;
                setGetLeaveInfo({...getLeaveInfo, transLeavesData: getTranslatorLeaves});
            })
        }

        const ensureValidTime = (new_time: string, from_time: string, to_time: string, name: 'from_time' | 'to_time', timepicker: any) => {
            from_time = leaveInfo.from_time
            to_time = leaveInfo.to_time
            const bad_from_time_update = name === 'from_time' && moment(new_time, 'HH:mm:ss').isAfter(moment(to_time, 'HH:mm:ss'))
            const bad_to_time_update = name === 'to_time' && moment(new_time, 'HH:mm:ss').isBefore(moment(from_time, 'HH:mm:ss'))
            if (bad_from_time_update) timepicker.timepicker('setTime', from_time);
            if (bad_to_time_update) timepicker.timepicker('setTime', to_time)
            return (bad_from_time_update || bad_to_time_update) ? (name === 'from_time' ? from_time : to_time) : new_time
        }

        function handleChange(e:any, timepicker?: any) {
            $("#"+e.target.name+"-error").css({"display": "none"});
            $(".servicesPicker").css({"color": "rgba(0,0,0,.87)"});
            let {name, value} = e.target;
            if (['from_time', 'to_time'].includes(name)) value = ensureValidTime(Number(e.time.value.split(':')[0] )< 10 ? `0${e.time.value}` : e.time.value, leaveInfo.from_time, leaveInfo.to_time, name, timepicker)
            setLeaveInfo(prev_state => cloneDeep(set(prev_state, name, value)));
        }

        function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
            event.preventDefault();
            if($("#leave_info_form").valid()) {
                API.post(`leave-info`, leaveInfo).then((res: AxiosResponse) => {
                    if(res.data.status){
                        successNotification(res.data.message);
                        ($('#availability') as any).modal('hide');
                        getLeaveData();
                        setLeaveInfo( AddLeaveFormState);
                    } else {
                        ErrorNotification(res.data.message);
                    }
                })
            }
        }

        function deleteLeave(e:any, leave_id:any){
            let confirm_text = Lang.delete_confirm;
            Swal.fire({
                title: Lang.is_sure,text: confirm_text,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: Lang.ok, cancelButtonText: Lang.cancel
            }).then((result:any) => {
                if (result.value) {
                    API.post(`leave-delete`,{'leave_id': leave_id}).then((res: AxiosResponse) => {
                        successNotification(res.data.message);
                        getLeaveData();
                    })
                }
            })
        }

        function closePopup() {
            setLeaveInfo(AddLeaveFormState);
        }
        
    return (
        <div id="profileTab3" className="tab-pane">
            <div className="row">
                <div className="col-md-12 mb-22">
                    <div className="edit-commen2">
                        <div className="cla_iconLeave">
                            <img src={window.PUBLIC_URL+"theme/images/leave-calendar-icon.png"} alt="vt-img" title="" width="35"/></div>
                        <h2 className="title mb-3">{Lang.add_leave_title}</h2>
                        <button type="button" className="btn addLangBtn"
                                data-toggle="modal" data-backdrop='static' data-target="#availability">{Lang.add_date} <i className="fas fa-plus hvr-push"/>
                        </button>
                        <div className="modal customModal fade" id="availability">
                            <div className="modal-dialog modal-mg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="title m"><i
                                            className="far fa-plus-square"/>{Lang.add_date}</h1>
                                        <button type="button" className="close"
                                                data-dismiss="modal" onClick={() => closePopup()}>&times;</button>
                                    </div>

                                    <div className="modal-body">
                                        <FormLayout id="leave_info_form" onSubmit={handleSubmit}  autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group customRadioGroup">
                                                        <label>{Lang.leave_type}</label>
                                                        <div className="interpreterBox customRadioGroup mb-22">
                                                            <div className="radioCustom">
                                                                <input type="radio" className="custom-control-radio" id="full_day" name="leave_type" value="1" onChange={(e) => {setLeaveInfo(cloneDeep({...leaveInfo, leave_type: 1, type_of_half_day: '', to_date: ''}))}} checked={leaveInfo.leave_type===1}/>
                                                                <label className="custom-control-radio2"
                                                                htmlFor="full_day"><span
                                                                className="fullDay"/><span>{Lang.full_day}</span><i
                                                                className="iconCheck fas fa-check-circle"/></label>
                                                            </div>
                                                            <div className="radioCustom">
                                                                <input type="radio" className="custom-control-radio" id="half_day" name="leave_type" value="2" onChange={(e) => {setLeaveInfo(cloneDeep({...leaveInfo, leave_type: 2, to_date: ''}))}} checked={leaveInfo.leave_type===2} />
                                                                <label className="custom-control-radio2"
                                                                htmlFor="half_day"><span
                                                                className="halfDay"/><span>{Lang.half_day}</span><i
                                                                className="iconCheck fas fa-check-circle"/></label>
                                                            </div>
                                                            <div className="radioCustom">
                                                                <input type="radio" className="custom-control-radio" id="multiple_days" name="leave_type" value="3" onChange={(e) => {setLeaveInfo(cloneDeep({...leaveInfo, leave_type: 3, type_of_half_day: ''}))}} checked={leaveInfo.leave_type===3} />
                                                                <label className="custom-control-radio2"
                                                                       htmlFor="multiple_days"><span
                                                                    className="multipleDays"/><span>{Lang.multiple}</span><i
                                                                    className="iconCheck fas fa-check-circle"/></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {leaveInfo.leave_type===2 &&
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label>{Lang.type_of_half_day}</label><br/>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" className="custom-control-input"
                                                                       id={'customRadio9'} name={'type_of_half_day'} onChange={(e) => {setLeaveInfo({...leaveInfo, type_of_half_day: 0})}} checked={leaveInfo.type_of_half_day===0} value={0}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="customRadio9">{Lang.first_half}</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" className="custom-control-input"
                                                                       id={'customRadio5'} name={'type_of_half_day'} onChange={(e) => {setLeaveInfo({...leaveInfo, type_of_half_day: 1})}} value={1} checked={leaveInfo.type_of_half_day===1}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="customRadio5">{Lang.second_half}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className=" col-md-12">
                                                    <div className="form-group">
                                                        <div className="demo-section k-content">
                                                            <DatetimePickerWrapper className="servicesPicker" options={{...datePicker,minDate: new Date(moment().subtract('1', 'days'))}}  id={'from_date'} name={'from_date'} onChange={handleChange} value={leaveInfo.from_date} placeholder={Lang.from_date_placeholder} autoComplete="off"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    leaveInfo.leave_type === 3 && leaveInfo.from_date !== '' && (
                                                        <>
                                                            <div className=" col-md-12">
                                                                <div className="form-group">
                                                                    <div className="demo-section k-content">
                                                                        <DatetimePickerWrapper
                                                                            className="servicesPicker"
                                                                            options={{...datePicker,minDate: new Date()}}
                                                                            name={'to_date'}
                                                                            onChange={handleChange}
                                                                            id={'to_date'}
                                                                            value={leaveInfo.to_date}
                                                                            placeholder={Lang.to_date_placeholder}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=" col-md-6">
                                                                <div className="form-group">
                                                                    <div className="demo-section k-content">
                                                                        <DatetimePickerWrapper
                                                                            className="servicesPicker"
                                                                            options={{...updatedTimePicker, defaultTime: leaveInfo.from_time}}
                                                                            name={'from_time'}
                                                                            onChange={handleChange}
                                                                            id={'from_time'}
                                                                            value={leaveInfo.from_time}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=" col-md-6">
                                                                <div className="form-group">
                                                                    <div className="demo-section k-content">
                                                                        <DatetimePickerWrapper
                                                                            className="servicesPicker"
                                                                            options={{...updatedTimePicker, defaultTime: leaveInfo.to_time}}
                                                                            name={'to_time'}
                                                                            onChange={handleChange}
                                                                            id={'to_time'}
                                                                            value={leaveInfo.to_time}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <div className=" col-md-12">
                                                    <div className="form-group">
                                                        <input id="reason" name="reason" type="text" className="form-control validate" value={leaveInfo.reason} onChange={(e) => {setLeaveInfo({...leaveInfo, reason: e.target.value})}} placeholder={Lang.reason_placeholder} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-4 btnSet text-center">
                                                <button className={'btn btn-default hvr-rectangle-out'} data-skin={'dark'} title={Lang.reset} onClick={(e) => {resetForm(e)}}>{Lang.reset}</button> &nbsp;&nbsp;
                                                <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_64_registrer}/>
                                            </div>
                                        </FormLayout>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                {(getLeaveInfo.transLeavesData &&
                    getLeaveInfo.transLeavesData.length !== 0) &&
                <table className="table masterTable">
                    <tr>
                        <th>{Lang.leave_type}</th>
                        <th>{Lang.from_date}</th>
                        <th>{Lang.to_date}</th>
                        <th>{Lang.reason}</th>
                        <th>{Lang.type_of_half_day}</th>
                        <th>{Lang.action}</th>
                    </tr>
                    {getLeaveInfo.transLeavesData.length > 0 && getLeaveInfo.transLeavesData &&
                    getLeaveInfo.transLeavesData.map((item: LeaveGetSchema, index: number) => {
                        return (
                            <tr key={item.id}>
                                <td>{(item.leave_type === 1) ? Lang.full_day : (item.leave_type === 2) ? Lang.half_day : Lang.multiple}</td>
                                <td>{moment(item.from_date).format('dddd')} {moment(item.from_date).format('DD-MM-YYYY')} {item.leave_type === 3 && `på ${removeSeconds(item.from_time)}`}</td>
                                <td>{moment(item.to_date).format('dddd')} {moment(item.to_date).format('DD-MM-YYYY')} {item.leave_type === 3 && `på ${removeSeconds(item.to_time)}`}</td>
                                <td>{item.reason}</td>
                                <td>{(item.type_of_half_day === 0) ? Lang.first_half : (item.type_of_half_day === 1) ? Lang.second_half : ''}</td>
                                <td>{ (moment().format('YYYY-MM-DD')<item.from_date)?
                                    <a className="btnStatusPrimary hvr-push" href="javascript:;" onClick={(e) => deleteLeave(e, item.id)}><i className="fas fa-trash"></i></a>:''}</td>
                            </tr>
                        )
                    })
                    }

                </table>
                }
                {(getLeaveInfo.transLeavesData &&
                    getLeaveInfo.transLeavesData.length === 0) &&
                <NoDataFound/>
                }
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {Link} from "react-router-dom";
import {QuizModel} from "../model";

export function OnlineQuiz (props: any) {

    const [onlineQuiz,  setOnlineQuiz] = useState([] as Array<QuizModel>);

    useEffect(() => {
        document.title = Lang.online_quiz+' - '+Lang.vitolker_translator;;
        API.get(`online-quiz`).then((res: AxiosResponse) => {
            setOnlineQuiz(res.data);
        })
    },[]);

  return(

      <div className="bgMain">
        <div className="wrapper">
            <Header {...props}/>
            <section className="mainContent">
                <div className="video-graphic">
                    <img src="theme/images/video-big.png" alt="" className={'img-fluid'}/>
                </div>

                <div className="container">
                    <div className="statisticsPage">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text-center mb-22">
                                <div className="video-heading">
                                    <h2>{Lang.we_learn_heading_first}<br/>{Lang.we_learn_heading_second} <span>{Lang.online_quiz}</span> {Lang.we_learn_heading_third}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {onlineQuiz.map((data:QuizModel, index: number) => {
                                return <div className="col-md-6 col-sm-12  col-lg-4 mb-30">
                                        <div className="boxShadow hover-vd hvr-grow-shadow">
                                            <div className="profile_box for-vd">
                                                <div className="imgbox">
                                                    <Link to={`online-quiz-detail/${data.id}`}><img src={data.image}  alt="" className={'img-fluid'} style={{height:'200px'}} /></Link>
                                                </div>
                                                <Link to={`online-quiz-detail/${data.id}`}>
                                                    <h4>{data.title}</h4>
                                                </Link>
                                                <p>{(data.description.length>130)?data.description.substr(0, 130)+'...':data.description}  <Link to={`online-quiz-detail/${data.id}`}>{Lang.read_more}</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
          <Footer/>
        </div>
      </div>
  )
}
import React, {useEffect, useState} from 'react';
import './App.css';
import {Auth, getUserInfo, UserInfo} from "./components/Auth/Auth";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Redirect} from "react-router";
import {Switch} from "react-router";
import {DashboardPage} from "./components/dashboard";
import {LoginPage} from "./components/Login";
import {ForgotPassword, ResetPassword} from "./components/password";
import {SignUpPage} from "./components/form/signup";
import {SignUpPageNew} from "./components/form/signup";
import {BasicInfo} from "./components/form/signup/BasicInfo";
import {BankInfo} from "./components/form/signup/BankInfo";
import {DocumentUpload} from "./components/form/signup/Document";
import {TranslatorProfile} from "./components/form/profile/TranslatorProfile";
import {MyAssignment} from "./components/my-assignment/MyAssignment";
import {ThankYou} from "./components/form/signup/ThankYou";
import {Orders} from "./components/orders/Orders";
import {CompleteOrders} from "./components/orders/CompleteOrders";
import {Invitation} from "./components/invitation/Invitation";
import {CompletePayment} from "./components/payment/CompletePayment";
import {PendingPayment} from "./components/payment/PendingPayment";
import {PaidPayment} from "./components/payment/PaidPayment";
import {ErrorNotification} from "./components/common/helpers";
import Calling from "./components/calling";
import {EnquiryCreatePage} from "./components/enquiry/EnquiryCreatePage";
import {OnlineTest} from "./components/online-test/OnlineTest";
import {WeLearn} from "./components/we-learn/WeLearn";
import {WeLearnDetail} from "./components/we-learn/WeLearnDetail";
import {QuizStartTabs} from "./components/we-learn/QuizStartTabs";
import {OnlineQuizStartTabs} from "./components/online-test/OnlineQuizStartTabs";
import {OnlineQuiz} from "./components/online-quiz/OnlineQuiz";
import {OnlineQuizDetail} from "./components/online-quiz/OnlineQuizDetail";
import {OnlineQuizStart} from "./components/online-quiz/OnlineQuizStart";
import {NotificationsPage} from "./components/notifications/NotificationsPage";
import Cookies from 'js-cookie';
import {NotFoundPage} from "./components/not-found-page/NotFound";
import moment from "moment-timezone";
import _ from "lodash";
import {userInfo} from "os";
import {OrdersDetailPage} from "./components/my-assignment/OrdersDetailPage";
import Lang from "./components/common/lang/Lang";
import {WeFileViewer} from "./components/we-file-viewer/WeFileViewer";
import {CreatePasswordWithOldPassword} from "./components/password/CreatePasswordWithOldPassword";

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

function SetLanguage(langName:any) {
    Lang.setLanguage(langName);
}

function changePagePosition() {
    window.scrollTo(0, 0);
}

const PrivateRoute = ({ component: Component, ...rest }: IProps) => (
    <>
  <Route {...rest} render={(props: any) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
{changePagePosition()}</>
);
const PublicRoute = ({ component: Component, ...rest }: IProps) => (
    <>
    <Route {...rest} render={(props: any) => (
        //!Auth.isAuthenticated
        Auth.isAuthenticated === false
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/dashboard',
                state: { from: props.location }
            }} />
    )} />
{changePagePosition()}</>
);

const TranslatorStepsRoute = ({ component: Component, ...rest }: IProps) => (
<>
    <Route {...rest} render={(props: any) => {

        let stepNo = localStorage.getItem('registration_step');
        let sendOnlineTest = localStorage.getItem('send_online_test');
        const user: any = getUserInfo();

        if(Auth.isAuthenticated === true) {
            if (stepNo === '1') {
                if (props.location.pathname !== '/basic-info') {
                    return <Redirect to={{
                        pathname: '/basic-info',
                        state: {from: props.location}
                    }}/>;
                } else if (props.location.pathname === '/basic-info') {
                    return <Component {...props} />
                }
            } else if (stepNo === '2') {
                if (props.location.pathname !== '/bank-info') {
                    return <Redirect to={{
                        pathname: '/bank-info',
                        state: {from: props.location}
                    }}/>;
                } else if (props.location.pathname === '/bank-info') {
                    return <Component {...props} />
                }
            } else if (stepNo === '3') {
                if (props.location.pathname !== '/document-upload') {
                    return <Redirect to={{
                        pathname: '/document-upload',
                        state: {from: props.location}
                    }}/>
                } else if (props.location.pathname === '/document-upload') {
                    return <Component {...props} />
                }
            } else {
                if (user.status !== 2) {
                    return <Component {...props} />

                } else if (user.status === 2 && (props.location.pathname === '/profile' || props.location.pathname === '/online-test' || props.location.pathname === '/quiz-start' || props.location.pathname.indexOf("online-test-start") > -1)) {
                    return <Component {...props} />
                } else {
                    if (sendOnlineTest !== null && sendOnlineTest === '1') {
                        return <Redirect to={{
                            pathname: '/online-test',
                            state: {from: props.location}
                        }}/>
                    }
                    ErrorNotification(Lang.profile_under_review);
                    return <Redirect to={{
                        pathname: '/profile',
                        state: {from: props.location}
                    }}/>
                }
            }
        } else {
            return <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
        }

    }} />
{changePagePosition()}</>
);


const App: React.FC = () => {
    moment.tz.setDefault('Europe/Copenhagen');
    const[appState,setAppState] = useState({ isAuthenticated:false, is_loading:true } );

    useEffect(() => {
        let defaultLang = Cookies.get('defaultLang');
        if(_.isUndefined(defaultLang) || _.isEmpty(defaultLang)) {
            defaultLang = 'da';
            Cookies.set('defaultLang',defaultLang);
        }

        if(defaultLang == 'da') {
            moment.locale('da', {
                months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split('_'),
                monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
                weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
                weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
                weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
                monthsParseExact : true,
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'HH:mm:ss',
                    L: 'DD.MM.YYYY',
                    LL: 'D. MMMM YYYY',
                    LLL: 'D. MMMM YYYY HH:mm',
                    LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
                },
                calendar: {
                    sameDay: '[i dag kl.] LT',
                    nextDay: '[i morgen kl.] LT',
                    nextWeek: 'på dddd [kl.] LT',
                    lastDay: '[i går kl.] LT',
                    lastWeek: '[i] dddd[s kl.] LT',
                    sameElse: 'L',
                },
                relativeTime: {
                    future: 'om %s',
                    past: '%s siden',
                    s: 'få sekunder',
                    ss: '%d sekunder',
                    m: 'et minut',
                    mm: '%d minutter',
                    h: 'en time',
                    hh: '%d timer',
                    d: 'en dag',
                    dd: '%d dage',
                    M: 'en måned',
                    MM: '%d måneder',
                    y: 'et år',
                    yy: '%d år',
                },
                dayOfMonthOrdinalParse: /\d{1,2}\./,
            });
        }

        Auth.reAuthenticate(Cookies.get('remember_token')).then((res:any) => {
            if(res){
                setAppState({...appState,isAuthenticated:true, is_loading:false})
            } else {
                setAppState({...appState,isAuthenticated:false, is_loading: false})
            }
        });
        SetLanguage(defaultLang);
    },[]);

    useEffect(()=>{
        jQuery.extend(jQuery.validator.messages, {
            required: Lang.requiredValidator,
            remote: Lang.remoteValidator,
            email: Lang.emailValidator,
            url: Lang.urlValidator,
            date: Lang.dateValidator,
            dateISO: Lang.dateISOValidator,
            number: Lang.numberValidator,
            digits: Lang.digitsValidator,
            creditcard:  Lang.creditcardValidator,
            equalTo: Lang.equalToValidator,
            accept: Lang.acceptValidator,
            maxlength: jQuery.validator.format(Lang.maxlengthValidator),
            minlength: jQuery.validator.format(Lang.minlengthValidator),
            rangelength: jQuery.validator.format(Lang.rangelengthValidator),
            range: jQuery.validator.format(Lang.rangeValidator),
            max: jQuery.validator.format(Lang.maxValidator),
            min: jQuery.validator.format(Lang.minValidator),
        });
    },[]);

    if(appState.is_loading){
        return null;
    }

    if(!appState.isAuthenticated){
        /*return <Redirect to={{
            pathname: '/login'
        }}/>*/
    }

  /*let userInfo: UserInfo| boolean  = getUserInfo();
  if(userInfo) {
    Auth.authenticate(userInfo);
  }*/

  return (
    <Router>
      {<Calling/>}
        <Switch>
        <TranslatorStepsRoute exact path='/' component={DashboardPage}/>

        <PublicRoute exact path='/login'  component={LoginPage}/>
        {/* <PublicRoute exact path='/apply'  component={SignUpPage}/> */}
        <PublicRoute exact path='/apply'  component={SignUpPageNew}/>
        <PublicRoute path='/first-verify-email-address'  component={ThankYou}/>

        <TranslatorStepsRoute path='/basic-info'  component={BasicInfo}/>
        <TranslatorStepsRoute path='/bank-info'  component={BankInfo}/>
        <TranslatorStepsRoute path='/document-upload'  component={DocumentUpload}/>
        <TranslatorStepsRoute exact path='/profile'  component={TranslatorProfile}/>
        <TranslatorStepsRoute path='/order-list'  component={Orders}/>
        <TranslatorStepsRoute path='/dashboard'  component={DashboardPage}/>
        <TranslatorStepsRoute path='/my-assignment'  component={MyAssignment}/>
        <TranslatorStepsRoute path='/order-detail/:id'  component={OrdersDetailPage}/>
        <TranslatorStepsRoute path='/invitation'  component={Invitation}/>
        <TranslatorStepsRoute path='/complete-order/:id'  component={CompleteOrders}/>
        <PublicRoute path='/thank-you'  component={ThankYou}/>
        <PublicRoute path='/welcome'  component={ThankYou}/>
        <PublicRoute path='/already-verified'  component={ThankYou}/>
        <PublicRoute path='/link-expired'  component={ThankYou}/>
        <PublicRoute path='/account-active'  component={ThankYou}/>
        <TranslatorStepsRoute path='/complete-payment'  component={CompletePayment}/>
        <TranslatorStepsRoute path='/pending-payment'  component={PendingPayment}/>
        <TranslatorStepsRoute path='/paid-payment'  component={PaidPayment}/>
        <PrivateRoute path='/enquiry' component={EnquiryCreatePage}/>
        <TranslatorStepsRoute exact path='/notifications' component={NotificationsPage }/>
        <TranslatorStepsRoute path='/online-test' component={OnlineTest}/>
        <TranslatorStepsRoute path='/quiz-start/:id' component={QuizStartTabs}/>
        <TranslatorStepsRoute path='/online-quiz' component={OnlineQuiz}/>
        
        {/* <TranslatorStepsRoute path='/online-test-start/:id' component={OnlineQuizStartTabs}/> */}
        {/* <TranslatorStepsRoute path='/online-quiz-start/:id' component={OnlineQuizStart}/> */}
        {/* The 2 routes above are the old versions of the routes below, since there is only one online-quiz, we are using
        the routes below to obtain a clearer url */}
        <TranslatorStepsRoute path='/online-test-start' component={OnlineQuizStartTabs}/>
        <TranslatorStepsRoute path='/online-quiz-start' component={OnlineQuizStart}/>
        
        <TranslatorStepsRoute path='/online-quiz-detail/:id' component={OnlineQuizDetail}/>
        <TranslatorStepsRoute path='/we-learn' component={WeLearn}/>
        <TranslatorStepsRoute path='/we-learn-detail/:id' component={WeLearnDetail}/>
        <TranslatorStepsRoute exact path='/file-view/:id/:documentType/' component={WeFileViewer} />

      <Route path='/forgot-password' component={ForgotPassword }/>
      <Route path='/create-new-password-with-old-password/' component={CreatePasswordWithOldPassword}/>
      <Route path='/reset-password/:token' component={ResetPassword }/>
      <Route path='/confirm-password/:token' component={ResetPassword }/>
      <Route path={"*"} component={NotFoundPage} />
        </Switch>
    </Router>
  );
}

export default App;
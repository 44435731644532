import React, {useEffect, useState} from 'react';
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {Link, RouteComponentProps} from "react-router-dom";
import Lang from "../common/lang/Lang";
import _ from 'lodash';
import {} from "./State";
import {ErrorNotification} from "../common/helpers";
import {Header} from "../partial";
import {NotificationsTitle} from "./NotificationsTitle";
import {NotificationsIcon} from "./NotificationsIcon";
let moment = require('moment');

declare let window: any;


interface notificationSchema {
    id:number,
    type:string,
    notifiable_type:string,
    notifiable_id:number,
    data:{
        title:string,
        message:string,
        icon:string,
        url:string,
        type?:string,
        badgeClass?:string,
        badgeHeading?:string,
    },
    read_at:string,
    created_at:string,
    updated_at:string,
}

export function NotificationsPage (props: RouteComponentProps) {
    let limit = 10;

    useEffect(()=>{
        document.title = Lang.notificationPageTitle;
    },[]);

    const [notification, setNotification] = useState([]);
    const [notificationData, setNotificationData] = useState({offset: 0, limit: 10, totalCount: 0});

    useEffect(()=>{
        API.get('get-all-notification',{ params: { limit: notificationData.limit, offset: notificationData.offset }}).then((res: AxiosResponse) => {
            setNotification(res.data.result);
            setNotificationData({...notificationData, offset: notificationData.offset + res.data.result.length, totalCount: res.data.TCount })
        });
    },[]);

    function notificationRead(event: any,notificationId:number,url:string) {
        event.preventDefault();
        let target:any = event.target;
        API.post('mark-read-notification',{id:notificationId}).then((res: AxiosResponse) => {
            if(!_.isEmpty(res.data) && res.data.status === true) {
                $(target).parents('li').hide();
                if(!_.isEmpty(url) && !_.isUndefined(url)) {
                    props.history.push(url);
                }
                //setAutoCount(!autoCount);
            } else {
                ErrorNotification(res.data.message);
            }
        });
    }

    function viewMoreData(event:any) {
        API.get('get-all-notification',{params:{ limit: notificationData.limit, offset: notificationData.offset}}).then((res: AxiosResponse) => {
            let calculateCount = notificationData.offset + res.data.result.length;
            if(!_.isEmpty(res.data.result)) {
                let newData:any = notification.concat(res.data.result);
                setNotification(newData);
                setNotificationData({...notificationData, offset: notificationData.offset + res.data.result.length, totalCount: res.data.TCount});
                if(calculateCount == res.data.TCount){
                    $('#viewMoreNotification').hide();
                }
            } else {
                $('#viewMoreNotification').hide();
            }
        });
    }

    return (
        <div className="bgMain">
            <div className="wrapper">
                <Header />
                <section className="mainContent">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-lg-8  offset-lg-2">
                                <div className="row align-items-center mb-20">
                                    <div className="col-xl-8 col-lg-6 col-md-12 colBugWidth">
                                        <h1 className="title"><i className="far fa-bell"/> {Lang.notification}</h1>
                                    </div>
                                </div>
                                <div className="boxWhite boxShadow">
                                    <ul className="notificationPage">
                                        {notification.length >0 && notification.map((value:notificationSchema, index:number) => {
                                            return(
                                                <div>
                                                {!_.isEmpty(value.data.title) &&
                                                <li key={index} className={(value.read_at==null)?'unread':'read'}>
                                                    {!_.isEmpty(value.data.url) &&
                                                        <Link className="notification-msg" onClick={(e:any)=>notificationRead(e,value.id,(!_.isUndefined(value.data.url) && !_.isEmpty(value.data.url))?value.data.url:'')} to={!_.isEmpty(value.data.url)?value.data.url:''} >
                                                            <div className="row">
                                                                <div className="col-md-1 pr-0 notificationIconInfo">
                                                                    <NotificationsIcon notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>
                                                                </div>

                                                                <div className="col-md-8 pl-0">
                                                                    {!_.isEmpty(value.data.title) &&
                                                                        <h2>{value.data.title}</h2>
                                                                    }
                                                                    {!_.isEmpty(value.data.message) &&
                                                                        <p>{value.data.message}</p>
                                                                    }
                                                                    {/*<NotificationsTitle notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>*/}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="notificationTime"><i className="far fa-clock" aria-hidden="true"/> {moment(value.created_at).toNow(true)} {Lang.ago}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    }
                                                    {_.isEmpty(value.data.url) &&
                                                        <div className="row">
                                                            <div className="col-md-1 pr-0 notificationIconInfo">
                                                                <NotificationsIcon notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>
                                                            </div>
                                                            <div className="col-md-8 pl-0">
                                                                {!_.isEmpty(value.data.title) &&
                                                                <h2>{value.data.title}</h2>
                                                                }
                                                                {!_.isEmpty(value.data.message) &&
                                                                <p>{value.data.message}</p>
                                                                }
                                                                <NotificationsTitle notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <span className="notificationTime"><i className="far fa-clock" aria-hidden="true"/> {moment(value.created_at).toNow(true)} {Lang.ago}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            }
                                            </div>
                                            );
                                        })}

                                        {notification.length == 0 &&
                                        <li className={'noNotificationLi'}>
                                            <div className="noData text-center">
                                                <div><img src={`${window.PUBLIC_URL}/theme/images/google-alerts.png`} width={'25'} alt={'logo'}/></div> <h6>{Lang.no_notification_found}</h6>
                                            </div>
                                        </li>
                                        }

                                    </ul>
                                    {notificationData.totalCount >10 &&
                                        <div className={'mt-5 text-center'}>
                                            <button type="button" onClick={(e)=>viewMoreData(e)} id={'viewMoreNotification'} className="btn btn-green hvr-rectangle-out">{Lang.viewMore}</button>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

import React from "react";

export const Da = {
  info: "Info",
  success: "Succes",
  warning: "Advarsel",
  error: "Fejl",
  subject: "Emne",
  message: "Besked",
  Lets_talk_about: "Lad os snakke om…",
  everything: "Er det alt",
  the_phone_is_open: "Telefonen er åben 24-7",
  submit: "Indsend",
  search: "Søg",
  reset: "Nulstil",
  accept_ok: "Acceptere",
  finish: "Afslut",
  active_confirm: "Er du sikker på, at du vil aktiver posten? ",
  inactive_confirm: "Er du sikker på, at du vil deaktiver posten?",
  delete_confirm: "Er du sikker på, at du vil slette posten?",
  online_test_confirm: "Er du sikker på, at du vil indsende din test?",
  online_test_confirm_text_first:
    "Ved at afkrydse denne boks bekræfter jeg ovenstående tilkendegivelse om mine sproglige kvalifikationer.",
  online_test_confirm_text_second:
    "Jeg besidder ikke, eller jeg er tvivl om, at jeg besidder ovenstående sproglige kvalifikationer på dansk og/eller fremmedsproget.",
  online_test_confirm_error:
    "Du skal markere en af ​​de to knapper, der bedst beskriver dine sprogfærdigheder.",
  edit: "Rediger",
  no_record_found: "Ingen data fundet…",
  page_not_found: "Siden blev ikke fundet",
  error_response: "Hov.. Der gik noget galt. Prøv igen.",
  no_internet_access: "Ingen internetadgang",
  please_wait: "Vent venligst...",
  is_sure: "Er du sikker?",
  yes: "Ja",
  no: "Nej",
  add_language: "Flere sprog",

  excel: "Hent excel",
  csv: "Hent CSV",
  export: "Hent filen",
  active: "Aktiver",
  deactivate: "Deaktiver",
  select: "Vælg",
  inactive: "Inaktiv",
  choose_fields: "Vælg felter",
  Fields: "Felter",
  admin_users: "Administratorbrugere",
  add_new: "Ny handling",
  customers: "Kunder",
  create: "Opret",
  users: "Brugere",
  add_customer: "Tilføj ny kunde",
  name: "Navn",
  email: "E-mail",
  email_address: "E-mail",
  iban: "IBAN",
  swift: "SWIFT",
  iban_placeholder: "Til internationale betalinger - indtast IBAN",
  bank_name: "Bankens navn",
  bank_name_placeholder: "Indtast din banks navn",
  account_holder_name: "Kontohavers navn",
  account_holder_name_placeholder: "Indtast kontohavers navn",
  password: "Adgangskode",
  email_placeholder: "Indtast e-mail",
  country_of_origin: "Oprindelsesland",
  country_of_origin_placeholder: "Vælg oprindelsesland",
  country_placeholder: "Vælg land",
  name_placeholder: "Indtast navn",
  current_password: "Nuværende kodeord",
  change_password: "Skift kodeord",
  new_password: "Nyt kodeord",
  password_placeholder: "Indtast adgangskoden",
  cn_password: "Gentag adgangskoden",
  cn_password_placeholder: "Indtast adgangskode igen",
  success_change_password: "Adgangskoden er ændret.",
  cpassword_not_match: "Den aktuelle adgangskode stemmer ikke overens med det indregistreret.",
  address: "Adresse",
  address_placeholder: "Indtast din adresse",
  zip_code: "Postnummer",
  zip_code_placeholder: "Indtast postnummer",
  city: "By",
  city_placeholder: "Indtast by-navn",
  mobile: "Mobilnummer",
  mobile_placeholder: "Indtast tlf.nr. her",
  contact_person: "Kontaktperson",
  contact_person_placeholder: "Indtast navn på kontaktperson",
  contact_phone: "Mobilnummer på kontaktperson",
  contact_phone_placeholder: "Indtast mobilnummer på kontaktperson",
  ean_number: "EAN-nummer",
  ean_number_placeholder: "Indtast EAN-nummer",
  cpr_number: "CPR-nummer",
  cpr_number_placeholder: "Indtast CPR-nummer",
  identification_code: "Medarbejder-ID",
  identification_code_placeholder: "Indtast medarbejder-ID",

  //customer
  department: "Afdeling",
  department_unit: "Afdelingsenhed",
  department_id_placeholder: "Indtast navn på afdeling",
  department_unit_id_placeholder: "Indtast afdelingsenhed",
  company_id_placeholder: "Indtast firmanavn",
  select_dept: "Vælg afdeling",
  select_dept_unit: "Vælg afdelingsenhed",
  customer_info: "Kundeoplysninger",
  send_login_d_btn: "Send kundelogin",
  total_order: "Samlet bestillinger",
  total_earning: "Samlet indtjening",
  active_order: "Aktive bestillinger",
  pending_order: "Bestillinger under behandling",
  create_order: "Opret ny bestilling",
  edit_customer: "Rediger kundeoplysninger",
  order: "Bestillinger",
  job_information: "Bestillingsinformation",
  distance: "Afstand",
  minutes: "minutter",
  travel_time: "Rejsetid",
  note_on_map: "Tid og afstand vist kun ensidigt",
  translation_type: "Bestillingstype",
  translation_type_placeholder: "Vælg bestillingstype",
  attach_document: "Vedhæft dokument",
  tel_message: "Besked",
  tel_message_placeholder: "Venligst indtast beskeden til borgeren",
  from_language: "Sprog, fra",
  to_language: "Sprog, til",
  language_placeholder: "Vælg sprog",
  date: "Dato",
  date_placeholder: "Vælg dato",
  order_from: "Bestil fra",
  order_from_placeholder: "Vælg mødets starttidspunkt: ",
  order_to: "Bestil til",
  order_to_placeholder: "Vælg mødets sluttidspunkt: ",
  call_time_should_be_greater:
    "Opkaldstiden kan variere alt efter tidspunktet og hvor mange, der ringer",
  client_name: "Borgernavn:",
  client_name_placeholder: "Indtast borgers navn",
  client_cpr: "Borgers CPR-nr.",
  client_cpr_placeholder: "Indtast borgers CPR-nr.",
  employee_id: "Medarbejder-ID.",
  employee_id_placeholder: "Indtast medarbejder-ID",
  comment_for_translator: "Bemærkninger til tolken",
  comment_for_translator_placeholder: "Indtast eventuel bemærkninger til tolk",
  billing_information: "Betalingsinformation",
  choose_file: "Vælg fil",
  contact_email: "Kontaktpersons e-mail",
  contact_email_2: "Anden kontaktperson e-mail",
  contact_name_2: "Anden kontaktpersons navn",
  contact_phone_2: "Anden kontaktpersons telefonnummer",
  contact_email_placeholder: "Indtast e-mail til kontaktperson",
  contact_email_2_placeholder: "Indtast anden kontaktpersons e-mail",
  second_contact_person: "Anden kontaktperson",
  forgot_password_content:
    "Indtast den e-mail-adresse, der er knyttet til din konto, så vi kan sende dig et magisk link for at nulstille din adgangskode ved at skrive en ny.",
  do_you_remember_password: "Kan du huske din adgangskode?",
  try_logging: "Prøv at logge ind",
  sign_language_translation: "Tegnsprogstolkning",
  order_detail_in_rejectcase: "Beklager, du har allerede afvist denne ordre.",
  doc_upload_title: "Vedhæft de oversatte dokumenter",

  //activity logs
  activity_logs: "Aktivitetshistorik",

  //Order Section
  orders: "Bestillinger",
  order_name: "Bestilling",
  not_assigned: "Ikke tildelt",
  firm_institution: "Firma / Institution",
  signature: "Underskrift",
  clear: "Klar",
  pending: "Afventende",
  completed: "Afsluttet",
  order_history: "Order Historik",
  paid: "Betalt",
  under_qa: "Under QA",
  complete: "Afsluttet",
  time_request: "Ønsket tid",
  activity_type: "Aktivitetstype",
  you_dont_have_permision: "Du har ikke tilladelse til at tilgå denne side.",
  comment: "Kommentar",
  new_invitation: "Nye Tolkeopgaver",
  browse: "Gennemse",
  translated_document: "Dokument fra oversætter",
  translation_of_phone_msg: "Tolkning af telefonbesked",
  order_detail: "Bestillingsdetaljer",
  preference: "Præference",
  download_document: "Hent filen",
  translator_download_document: "Dokument fra oversætter",
  customer_download_document: "Dokument fra kunde",
  translator: "Tolk",
  vitolker_translator: "ViTolker",
  customer: "Kunde",
  contact_first: "Den første kontaktperson",
  contact_second: "Den anden kontaktperson",
  create_at: "Oprettet d. ",
  addition_info: "Præciserende oplysninger om adresse og mødested",
  order_feedback: "Feedback på opgaven",
  rating: "Bedømmelse",
  e_signature: "e-Signature",
  call_duration: "Samlet opkaldstid",

  //Claim Section
  claims: "Klager",
  claim_created: "Klagen er oprettet",
  claim_rejected: "Klagens krav er afvist",
  claim_resolved: "Klagen er blevet håndteret",
  add_new_claim: "Tilføj ny klage",
  claim_subject: "Emne",
  claim_reason: "Årsag",
  claim_status_resolved: "Løst",
  claim_status_new: "Ny",
  claim_status_reject: "Afvis",
  claim_status_admin_replaed: "Vi har besvaret klagen",
  claim_creator_type_admin: "Admin",
  claim_creator_type_customer: "Kunde",
  claim_creator_type_translator: "Tolk",
  claim_detail: "Klagens indhold",
  is_claim_reject: "Er du sikker på, at du vil afvise klagens anmodning?",
  is_claim_resolve: "Er du sikker på, at klagen er færdigbehandlet?",

  //Registration Section
  requiredValidator: "Dette felt skal udfyldes",
  remoteValidator: "Fix dette felt",
  emailValidator: "Indtast venligst en gyldig e-mailadresse.",
  urlValidator: "Indtast en gyldig URL",
  dateValidator: "Indtast en gyldig dato",
  dateISOValidator: "Indtast en gyldig dato (ISO)",
  numberValidator: "Indtast et gyldigt nummer",
  digitsValidator: "Indtast kun chifre",
  creditcardValidator: "Indtast venligst et gyldigt kreditkort nummer",
  equalToValidator: "Venligst indtast den samme beløb for at bekræfte",
  acceptValidator: "Indtast et beløb med en gyldig endelse",
  maxlengthValidator: "Indtast højst {0} tegn",
  minlengthValidator: "Indtast mindst {0} tegn",
  rangelengthValidator: "Indtast et beløb mellem {0} og {1} tegn lang",
  rangeValidator: "Indtast et beløb mellem {0} og {1}",
  maxValidator: "Indtast en værdi, der er mindre end eller lig med {0}",
  minValidator: "Indtast en værdi, der er større end eller lig med {0}",
  companyTypeValidator: "Kundetypen skal udfyldes",
  lettersAndSpaceValidator: "Brug kun bogstaver og mellemrum",
  public: "Offentlig institution",
  private: "Virksomhed",
  company_type: "Virksomhedstype",
  create_account: "Opret konto",
  source_lang: "Sprog, jeg tolker fra",
  target_lang: "Sprog, jeg tolker til",
  select_source_lang: "Vælg sprog, der skal oversættes fra",
  select_target_lang: "Vælg sprog, der skal oversættes til",
  next_button: "Næste",
  back_button: "Tilbage",
  previous_button: "Tidligere",
  term_and_condition: "Samtykke",
  term_and_condition_text:
    "Jeg har læst og accepterer betingelserne Betingelser for brug og Privatliv og privatlivspolitik",
  click_to_accept: "Klik for at acceptere",
  get_it_for_free: "Få det gratis",
  choose_your_native: "Vælg din native platform og",
  download_app_free: "download appen gratis!",
  app_store: "App butik",
  google_play: "Google Play",
  vitolker_with_love: "Vitolker - med kærlighed til sprog.",
  privacy_policy: "Fortrolighedspolitik",
  we_love_data_regulation: "Vi værner om GDPR",
  find_us_on: "Oplysninger om os",
  all_rights_reserved: "Alle rettigheder forbeholdes ViTolker",
  try_our_app: "For at få bedre oplevelse af videoopkald, bedes du bruge vores mobilapplikation",
  download_now: "Download Now",
  gender_heading: "Ønsket køn på tolken",
  gender_title: "Køn",
  male: "Mand",
  female: "Kvinde",
  no_sig: "Ligegyldigt",
  registration_success: "Din tolkeprofil er succesfuldt oprettet.",
  verification_number: "Står du på rigspolitiets tolkeliste?",
  verification_number_placeholder: "Indtast dit rigspolitinummer",
  driver_license: "Har du kørekort?",
  driver_license_placeholder: "Indtast",
  social_security_no: "CPR nr.",
  social_placeholder: "Indtast dit CPR.",
  vehicle: "Råder du over et køretøj?",
  vehicle_placeholder: "Jeg har et køretøj",
  certified: "Tolkeuddannet",
  certified_placeholder: "Indtast certificeringsnummer",
  police_confirmed: "Står du på rigspolitiets tolkeliste?",
  police_conf_placeholder: "Indtast dit politinummer",
  push_agreement: "Ønsker du et åbent kalender aftale?",
  basic_info_page_title: "Basale informationer",
  bank_info_page_title: "Betalingsinformationer",
  availability_page_title: "Tilgængelighedsindstillinger",
  document_page_title: "Vedhæft dokument",
  basic_info_success: "Dine oplysninger er blevet gemt.",
  bank_info_success: "Dine bankoplysninger er blevet gemt.",
  bank_reg_no: "Reg.nr (4 tal)",
  bank_reg_placeholder: "Kontonr.",
  bank_account_no: "Kontonr.",
  bank_accno_placeholder: "Indtast Kontonr.",
  resume: "Dit CV",
  document_upload_success: "Dine dokumenter er blevet succesfuldt vedhæftet.",
  profile_image_success: "Profilbillede er blevet uploadet.",
  children_certificate: "Børneattest",
  penalty_certificate: "Straffeattest",
  contract_sign_success: "Tilknytningsaftalen er nu underskrevet.",
  contract_sign_error: "Underskriv venligst i feltet her ovenover",
  updt_bank_info_title: "Udfyld dine bankoplysninger",
  updt_basic_info_title: "Udfyld dine personoplysninger",
  otp_sent_msg: "Kontroller dit telefonnummer.",
  otp_expire: "Beklager, sikkerhedskoden er udløbet.",
  verify_translator_phone: "Bekræft venligst dit telefonnummer",
  email_already_register: "Denne e-mailadresse er allerede registreret.",
  invalid_address: "Dette er ikke en gyldig adresse, prøv at være mere specifik.",
  mobile_already_register: "Dette mobile nr findes allerede i vores database.",
  valid_name: "Indtast et gyldigt navn.",
  valid_mobile_no: "Indtast et gyldigt mobilnummer",
  valid_email: "Indtast venligst en gyldig e-mailadresse.",
  strong_password_text: "Anvend venligst både små og store bogstaver, tal og specialtegn",
  weak_password_text: "Brug både bogstaver og tal, og sørg for at indtaste mindst 8 tegn",
  fromlang_valid: "Sprog, der oversættes fra og til kan ikke være det samme sprog",
  welcome_back: "Velkommen tilbage",
  welcome_back_desc1: "Opretholde din adgang til vores kunder",
  welcome_back_desc2: "Log ind med din e-mailadresse og adgangskode",
  welcome_translator_portal: "Velkommen til ViTolkers tolkeportal",
  check_your_mail:
    "Du er et skridt væk for at være en bekræftet oversætter hos ViTolker. Kontroller venligst din postkasse for at afslutte registreringsprocessen.",
  thanks_email_verified_text: "Tak, din e-mailadresse er bekræftet.",
  account_active_h1_title:
    "Din e-mailadresse er nu bekræftet, og du kan nu logge ind og færdigudfylde din profil.",
  account_active_next_steps_1:
    "Vi gennemgår alle ansøgninger og kontakter løbende kandidater med sprogkundskaber, som matcher det aktuelle tolke- og oversættelsesbehov.",
  account_active_next_steps_2:
    "Du vil derefter blive bedt om at bestå en online-test for god tolkning og etisk praksis inden den endelige samtale.",
  account_active_next_steps_3:
    "Når du er færdig med din endelige samtale, sender vi dig din kontrakt til underskrift, og du bliver officielt en del af vores tolketeam.",
  already_email_verified: "Din e-mail-adresse er allerede bekræftet",
  email_link_expire: "Beklager, linket til bekræftelse af din e-mailadresse er udløbet.",
  first_verify_email: "Beklager! Du skal først bekræfte din e-mailadresse",
  login: "Log på",
  login_vitolker: "Log på",
  sign_in: "Log ind",
  sign_up: "Opret bruger",
  sign_up_vitolker: "Opret bruger",
  hello_friend: "Hej ven",
  enter_your_detail_text: "Indtast dine personlige oplysninger",
  start_journey: "og start rejsen med os",
  sign_in_vitolker: "Log ind på ViTolker",
  forgot_password_title: "Glemt kodeord",
  reset_password_title: "Nulstil kodeord",
  reset_password_heading: "Nulstil din gamle adgangskode ved at skrive en ny",
  dont_have_account: "Har du ikke en kundeprofil?",
  remember_me: "Husk mig",
  email_does_not_match: "E-mail findes ikke i vores system.",
  verify: "Bekræft",
  push_agreement_info:
    "Ved at vælge denne funktion giver du ViTolker tilladelse at tildele dig tolkeopgaver - uden din forhåndsgodkendelse. ",
  //source_target_not_same: "Source and Target languguage can’t be same.",
  choose_a_file: "Vælg en fil",

  // Translator Profile
  profile: "profil",
  profile_under_review: "Vi har modtaget din ansøgning om adgang til ViTolkers tolkeopgaver.",
  profile_under_review_content:
    "Din profil er nu til gennemgang - vi kontakter dig snarest muligt.",
  contract_send: "Kontrakten er sendt til dig.",
  contract_content:
    "Velkommen til ViTolker. Det er med glæde, at du nu er en del af ViTolker, denne kontrakt er underskrevet med digital signatur.",
  contract: "Kontrakt",
  online_test_assigned: "Du har nu adgang til vores GDPR-prøve.",
  online_test_assigned_content: "Venligst udfyld GDPR-prøven.",
  click_here: "Klik her",
  already_email_change_req: "Du har allerede sendt en anmodning med denne e-mail-adresse.",
  language_title_on_profile: "Sprog Som Tolken Behersker",
  translation_service_title: "Vælg en bestillingstype?",
  do_you_sign_translation: "Kan du tegnsprog?",
  document_title: "Dokumenter",
  unverified: "Endnu ikke bekræftet",
  review_title: "Anmeldelser",
  rejected: "Afvist",
  current_location: "Adresse",
  jobs_completed: "Opgaven afsluttet",
  hrs: "tolketimer",
  certificate: "Certifikat",
  working_hours: "tolketimer",
  verifying_email_address: "Kontroller din e-mail for at bekræfte din e-mail-adresse.",
  profile_pic: "Profilbillede",
  upload: "Vedhæft",
  add_date: "Tilføj dato",
  enter_hours_placeholder: "Indtast tolketimer",

  //Translator Dashboard
  new_task_invitations: "Nye Tolkeopgaver",
  my_task: "Alle ordrer",
  complete_task: "Afsluttede bestillinger",
  awaiting_invoice_amount: "Honorar - afventer din godkendelse",
  complete_invoice_amount: "Honorar - godkendt",
  order_statistics: "Statistik",
  view_all: "Se alt",
  physical_translation_description: "Fremmøde-tolkning",
  physical_translation: "Fremmødetolkning",
  video_translation: "Video-tolkning",
  video_translation_description: "Video-tolkning",
  telephone_translation: "Telefon-tolkning",
  telephone_translation_description: "Telefon-tolkning",
  telephone_translation_msg: "Telefonbesked-tolkning",
  telephone_besked_description: "Telefonbesked tolkning",
  telephone_translated_msg: "Telefonbesked-tolkning",
  sign_translation: "Tegnsprogstolkning",
  document_translation: "Skriftlig oversættelse",
  document_translation_description: "Skriftlig oversættelse",
  new_assignment: "Nye Tolkeopgaver",
  my_assignment: "Mine opgaver",
  latest_assignment: "Seneste opgave",
  my_account: "Min profil",
  order_list: "Opgaverlisten",
  order_no: "Bestillingsnummer",
  assigned: "Tildelt",
  cancelled: "Annulleret",
  ok: "Ja",
  cancel: "Nej",
  overdue: "Afventer",
  order_type: "Bestillingstype",
  company_name: "Firma / Institution",
  language: "Sprog",
  action: "Muligheder",
  //view: "Vis mere",
  view: "Se detaljer",
  route: "Rute",
  time: "Tid",
  customer_type: "Kundetype",
  employee_id_number: "Medarbejder-ID-nummer",
  identification_code_translator: "Identifikationskode",
  type_interpretation: "Tolkningstype",
  company_institution: "Virksomhedsinstitution",
  preferably_the_gender: "Ønsket køn på tolken",
  meeting_address: "Fremmødeadresse",
  comments_interpreter: "Bemærkninger til tolken",
  comments_interpreter_toll_booth: "Bemærkninger",
  notes_office: "Bemærkninger til kontoret",
  history: "Historik",
  order_correction: "Rettelse til bestilling",
  dashboard: "Kontrolpanel",
  accept_assignment_confirmation: "Er du sikker på, at du vil acceptere denne opgave?",
  accept_assignment: "Du har accepteret opgaven.",
  compelete_assignment_confirmation: "Er du sikker på, at du vil afslutte opgaven?",
  reject_assignment_confirmation: "Er du sikker på, at du vil afvise denne opgave?",
  reject_assignment: "Du har afvist opgaven.",
  translation_service_update: "Tolkninstyper er nu ændret.",
  translation_service_confirm: "Er du sikker på, at du vil opdatere oversættelsestjenester?",
  atleastOneTranslation: "Vælg mindst en tolkningstype.",
  language_delete_confirm: "Er du sikker på, at du vil slette dette sprog?",
  language_delete_success: "Sproget er blevet slettet.",
  language_add_success: "Sproget er tilføjet med succes.",
  order_complete_success: "Bestillingen er afsluttet.",
  already_accepted_someone: "Beklager, nogen har accepteret denne opgave.",
  order_invitation_title: "Nye Tolkeopgaver",
  accept: "Accepter",
  reject: "Afvis",
  see_more: "Se mere",
  request_for_correction: "Anmod om en ændring i bestillingen",
  order_cancel: "Bestil Annuller",
  cancel_date_and_time: "Annuller dato og tid",
  cancel_message: "Besked",
  cancel_reason: "Evt. bemærkninger",
  amount: "Beløb",
  order_status: "Bestillingsstatus",
  payout: "Honorar",
  my_orders: "Mine bestillinger",
  all_orders: "Alle opgaver",
  passwordStrength: "Adgangskodestyrke",
  pwcheck: "Anvend venligst både små og store bogstaver, tal og specialtegn",
  pwHint: "Adgangskode tip: David@123",
  status: "Status",

  // add language
  add_language_headline: "Vil du tilføje flere sprog?",
  add_language_title: "Tilføj sprog",
  from_accent: "Dialekt",
  from: "Fra",
  to: "Til",
  to_accent: "Dialekt",
  from_accent_placeholder: "Indtast kildedialket",
  to_accent_placeholder: "Indtast måldialkekt",
  upload_certificate: "Vedhæft uddannelsesbevis",
  level: "Niveau",
  select_level: "Vælg niveau",
  certification: "Certificering",
  how_many_hours: "Hvor mange timers tolkeerfaringer har du tilegnet i dette sprogkombination?",
  source_mother_tongue: "Er det dit modersmål?",
  target_mother_tongue: "Er det dit modersmål?",
  mother_tongue: "Modersmål",
  notifications: "Notifikationer",
  ago: "siden",
  no_notification_found: "Ingen meddelser er fundet",

  // add leave
  add_leave_title: "Fortæl os om din tilgængelighed ved at tilføje datoen, hvor du er optaget.",
  leave_type: "Hvor lang tid er du utilgængelig?",
  full_day: "Heldag",
  half_day: "Halvdag",
  multiple: "Optaget i en periode",
  reason: "Evt. bemærkninger",
  type_of_half_day: "Hvornår i dagen er du optaget?",
  first_half: "Første halvdel af dagen",
  second_half: "Sidste halvdel af dagen",
  reason_placeholder: "Evt. bemærkninger",
  from_date: "Fra dato",
  from_date_placeholder: "Indtast fra dato",
  to_date: "Til dato",
  to_date_placeholder: "Indtast til dato",
  no_data_found: "Ingen data fundet",
  leave_added_success: "Indstillingen for utilgængelighed er succesfuldt tilføjet.",

  // online test
  online_test_heading: "GTEP-prøve",
  online_quiz: "Online-test",
  quiz_start_text: "Start",
  subjective: "Subjektiv",
  OBJECTIVE: "Objektiv",
  MULTI_CHOICE: "Flere valgmuligheder",
  // thank_you_test: 'Mang tak.',
  thank_you_test: "Mange tak for din besvarelse.",
  // thanku_submit_online_test: 'Du har sendt din test med succes.',
  thanku_submit_online_test: "Vi gennemgår din besvarelse og ser frem til vores samtale.",
  already_submit_online_test: "Du har allerede sendt din test.",
  answer_question_validation: "Besvar venligst dette spørgsmål.",
  question: "SPØRGSMÅL",
  your_answer: "Dine svar",
  // online_test_lang_heading: 'Jeg tilkendegiver, at jeg kan tolke på dansk samt følgende fremmedsprog,',
  online_test_lang_heading: "Jeg tilkendegiver, at jeg kan tolke på følgende sprogkombinationer:",
  online_test_lang_heading_end: "på et niveau, hvor jeg kan:",
  online_test_bullet_one:
    "udtrykke mig på en klar, præcis, letforståelig og modersmålslignende måde",
  online_test_bullet_second: "udtrykke mig spontant uden bemærkelsesværdig søgen efter ordene",
  online_test_bullet_third:
    "læse og forstå selv abstrakte tekster, der indeholder svære ord og sætningsstrukturer, såsom manualer og fagtekster.",
  error_401_message:
    "Ups! Sessionen er udløbet på grund af at du for nylig er logget ind på en anden enhed.",
  error_403_message: "Du har ikke tilladelse til denne handling.",
  error_404_message: "Siden blev ikke fundet.",
  error_419_message: "Ugyldig forespørgsel",

  // menu text
  we_learn: "Certificeringsportal",
  complete_order: "Afslut bestillingen",
  invitation: "Nye Tolkeopgaver",
  payment: "Honorar",
  my_profile: "Min profil",
  contact_us: "Kontakt os",
  logout: "Log ud",
  we_learn_heading_first: "Vores kvalificeret tolkekorps",
  we_learn_heading_second: "Til",
  we_learn_heading_third: "Ydelser",
  read_more: "Læs mere",

  // edit phone number
  edit_phone_number_title: "Rediger dit mobilnummer",
  enter_otp_number: "Indtast verifikationskode",

  // notifications
  notificationPageTitle: "Notifikationer",
  viewMore: "Se mere",
  notification: "Notifikationer",
  na: "N/A",
  invalid_file: "Ugyldig fil",
  email_templates: "E-mail-skabeloner",

  vt_trans_lang_1_full_name: "Fulde navn",
  vt_trans_lang_2_email: "E-mail",
  vt_trans_lang_3_mobile_number: "Mobilnummer",
  vt_trans_lang_4_verify_phone_number: "Bekræft venligst dit mobilnummer",
  vt_trans_lang_5_enter_otp_number:
    "Indtast venligst sikkerhedskoden, som er sendt til dit mobilnummer.",
  vt_trans_lang_6_send_otp: "Send sikkerhedskoden",
  vt_trans_lang_7_resend_otp: "Gensend sikkerhedskoden",
  vt_trans_lang_8_to_language: "Vælg sprog",
  vt_trans_lang_9_gender_heading: "Køn",
  vt_trans_lang_10_password: "Indtast din ønskede adgangskode",
  vt_trans_lang_11_cn_password: "Gentag adgangskode",
  vt_trans_lang_12_address: "indtast din adresse",
  vt_trans_lang_13_backbutton: "Tilbage",
  vt_trans_lang_14_sendbutton: "Send",
  vt_trans_lang_15_termcondition_errormsg: "Ovenstående felt er obligatorisk",
  vt_trans_lang_16_welcome_text: "Velkommen til ViTolkers oversættelses- og tolkeportal",
  vt_trans_lang_17_checkmail_text:
    "Tjek venligst din e-mail indbakke for at gennemføre registreringsprocessen.",
  vt_trans_lang_17_checkmail_text_2:
    "Du har modtaget en e-mail med et link, hvor du skal klikke for at verificere din mail.",
  vt_trans_lang_18_thanks_emailverified: "Velkommen til ViTolkers oversættelses- og tolkeportal",
  vt_trans_lang_19: "Bliv en del af ViTolker",
  vt_trans_lang_20_signup: "ANSØG HER",
  vt_trans_lang_21_signin: "Log ind som tolk",
  vt_trans_lang_22_email: "E-mail",
  vt_trans_lang_23_password: "Ny adgangskode",
  vt_trans_lang_24_forgot_password: "Glemt adgangskode?",
  vt_trans_lang_25_remember_me: "Forbliv logget ind",
  vt_trans_lang_26_loginbutton: "Log på",
  vt_trans_lang_27_account: "Har du endnu ikke adgang til ViTolkers tolk platform?",
  vt_trans_lang_28_button: "Ansøg her",
  vt_trans_lang_29_welcome: "Er du allerede en del af ViTolker?",
  vt_trans_lang_30_welcome_des1: "Log ind på din tolk platform, og få",
  vt_trans_lang_31_welcome_des2: "adgang til ViTolkers spændende sprogopgaver.",
  vt_trans_lang_32_create_account: "Bliv tolk",
  vt_trans_lang_33_pwcheck:
    "Anvend venligst både små (abcde) og store bogstaver (ABCDE), tal (123456) og specialtegn (@!/$/=?+)",
  vt_trans_lang_34_popup_heading: "HENT VORES GRATIS APP NU",
  vt_trans_lang_35_popup_des1: "Få adgang til en verden af sprogmuligheder.",
  vt_trans_lang_36_login_entertext: "Vi er altid på udkig efter nye dygtige kolleger, der",
  vt_trans_lang_37_login_des: "kan hjælpe os med at levere kvalitetsbevidste",
  vt_trans_lang_38_login_des1: "sprogydelser i hele verden.",
  vt_trans_lang_39_policyText: "Læst og acceptér",
  vt_trans_lang_40_findUson: "Download vores app!",
  vt_trans_lang_41_forgotpw_title: "Nulstil din adgangskode",
  vt_trans_lang_42_forgotpw_des:
    "Indtast den e-mailadressen, der er knyttet til din konto. Du vil herefter modtage et link, hvor du kan nulstille adgangskoden.",
  vt_trans_lang_43_example: "F.eks. abc@vitolker.dk",
  vt_trans_lang_44_forgotbutton: "Send",
  vt_trans_lang_45_Resetpw_title: "Opret adgangskode",
  vt_trans_lang_46_Resetpw_placeholder: "********",
  vt_trans_lang_47_Resetpw_placeholder1: "********",
  vt_trans_lang_48_documentBackbutton: "Tilbage",
  vt_trans_lang_49_okbutton: "Ja",
  vt_trans_lang_50_cancelbutton: "Ikke alligevel",
  vt_trans_lang_51_invationDate: "Mødedato og tidspunkt",
  vt_trans_lang_52_invationtranslator: "Tolk",
  vt_trans_lang_53_Institution: "Institution",
  vt_trans_lang_54_invationCustomer: "Kontaktperson",
  vt_trans_lang_55_invationContact: "Kundens kontaktoplysninger",
  vt_trans_lang_56_invationdate: "Oprettet",
  vt_trans_lang_57_telephoneMSG: "Beskeden, som skal oversættes til modtageren",
  vt_trans_lang_58_comment: "Bemærkninger",
  vt_trans_lang_59_submit: "Afslut opgaven",
  vt_trans_lang_60_Completesubmit: "Afslut",
  vt_trans_lang_61_order_history: "Historik",
  vt_trans_lang_62_selecttype: "Vælg",
  vt_trans_lang_63_option: "Se valgmuligheder",
  vt_trans_lang_64_registrer: "Registrer",
  vt_trans_lang_65_complete_task: "Afsluttede opgaver",
  vt_trans_lang_66_all_task: "Alle opgaver",
  vt_trans_lang_67_new_task: "Nye opgaver",
  vt_trans_lang_68_complete: "Gennemfør",
  vt_trans_lang_69_passwordtitle: "Af sikkerhedsmæssige årsager skal du ændre din adgangskode",
  vt_trans_lang_70_document_title: "Vedhæft venligst nedenstående dokumenter (kan gøres senere)",
  vt_trans_lang_71_termCondition_title: "Fortroligheds- og databeskyttelsespolitik",
  vt_trans_lang_72_enquiry_title: "Du er altid velkommen til at kontakte os!",
  vt_trans_lang_73_browse: "Vedhæft",
  vt_trans_lang_74_browse_tit: "Dokumenter til ViTolker",
  vt_trans_lang_75_service_title: "Hvad tilbyder du?",
  vt_trans_lang_76_title_onprofile: "Sproglige og faglige kompetencer",
  vt_trans_lang_77_addmore_language:
    "Har du tolke- og/eller oversættelseskompetencer i flere sprog?",
  vt_trans_lang_78_job_completed: "Gennemført opgaver",
  vt_trans_lang_79_overview: "Oversigt",
  vt_trans_lang_80_latest_assigment: "Seneste opgaver",
  vt_trans_lang_81_view_All: "Se alle",
  vt_trans_lang_82_enquiry_msg: "Indtast din henvendelse her",
  vt_trans_lang_83_send: "Send",
  vt_trans_lang_84_current_pw: "Nuværende adgangskode",
  vt_trans_lang_85_new_pw: "Ny adgangskode",
  vt_trans_lang_86_register: "Registrer",
  vt_trans_lang_87_verification: "Hvad er dit tidligere rigspolitiets tolkenummer?",
  vt_trans_lang_88_back: "Tilbage",
  vt_trans_lang_89_enquiry_email: "Indtast emnet for din henvendelse",
  vt_trans_lang_90_save: "Gem",
  vt_trans_lang_91_document_success:
    "De indtaster oplysninger er succesfuldt opdateret i din profil.",
  vt_trans_lang_92_accept: "Godkend",
  vt_trans_lang_93_condition_text: "Jeg har læst og er indforstået",
  vt_trans_lang_94: "Jeg har læst og accepterer ViTolkers",
  vt_trans_lang_95: "Samarbejdsvilkår",
  vt_trans_lang_96: "og",
  vt_trans_lang_97: "Persondata og fortrolighedspolitik",
  vt_trans_lang_98: "Dette felt skal udfyldes",
  vt_trans_lang_99: "Privatliv og privatlivspolitik",
  vt_trans_lang_100_cnpw: "Adgangskoden er ikke den samme",
  vt_trans_lang_101_thank_you_registration: "Mange tak for din ansøgning.",
  vt_trans_lang_102_spam_folder: "Tjek evt. i din spam-mappe om mailen ligger der.",

  vt_translator_lang_121_today: "Dagens opgaver",
  vt_translator_lang_122_month: "Måned",
  vt_translator_lang_123_week: "Uge",
  vt_translator_lang_124_day: "Dag",

  wordMinLength: "Din adgangskode er for kort",
  wordMaxLength: "Din adgangskode er for lang",
  wordInvalidChar: "Din adgangskode indeholder et ugyldigt tegn",
  wordNotEmail: "Brug ikke din e-mail som din adgangskode",
  wordSimilarToUsername: "Dit kodeord kan ikke indeholde dit brugernavn",
  wordTwoCharacterClasses: "Brug forskellige karakterklasser",
  wordRepetitions: "For mange gentagelser",
  wordSequences: "Din adgangskode indeholder sekvenser",
  errorList: "fejl:",
  veryWeak: "Meget svag",
  weak: "Svag",
  normal: "Middel",
  medium: "Middel",
  strong: "Stærk",
  veryStrong: "Stærk",

  wordIsACommonPassword: "",
  wordLetterNumberCharCombo: "",
  wordLetterNumberCombo: "",
  wordLowercase: "",
  wordOneNumber: "",
  wordOneSpecialChar: "",
  wordThreeNumbers: "",
  wordTwoSpecialChar: "",
  wordUpperLowerCombo: "",
  wordUppercase: "",

  vt_customer_lang_121_today: "Dagens opgaver",
  vt_customer_lang_122_month: "Måned",
  vt_customer_lang_123_week: "Uge",
  vt_customer_lang_124_day: "Dag",
  select_type: "Vælg Type",
  about_yourself: "Beskrivelse af dig selv",
};

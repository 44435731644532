import React from 'react';
import {CallingServices} from "../services";
import 'jquery-ui';
import {CallStatus, CallType, ConnectyInfo, getLoginUserInfo} from "./Config";
import {CallingUI} from "./ui";
import {ConnectyCubeUser} from "../model";
import _ from 'lodash';
import {ErrorNotification} from "../common/helpers";

const ConnectyCube = require('connectycube');
const config = ConnectyInfo;
let login_user: any = getLoginUserInfo();
export default class Calling extends React.Component {
    state = {
        session: null,
        isConnectedToChat: false,
        call_session: null,
        extension: null,
        status: 0,
        call_type: 0,
        mute: false,
        user: {} as ConnectyCubeUser,
        call_id: 0,
        order_id: 0,
    };

    UNSAFE_componentWillMount() {
        this.intializeConnectycube();

        CallingServices.getCall().subscribe((msg: any) => {
            if (msg.call === CallType.VIDEO) {
                // this.createCall(232550, msg.id, msg.connecty_cube_translator.user_id);
            }
            if (msg.call === CallType.AUDIO) {
                // this.createAudioCall(msg.id);
            }
            else if(msg.call === "3"){
                ConnectyCube.chat.disconnect();
            } else {
                console.log("here")
                this.intializeConnectycube();
            }
        })

    }

    intializeConnectycube(){
        login_user = getLoginUserInfo();
        if(!_.isEmpty(login_user)){
            ConnectyCube.init(...config);
            ConnectyCube.createSession(login_user, (error: any, session: any) => {
                if (session && !error) {
                    console.log(session);
                    this.setState({session: session});
                } else {
                    this.setState({session: null});
                }
            });
            const ___this = this;
            ConnectyCube.videochat.onCallListener = function (session: any, extension: any) {
                ___this.setState({status: CallStatus.NONE});
                console.log("getting call", session, extension);
                ___this.getUserInfo(session.initiatorID, () => {
                    ___this.setState({
                        call_session: session,
                        extension: extension,
                        status: CallStatus.INCOMING,
                        call_type: session.callType
                    });
                });
            };
            ConnectyCube.videochat.onAcceptCallListener = function (session: any, userId: any, extension: any) {
                console.log("call connected 1", session, userId, extension);
                ___this.setState({status: CallStatus.CONNECT, call_session: session, extension: extension});
            };
            ConnectyCube.videochat.onUserNotAnswerListener = function (session: any, userId: any) {
                console.log("call not answered", session, userId);
                ___this.setState({status: CallStatus.NOT_ANSWERED});
            };
            ConnectyCube.videochat.onRemoteStreamListener = function (session: any, userID: any, remoteStream: any) {
                // attach the remote stream to DOM element
                console.log("call connected 2");
                session.attachMediaStream('remoteOpponentVideoElementId', remoteStream);
            };
            // on call ended
            ConnectyCube.videochat.onStopCallListener = function (session: any, userId: any, extension: any) {
                console.log("call disconnected");
                ___this.setState({status: CallStatus.END});
            };
            // reject call
            ConnectyCube.videochat.onRejectCallListener = function (session: any, userId: any, extension: any) {
                console.log("call rejected");
                //session.reject(extension);
                ___this.setState({status: CallStatus.REJECT});
            };

            ConnectyCube.videochat.onSessionConnectionStateChangedListener = function(session: any, userID:any, connectionState:any) {
                console.log("connectionState");
                if(
                  connectionState === ConnectyCube.videochat.SessionConnectionState.CLOSED ||
                  connectionState === ConnectyCube.videochat.SessionConnectionState.UNDEFINED ||
                  connectionState === ConnectyCube.videochat.SessionConnectionState.FAILED  ||
                  connectionState === ConnectyCube.videochat.SessionConnectionState.DISCONNECTED ||
                  connectionState === ConnectyCube.videochat.SessionConnectionState.COMPLETED
                ){
                    console.log("stopping call")
                    setTimeout(() => {
                        ___this.setState({status: CallStatus.NONE});
                    }, 1000);

                    // session.stop({});
                    //CallingApis.callClosed(___this.state.call_id);
                }
            }
        }
    }

    UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
        const {isConnectedToChat} = this.state;
        if (nextState.session && nextState.session.user && !isConnectedToChat && login_user) {
            ConnectyCube.chat.connect({
                userId: login_user.id,
                password: login_user.password
            }, (error: any, contacts: any) => {
                if (contacts && !error) {
                    this.setState({isConnectedToChat: true});
                } else {
                    this.setState({isConnectedToChat: false});
                }
            });
        }
    }

    acceptCall = () => {
        const {call_session, extension}: any = this.state;
        this.getUserInfo(call_session.initiatorID, () => {
            this.setState({accept: true, status: CallStatus.CONNECT}, () => {
                call_session.getUserMedia({
                    audio: true,
                    video: call_session.callType === CallType.VIDEO,
                    elementId: 'myVideoElementId'
                }, function (error: any, stream: any) {
                    if (stream && !error) {
                        call_session.accept(extension);
                    }
                });
            });
        });
    };
    rejectCall = () => {
        const {call_session, extension}: any = this.state;
        call_session.reject(extension);
        this.setState({status: CallStatus.REJECT});
    };
    stopCall = () => {
        const {call_session, extension}: any = this.state;
        call_session.stop(extension);
        this.setState({status: CallStatus.END});
    };
    createCall = (id: number, order_id: number, translator_id: number) => {
        this.getUserInfo(id, () => {
            let calleesIds = [id];
            let sessionType = ConnectyCube.videochat.CallType.VIDEO; // AUDIO is also possible
            let additionalOptions = {};
            let session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);
            let mediaParams = {
                audio: true,
                video: true,
                elementId: 'myVideoElementId' // ID of audio/video DOM element to attach a video stream to
            };
            this.setState({status: CallStatus.RINGING, call_session: session, call_type: CallType.VIDEO});
            session.getUserMedia(mediaParams, function (error: any, stream: any) {
                if (stream && !error) {
                    let extension = {};
                    session.call(extension, function (error: any) {
                        console.log(error);
                    });
                }
            });
        });
    };
    createAudioCall = (id: number) => {
        //let calleesIds = [id];
        this.getUserInfo(id, () => {
            let calleesIds = [id];
            let sessionType = ConnectyCube.videochat.CallType.AUDIO; // AUDIO is also possible
            let additionalOptions = {};
            let session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);
            let mediaParams = {
                audio: true,
                video: false,
            };
            this.setState({status: CallStatus.RINGING, call_session: session, call_type: CallType.AUDIO});
            session.getUserMedia(mediaParams, function (error: any, stream: any) {
                if (stream && !error) {
                    let extension = {};
                    session.call(extension, function (error: any) {
                        console.log(error);
                    });
                }
            });
        });
    };
    getUserInfo = (id: number, callback: any) => {
        const ___this = this;
        var searchParams = {filter: {field: 'id', param: 'in', value: [id]}};
        ConnectyCube.users.get(searchParams, function (error: any, result: any) {
            if (result && !error) {
                if (!_.isEmpty(result.items)) {
                    ___this.setState({user: result.items[0].user}, callback)
                } else {
                    ErrorNotification('Translator not found.');
                }
            }
        })
    }
    mute = () => {
        const {call_session, extension}: any = this.state;
        if (this.state.mute) {
            this.setState({mute: false});
            call_session.unmute('audio');
        } else {
            this.setState({mute: true});
            call_session.mute('audio');
        }
    }

    render() {
        return <CallingUI {...this.state} acceptCall={this.acceptCall} rejectCall={this.rejectCall}
                          stopCall={this.stopCall} onMute={this.mute}/>
    }
}
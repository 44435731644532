import { SubmitButtonService} from "../services";
import {SwitchLoader} from "./AxoisClient";
import {InlineLoaderService} from "../services/InlineLoaderService";
let Mprogress:any = require('mprogress/mprogress.min');

let mprogress = new Mprogress();
declare var window: any;

export enum LoaderType {
  BUTTON = 'button',
  COMMON = 'common',
  INLINE = 'inline',
  NONE = 'none',
}


export const ShowLoader = (type:any) => {
  switch (type){

    case LoaderType.BUTTON:
      SubmitButtonService.loadStart();
      break;

    case LoaderType.INLINE:
      InlineLoaderService.loadStart();
      break;

    case LoaderType.COMMON:
      mprogress.start();
      mprogress.set(0.3);
  }
};


export const StopLoader = (type:any) => {
  switch (type){

    case LoaderType.BUTTON:
      SubmitButtonService.clearLoading();
      break;

    case LoaderType.INLINE:
      InlineLoaderService.clearLoading();
      break;

    case LoaderType.COMMON:
      mprogress.end();
  }

  SwitchLoader(LoaderType.COMMON)
};
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import API from './../../common/AxoisClient'
import {AxiosResponse} from "axios";
import Lang from "../../common/lang/Lang";
import {BankInfoFormState} from "./State";
import {successNotification} from "../../common/helpers";
import {BankInfoValidations, Progress} from "./Validations";
import {Auth} from "../../Auth/Auth";
import {FormLayout, Submit} from "../../common";
import Cookies from "js-cookie";
const moment =  require ("moment");
declare let window:any;

export function BankInfo(props: RouteComponentProps) {

    const [bankInfo, setBankInfo] = useState(BankInfoFormState);

    useEffect(() => {

        document.title = Lang.bank_info_page_title+' - '+Lang.vitolker_translator;;
        ($('[data-toggle="tooltip"]') as any).tooltip();
        API.get(`register/${Auth.userInfo.id}`).then((response: AxiosResponse) => {
            setBankInfo({...bankInfo, user_id: localStorage.getItem("login_user_id"), account_holder_name: (response.data.account_holder_name !== '' && response.data.account_holder_name !== null)?response.data.account_holder_name:Auth.userInfo.name, bank_name: response.data.bank_name, bank_registration_no: response.data.bank_registration_no, account_no: response.data.account_no})
        });
        BankInfoValidations();
        ($('.scrollbar-inner') as any).scrollbar();
        $(window).on('scroll', function() {
            let navHeight:number;
            navHeight = ($(window) as any).height() - 600;
            if (($(window) as any).scrollTop() > navHeight) {
                ($('.mainHeader') as any).addClass('navShadow');
            } else {
                ($('.mainHeader') as any).removeClass('navShadow');
            }
        });
        //setBankInfo({...bankInfo, user_id: localStorage.getItem("login_user_id")})
    }, []);

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
        event.preventDefault();
        if($("#bankinfo_form").valid()) {
            API.post(`bankinfo`, bankInfo).then((res: AxiosResponse) => {
                successNotification(Lang.bank_info_success);
                localStorage.setItem('registration_step', '3');
                props.history.push('/document-upload');
            })
        }
    }

    function tabChange(event: any,tab:string){
        API.post(`change-registration-step`, {'registration_step': tab}).then((res: AxiosResponse) => {
            localStorage.setItem('registration_step', '1');
            props.history.push('/basic-info');
        })
    }

    function Logout() {
        Cookies.set('remember', '', { expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        Cookies.set('remember_token', '', {expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        ($('.bs-tooltip-bottom') as any).removeClass('show');
        ($('.tooltip') as any).addClass('hide');
        Auth.signout();
        //window.location.href = '/login';
    }

    return(
        /*<div  className="bgCR">*/
        <div  className="bgMain">
        <div className="wrapper">
            <header className="mainHeader">
                <div className="container">
                    <nav className="navbar">
                        <div className="col-6">
                            <Link to={'#'}><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/> </Link>
                        </div>
                        <div className="col-6 text-right">
                            <ul>
                                <li>
                                    <a href={'#'} ><span data-toggle='tooltip' data-placement='bottom' data-original-title="Logout" onClick={() => Logout()}><i className="fas fa-power-off"/></span></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <section className="mainContent processBarWOutHead">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                            <div className="FormSite basicInfoBox userinfoStep">
                                <ul id="progressbar" className="stepListNav userTNav">
                                    <li className="active"/>
                                    <li className="active">
                                        <div className="step">
                                            <div className="step1"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="step">
                                            <div className="step2"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="step">
                                            <div className="step3"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className={"boxWhite boxShadow"}>
                                <h1 className={'title'}><i className="fas fa-university"/>{Lang.bank_info_page_title}</h1>
                                {/*<form className="centerForm forgotForm" onSubmit={handleSubmit} id="bankinfo_form" autoComplete="off">*/}
                                    <FormLayout id="bankinfo_form" onSubmit={handleSubmit}  autoComplete="off">
                                    <div className="row">
                                        {/*<div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="bank_name">{Lang.bank_name} <span className="required">*</span></label>
                                                <input id="bank_name" name="bank_name" type="text" className="form-control validate" value={bankInfo.bank_name} onChange={(e) => {setBankInfo({...bankInfo, bank_name: e.target.value})}}
                                                       placeholder={Lang.bank_name_placeholder} />
                                            </div>
                                        </div>*/}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="account_holder_name">{Lang.account_holder_name} <span className="required">*</span></label>
                                                <input id="account_holder_name" name="account_holder_name" type="text" className="form-control validate" value={bankInfo.account_holder_name} onChange={(e) => {setBankInfo({...bankInfo, account_holder_name: e.target.value})}}
                                                       placeholder={Lang.account_holder_name_placeholder} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="email">{Lang.bank_reg_no} <span className="required">*</span></label>
                                                <input id="bank_registration_no" name="bank_registration_no" type="text" className="form-control validate" value={bankInfo.bank_registration_no} onChange={(e) => {setBankInfo({...bankInfo, bank_registration_no: e.target.value})}}
                                                       placeholder={Lang.bank_reg_placeholder} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="email">{Lang.bank_account_no} <span className="required">*</span></label>
                                                <input id="account_no" name="account_no" type="text" className="form-control validate" value={bankInfo.account_no} onChange={(e) => {setBankInfo({...bankInfo, account_no: e.target.value})}}
                                                       placeholder={Lang.bank_accno_placeholder} />
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="iban">{Lang.iban} </label>
                                                <input id="iban" name="iban" type="text" className="form-control validate" value={bankInfo.iban} onChange={(e) => {setBankInfo({...bankInfo, iban: e.target.value})}}
                                                       placeholder={Lang.iban_placeholder} />
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="mt-5 text-center">
                                        <button className="previous btn btn-default hvr-rectangle-out" type={'button'} onClick={(e) => tabChange(e,'1')}>{Lang.vt_trans_lang_88_back}</button> &nbsp;
                                        <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.next_button}/>
                                    </div>
                                </FormLayout>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="mainFooter">
                <div className="footer_section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <ul className="footer_right footer_left">
                                    <li>{Lang.we_love_data_regulation}</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="storeMobLink">
                                    <span className="findOnUsText mr-2 text-center">{Lang.find_us_on}</span>
                                    <ul className="BtnApp">
                                        <li>
                                            <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/googlePlay.png`} alt="" title=""/></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/appStore.png`} alt="" title=""/></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="footer_right">
                                    <p>{Lang.all_rights_reserved} &copy; {moment().year()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </div>

    )
}
import React from 'react';
import Lang from "../../common/lang/Lang";

export const TCropProfileImage = (props: any) => {

    return (
        <div className="modal customModal fade" id="editTProfileImagePopup">
            <div className="modal-dialog modal-dialog-centered modal-lg modal-ml">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="title mb-0">
                            <i className="far fa-edit"/>
                            {Lang.profile_pic}
                        </h1>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className={'col-md-12'}>
                                <img className={'img-fluid'} alt={''} id={"uploadedImage1"}
                                     src={'http://localhost/HD%20Backgrounds/Praful%20Editx%20Backgrounds%20(1).jpg'}/>
                            </div>
                        </div>
                        <div className="mt-4 btnSet text-center">
                            <button className={'btn btn-lg btn-green hvr-rectangle-out'}
                                    id={'updateProfilePictureOfUser'}>{Lang.upload}</button>
                            <button className={'btn btn-lg btn-green hvr-rectangle-out'}
                                    id={'ProfilePicturePleaseWait'}>{Lang.please_wait}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
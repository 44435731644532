import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import Lang from "../common/lang/Lang";
import {QuizModel} from "../model/Quiz";
import {FormLayout, Submit} from "../common";
import _ from 'lodash';
import Swal from "sweetalert2";
import {GetLanguagesState} from "../form/profile/State";
import {LanguageGetFormSchema} from "../form/profile/Schema";
declare let window:any;

export function QuizStart (props:any) {

    const checkArr:any = [];
    const [onlineTest, setOnlineTest] = useState({} as QuizModel);
    const [questionData, setQuestionData] = useState({questionNo: 0, progressPercentage:0, noOfQuestion:0, showPercent:0});
    const [answerData, setAnswerData] = useState<any>();
    const [quizStatus, setQuizStatus] = useState({send_online_test: 0});
    const [checkTestStatus, setCheckTestStatus] = useState({check_test_status: 0});
    const [confirmTestWindow, setConfirmTestWindow] = useState(false);
    const [transLanguage, setTransLanguage] = useState(GetLanguagesState);

    useEffect(() => {

        document.title = props.from+' - '+Lang.vitolker_translator;
        getTranslatorData();
        $('#thank_you_block').hide();
        $('#test_block').show();
        API.get(`online-test`,{params:{'id': props.quiz_id}}).then((response: AxiosResponse) => {
            const onlineTestData = response.data[0];
            setOnlineTest(onlineTestData);
            // progress percentage calculation
            let showPercent = questionData.showPercent + 1;
            let calculateQuesPercentage = Math.round((showPercent / response.data[0].questions.length) * 100);
            setQuestionData({ ...questionData, questionNo: questionData.questionNo, progressPercentage: calculateQuesPercentage, showPercent: questionData.showPercent + 1, noOfQuestion: response.data[0].questions.length});

        });
        API.get(`online-tests/get-translator-test-answers`,{params:{'quiz_id' : props.quiz_id}}).then((res: AxiosResponse) => {
            if(!_.isUndefined(res.data[0]) && res.data[0].quiz_answers){
                $('#thank_you_block').show();
                setCheckTestStatus({ ...checkTestStatus, check_test_status: res.data.length});
            }
        })
        API.get(`online-tests/get-translator-languages`).then((response: AxiosResponse) => {
            setTransLanguage({...transLanguage, transLanguageData: response.data.translatorLanguage});
        });
    },[]);

    function getTranslatorData() {
        API.get(`online-tests/get-quiz-status`).then((response: AxiosResponse) => {
            setQuizStatus({...quizStatus, send_online_test: response.data[0].send_online_test});
        });
    }

    function changeQuestion(event:any, questionType:string, fromFinalPage?:string) {
        setConfirmTestWindow(false);
        $('#online_test_error').hide();
        if (questionType === 'next') {
            let ques_id = onlineTest.questions[questionData.questionNo].id;
            if(answerData != undefined && answerData[ques_id] != undefined && answerData[ques_id] != ''){ // validations
                let showPercent = questionData.showPercent + 1;
                let calculateQuesPercentage = Math.round((showPercent / questionData.noOfQuestion) * 100);
                setQuestionData({ ...questionData, questionNo: questionData.questionNo + 1, progressPercentage: calculateQuesPercentage, showPercent: questionData.showPercent + 1});

                if(answerData[onlineTest.questions[questionData.questionNo + 1].id]=='' || answerData[onlineTest.questions[questionData.questionNo + 1].id]==undefined){ // remove fillable next question
                    setAnswerData({...answerData, [onlineTest.questions[questionData.questionNo + 1].id]: ''});
                }
            } else {
                $('#online_test_error').show();
            }
        } else
            if (questionType === 'previous') {
                if(fromFinalPage=='fromFinalPage'){
                    let showPercent = questionData.showPercent;
                    let calculateQuesPercentage = Math.round((showPercent / questionData.noOfQuestion) * 100);
                    setQuestionData({...questionData, questionNo: questionData.questionNo, progressPercentage: calculateQuesPercentage, showPercent: questionData.showPercent
                    });
                } else {
                    let showPercent = questionData.showPercent - 1;
                    let calculateQuesPercentage = Math.round((showPercent / questionData.noOfQuestion) * 100);
                    setQuestionData({...questionData, questionNo: questionData.questionNo - 1, progressPercentage: calculateQuesPercentage, showPercent: questionData.showPercent - 1
                    });
                }
            }
    }

    function confirmPage(){
        let ques_id = onlineTest.questions[questionData.questionNo].id;
        if(answerData!=undefined && answerData[ques_id]!=undefined && answerData[ques_id]!=''){
            $('#final_submit_error').hide();
            setConfirmTestWindow(true);
        } else {
            $('#online_test_error').show();
        }
    }

    function handleChangeFinalConfirm(event:React.ChangeEvent<any>) {
        const checkValue = parseInt(event.target.value);
        setAnswerData({...answerData, test_condition_accept: checkValue});
        $('#final_submit_error').hide();
    }

    function handleChange(event:React.ChangeEvent<any>){

        const checkValue = parseInt(event.target.value);
        const dataSet = event.target.dataset;
        $('#online_test_error').hide();
        if(event.target.type === 'checkbox'){
            let que_id = dataSet.questionId;
            let checkArr = (answerData !== undefined && answerData[que_id] !== undefined && answerData[que_id] !== '')?answerData[que_id]:[];
            if(event.target.checked) {
                checkArr.push(checkValue);
            } else if(!event.target.checked){
                _.pull(checkArr, checkValue);
            }
            setAnswerData({...answerData, [dataSet.questionId]: checkArr});
        } else if(event.target.type === 'radio'){
            setAnswerData({...answerData, [dataSet.questionId]: [checkValue]});
        } else if(event.target.type === 'textarea'){
            setAnswerData({...answerData, [dataSet.questionId]: event.target.value});
        }
    }

    function is_check(answer:number, question_id: number) {
        if(answerData !== undefined ){
            if( answerData[question_id] !== undefined &&  answerData[question_id].indexOf( answer ) > -1 ){
                return true;
            } else {
                return  false;
            }
        }
        return  false;
    }

    function quizSubmit(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        let completeTest = false;
        $('#final_submit_error').hide();
        let ques_id = onlineTest.questions[questionData.questionNo].id;
        if(answerData!=undefined && answerData[ques_id]!=undefined && answerData[ques_id]!='') {
            if(answerData.test_condition_accept>0 && props.from === 'Online Quiz Start'){
                completeTest = true;
            } else if(props.from === 'We Learn'){
                completeTest = true;
            }
            if(completeTest){
                let confirm_text = Lang.online_test_confirm;
                Swal.fire({
                    title: Lang.is_sure,
                    text: confirm_text,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Lang.ok,
                    cancelButtonText: Lang.cancel
                }).then((result: any) => {
                    if (result.value) {
                        API.post(`online-test`, answerData, {
                            params: {
                                from: props.from,
                                quiz_id: props.quiz_id
                            }
                        }).then((res: AxiosResponse) => {
                            $('#thank_you_block').show();
                            $('#test_block').hide();
                            if (props.from === 'Online Quiz Start') {
                                localStorage.setItem('send_online_test', '2');
                                getTranslatorData();
                            }
                        })
                    }
                })
            } else {
                $('#final_submit_error').show();
            }
        } else {
            $('#online_test_error').show();
        }
    }

    function createMarkup(content: string) {
        return { __html: content };
    }

    return(

      <div className="bgMain">
        <div className="wrapper">
            <Header {...props}/>
            {checkTestStatus.check_test_status===0 &&
             <><section className="mainContent" id={'test_block'}>
                <div className="container">
                    <div className="statisticsPage">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                                {props.from==='Online Quiz Start' &&
                                    <h1 className="title mb-20"><i className="fas fa-file-alt"/> {Lang.online_test_heading}</h1>
                                }
                                {props.from==='We Learn' &&
                                    <h1 className="title mb-20"><i className="fas fa-file-alt"/> {Lang.we_learn}</h1>
                                }
                                {props.from==='Online Quiz' &&
                                    <h1 className="title mb-20"><i className="fas fa-file-alt"/> {Lang.online_quiz}</h1>
                                }
                            </div>
                        </div>

                        <div className="boxWhite boxShadow chartPieJet">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <FormLayout id="edit_email_form" onSubmit={quizSubmit}  autoComplete="off">
                                        {(onlineTest.questions!==undefined && onlineTest.questions[questionData.questionNo]!==undefined && confirmTestWindow==false) &&
                                        <div className="questionin">
                                            <div className="progress">
                                                <div className="dot"><i className="fas fa-circle"/></div>
                                                <div className="progress-bar" style={{width:questionData.progressPercentage+'%'}}><label>{questionData.progressPercentage+'%'}</label></div>

                                            </div>
                                            <span className="question_step">{Lang.question} {questionData.questionNo+1}</span>
                                            <p className="quns" dangerouslySetInnerHTML={ createMarkup(onlineTest.questions[questionData.questionNo].question) }/>
                                            {
                                                onlineTest.questions[questionData.questionNo].options &&
                                            onlineTest.questions[questionData.questionNo].options.map((item, index: number) => {
                                                const que_id = onlineTest.questions[questionData.questionNo].id;
                                                const question_type = onlineTest.questions[questionData.questionNo].question_type;

                                                return <div className="row">
                                                    <div className="col-sm-12 col-md-12">
                                                        {question_type === 2 &&
                                                        <><div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" data-question-id={que_id} className="custom-control-input" name={'answer'} checked={is_check(item.id, que_id)} onChange={handleChange} value={item.id}
                                                                   id={'customRadio_'+item.id}  />
                                                            <label className="custom-control-label"
                                                                   htmlFor={'customRadio_'+item.id}>{item.options}</label>
                                                        </div></>
                                                        }
                                                        {question_type === 3 &&
                                                        <><div className="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" data-question-id={que_id} className="custom-control-input" name={'answer'} checked={is_check(item.id, que_id)} onChange={handleChange} value={item.id}
                                                                   id={'customRadio_'+item.id}  />
                                                            <label className="custom-control-label"
                                                                   htmlFor={'customRadio_'+item.id}>{item.options}</label>

                                                        </div></>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                            }

                                            {onlineTest.questions[questionData.questionNo].question_type===1 &&
                                            <>
                                                <textarea data-question-id={onlineTest.questions[questionData.questionNo].id} className="form-control form-control2 input" name='answer' value={(answerData!==undefined && answerData[onlineTest.questions[questionData.questionNo].id]!='')?answerData[onlineTest.questions[questionData.questionNo].id]:''} onChange={handleChange} />
                                            </>
                                            }
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <span style={{color:'red',display:'none'}} id={'online_test_error'}>{Lang.answer_question_validation}</span>
                                                    <div className="qus_btn">
                                                        {questionData.questionNo > 0 &&
                                                            <a href="javascript:;"
                                                           className="btn btn-white hvr-rectangle-out mr-2"
                                                           onClick={(e) => changeQuestion(e, 'previous')}>{Lang.back_button}</a>
                                                        }
                                                        {questionData.noOfQuestion !== questionData.questionNo+1 &&
                                                            <a href="javascript:;"
                                                               className="btn btn-green hvr-rectangle-out" onClick={(e) => changeQuestion(e, 'next')}>{Lang.next_button}</a>
                                                        }
                                                        {(questionData.noOfQuestion === questionData.questionNo+1 && props.from=='Online Quiz Start') &&
                                                        <a href="javascript:;"
                                                           className="btn btn-green hvr-rectangle-out" onClick={(e) => confirmPage()}>{Lang.next_button}</a>
                                                        }
                                                        {(props.from !== 'Online Quiz Start') &&
                                                            <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.submit}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        }
                                        { (confirmTestWindow && props.from=='Online Quiz Start') &&
                                        <div>
                                            <h6>{Lang.online_test_lang_heading}</h6>

                                                {transLanguage.transLanguageData  &&
                                                transLanguage.transLanguageData.map((item:LanguageGetFormSchema, index: number) => {
                                                    return <div className={'languageTab'}>

                                                    <div className={'langtabIn'}>
                                                    <span className="img-cricle">
                                                                            {item.from_language.countries!==undefined && <img src={(item.from_language.countries.iso!==undefined?window.PUBLIC_URL +'/flags/'+item.from_language.countries.iso.toLowerCase()+'.png':'')}/>
                                                                            }
                                                                            </span>

                                                        {item.from_language.language_name}
                                                    </div>
                                                            <div className={'langTranslateIcon'}>
                                                                            <span className="exchangeIcon"><i
                                                                                className="fas fa-exchange-alt"/></span>
                                                            </div>
                                                        <div className={'langtabIn'}>
                                                            <span className="img-cricle">
                                                                                {item.to_language.countries!==undefined && <img src={(item.to_language.countries.iso!==undefined?window.PUBLIC_URL +'/flags/'+item.to_language.countries.iso.toLowerCase()+'.png':'')}/>
                                                                                }
                                     </span> {item.to_language.language_name}
                                                        </div>
                                                        </div>
                                                }
                                                )} <p>{Lang.online_test_lang_heading_end}</p>
                                            <ul>
                                                <li><i className="fas fa-chevron-right"/>{Lang.online_test_bullet_one}</li>
                                                <li><i className="fas fa-chevron-right"/>{Lang.online_test_bullet_second}</li>
                                                <li><i className="fas fa-chevron-right"/>{Lang.online_test_bullet_third}</li>
                                            </ul>
                                            <div className={'radioChoose'}>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" name={'final_confirm'} onChange={handleChangeFinalConfirm} value={1} id={'customRadiooo1'} checked={answerData.test_condition_accept==1} />
                                                        <label className="custom-control-label"
                                                               htmlFor={'customRadiooo1'}>{Lang.online_test_confirm_text_first}</label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" name={'final_confirm'} onChange={handleChangeFinalConfirm} value={2} id={'customRadiooo2'}  checked={answerData.test_condition_accept==2} />
                                                        <label className="custom-control-label"
                                                               htmlFor={'customRadiooo2'}>{Lang.online_test_confirm_text_second}</label>
                                                    </div>
                                            </div>
                                            <div id="final_submit_error" style={{color:'red',display:'none'}}>
                                                <p className="error">{Lang.online_test_confirm_error}</p>
                                            </div>
                                            <div>
                                                <a href="javascript:;"
                                                   className="btn btn-white hvr-rectangle-out mr-2"
                                                   onClick={(e) => changeQuestion(e, 'previous', 'fromFinalPage')}>{Lang.back_button}</a>
                                                <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.submit}/>
                                            </div>
                                        </div>
                                        }
                                    </FormLayout>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             </>
          }
            <section className="mainContent" id={'thank_you_block'}>
                <div className="container">
                    <div className="statisticsPage">
                        <div className="row align-items-center mb-10">
                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                                {props.from==='Online Quiz Start' &&
                                    <h1 className="title">{Lang.online_test_heading}</h1>
                                }
                                {props.from==='We Learn' &&
                                    <h1 className="title">{Lang.we_learn}</h1>
                                }
                                {props.from==='Online Quiz' &&
                                    <h1 className="title">{Lang.online_quiz}</h1>
                                }
                            </div>
                        </div>
                        <div className="PageContent text-center">
                            <div className="container">
                                <h1>{Lang.thank_you_test}</h1>
                                {checkTestStatus.check_test_status === 0 &&
                                    <p>{Lang.thanku_submit_online_test}</p>
                                }
                                {checkTestStatus.check_test_status > 0 &&
                                    <p>{Lang.already_submit_online_test}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
      </div>
  )
}
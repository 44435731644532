import React, {useEffect, useRef, useState} from 'react';
import {Footer, Header} from "../partial";
import {AxiosResponse} from "axios";
import API, {base_url} from "../common/AxoisClient";
import {OrderDataState} from "../dashboard/State";
import Lang from "../common/lang/Lang";
import {ErrorNotification, getToLanguage, showNotification, successNotification} from "../common/helpers";
import {CompleteOrderValidations} from "./Validations";
import {DatetimePickerWrapper, timePicker} from "../common/DatetimePicker";
import {TimePickerWrapper} from "../common/TimePicker";
import {FormLayout, Submit} from "../common";
import {clientData as ClientDataObject, OrderModel, OrderStatus, OrderType} from "../model/Order";
import {isMobile} from 'react-device-detect';
import moment from "moment";
import _ from "lodash";
import {Document} from "./Document";
import {Auth} from "../Auth/Auth";
declare let window:any;

let docs: any = [];
const defaultFormState = {
  order_to: '',
  signatureImage: '',
  translation_of_phone_message: '',
  order_id: '',
  translated_document: [],
  document_remove_filename: [],
  translated_document_display_filename: '',
  e_signature: '',
  translation_type_id: 0
}

export function CompleteOrders (props:any) {

  $('body').removeClass('modal-open');
  $(".modal-backdrop").remove();

  const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);

  const [signature, setSignature] = useState(defaultFormState);

  const [displayCompleteOrder, setDisplayCompleteOrder] = useState({ 'display_complete_order': 2 });

  let dataImage : any;
  let imageBase64 : any;

  $(document).ready(function() {
    setTimeout(function(){
      dataImage = ($(".sigPad") as any).signaturePad({drawOnly:true, lineWidth:0});
    }, 1000);
  });

  useEffect(() => {
    document.title = Lang.complete_order+' - '+Lang.vitolker_translator;
    API.post(`orders/order-detail`, {'order_id': props.match.params.id}).then((res: AxiosResponse) => {
      const translatorOrderDetail = res.data.orderData;
      
      if(translatorOrderDetail[0].status == OrderStatus.TRANS_ASSIGNED){
        setDisplayCompleteOrder({...displayCompleteOrder, display_complete_order: 1});
      } else {
        setDisplayCompleteOrder({...displayCompleteOrder, display_complete_order: 0});
      }
      setSignature({
        ...signature,
        order_to: translatorOrderDetail[0].order_to,
        order_id: translatorOrderDetail[0].id,
        translation_type_id: translatorOrderDetail[0].translation_type_id,
        translated_document: []
      } as any);
      setTransOrderDetail({...transOrderDetail, orderData: translatorOrderDetail});
      ($('[data-toggle="tooltip"]') as any).tooltip();
    });
    

    setTimeout(function(){
      CompleteOrderValidations();
    }, 1000);
  }, []);

  function handleSign() {
    if(!_.isUndefined(dataImage) && !_.isEmpty(dataImage)){
      if(dataImage.getSignatureString() !='[]') {
        $('#errorSign').hide();
        $('.sigPad .error').hide();
        if(isMobile) {
          imageBase64 = dataImage.getSignatureImage("image/png", 100);
          setSignature({...signature, signatureImage: imageBase64});
        }
      } else {
        $('#errorSign').show();
      }
    }
  }

  function handleChange(e:any) {
    var i = 1;
    const target : any = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === 'order_to') {
      let orderFromTime = moment(transOrderDetail['orderData'][0].order_from, 'HH:mm:ss');
      let orderToTime = moment(value, 'HH:mm:ss');
      if (orderToTime <= orderFromTime) {
        ErrorNotification(Lang.call_time_should_be_greater);
        setSignature(prevState => ({...prevState, order_to: ''}));
      } else {
        setSignature(prevState => ({...prevState, order_to: e.target.value}));
      }
    } else if(name === 'e_signature'){
      if(target.checked == true){
        setSignature({...signature, e_signature: value});
      } else if(target.checked == false){
        setSignature({...signature, e_signature: ''});
      }
    }
  }

  function clearSign() {
    $('#errorSign').show();
    setSignature({...signature, signatureImage: ''});
  }

  function handleMultipleUpload(file: any) {
    const old_files: any = [...signature.translated_document];
    file.map((item: any) => {
      old_files.push(item);
    });
    setSignature({...signature, translated_document: old_files});
  }

  function cancelMultipleUpload(file:any) {
    const old_files: any = [...signature.translated_document];
    const files = old_files.filter((item:any) => {
      return item.file_name !== file.name;
    })
    setSignature({...signature, translated_document: files});
  }

  function handleCompleteOrderSubmit(event: React.ChangeEvent<HTMLFormElement>){
    event.preventDefault();
    handleSign();
    if ($("#complete_order").valid()) {
      if (_.isEmpty(signature.signatureImage) && isMobile && signature.translation_type_id == OrderType.PHYSICAL) {

      } else {
        if(signature.translation_type_id !== OrderType.DOCUMENT && signature.translation_type_id !== OrderType.PHONE_MESSAGE){
          let orderFromTime = moment(transOrderDetail['orderData'][0].order_from, 'HH:mm:ss');
          let orderToTime = moment(signature.order_to, 'HH:mm:ss');
          if(orderToTime <= orderFromTime) {
            ErrorNotification(Lang.call_time_should_be_greater);
            setSignature(prevState => ({...prevState, order_to: ''}));
          } else {
            CompleteOrder();
          }
        } else {
          CompleteOrder();
        }
      }
    }
  }

  function CompleteOrder(){
    API.post(`orders/complete-orders`, signature).then((res: AxiosResponse) => {
      successNotification(Lang.order_complete_success);
      props.history.push('/my-assignment');
    });
  }

  function total_call_duration(order: OrderModel){
    if(!_.isEmpty(order.total_call_time)){
      let diff = moment(order.total_call_time[0].total_call_time, 'HH:mm:ss');
      if(diff.hours() > 0){
        return `${diff.hours()} Hour ${diff.minutes()} minutes ${diff.seconds()} seconds`
      } else {
        return `${diff.minutes()} minutes ${diff.seconds()} seconds`
      }
    } else {
      return `0 minutes`
    }
  }

  return(
      <div className="bgMain">
        <div className="wrapper">
          <Header {...props}/>
          <section className="mainContent orderCompleted">
            <div className="container">
              { displayCompleteOrder.display_complete_order === 1 &&
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-sm-12">
                  <h1 className="pageTitle text-center"> {Lang.complete_order}</h1>
                  <FormLayout id="complete_order" className="centerForm forgotForm" onSubmit={handleCompleteOrderSubmit}
                              autoComplete="off">
                    {transOrderDetail['orderData'][0] !== undefined && transOrderDetail['orderData'][0].language_from.country &&
                    <div className="mobiSpace30">
                      <div className="borderRightSep">
                        <div className="blockStep">
                          <div className="blockStepList">
                            <div className="row mt-5 mb-5">
                              <div className="col-sm-6 mobileSpace">
                                { transOrderDetail.orderData[0].translate_by!=undefined &&
                                <div className="row">
                                  <div className="col-left">
                                    <img src={transOrderDetail.orderData[0].translate_by.profile_image_small}
                                         alt={transOrderDetail.orderData[0].translate_by.name} title={transOrderDetail.orderData[0].translate_by.name} className="img-fluid rounded-circle"
                                         width="78" /></div>
                                  <div className="col-right infoUserP">
                                    <h4 className="Usertype">{Lang.translator}</h4>
                                    <ul>
                                      <li><i className="far fa-user"/>{transOrderDetail.orderData[0].translate_by.name}</li>
                                      <li><i className="far fa-envelope"/>
                                        <a href={'mailto:'+transOrderDetail.orderData[0].translate_by.email}>{transOrderDetail.orderData[0].translate_by.email}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                }
                              </div>
                              <div className="col-sm-6">
                                { transOrderDetail.orderData[0].customer!=undefined &&
                                <div className="row">
                                  <div className="col-left"><img
                                      src={transOrderDetail.orderData[0].customer.profile_image_medium}
                                      alt={transOrderDetail.orderData[0].customer.name} title={transOrderDetail.orderData[0].customer.name}
                                      className="img-fluid rounded-circle" width="78"/></div>
                                  <div className="col-right infoUserP">
                                    <h4 className="Usertype">{Lang.customer}</h4>
                                    <ul>
                                      <li><i className="far fa-user"/>{transOrderDetail.orderData[0].customer.name}</li>
                                    </ul>
                                  </div>
                                </div>
                                }
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData"><span>{Lang.order_no}</span> #{transOrderDetail.orderData[0].order_no}</div>
                              </div>
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData"><span>{Lang.translation_type}</span>
                                  <span data-toggle="tooltip" data-placement="left" title={transOrderDetail['orderData'][0].translation_type.title}><img
                                      src={transOrderDetail['orderData'][0].translation_type.small_icon} alt="" width="25"/>{transOrderDetail['orderData'][0].translation_type.title}</span>
                                </div>
                              </div>
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.language}</span>
                                  <span className="lang-from-to"><span><img src={(transOrderDetail['orderData'][0].language_from.country.iso !== undefined ? window.PUBLIC_URL + '/flags/' + transOrderDetail['orderData'][0].language_from.country.iso.toLowerCase() + '.png' : '')}/>{transOrderDetail['orderData'][0].language_from.language_name}</span><span
                                      className="exchangeIcon"><i className="fas fa-exchange-alt"/></span><span>{getToLanguage(transOrderDetail['orderData'][0])}</span></span>
                                </div>
                              </div>
                              {
                                (transOrderDetail['orderData'][0].clients !== undefined) && transOrderDetail['orderData'][0].clients.map((client: ClientDataObject, index: number) => {
                                  if (!_.isEmpty(client)) {
                                    let ClientDisplay = (
                                      <>
                                        <div className="col-xl-4 col-sm-6">
                                          <div className="colData"><span>{Lang.client_name}</span>{(client.client_name !== null)?client.client_name:'- - -'}</div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                          <div className="colData"><span>{Lang.client_cpr}</span>- - -</div>
                                        </div>
                                      </>
                                    )
                                    return (
                                      <React.Fragment key={index}>
                                        {ClientDisplay}
                                        <div className="col-xl-4 col-sm-6">&nbsp;</div>
                                      </React.Fragment>
                                    )
                                }
                              })
                              }
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData"><span>{Lang.firm_institution}</span>
                                  {!_.isEmpty(transOrderDetail['orderData'][0].company)&&!_.isEmpty(transOrderDetail['orderData'][0].company.name)&&
                                  <>
                                    {transOrderDetail['orderData'][0].company.name}
                                  </>
                                  }
                                </div>
                              </div>
                              {transOrderDetail['orderData'][0].comment_for_translator !== null &&
                              <div className="col-xl-12 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.comments_interpreter}</span>{transOrderDetail['orderData'][0].comment_for_translator}
                                </div>
                              </div>
                              }
                              <input type="hidden" id={'translation_type_id_hidden'} name={'translation_type_id_hidden'} value={transOrderDetail['orderData'][0].translation_type_id}/>
                            </div>
                            <div className="row">
                              { transOrderDetail['orderData'][0].translation_type_id === OrderType.VIDEO &&
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.call_duration}</span>
                                  <p>
                                    <i className="far fa-clock"/> {total_call_duration(transOrderDetail['orderData'][0])}
                                  </p>
                                </div>
                              </div>
                              }
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.date}</span>
                                  <p>
                                    <i className="far fa-calendar-alt"/> {moment(transOrderDetail['orderData'][0].order_date).format('dddd')}  {moment(transOrderDetail['orderData'][0].order_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                              </div>

                              { transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                              <div className="col-xl-8 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.meeting_address}</span>
                                  <p>
                                    <i className="fas fa-map-marker-alt"/> {(transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL || transOrderDetail['orderData'][0].translation_type_id === OrderType.SIGN_LANGUAGE) ? transOrderDetail['orderData'][0].meeting_address : transOrderDetail['orderData'][0].address}
                                  </p>
                                </div>
                              </div>
                              }
                              <div className="col-sm-6">
                                <div className="colData">
                                  <span>{Lang.time}</span>
                                  <div className="row mt-2">
                                    {(transOrderDetail['orderData'][0].translation_type_id !== OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id !== OrderType.PHONE_MESSAGE) &&
                                      <div className="col-6">
                                        <input type="text" className="servicesPicker form-control" data-id="order_to1"
                                               name="order_from" disabled placeholder="HH:MM" value={ transOrderDetail['orderData'][0].order_from }/>
                                      </div>
                                    }
                                    <div className="col-6">
                                      {(transOrderDetail['orderData'][0].translation_type_id !== OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id !== OrderType.PHONE_MESSAGE) &&
                                      /*<DatetimePickerWrapper readOnly={true} type="text" options={timePicker}
                                                             name={'order_to'} onChange={handleChange}
                                                             defaultValue={signature.order_to}
                                                             style={{height: '40px'}}/>*/
                                      <TimePickerWrapper type={'text'} className="servicesPicker order_from_time_picker"
                                                         id={'time_picker'}
                                                         data-id={transOrderDetail['orderData'][0].id} name={'order_to'}
                                                         onChange={(e) => handleChange(e)}
                                                         value={signature.order_to}
                                                         options={{defaultTime: signature.order_to}}
                                                         //options={timePicker}
                                                         placeholder={Lang.time}/>
                                      }
                                      {(transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT || transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE) &&
                                        <input type="text" className="servicesPicker form-control" data-id="order_to1"
                                               name="order_to" disabled placeholder="HH:MM" value={ transOrderDetail['orderData'][0].order_to }/>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              { transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE &&
                              <div className="col-sm-12">
                                <div className="form-group group">
                                  <div className={'colData '}>
                                    <span>{Lang.translation_of_phone_msg} <em className="required">*</em></span>
                                  </div>
                                  <textarea className={'form-control form-control2'} name={'translation_of_phone_message'} id={'translation_of_phone_message'} onChange={(e) => {setSignature({...signature, translation_of_phone_message: e.target.value})}}/>
                                </div>
                              </div>
                              }
                              {transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT &&
                              <div className="col-sm-12 docUploadCompleteOrder">
                                <div className="colData">
                                  <span>{Lang.translated_document} <em className="required">*</em></span>
                                </div>
                                <DocUpdate  addDoc={handleMultipleUpload} removeDoc={cancelMultipleUpload}/>
                              </div>
                              }
                              {(isMobile && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) &&
                                <div className="col-sm-12">
                                  <div className="colData form-group group">
                                    <span>{Lang.signature} <em className="required">*</em></span>
                                    <div className="sigPad">
                                      <canvas width="490" height="130" id={'canvas'} onClick={handleSign}/>
                                      <input type="submit" className="clearButton" onClick={clearSign} value={Lang.clear}/>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>

                          {(!isMobile && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) &&
                          <div className="custom-control custom-checkbox d-block">
                            <input onChange={handleChange} type="checkbox" className="custom-control-input" id="eSignature" name="e_signature" />
                            <label className="custom-control-label" htmlFor="eSignature">{Lang.e_signature}</label>
                          </div>
                          }

                          <div className="text-center mt-5">
                            {(isMobile && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) &&
                              <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} onClick={handleSign}
                                    label={Lang.vt_trans_lang_60_Completesubmit}/>
                            }
                            {!isMobile &&
                              <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_60_Completesubmit}/>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </FormLayout>
                </div>
              </div>
              }
              {displayCompleteOrder.display_complete_order === 0 &&
              <div className="row">
                <div className="col-xl-6 offset-xl-4 col-lg-8  offset-lg-2">
                  <h5>{Lang.you_dont_have_permision}</h5>
                </div>
              </div>
              }
              {/*{displayCompleteOrder.display_complete_order === 2 &&
              <div className="row">
                <div className="col-xl-6 offset-xl-6 col-lg-8  offset-lg-2">
                  <h5>{Lang.please_wait}</h5>
                </div>
              </div>
              }*/}
            </div>
          </section>
          <Footer/>
        </div>
      </div>
  )
}

class DocUpdate extends React.Component<any, any>{

  addDoc = (file:any) => {
    this.props.addDoc(file);
  }

  removeDoc = (file:any) => {
    this.props.removeDoc(file);
  }

  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
        <Document addDoc={this.addDoc} removeDoc={this.removeDoc}/>
    )
  }
}
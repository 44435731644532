import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";
import {Link, RouteComponentProps} from "react-router-dom";
import {Footer, Header} from "../partial";
declare let window: any;
export function NotFoundPage (props: RouteComponentProps) {

    useEffect(()=>{
        document.title = Lang.page_not_found;
        $('body').addClass('blankVector');
    },[]);

    return (

        <div className="bgMain">
            <div className="wrapper">
                <header className="mainHeader">
                    <div className="container">
                        <nav className="navbar">
                            <div className="col-6">
                                <Link className="navbar-brand" to="/">
                                    <img src="/theme/images/logo.png" alt="" title=""/>
                                </Link>
                            </div>
                            <div className="col-6 text-right">
                            </div>
                        </nav>
                    </div>
                </header>
                <section className="mainContent errorpagein">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                                <div className="error_mid">
                                    <img src="/theme/images/404_screen4.gif"/>
                                        <h2><strong> Sorry,</strong> the page you are looking for does not exist. <br/>
                                            Please continue to our <Link to="/">Home</Link> page or to your <Link to="/">Dashboard</Link></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>

    )
}

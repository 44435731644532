import React from 'react';
import {PasswordLayout} from "../layout";
import {ForgotPasswordForm, PropsSchema, ResetPasswordForm} from "../form/password";

export function ResetPassword (props: PropsSchema) {
  return(
    <PasswordLayout>
      <div className="forgot_password_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/*<div className="forgot_box rightbox">*/}
                <div className="boxin">
                  <ResetPasswordForm {...props}/>
                </div>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </PasswordLayout>




  )
}
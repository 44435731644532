import React, {useEffect} from 'react';
import {Footer, Header} from "../partial";
import {OrderInfo} from "./OrderInfo";
import {MapInfo} from "./MapInfo";
import {TranslatorFullCalender} from "./TranslatorFullCalender";
import Lang from "../common/lang/Lang";
import {getTranslationTypeList} from "../services";
import {OrderInfoService} from "./Schema";

export function MyAssignment (props: any) {

    useEffect(()=>{
        document.title = Lang.all_orders+' - '+Lang.vitolker_translator;
    }, []);

  return(

      <div className="bgMain">
        <div className="wrapper">
          <Header {...props}/>
          <section className="mainContent">
            <div className="container">

                <TranslatorFullCalender assignment_data={false}/>

            </div>
          </section>

          <div className="modal right fade scroll-touch" id="exampleModal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="sideMenuBarRight" id="orderDetailsBox">
                  <a href="#" className="btn closeBtn" data-dismiss="modal" onClick={OrderInfoService.hide}><span>×</span></a>
                  <OrderInfo />
                </div>
              </div>
            </div>
          </div>

          <div className="modal right fade scroll-touch" id="exampleModalMap" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
             <div className="modal-content">
                 <div id="orderDetailsBox" className={'routeMapPopup'}>
                 <a href="#" className="btn closeBtn" data-dismiss="modal"><span>×</span></a>
               <MapInfo/>
              </div>
              </div>
            </div>
          </div>

          <Footer/>
        </div>
      </div>
  )
}
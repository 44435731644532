import React from 'react';
import {Link} from "react-router-dom";
import Lang from "../common/lang/Lang";

export function QuickLinks () {

  return(
    <>
      <h2 className="title"><i className="far fa-sticky-note"></i> {Lang.vt_trans_lang_79_overview}</h2>
      <ul>
          <li className={(window.location.pathname==='/invitation'?'active':'')}>
          <Link className="navbar-brand" to={'invitation'}><i className="fas fa-folder-plus"></i> {Lang.new_invitation}</Link>
        </li>
          <li className={(window.location.pathname==='/my-assignment'?'active':'')}>
          <Link className="navbar-brand" to={'my-assignment'}><i className="fas fa-list-ul"></i> {Lang.all_orders}</Link>
        </li>
          <li className={(window.location.pathname==='/order-list'?'active':'')}>
          <Link className="navbar-brand" to={'order-list'}><i className="fas fa-file-alt"></i> {Lang.vt_trans_lang_61_order_history}</Link>
        </li>
      </ul>
    </>
  )
}
import {OrderDataSchema, OrderSearchSchema} from "./Schema";

export const OrderDataState: OrderDataSchema = {
  orderData: [],
  ordersInvitation: [],
  highRatedOrders: [],
  //translation_type: []
}

export const OrderSearchState: OrderSearchSchema = {
  order_from: '',
  order_to: '',
  type: ''
}

import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";

export function NoDataFound () {
    return (
        <div className="equalHeight noData">
            <div className="blankData">
                <img src="theme/images/sad.png" className="img-fluid" alt="No Record Found" title={Lang.no_record_found}/>
                <h3>{Lang.no_record_found}</h3>
            </div>
        </div>
    );
}
import React, {useEffect, useState} from 'react';
import {Auth, getUserInfo} from "../Auth/Auth";
import {Link} from "react-router-dom";
import {DefaultJQueryFun} from "../dashboard/DefaultJQueryFile";
import {ErrorNotification} from "../common/helpers";
import Lang from "../common/lang/Lang";
import {SwitchLoader} from "../common/AxoisClient";
import {LoaderType} from "../common/Loader";
import {AxiosResponse} from "axios";
import API from "../common/AxoisClient";
import _ from 'lodash';
import Cookies from 'js-cookie';
import {NotificationsIcon} from "../notifications/NotificationsIcon";
import {PrivacyandPolicy} from "../terms-and-condition/PrivacyandPolicy";

import {WebAppDownloadModal} from "./WebAppDownloadModal";
import {isMobile, isIPad13,} from "react-device-detect";
import {AppDownloadModal} from "./AppDownloadModal";
const moment =  require ("moment");
declare let window: any;

export function Header (props: any) {

  const [right, setRight] = useState("-320px");
  const user:any = getUserInfo();

  const [notification, setNotification] = useState([]);
  const [totalUnread, setTotalUnread] = useState(0);
  const [autoCount, setAutoCount] = useState(false);
  const[currentPathName,SetCurrentPathName] = useState('/');
  const [SendOnlineTest, setSendOnlineTest] = useState<number | null>(null);

  useEffect(() => {
    setTimeout(function () {
      API.get('get-user-data').then((res: AxiosResponse) => {
        if (res.data.result.is_accept_privacy_and_policy == 0) {
          ($('#privacy-and-policy') as any).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }
        // updates send_online_test in sidebar state and in localStorage for other components to use (would be better if persistent state was used)
        localStorage.setItem('send_online_test', String(res.data.translator_data.send_online_test));
        setSendOnlineTest(res.data.translator_data.send_online_test);
      });

      SwitchLoader(LoaderType.NONE);
      DefaultJQueryFun();
      let pageURL: any = window.location.href;
      let lastURLSegment: string = pageURL.substr(pageURL.lastIndexOf('/') + 1);

      SetCurrentPathName(lastURLSegment);
      API.get('get-unread-notifications').then((res: AxiosResponse) => {
        setNotification(res.data.notifications);
        setTotalUnread(res.data.totalUnread);
      });
      setTimeout(function () {
        setAutoCount(!autoCount);
      }, 400000);
    },4000);

  },[autoCount]);

  /*$(".overally").removeClass("ovrActv");
  $(".mnuopn").removeClass("mnuopn");*/

  function Logout() {
    Cookies.set('remember', '', { expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
    Cookies.set('remember_token', '', {expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
    Auth.signout();
   window.location.href = '/login';
  }

  let TanslatorHeaderData = localStorage.getItem('TanslatorHeaderData');
  let TheaderData = (TanslatorHeaderData!=null)?JSON.parse(TanslatorHeaderData):'';
  let translator_profile_image = TheaderData.profile_image;
  let translator_small_profile_image = TheaderData.profile_image_small;

  let translator_name = TheaderData.name;
  let user_code = user.user_code;

  function profileUnderReview(event:any){
    /*$(".overally").removeClass("ovrActv");
    $(".mnuopn").removeClass("mnuopn");*/
      ErrorNotification(Lang.profile_under_review);
  }

  function notificationHasRead(event: any,notificationId:number,url:string) {
    event.preventDefault();
    let target:any = event.target;
    API.post('mark-read-notification',{ id: notificationId }).then((res: AxiosResponse) => {
      if(!_.isEmpty(res.data) && res.data.status === true) {
        $(target).parent('li').hide();
        if(!_.isEmpty(url) && !_.isUndefined(url)) {
          if(!_.isUndefined(props.history) && !_.isEmpty(props.history)){
            props.history.push(url);
          }
        }
        setAutoCount(!autoCount);
      } else {
        ErrorNotification(res.data.message);
      }
    });
  }

  interface notificationSchema {
    id:number,
    notifiable_type:string,
    notifiable_id:number,
    data:{
      title:string,
      message:string,
      icon:string,
      type?:string,
      url:string,
    },
    read_at:string,
    created_at:string,
    updated_at:string,
  }

  return (
      <>
        <WebAppDownloadModal/>
        <header className="mainHeader">
          { (isMobile || isIPad13)  &&
              <AppDownloadModal/>
          }
          <div className="container">
            <nav className="navbar">
              <div className="col-6">

                <Link className="navbar-brand" to={'/dashboard'}>
                  <img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/>
                </Link>
              </div>
              <div className="col-6">
                <div className="text-right">
                  <ul className="notification_full">
                    <li>
                      <div className="dropdown notification-area">

                        {/*commented - 17-Mar-2019*/}
                        {/*<Link className="notification_in notification_hide_show" to="#">
                          <i className={'far fa-bell '+(notification.length?'animationEffect':'')}  aria-hidden="true"/>
                          { totalUnread > 0 &&
                            <span className="notification">{totalUnread}</span>
                          }
                        </Link>*/}

                        <a className="notification_in notification_hide_show" href="#" data-toggle="dropdown" aria-expanded="false">
                          <i className={'far fa-bell '+(notification.length?'animationEffect':'')}  aria-hidden="true"/>
                          { totalUnread > 0 &&
                            <span className="notification">{totalUnread}</span>
                          }
                        </a>

                          <div className={'notification-at-header'}>
                            <div className="show-notification notification-views dropdown-menu" data-dropdown-in="fadeIn"
                                 data-dropdown-out="fadeOut" style={{width: '340px',padding: '0px'}}>
                              <ul>
                                <li style={{borderBottom: '1px solid #dce1e7'}}>
                                  <h6>{Lang.notifications}</h6>
                                  <Link to="/notifications" className="fullViewPage">{Lang.view_all}</Link>
                                </li>

                                {notification.length >0 && notification.map((value:notificationSchema, index:number) => {
                                  return(
                                    <div>
                                      {!_.isEmpty(value.data.title) &&
                                      <li key={index} className={(value.read_at==null)?'unread':'read'}>
                                        {!_.isEmpty(value.data) &&
                                        <Link onClick={(e:any)=>notificationHasRead(e,value.id,(!_.isUndefined(value.data.url) && !_.isEmpty(value.data.url))?value.data.url:'')} to={!_.isEmpty(value.data.url)?value.data.url:''} className="notification-msg"  key={index}>
                                          <span className="icoNotfi" style={{minHeight: '38px',color: '#33bf98'}}>
                                            <NotificationsIcon notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>
                                          </span>
                                          <main className="twoRow">
                                            {!_.isEmpty(value.data.message) &&
                                              <span className="msgNotfi">{value.data.message}</span>
                                            }
                                            <span className="notification-time">
                                              <i className="far fa-clock"/> {moment(value.created_at).toNow(true)} {Lang.ago}
                                            </span>
                                          </main>
                                          <span className="linkNotfi"><i className="fa fa-angle-right"  aria-hidden="true"/></span>
                                        </Link>
                                        }
                                      </li>
                                    }
                                    </div>
                                  );
                                })}
                                {notification.length == 0 &&
                                <li className={'noNotificationLi'}>
                                  <div className="noData text-center">
                                    <div><img src={`${window.PUBLIC_URL}/theme/images/google-alerts.png`} width={'25'} alt={'logo'}/></div> <h6>{Lang.no_notification_found}</h6>
                                  </div>
                                </li>
                                }
                              </ul>
                            </div>
                          </div>

                      </div>
                    </li>
                  </ul>
                  <span className="profileArea smobitrigger">
                    <a href="javascript:;">
                      <img src={(translator_small_profile_image!=null)?translator_small_profile_image:''} alt={'small-img'} id="current_profile_pic"/> <span>{Lang.vt_trans_lang_63_option}</span> <i className="fas fa-bars MenuIcon"/>
                        </a>
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <div className={'container'}>
          <div className={'notification-at-header'}>
            <div className="show-notification notification-view">
              {/* commented - 17-Mar-2019
              <ul>
                <li>
                  <h6>{Lang.notifications}</h6>
                  <Link to="/notifications" className="fullViewPage">{Lang.view_all}</Link>
                </li>

                {notification.length >0 && notification.map((value:notificationSchema, index:number) => {
                  return(
                      <li key={index} className={(value.read_at==null)?'unread':'read'}>
                        {!_.isEmpty(value.data) &&
                        <Link onClick={(e:any)=>notificationHasRead(e,value.id,(!_.isUndefined(value.data.url) && !_.isEmpty(value.data.url))?value.data.url:'')} to={!_.isEmpty(value.data.url)?value.data.url:''} className="notification-msg"  key={index}>
                          <span className="icoNotfi">
                            <NotificationsIcon notification_type={(!_.isEmpty(value.data.type))?value.data:''}/>
                          </span>
                          <main className="twoRow">
                            {!_.isEmpty(value.data.message) &&
                              <span className="msgNotfi">{value.data.message}</span>
                            }
                            <span className="notification-time">
                              <i className="far fa-clock"/> {moment(value.created_at).toNow(true)} {Lang.ago}
                            </span>
                          </main>
                          <span className="linkNotfi"><i className="fa fa-angle-right"  aria-hidden="true"/></span>
                        </Link>
                        }
                      </li>
                  );
                })}
                {notification.length == 0 &&
                  <li className={'noNotificationLi'}>
                    <div className="noData text-center">
                      <div><img src={`${window.PUBLIC_URL}/theme/images/google-alerts.png`} width={'25'} alt={'logo'}/></div> <h6>{Lang.no_notification_found}</h6>
                    </div>
                  </li>
                }
              </ul>*/}
            </div>
          </div>
        </div>

        <div className="mobimenu">
          <div className=" sideMenuBar side-menu sideMenuBarRight">
            <div className={'text-right'}>
              <a href={'javascript:;'} className="mnuclose btn closeBtn"><span>×</span></a>
            </div>
            <div className='vertical-menu  scrollbar-inner'>
              <div className="profile_box">
                <div className="profile_boxMain">
                  <div className="imgbox">
                    <Link to={'/profile'}>
                        <img src={(translator_small_profile_image!=null)?translator_small_profile_image:''} alt={'small-img'} id="current_profile_pic"/>
                    </Link>
                  </div>
                  <div className="namebox">
                    <span>{translator_name}</span><br/>
                    <span>{user_code}</span>
                  </div>
                </div>
                <div className="side-menu2">
                  <div className="side-menu-container">
                    <ul className="navbar-nav">
                      {(user.status===1) &&
                        <>
                        {/* if translator is active and GTEP Test is sent but not yet taken */}
                        { SendOnlineTest === 1 &&
                          <li className={currentPathName==='online-test'?'active':''}><Link className="navbar-brand" to={'/online-test'}><i className="fas fa-file-alt"/><span>{Lang.online_test_heading}</span></Link></li>
                        }
                        <li className={currentPathName==='dashboard'?'active':''}><Link className="navbar-brand" to={'/dashboard'}><i className="fas fa-chart-pie"/><span>{Lang.dashboard}</span></Link></li>
                        <li className={currentPathName==='invitation'?'active':''}><Link className="navbar-brand" to={'/invitation'}><i className="fas fa-folder-plus"/><span>{Lang.new_invitation}</span></Link></li>
                        <li className={currentPathName==='my-assignment'?'active':''}><Link className="navbar-brand" to={'/my-assignment'}><i className="fas fa-list"/><span>{Lang.my_orders}</span></Link></li>
                        <li className={currentPathName==='complete-payment'?'active':''}><Link className="navbar-brand" to={'/complete-payment'}><i className="fas fa-credit-card"/><span>{Lang.payment}</span></Link></li>
                        <li className={currentPathName==='we-learn'?'active':''}><Link className="navbar-brand" to={'/we-learn'}><i className="fas fa-video"/><span>{Lang.we_learn}</span></Link></li>
                        {/*<li className={currentPathName==='online-quiz'?'active':''}><Link className="navbar-brand" to={'/online-quiz'}><i className="fas fa-chess-knight"/><span>{Lang.online_quiz}</span></Link></li>*/}</>
                      }
                      {(user.status===2 || user.status===3 || user.status===4) &&
                      <>
                        {/* if translator not active then show GTEP test any time it was sent  */}
                        { (SendOnlineTest === null) ?
                          <li className={currentPathName==='online-test'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-file-alt"/><span>{Lang.online_test_heading}</span></a></li>
                          :
                          <li className={currentPathName==='online-test'?'active':''}><Link className="navbar-brand" to={'/online-test'}><i className="fas fa-file-alt"/><span> {Lang.online_test_heading}</span></Link></li>
                        }
                        <li className={currentPathName==='dashboard'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-chart-pie"/><span>{Lang.dashboard}</span></a></li>
                        <li className={currentPathName==='invitation'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-folder-plus"/><span>{Lang.new_invitation}</span></a></li>
                        <li className={currentPathName==='my-assignment'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-list"/><span>{Lang.my_orders}</span></a></li>
                        <li className={currentPathName==='complete-payment'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-credit-card"/><span>{Lang.payment}</span></a></li>
                        <li className={currentPathName==='we-learn'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-video"/><span>{Lang.we_learn}</span></a></li>
                        {/*<li className={currentPathName==='online-quiz'?'active':''}><a href='javascript:;' className="disabled navbar-brand" onClick={profileUnderReview}><i className="fas fa-chess-knight"/><span>{Lang.online_quiz}</span></a></li>*/}</>
                      }
                      <li className={currentPathName==='profile'?'active':''}><Link className="navbar-brand" to={'/profile'}><i className="fas fa-id-badge"/><span>{Lang.my_profile}</span></Link></li>
                      <li className={currentPathName==='enquiry'?'active':''}><Link className="navbar-brand" to={'/enquiry'}><i className="fas fa-headset"/><span>{Lang.contact_us}</span></Link></li>
                       <li><a href="javascript:;"  onClick={() => Logout()}><i className="fas fa-sign-out-alt"/><span>{Lang.logout}</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <PrivacyandPolicy aftre_login={1}/>
      </>
  )
}
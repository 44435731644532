import Lang from "../common/lang/Lang";
import { translateThisKey } from "../common/helpers";

export const ForgotPasswordValidations = function () {
  $(document).ready(function () {

    $( "#forgot_password" ).validate({
      rules: {
        email: {
          required: true,
          email: true,
          minlength: 8,
          maxlength: 50,
        },
      },
    });

  });
}

export const ResetPasswordValidations = function () {
  $(document).ready(function () {

    // let strongRegex:any = new RegExp("^.*(?=.{8,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]){1})(?=.*\\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$");
    let weakRegex:any = new RegExp("^.*(?=.{8,})((?=.*[a-z]){1}).*$");
    $.validator.addMethod("pwcheck", function(value:any) {
      $.validator.messages.pwcheck = Lang.weak_password_text;
      return weakRegex.test(value);
    });

    $( "#reset_password" ).validate({
      rules: {
        password1: {
          minlength : 8,
          required: true,
          pwcheck:true,
        },
        cn_password: {
          minlength : 8,
          required: true,
          equalTo: "#password1",
          pwcheck:true,
        },
      },
    });
  });


  setTimeout(function () {
    ($('#password1') as any).pwstrength({
      common: {
        minChar:8,
        maxChar:20,
        onScore: function (options:any, word:any, totalScoreCalculated:any) {
          ($('#password1')as any).parents('.password').find('.progressBar_message ').show();
          let scoreData:number = 0;
          if (word.length<8) {
            scoreData += -(2.9)*word.length;
          }

          if (word.match(/[!@#$%^&*()\-_=+{};:,<.>]/)) {
            scoreData += (1.9)*(word.length/2);
          }else{
            scoreData += -(4.98)*(word.length/2);
          }

          if (word.match(/[0-9]/)) {
            scoreData += (1.9)*(word.length/2);
          }else{
            scoreData += -(4.98)*(word.length/2);
          }

          if (word.match(/[a-z]/)) {
            scoreData += (1.9)*word.length;
          }else{
            scoreData += -(4.98)*word.length;
          }

          if (word.match(/[A-Z]/)) {
            scoreData += (1.9)*word.length;
          } else {
            scoreData += -(4.98)*word.length;
          }

          if(scoreData > 100){
            scoreData = (scoreData-(2.38)*word.length)
          } else if(scoreData < 0){
            scoreData = (2.38)*word.length;
          }
          return scoreData;
        },
      },
      rules: {
        scores: {
          wordInvalidChar: -100,
          wordIsACommonPassword: -100,
          wordLetterNumberCharCombo: -2,
          wordLetterNumberCombo: -2,
          wordLowercase: -1,
          wordMaxLength: 2,
          wordMinLength: 1,
          wordNotEmail: -100,
          wordOneNumber: -3,
          wordOneSpecialChar: -3,
          wordRepetitions: -25,
          wordSequences: -20,
          wordSimilarToUsername: -100,
          wordThreeNumbers: -5,
          wordTwoCharacterClasses: -2,
          wordTwoSpecialChar: -5,
          wordUpperLowerCombo: -2,
          wordUppercase: -3,
        }
      },
      i18n:{
        t: function (key:string) {
            let result:string = translateThisKey(key);
            return result === key ? '' : result;
        }
      },
      ui:{
        container: "#pwd-container",
        viewports: {
          progress: "#passwordStrengthProgress",
          verdict: "#passwordStrengthVerdict"
        },
      }
    });
  },500);

}

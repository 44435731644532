import {OrderContactPerson} from "./OrderContactPerson";
import {CompanyModel} from "./Company";
import {DepartmentModel} from "./Department";
import {LanguageModel} from "./Language";
import {UserModel} from "./User";
import {OrderInvitation} from "./OrderInvitation";
import {TranslationTypeModel} from "./TranslationType";
import {OrderCommentModel} from "./OrderComment";
import {OrderFeedbackModel} from "./OrderFeedback";
//import {CancelOrders} from "../orders/orders/CancelOrders";
import {OrderCancelModel} from "./OrderCancel";
import {OrderEditCommentModel} from "./OrderEditComment";
import {ConnectyCubeModel} from "./ConnectyCube";
import {OrderFile} from "./OrderFile";


export interface clientData {
  client_name?: string;
  client_cpr?: string;
  client_lat?: string;
  client_long?: string;
}
export interface OrderModel {
  id?: number;
  order_no?: string;
  customer_id?: number;
  translator_id?: number;
  translation_type_id: number;
  translator_gender?: string;
  order_date?: string;
  order_from?: string;
  order_to?: string;
  complete_order_to?: string;
  office_note?: string;
  from_language?: number;
  to_language?: number;
  company_id: number|string;
  department_id: number|string;
  department_unit_id?: number;
  distance_in_km?: any;
  project_id?: number;
  address: string;
  city?: string;
  zip_code?: string;
  fax?: string;
  mobile_no?: string;
  email?: string;
  meeting_address_same?: boolean;
  meeting_address?: string;
  addition_info?: string;
  meeting_city?: string;
  meeting_zip_code?: string;
  meeting_lat?: string;
  meeting_long?: string;
  schedule_team_meeting?: boolean;
  video_meeting_link?: string;
  doc_price?: number;
  fix_price?: number;
  total_distance?: number;
  travel_time?: number;
  document?: string;
  tel_message?: string;
  clients?: Array<clientData>;
  employee_id?: string;
  comment_for_translator?: string;
  ean_number?: string;
  status?: number;
  created_by?: number;
  completed_by?: number;
  created_at ?: string;
  is_dumb ?: number;
  is_second_person ?: boolean;
  contact_persons: Array<OrderContactPerson>;
  company: CompanyModel;
  department?: DepartmentModel;
  language_from:LanguageModel
  language_to:LanguageModel;
  departmentUnit?:DepartmentModel
  project?:any;
  createdBy?:UserModel;
  ModalData?: OrderModel;
  order_files?: Array<OrderFile>;
  feedback?: OrderFeedbackModel;
  show?: boolean;
  translate_by?: UserModel;
  customer: UserModel;
  lat?:string;
  lng?:string;
  billing_email?:string;
  billing_mobile_number?:string;

  invitations?: Array<OrderInvitation>;
  translation_type: TranslationTypeModel;

  comments_count?: number
  comments?: Array<OrderCommentModel>
  total_call_time: Array<any>
  edit_request?: Array<OrderEditCommentModel>

  cancel?: OrderCancelModel;
  cancel_history?: Array<OrderCancelModel>;

  connecty_cube_customer?:ConnectyCubeModel;

}


export const OrderStatus = {
  NEW_ORDER         : 1,
  INVITATION_SENT   : 2,
  TRANS_ASSIGNED    : 3,
  CANCELLED         : 4,
  COMPLETED         : 5,
  PAID              : 6,
  UNDER_QA          : 7
};

export const OrderType = {
  TELEPHONE : 1,
  PHYSICAL : 2,
  VIDEO : 3,
  SIGN_LANGUAGE : 4,
  PHONE_MESSAGE : 5,
  DOCUMENT : 6
}

export const DateTimeFormat = {
  DATE : 'DD-MM-YYYY',
  TIME : 'HH:mm',
}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Icons } from "../../common/Icon";
import {
  CountryFlags,
  LanguageFlags,
  Select2Wrapper,
} from "../../common/Select2";
import API, { SwitchLoader } from "./../../common/AxoisClient";
import {
  OTPExpiryState,
  PhoneNoForPopupState,
  RegisterFormState,
  TimerCountState,
} from "./State";
import { TranslatorLanguageInterface } from "./Schema";
import { AxiosResponse } from "axios";
import Lang from "../../common/lang/Lang";
import { getMapContent, GooglePlace } from "../../common/GooglePlace";
import { InlineLoader } from "../../common/InlineLoader";
import {
  ErrorNotification,
  successNotification,
  showNotification,
} from "../../common/helpers/Notification";
import { RegistrationValidations, Progress } from "./Validations";
import { getCountryList, getDefaultCountry } from "../../services";
import { getLanguageList } from "../../services";
import { FormLayout, Submit } from "../../common";
import { LoaderType } from "../../common/Loader";
import { TermsAndConditions } from "../../terms-and-condition/TermsAndConditions";
import { PrivacyandPolicy } from "../../terms-and-condition/PrivacyandPolicy";
import * as _ from "lodash";
import { WebAppDownloadModal } from "../../partial/WebAppDownloadModal";
import { AppDownloadModal } from "../../partial/AppDownloadModal";
import { isIPad13, isMobile } from "react-device-detect";
import { Options } from "select2";
import { cloneDeep } from "lodash";

declare let window: any;

export interface Select2Props
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  data: Options;
}

export function SignUpPageNew(
  props: RouteComponentProps,
  select2Props: Select2Props
) {
  const [step, setStep] = useState("first");
  const [language, setLanguage] = useState();
  const [country, setCountry] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [register, setRegister] = useState(RegisterFormState);
  const [EmailExistsCheck, setEmailExistsCheck] = useState({
    emailExistsReturn: 2,
  });
  const [MobileExistsCheck, setMobileExistsCheck] = useState({
    mobileExistsReturn: 2,
  });
  const [timerCountDown, setTimerCountDown] = useState(TimerCountState);
  const [OTPExpiryTime, setOTPExpiryTime] = useState(OTPExpiryState);
  const [phoneNoForPopup, setPhoneNoForPopup] = useState(PhoneNoForPopupState);
  const [GenderIsRequiredError, setGenderIsRequiredError] = useState(false);
  const [AddressWasAutofilledError, setAddressWasAutofilledError] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  //const [fromToLangCheck, setFromToLangCheck] = useState({'fromToNotSame':0});
  useEffect(() => {
    document.title = Lang.sign_up_vitolker;
    SwitchLoader(LoaderType.NONE);
    getCountryList().then((country) => {
      setCountry(country);
    });
    getDefaultCountry().then((country) => {
      setPhoneNoForPopup({ ...phoneNoForPopup, country_code: country });
      setRegister({
        ...register,
        country_code: country,
        from_language_id: "96",
      });
    });
    getLanguageList().then((language) => {
      setLanguage(language);
    });
    RegistrationValidations();
  }, []);

  function JqueryFunction(index: number) {
    //$('.translator-language').each(function(){
    //$(this).select2('destroy');
    $("#from_language_id_" + index).select2({
      placeholder: Lang.select_source_lang,
      data: language,
      templateResult: LanguageFlags,
      templateSelection: LanguageFlags,
    });
    $("#to_language_id_" + index).select2({
      placeholder: Lang.vt_trans_lang_8_to_language,
      data: language,
      templateResult: LanguageFlags,
      templateSelection: LanguageFlags,
    });
    //});
  }

  function removeLanguages(index: number) {
    let languages: Array<TranslatorLanguageInterface> = [...register.language];
    _.pullAt(languages, index);
    setRegister({ ...register, language: languages });
  }

  function tabChange(event: any, tab: string) {
    if (
      tab === "second" &&
      (EmailExistsCheck.emailExistsReturn === 0 ||
        EmailExistsCheck.emailExistsReturn === 2)
    ) {
      if ($("#registration_form").valid()) {
        $("#email-exists-error").hide();
        /*if(register.from_language_id !== register.to_language_id){
                    setFromToLangCheck({...fromToLangCheck,fromToNotSame : 0});*/
        setStep(tab);
        Progress();
        setTimeout(function () {
          //($('#password1')as any).parents('.password').find('.progress ').hide();
          ($("#password1") as any)
            .parents(".password")
            .find(".progressBar_message ")
            .hide();
        }, 500);
        /*} else {
                    setFromToLangCheck({...fromToLangCheck,fromToNotSame : 1});
                }*/
      }
    } else if (tab === "first") {
      setStep(tab);
    }
  }

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    const register_data = cloneDeep(register);
    register_data.language = register_data.language.filter(
      (lang, index) => lang.to_language_id !== "" || index === 0
    );
    // the error display for gender gets inserted in the wrong spot suing jquery validation, this is a workaround
    !register_data.gender
      ? setGenderIsRequiredError(true)
      : setGenderIsRequiredError(false);
    // the city value gets added by google input component and not by user so it's a good way to judge whether address input was autofilled.
    !register_data.city || !register_data.zip_code
      ? setAddressWasAutofilledError(true)
      : setAddressWasAutofilledError(false);
    // state changes aren't done immediately, so we check for the values instead of state
    if (
      $("#registration_form").valid() &&
      register_data.gender &&
      register_data.city &&
      register_data.zip_code
    ) {
      register_data.name = `${firstName} ${lastName}`;
      API.post(`register`, register_data).then((res: AxiosResponse) => {
        successNotification(Lang.registration_success);
        props.history.push("/thank-you");
      });
    }
    setRegister((state) => ({
      ...state,
      language: state.language.filter(
        (lang, index) => lang.to_language_id !== "" || index === 0
      ),
    }));
  }

  function googleGetAddress(place: any) {
    const { address, city, lat, lng, zip_code } = getMapContent(place);
    zip_code !== ""
      ? $("#zip_code-error").css({ display: "none" })
      : $("#zip_code-error").css({ display: "block" });
    address !== ""
      ? $("#address-error").css({ display: "none" })
      : $("#address-error").css({ display: "block" });
    if (!zip_code || !address) {
      setAddressWasAutofilledError(true);
    } else {
      setAddressWasAutofilledError(false);
    }
    setRegister((prevState) => ({
      ...prevState,
      address: address,
      zip_code: zip_code,
      city: city,
      lat: lat,
      lng: lng,
    }));
  }

  function handleChange(e: any) {
    if (e.target.className.includes("translator-language")) {
      // control only contact order inputs
      let languages: any = [...register.language];
      languages[e.target.dataset.id][e.target.dataset.name] = e.target.value;
      setRegister((prevSate) => ({
        ...prevSate,
        [e.target.dataset.name]: languages,
      }));
      $(`#${e.target.id}-error`).css({ display: "none" });
    } else {
      $("#" + e.target.name + "-error").css({ display: "none" });
      const { name, value } = e.target;
      setRegister((prevSate) => ({ ...prevSate, [name]: value }));
    }
    //setPhoneNoForPopup(prevSate => ({...prevSate, [name]: value}));
  }

  function handleChangeTermsCondition(event: React.ChangeEvent<any>) {
    if (event.target.checked === true) {
      setRegister({ ...register, terms_and_condition: "1" });
    } else {
      setRegister({ ...register, terms_and_condition: "0" });
    }
  }

  // this has been disabled in the form but is left here for convience just in case.
  function checkMobile() {
    if (register.mobile_no && register.country_code) {
      SwitchLoader(LoaderType.INLINE);
      API.get(`check-mobile-exists`, {
        params: {
          mobile_no: register.mobile_no,
          country_code: register.country_code,
        },
      }).then((res: AxiosResponse) => {
        if (res.data.status === 0) {
          setMobileExistsCheck({ ...MobileExistsCheck, mobileExistsReturn: 0 });
        } else {
          setMobileExistsCheck({ ...MobileExistsCheck, mobileExistsReturn: 1 });
        }
      });
    } else {
      setMobileExistsCheck({ ...MobileExistsCheck, mobileExistsReturn: 2 });
    }
  }

  function sendOTP(event: any, type: string) {
    let startDate = new Date();
    let letterNumber = /^[0-9]+$/;
    if (
      phoneNoForPopup.mobile_no.length > 7 &&
      phoneNoForPopup.mobile_no.match(letterNumber)
    ) {
      API.post(`send-otp`, phoneNoForPopup).then((res: AxiosResponse) => {
        if (type === "send_otp" && res.data.errors === undefined) {
          setRegister({
            ...register,
            otp: res.data.translator_phone_number_otp_enc,
            send_otp_text: "Resend OTP",
            translator_phone_number: res.data.translatorPhoneNumber,
          });
          setTimerCountDown({ ...timerCountDown, timer_count: "01:00" });
          setOTPExpiryTime({ ...OTPExpiryTime, start_time: startDate });
          startTimer(60 * 1);
          successNotification(Lang.otp_sent_msg);
        } else {
          ErrorNotification(res.data.errors.mobile_no[0]);
        }
      });
    } else {
      ErrorNotification(Lang.valid_mobile_no);
    }
  }

  function startTimer(duration: any) {
    var start = Date.now(),
      diff,
      minutes,
      seconds;
    function timer() {
      diff = duration - (((Date.now() - start) / 1000) | 0);
      if (diff == 0) {
        setTimerCountDown({ ...timerCountDown, timer_count: "420" });
        clearInterval(refreshIntervalId);
        return;
      }
      minutes = (diff / 60) | 0;
      seconds = diff % 60 | 0;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setTimerCountDown({
        ...timerCountDown,
        timer_count: minutes + ":" + seconds,
      });
      if (diff <= 0) {
        start = Date.now() + 1000;
      }
    }
    var refreshIntervalId = setInterval(timer, 1000);
  }

  function checkOTP(event: any, type: string) {
    let get_start_time = OTPExpiryTime.start_time;
    let get_end_time = new Date();
    var seconds = (get_end_time.getTime() - get_start_time.getTime()) / 1000;
    if (seconds < 300) {
      API.post(`check-otp`, register).then((res: AxiosResponse) => {
        if (type === "check_otp" && res.data.verified_phone_number === "0") {
          setRegister({
            ...register,
            verified_phone_number: res.data.verified_phone_number,
          });
          ErrorNotification("Invalid OTP");
        } else if (
          type === "check_otp" &&
          res.data.verified_phone_number === "1"
        ) {
          setRegister({
            ...register,
            translator_phone_number: res.data.translatorPhoneNumber,
            verified_phone_number: res.data.verified_phone_number,
            send_otp_text: "Send OTP",
            translator_otp: "",
            final_phone_number: res.data.translatorPhoneNumber,
            mobile_no: res.data.mobile_no,
            country_code: res.data.country_code,
          });
          setPhoneNoForPopup(PhoneNoForPopupState);
          $("#temp_mobile_no-error").css("display", "none");
          $("#mobile_no").val("");
          $("#translator_otp").val("");
          ($("#edit-mobile-no") as any).modal("hide");
        }
      });
    } else {
      ErrorNotification(Lang.otp_expire);
    }
  }

  function checkEmail() {
    //let re = /^(([^<>()[\]\\,;:\s@\"]+(\[^<>()[\]\\,;:\s@\"]+)*)|(\"+\"))@((\[[0-9]{1,3}\[0-9]{1,3}\[0-9]{1,3}\[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+/;
    if (re.test(register.email)) {
      SwitchLoader(LoaderType.INLINE);
      API.get(`check-email-exists`, { params: { email: register.email } }).then(
        (res: AxiosResponse) => {
          if (res.data.status === 0) {
            setEmailExistsCheck({ ...EmailExistsCheck, emailExistsReturn: 0 });
          } else {
            setEmailExistsCheck({ ...EmailExistsCheck, emailExistsReturn: 1 });
          }
        }
      );
    } else {
      setEmailExistsCheck({ ...EmailExistsCheck, emailExistsReturn: 2 });
    }
  }

  function handleGenderChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setRegister((prevSate) => ({ ...prevSate, [name]: value }));
    setGenderIsRequiredError(false);
  }
  return (
    <div className="login_page" id="register_page">
      <WebAppDownloadModal />
      {(isMobile || isIPad13) && <AppDownloadModal />}
      <div className="container-fluid">
        <div className="row FormSite">
          {/***********************************************         Sign Up Form */}
          <div className="col-lg-12 order-lg-1 nopadding boxRow">
            <div id="tile_1">
              <div className="rightbox back" id="tile_signup">
                {/* ****************************    LOGO */}
                <div className="logo_mobile hidden-sm hidden-md hidden-lg">
                  {" "}
                  <Link to={"#"}>
                    <img
                      src={`${window.PUBLIC_URL}/theme/images/logo.png`}
                      alt={"logo"}
                    />{" "}
                  </Link>
                </div>

                <div
                  className="centerbox bg-white rounded shadow"
                  id="register_form"
                >
                  <div className="boxin">
                    {/* ***********     Title */}
                    <h1>{Lang.vt_trans_lang_32_create_account}</h1>

                    {/* ***********     Register Form */}
                    <FormLayout
                      id="registration_form"
                      onSubmit={handleSubmit}
                      autoComplete="nope"
                    >
                      {step === "first" && (
                        <fieldset id="first_step">
                          {/* ************** first name and last name fields */}
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label>Fornavn</label>
                              <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className="form-control boxed-input"
                                placeholder="Skriv fornavn her"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                              ></input>
                            </div>
                            <div className="form-group col-lg-6">
                              <label>Efternavn</label>
                              <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className="form-control boxed-input"
                                placeholder="Skriv efternavn her"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                              ></input>
                            </div>
                          </div>
                          {/* 2nd row */}
                          <div className="row">
                            {/* *********     Email field */}
                            <div className="form-group group password col-lg-6">
                              <InlineLoader>
                                <label>{Lang.email}</label>
                                <input
                                  id="email"
                                  type="email"
                                  placeholder={Lang.vt_trans_lang_2_email}
                                  value={register.email}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setRegister({
                                      ...register,
                                      email: e.target.value,
                                    });
                                  }}
                                  onKeyUp={checkEmail}
                                  className="form-control boxed-input"
                                  name="email"
                                />
                                {EmailExistsCheck.emailExistsReturn === 0 && (
                                  <i
                                    className="fa fa-check"
                                    style={{ color: "green" }}
                                    aria-hidden="true"
                                  />
                                )}
                              </InlineLoader>
                              {EmailExistsCheck.emailExistsReturn === 1 && (
                                <>
                                  <i
                                    className="fa fa-times"
                                    style={{ color: "red" }}
                                    aria-hidden="true"
                                  />
                                  <label
                                    id="email-exists-error"
                                    className={"error"}
                                    style={{ color: "red" }}
                                  >
                                    {Lang.email_already_register}
                                  </label>
                                </>
                              )}
                            </div>
                            {/* *********     Phone field */}
                            <div className="col-lg-6 group form-group mobile-field">
                              <label>Telefonnummer</label>
                              <div className="col-md-4 pl-0 pr-0">
                                <Select2Wrapper
                                  value={register.country_code || ""}
                                  onChange={handleChange}
                                  className={"form-control boxed-input"}
                                  id={"country_code"}
                                  name={"country_code"}
                                  data={{
                                    placeholder: Lang.country_placeholder,
                                    data: country,
                                    templateResult: CountryFlags,
                                    templateSelection: CountryFlags,
                                  }}
                                >
                                  <option value={""}>
                                    {Lang.country_placeholder}
                                  </option>
                                </Select2Wrapper>
                              </div>

                              <div
                                className="col-md-8"
                                id="translator-register-phone"
                              >
                                <InlineLoader>
                                  <input
                                    id="mobile_no"
                                    type="text"
                                    placeholder={Lang.mobile_placeholder}
                                    className="phone_no form-control boxed-input"
                                    name="mobile_no"
                                    value={register.mobile_no}
                                    onChange={(e) => {
                                      setRegister({
                                        ...register,
                                        mobile_no: e.target.value,
                                      });
                                    }}
                                  />

                                  {MobileExistsCheck.mobileExistsReturn ===
                                    0 && (
                                    <i
                                      className="fa fa-check"
                                      style={{ color: "green" }}
                                      aria-hidden="true"
                                    />
                                  )}
                                </InlineLoader>
                              </div>
                              {MobileExistsCheck.mobileExistsReturn === 1 && (
                                <>
                                  <i
                                    className="fa fa-times"
                                    style={{ color: "red" }}
                                    aria-hidden="true"
                                  />
                                  <label
                                    id="email-exists-error"
                                    className={"error"}
                                    style={{ color: "red" }}
                                  >
                                    {Lang.mobile_already_register}
                                  </label>
                                </>
                              )}
                            </div>
                          </div>

                          {/* *********     Address field */}
                          <div
                            className="group form-group"
                            id="contains-boxed-input"
                          >
                            <label>Adresse</label>
                            <GooglePlace
                              onChooseAddress={googleGetAddress}
                              name={"address"}
                              className="form-control"
                              defaultValue={register.address}
                              placeholder={Lang.address_placeholder}
                              autoComplete="nope"
                            />
                            {AddressWasAutofilledError && (
                              <label
                                id="address-autofill-error"
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  display: "block",
                                  fontSize: "12px",
                                }}
                              >
                                <i
                                  className="fa fa-times"
                                  style={{ color: "red" }}
                                  aria-hidden="true"
                                />
                                &nbsp;{Lang.invalid_address}
                              </label>
                            )}
                          </div>
                          <div className="group form-group ">
                            <input
                              type="hidden"
                              className="form-control input"
                              id="zip_code"
                              name="zip_code"
                              onChange={(e) => {
                                setRegister({
                                  ...register,
                                  zip_code: e.target.value,
                                });
                              }}
                              placeholder={Lang.zip_code_placeholder}
                              value={register.zip_code}
                            />
                          </div>
                          {/**********     Gender Input*/}
                          <div style={{ marginBottom: "-0.4rem" }}>
                            <div
                              className="form-group"
                              style={{
                                textAlign:
                                  window.innerWidth > 991 ? "left" : "center",
                                marginBottom: "-0.3rem",
                                paddingBottom: 0,
                              }}
                            >
                              <label style={{ marginBottom: "0" }}>
                                {" "}
                                {Lang.gender_title}{" "}
                              </label>
                            </div>
                            <div
                              className="col-lg"
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="form-check"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "-0.4rem",
                                }}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="female"
                                  onChange={handleGenderChange}
                                  value="1"
                                  name="gender"
                                  style={{
                                    padding: "0.25rem 1rem 0.25rem 0",
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"female"}
                                  style={{
                                    padding: "0.25rem 1rem 0.25rem 0",
                                  }}
                                >
                                  {" "}
                                  {Lang.male}{" "}
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="male"
                                  onChange={handleGenderChange}
                                  value="2"
                                  name="gender"
                                  style={{
                                    padding: "0.25rem 1rem 0.25rem 0",
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"male"}
                                  style={{
                                    padding: "0.25rem 1rem 0.25rem 0",
                                  }}
                                >
                                  {" "}
                                  {Lang.female}{" "}
                                </label>
                              </div>
                            </div>
                            {GenderIsRequiredError && (
                              <label
                                id="gender-required-error"
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  display: "block",
                                  fontSize: "12px",
                                }}
                              >
                                <i
                                  className="fa fa-times"
                                  style={{ color: "red" }}
                                  aria-hidden="true"
                                />
                                &nbsp;{Lang.requiredValidator}
                              </label>
                            )}
                          </div>

                          {/* *********     Languages field */}
                          {register.language &&
                            register.language.map(
                              (
                                item: TranslatorLanguageInterface,
                                index: number
                              ) => {
                                let deleteButton: JSX.Element = <></>;
                                if (index > 0) {
                                  deleteButton = (
                                    <div
                                      className={
                                        "col-md-12 text-right closeButton"
                                      }
                                    >
                                      <button
                                        type={"button"}
                                        onClick={() => removeLanguages(index)}
                                        className={"btn"}
                                      >
                                        <span>-</span>
                                      </button>
                                    </div>
                                  );
                                }
                                return (
                                  <div className="row" key={index}>
                                    {deleteButton}
                                    {/* From language */}
                                    <div className="col-md-6 col-sm-6">
                                      <div className="form-group md-form mt-3 validation-posi">
                                        <label className="control-label d-block">
                                          {Lang.source_lang}{" "}
                                          {/* <span className="required">*</span> */}
                                        </label>
                                        <div className="text__center">
                                          <Select2Wrapper
                                            value={
                                              register.language[index]
                                                .from_language_id
                                            }
                                            data-id={index}
                                            data-name={"from_language_id"}
                                            onChange={handleChange}
                                            id={`from_language_id_${index}`}
                                            name={"from_language_id"}
                                            className={
                                              "form-control translator-from-language translator-language boxed-input"
                                            }
                                            data={{
                                              placeholder:
                                                Lang.select_source_lang,
                                              data: language,
                                              templateResult: LanguageFlags,
                                              templateSelection: LanguageFlags,
                                            }}
                                          >
                                            <option value={""}>
                                              {Lang.select_source_lang}
                                            </option>
                                          </Select2Wrapper>
                                        </div>
                                      </div>
                                    </div>
                                    {/* To language */}
                                    <div className="col-md-6 col-sm-6">
                                      <div className="form-group md-form mt-3 validation-posi">
                                        <label className="control-label d-block">
                                          {Lang.target_lang}{" "}
                                          {/* <span className="required">*</span> */}
                                        </label>
                                        <div className="text__center">
                                          <Select2Wrapper
                                            className={
                                              "form-control translator-to-language translator-language boxed-input"
                                            }
                                            data-id={index}
                                            data-name={"to_language_id"}
                                            value={
                                              register.language[index]
                                                .to_language_id
                                            }
                                            onChange={handleChange}
                                            id={`to_language_id_${index}`}
                                            name={"to_language_id"}
                                            data={{
                                              placeholder:
                                                Lang.vt_trans_lang_8_to_language,
                                              data: language,
                                              templateResult: LanguageFlags,
                                              templateSelection: LanguageFlags,
                                            }}
                                          >
                                            <option value={""}>
                                              {Lang.vt_trans_lang_8_to_language}
                                            </option>
                                          </Select2Wrapper>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <label />
                                    </div>
                                  </div>
                                );
                              }
                            )}

                          {/* Add language button */}
                          <div className="col-md-12 mt-20">
                            <div className="row align-items-center mb-1-mobile">
                              <div className="col-sm-7 col-12"></div>
                              <div className="col-sm-5 col-12 mobiCenter">
                                <button
                                  type="button"
                                  onClick={(event) => {
                                    let languages: Array<TranslatorLanguageInterface> =
                                      [...register.language];
                                    languages.push({
                                      from_language_id: 96,
                                      to_language_id: "",
                                    });
                                    setRegister({
                                      ...register,
                                      language: languages,
                                    });
                                    JqueryFunction(languages.length - 1);
                                  }}
                                  className="btn addLangBtn"
                                >
                                  {Lang.add_language}
                                  <i className="fas fa-plus hvr-push" />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* *********     Comments for HR field */}
                          <div className="group form-group">
                            <label className="control-label d-block">
                              Bemærkninger til HR{" "}
                              <span className="optional">(valgfrit)</span>
                            </label>
                            <textarea
                              value={register.about}
                              className="form-control input about-translator boxed-input"
                              name={"about"}
                              onChange={(e: any) => {
                                setRegister({
                                  ...register,
                                  about: e.target.value,
                                });
                              }}
                              placeholder={Lang.about_yourself}
                              id="about"
                            ></textarea>
                          </div>
                          <div className="form-group group password">
                          </div>
                        </fieldset>
                      )}
                      <fieldset id="second_step">
                        {/* New policy field */}
                        <div className="group form-group text-left">
                          <div className="custom-control pl-0">
                            <label
                              className="custom-control-label"
                              htmlFor={"terms_and_condition"}
                              id="petite-policy"
                            >
                              {" "}
                              Jeg har læst og accepterer ViTolkers{" "}
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#privacy-and-policy"
                              >
                                <strong>
                                  Persondata og fortrolighedspolitik
                                </strong>
                              </a>
                              . Ydermere giver jeg hermed samtykke til, at
                              oplysninger jeg har givet om mig selv, kan
                              registreres af ViTolker, idet registreringen er
                              nødvendig for vurdering af ansøgningen til
                              stillingen som tolk eller oversætter hos ViTolker.
                              Jeg er bekendt med, at samtykket til enhver tid
                              kan tilbagekaldes, hvorved ViTolker forpligtes til
                              at slette oplysningerne om mig jf.{" "}
                              <a
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#privacy-and-policy"
                              >
                                <strong>
                                  ViTolkers Persondata og fortrolighedspolitik
                                </strong>
                              </a>
                              .{" "}
                            </label>
                          </div>
                        </div>
                        {/* *********     Submit button */}
                        <Submit
                          className={
                            "btn btn-lg btn-green hvr-rectangle-out float-right"
                          }
                          label={Lang.vt_trans_lang_14_sendbutton}
                        />
                      </fieldset>
                    </FormLayout>
                    <div className="modal customModal fade" id="edit-mobile-no">
                      <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h2 className="title m">
                              <i className="fas fa-key" />{" "}
                              {Lang.vt_trans_lang_4_verify_phone_number}
                            </h2>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="row form-group">
                              <div className="col-md-4">
                                <Select2Wrapper
                                  value={phoneNoForPopup.country_code}
                                  onChange={handleChange}
                                  id={"country_code"}
                                  name={"country_code"}
                                  data={{
                                    placeholder: Lang.country_placeholder,
                                    data: country,
                                    templateResult: CountryFlags,
                                    templateSelection: CountryFlags,
                                  }}
                                >
                                  <option value={""}>
                                    {Lang.country_placeholder}
                                  </option>
                                </Select2Wrapper>
                              </div>
                              <div className="col-md-8">
                                <input
                                  id="mobile_no"
                                  type="text"
                                  placeholder={Lang.mobile_placeholder}
                                  className="phone_no form-control "
                                  name="mobile_no"
                                  value={phoneNoForPopup.mobile_no}
                                  onChange={(e) => {
                                    setPhoneNoForPopup({
                                      ...phoneNoForPopup,
                                      mobile_no: e.target.value,
                                    });
                                    setRegister({
                                      ...register,
                                      mobile_no: e.target.value,
                                    });
                                  }}
                                />
                                {Lang.vt_trans_lang_6_send_otp ===
                                  "Send sikkerhedskoden" && (
                                  <button
                                    type={"button"}
                                    className="btn btn-secondary senOtpBtn"
                                    id="VerifyOtpBtn"
                                    onClick={(e) => sendOTP(e, "send_otp")}
                                  >
                                    {Lang.vt_trans_lang_6_send_otp}
                                  </button>
                                )}
                                {Lang.vt_trans_lang_7_resend_otp ===
                                  "Gensend sikkerhedskoden" &&
                                  timerCountDown.timer_count == "420" && (
                                    <button
                                      type={"button"}
                                      className="btn btn-secondary senOtpBtn"
                                      id="VerifyOtpBtn"
                                      onClick={(e) => sendOTP(e, "send_otp")}
                                    >
                                      {Lang.vt_trans_lang_7_resend_otp}{" "}
                                    </button>
                                  )}
                                {register.send_otp_text === "Resend OTP" &&
                                  timerCountDown.timer_count !== "420" && (
                                    <button
                                      type={"button"}
                                      className="btn btn-secondary senOtpBtn"
                                      id="VerifyOtpBtn"
                                    >
                                      {timerCountDown.timer_count}
                                    </button>
                                  )}
                              </div>
                            </div>
                            {register.send_otp_text === "Resend OTP" && (
                              <>
                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <input
                                      id="translator_otp"
                                      type="text"
                                      placeholder={
                                        Lang.vt_trans_lang_5_enter_otp_number
                                      }
                                      className="phone_no form-control"
                                      name="translator_otp"
                                      onChange={(e) => {
                                        setPhoneNoForPopup({
                                          ...phoneNoForPopup,
                                          translator_otp: e.target.value,
                                        });
                                        setRegister({
                                          ...register,
                                          translator_otp: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                {phoneNoForPopup.translator_otp !== "" &&
                                  phoneNoForPopup.translator_otp.length > 5 && (
                                    <div className="mt-4 btnSet text-center">
                                      <button
                                        type={"button"}
                                        onClick={(e) =>
                                          checkOTP(e, "check_otp")
                                        }
                                        className="btn btn-green btn-xl hvr-rectangle-out"
                                      >
                                        {Lang.verify}
                                      </button>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <PrivacyandPolicy aftre_login={0} />
                    <TermsAndConditions aftre_login={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

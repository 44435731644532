import API from "../common/AxoisClient";

export const getLanguageList = async() => {
  const languages = await API.get(`language-list`);
  return languages.data;
}

export const getLevelList = async() => {
  const levels = await API.get(`level-list`);
  return levels.data;
}

export const getTranslationTypeList = async() => {
  const translationTypes = await API.get(`translation-type-list`);
  return translationTypes.data;
}
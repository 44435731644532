import React from 'react';
import {Footer} from "../partial";
import {Link} from "react-router-dom";

declare let window: any;

export function PasswordLayout(props: any) {
  return(
    <>
      {/*<header className="mainHeader">
        <div className="header-section forgotpassword">
          <div className="top-header">
            <div id="navbar" className="header affix" data-spy="affix" data-offset-top="197">
              <div className="container">
                <div className="row">
                  <nav className="navbar navbar-default navbar-static-top" role="navigation">
                    <div className="col-md-6">
                      <div className="navbar-header">
                        <Link to={'/'} className="navbar-logo"><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/> </Link> </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>*/}

      <header className="mainHeader">
        <div className="container">
          <nav className="navbar">
            <div className="col-6">
              <Link to={'/'} className="navbar-logo"><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/> </Link>
            </div>
            <div className="col-6 text-right">
            </div>
          </nav>
        </div>
      </header>

      {props.children}
      <Footer/>

    </>
  )
}
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import Lang from "../../common/lang/Lang";
import { Auth, getUserInfo } from "../../Auth/Auth";
import { Modal } from "react-bootstrap";
import API from "../../common/AxoisClient";
import { AxiosResponse } from "axios";
import { successNotification } from "../../common/helpers";
import { EditBankInfoValidations } from "./Validations";
import { FormLayout, Icons, Submit } from "../../common";
import _, { isEmpty } from "lodash";
import style from "./TranslatorProfile.module.scss";
import { BankInfoFormState } from "../signup/State";
declare let window: any;

export const TBankInfo = (props: any) => {
  const user: any = getUserInfo();

  const [EditBankInfoPopup, setEditBankInfoPopup] = useState({
    bank_registration_no: "",
    account_no: "",
    show: false,
    user_id: Auth.userInfo.id,
    iban: "",
    swift: "",
    bank_name: "",
    account_holder_name: "",
  });

  function popup(event: any, mode: boolean) {
    setEditBankInfoPopup({
      ...EditBankInfoPopup,
      bank_registration_no: "",
      account_no: "",
      show: mode,
      user_id: Auth.userInfo.id,
      iban: "",
      swift: "",
      bank_name: "",
      account_holder_name: "",
    });
    if (mode === true) {
      setTimeout(function () {
        EditBankInfoValidations();
      }, 1000);
      API.get(`translator-data/${Auth.userInfo.id}`).then((res: AxiosResponse) => {
        const getTranslatorData = res.data.translatorData;
        setTimeout(function () {
          setEditBankInfoPopup({
            ...EditBankInfoPopup,
            bank_registration_no: getTranslatorData.bank_registration_no,
            account_no: getTranslatorData.account_no,
            iban: getTranslatorData.iban,
            swift: getTranslatorData.swift,
            bank_name: getTranslatorData.bank_name,
            account_holder_name: getTranslatorData.account_holder_name,
            show: mode,
          });
        }, 500);
      });
    }
  }

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    if ($("#edit_bankinfo_form").valid()) {
      API.post(`profile/update-bank-info`, EditBankInfoPopup).then((res: AxiosResponse) => {
        successNotification(Lang.bank_info_success);
        setEditBankInfoPopup({ ...EditBankInfoPopup, show: false });
        props.refreshData();
      });
    }
  }
  const missingIbanOrSwift =
    isEmpty(props.translator_bank_info.iban) || isEmpty(props.translator_bank_info.iban);
  return (
    <div id="profileTab2" className="tab-pane">
      <div className="row">
        <div className="col-md-12">
          <div className="edit-commen">
            <button
              type="button"
              className="ico-btn"
              data-toggle="modal"
              data-target="#makeComplaint"
              onClick={(e) => popup(e, true)}
            >
              <i className="fas fa-pencil-alt" />
            </button>
            <span className={"editLabel"}>{Lang.edit}</span>
            <Modal
              show={EditBankInfoPopup.show}
              size="lg"
              className="customModal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h1 className="title ">
                    <i className="far fa-edit" /> {Lang.updt_bank_info_title}
                  </h1>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={(e) => popup(e, false)}
                  >
                    &times;
                  </button>
                </Modal.Title>
              </Modal.Header>
              {EditBankInfoPopup.account_no == "" && (
                <div className="dataLoad">{Lang.please_wait}</div>
              )}
              {EditBankInfoPopup.account_no != "" && (
                <Modal.Body>
                  <FormLayout id="edit_bankinfo_form" onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            {Lang.bank_reg_no} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bank_registration_no"
                            placeholder={Lang.bank_reg_placeholder}
                            value={EditBankInfoPopup.bank_registration_no}
                            onChange={(e) => {
                              setEditBankInfoPopup({
                                ...EditBankInfoPopup,
                                bank_registration_no: e.target.value,
                              });
                            }}
                            name="bank_registration_no"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            {Lang.bank_account_no} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="account_no"
                            placeholder={Lang.bank_accno_placeholder}
                            value={EditBankInfoPopup.account_no}
                            onChange={(e) => {
                              setEditBankInfoPopup({
                                ...EditBankInfoPopup,
                                account_no: e.target.value,
                              });
                            }}
                            name="account_no"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="account_holder_name">
                            {Lang.account_holder_name} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="account_holder_name"
                            placeholder={Lang.account_holder_name_placeholder}
                            value={EditBankInfoPopup.account_holder_name}
                            onChange={(e) => {
                              setEditBankInfoPopup({
                                ...EditBankInfoPopup,
                                account_holder_name: e.target.value,
                              });
                            }}
                            name="account_holder_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="bank_name">
                            {Lang.bank_name} <span className={"required"}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bank_name"
                            placeholder={Lang.bank_name_placeholder}
                            value={EditBankInfoPopup.bank_name}
                            onChange={(e) => {
                              setEditBankInfoPopup({
                                ...EditBankInfoPopup,
                                bank_name: e.target.value,
                              });
                            }}
                            name="bank_name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="iban">
                            IBAN <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="iban"
                            placeholder={Lang.iban_placeholder}
                            value={EditBankInfoPopup.iban}
                            onChange={(e) =>
                              setEditBankInfoPopup({ ...EditBankInfoPopup, iban: e.target.value })
                            }
                            name="iban"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="swift">
                            SWIFT <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="swift"
                            placeholder="SWIFT"
                            value={EditBankInfoPopup.swift}
                            onChange={(e) =>
                              setEditBankInfoPopup({ ...EditBankInfoPopup, swift: e.target.value })
                            }
                            name="swift"
                          />
                        </div>
                      </div>
                      {/*<div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="iban">{Lang.iban}</label>
                                                <input type="text"
                                                       className="form-control"
                                                       id="account_no"
                                                       placeholder={Lang.iban_placeholder} value={EditBankInfoPopup.iban} onChange={(e) => {setEditBankInfoPopup({...EditBankInfoPopup, iban: e.target.value})}} name="iban"/>
                                            </div>
                                        </div>*/}
                    </div>
                    <div className="mt-4 btnSet text-center">
                      <Submit
                        className={"btn btn-lg btn-green hvr-rectangle-out"}
                        label={Lang.submit}
                      />
                    </div>
                  </FormLayout>
                </Modal.Body>
              )}
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <ul className="infoList basic_information row">
        <li className="col-md-4 col-sm-6">
          <span className="label">
            <i className="fas fa-university colorPink" />
            {Lang.bank_reg_no}
          </span>
          <div className="dataSet">{props.translator_bank_info.bank_registration_no}</div>
        </li>
        <li className="col-md-4 col-sm-6">
          <span className="label">
            <i className="fas fa-registered colorPink" />
            {Lang.bank_account_no}
          </span>
          <div className="dataSet">{props.translator_bank_info.account_no}</div>
        </li>
        {/*{!_.isEmpty(props.translator_bank_info.iban) &&
                    <li className='col-md-4 col-sm-6'>
                        <span className="label"><i className="fas fa-barcode colorPink"/>{Lang.iban}</span>
                        <div className="dataSet">{props.translator_bank_info.iban}</div>
                    </li>
                }*/}
        <li className="col-md-4 col-sm-6">
          <span className="label">
            <i className="fas fa-user-circle colorYellow" />
            {Lang.account_holder_name}
          </span>
          <div className="dataSet">{props.translator_bank_info.account_holder_name}</div>
        </li>

        <li className={`col-md-4 col-sm-6 ${missingIbanOrSwift ? style["title-pulse"] : ""}`}>
          <span className="label">
            <i className={`${Icons.wallet} text-success`} />
            {Lang.iban}
          </span>
          <div className="dataSet">{props.translator_bank_info.iban}</div>
        </li>
        <li className={`col-md-4 col-sm-6 ${missingIbanOrSwift ? style["title-pulse"] : ""}`}>
          <span className="label">
            <i className={`${Icons.globe} text-success`} />
            {Lang.swift}
          </span>
          <div className="dataSet">{props.translator_bank_info.swift}</div>
        </li>

        {/* <li className='col-md-4 col-sm-6'>
                    <span className="label"><i className="fas fa-university colorYellow"/>{Lang.bank_name}</span>
                    <div className="dataSet">{props.translator_bank_info.bank_name}</div>
                </li> */}
      </ul>
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import {AxiosResponse} from "axios";
import API, {base_url} from "../common/AxoisClient";
import _ from "lodash";
import {OrderDataState, OrderSearchState} from "../dashboard/State";
import Lang from "../common/lang/Lang";
import {OrderEditCommentModel} from "../model/OrderEditComment";
import { getToLanguage} from "../common/helpers";
import {datePicker, DatetimePickerWrapper} from "../common/DatetimePicker";
import {Select2Wrapper} from "../common/Select2";
import {getTranslationTypeList} from "../services";
import {QuickLinks} from "../quick-links/QuickLinks";
import {Auth} from "../Auth/Auth";
import {NoDataFound} from "../no-data-found/NoDataFound";
import {FormLayout, Icons, Submit} from "../common";
import {DateTimeFormat, OrderModel, OrderStatus, OrderType} from "../model/Order";
import {Link} from "react-router-dom";
import moment from "moment";
import { renderToStaticMarkup } from 'react-dom/server'
import VideoLink from './components/VideoLink/VideoLink';
declare let window:any;
let DataTableObj:any;
let currentLangName:string = 'English'; //'Danish';

export function Orders (props: any) {

  const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);
  const [translationTypes, setTranslationTypes] = useState();
  const [search, setSearch] = useState(OrderSearchState);

  function total_call_duration(order: OrderModel){
    if(!_.isEmpty(order.total_call_time)){
      let diff = moment(order.total_call_time[0].total_call_time, 'HH:mm:ss');
      if(diff.hours() > 0){
        return `${diff.hours()} Hour ${diff.minutes()} minutes ${diff.seconds()} seconds`
      } else {
        return `${diff.minutes()} minutes ${diff.seconds()} seconds`
      }
    } else {
      return `0 minutes`
    }
  }

  function total_audio_call_duration(order: OrderModel){
    if(!_.isEmpty(order.complete_order_to) && !_.isEmpty(order.order_from)) {
      let diff = moment.duration(moment(order.complete_order_to, "HH:mm:ss a").diff(moment(order.order_from, "HH:mm:ss a")));
      return `${diff.hours()} Hour ${diff.minutes()} minutes`
    } else {
      return `0 minutes`
    }
  }

  function getOrderDetail(order_id: number, show: string) {
    if(show=='1'){
      setTransOrderDetail(OrderDataState);
      API.post(`orders/order-detail`, {'order_id': order_id}).then((res: AxiosResponse) => {
        show = '0';
        const translatorOrderDetail = res.data.orderData;
        setTimeout(function () {
          let mainObj:any = $('.custom-rating-detail');
          if(mainObj.length>0) {
            mainObj.each(function (index:any,item:any) {
              let obj:any = $(item);
              let objRating = obj.attr('data-rating');
              objRating = objRating!='null'?objRating:0;
              obj.rateYo({
                starWidth: "20px",
                spacing: "4px",
                rating:objRating,
                readOnly: true
              });
            })
          }
        },100);
        setTransOrderDetail({...transOrderDetail, orderData: translatorOrderDetail});
        ($('[data-toggle="tooltip"]') as any).tooltip();
      });
    }
  }

  useEffect(()=>{
    document.title = Lang.order_history+' - '+Lang.vitolker_translator;
    getOrderList([5,6],'','','');
    getTranslationTypeList().then(translationTypes => {
      setTranslationTypes(translationTypes);
    });
  }, []);

  function getOrderList(status:any,order_from:any,order_to:any,type:any){


    ($('#orderDataListing') as any).DataTable().destroy();

    ($.fn as any).dataTableExt.sErrMode = 'throw';

    DataTableObj = ($('#orderDataListing') as any).DataTable( {
      "processing": true,
      "serverSide": true,
      'responsive': true,
      "order": [[ 0, "desc" ]],
      lengthMenu: [
        [ 10, 15, 20, -1 ],
        [ '10', '15', '20', 'All' ]
      ],
      "ajax": {
        url: base_url+'api/translator/v1/orders/order-list',
        type: 'GET',
        beforeSend: function (request:any) {
          $('#orderDataListing').find('td:first').attr('colspan',0);
          request.setRequestHeader("Authorization", "Bearer "+Auth.token);
          request.setRequestHeader("RequestFrom", "web");
        },
        "data": {
          "status":status.toString(),
          "order_from":order_from,
          "order_to":order_to,
          "type":type
        },
        "complete": function() {
          let mainObj:any = $('.custom-rating');
          if(mainObj.length>0){
            mainObj.each(function (index:any,item:any) {
              let obj:any = $(item);
              let objRating = obj.attr('data-rating');
              obj.rateYo({
                rating:objRating,
                readOnly: true
              });
            })
          }
          $('#orderDataListing').off( 'click', '.showDetailsInfo').on('click', '.showDetailsInfo', function () {
            let data = DataTableObj.row( $(this).parents('tr') ).data();
             getOrderDetail(data.id, '1');
          });
        }
      },
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/"+currentLangName+".json"
      },
      "columns": [
        {
          data: null, name: 'order_no', orderable: true, searchable: true, "width": "2%", "render": function (data: any, type: any, row: OrderModel) {
            let content:string = '';
            let content_order_type:string = '';
            if(!_.isEmpty(row.translation_type.title)) {
              content_order_type = row.translation_type.title;
            }
            if(!_.isEmpty(row.order_no)) {
              content += renderToStaticMarkup(
                <>
                  <span className="ServiceIconSat" data-toggle="tooltip" data-placement="bottom" data-original-title={content_order_type.replace(" ", "-")}>
                    <img src={row.translation_type.small_icon} style={{width:'18px'}}/>
                  </span>
                  <a className="showDetailsInfo" href="#" data-toggle="modal" data-target="#exampleModal">
                    #{row.order_no}</a>
                  <p style={{ margin: 0 }}>{content_order_type.replace(" ", "-")}</p>
                  { row.schedule_team_meeting ? <span data-toggle="tooltip" data-placement="bottom" title={!row.video_meeting_link ? "Der oprettes et link til et videomøde, når der er tildelt en oversætter" : "videolink genereret"}>(Microsoft meeting <i className={row.video_meeting_link ? Icons.link : Icons.clock}/>)</span> : null}
                </>
              )
            }
            return content;
          }
        },
        {
          data: null, name: 'company_name', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.company.name)) {
              content = row.company.name;
            }
            return content;
          }
        },
        {
          data: null, name: 'language', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.language_from) && !_.isEmpty(row.language_to) && row.is_dumb!=='1') {
              content = '<img src='+window.PUBLIC_URL +'/flags/'+row.language_from.country.iso.toLowerCase()+'.png /> ' +row.language_from.language_name+ '<span class="exchangeIcon"><i class="fas fa-exchange-alt"></i></span> <img src='+window.PUBLIC_URL +'/flags/'+row.language_to.country.iso.toLowerCase()+'.png /> '+row.language_to.language_name;
            } else if(row.is_dumb==='1'){
              content = '<img src='+window.PUBLIC_URL +'/flags/'+row.language_from.country.iso.toLowerCase()+'.png /> '+row.language_from.language_name+' <span class="exchangeIcon"><i class="fas fa-exchange-alt"></i></span> <img src='+window.PUBLIC_URL +'theme/images/sign-lang.png width="16"/> '+Lang.sign_language_translation;
            }
            return content;
          }
        },

        {
          data: null, name: 'date', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.order_date)) {
              let toTime = (row.status==OrderStatus.COMPLETED)?row.complete_order_to:row.order_to;
              if(row.translation_type_id == OrderType.DOCUMENT || row.translation_type_id == OrderType.PHONE_MESSAGE){
                content = moment(row.order_date).format('dddd');
                content +=  '<br/><span class="order-day-with-time">';
                content += moment(row.order_date).format('DD-MM-YYYY')+'<br/>';
                content +=  '  '+toTime+' ';
                content +=  '</span>';
              } else {
                content = moment(row.order_date).format('dddd');
                content +=  '<br/><span class="order-day-with-time">';
                content += moment(row.order_date).format('DD-MM-YYYY')+'<br/>';
                content +=  ' '+row.order_from;
                content +=  ' - '+toTime+' ';
                content +=  '</span>';
              }
            }
            return content;
          }
        },
        {
          data: null, name: 'view', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: OrderModel) {
            let content:string = '';
            if(!_.isEmpty(row.translation_type.title)) {
              content = row.translation_type.title;
            }
            type = Lang.view;
            return renderToStaticMarkup(
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {row.video_meeting_link && <VideoLink video_meeting_link={row.video_meeting_link!} />}
                <a className="btnStatusPrimary hvr-push showDetailsInfo" href="#" data-toggle="modal" data-target="#exampleModal"><i className="fas fa-eye"></i> {type}</a>
              </div>
            )
          }
        },

          ],
      "initComplete": function(settings:any, json:any) {
        if($('#orderDataListing_wrapper').find('.dataTables_empty').is(':visible')){
          $('.no-record-found-in-list').removeClass('hide_me');
          $('#orderDataListing_wrapper').hide();
        } else {
          $('.no-record-found-in-list').addClass('hide_me');
        }
        $('#orderDataListings').on('click', '.copy_link', (e) => { navigator.clipboard.writeText(e.currentTarget.dataset.link!) })
      },
    });
  }

  function handleChange(e:any) {
    const {name, value} = e.target;
    setSearch(prevSate => ({...prevSate, [name]: value}));
  }

  function formDataReset(event: any) {
    event.preventDefault();
    getOrderList([5,6],'','','');
    setSearch(OrderSearchState);
  }

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
    event.preventDefault();
    getOrderList([5,6],search.order_from,search.order_to,search.type);
  }

  return(

      <div className="bgMain">
        <div className="wrapper">
          <Header {...props}/>
          <section className="mainContent">
            <div className="container">
                <FormLayout id="search_order" onSubmit={handleSubmit}  autoComplete="off">
                <div className="row align-items-center mb-20">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group md-form">
                      <div className="example">
                        <div className="demo-section k-content">
                          <label>{Lang.from_date}</label>

                          <DatetimePickerWrapper className="servicesPicker" options={datePicker}
                                                 name={'order_from'} placeholder={Lang.from_date_placeholder} value={search.order_from} onChange={handleChange}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group md-form">
                      <div className="example" >
                        <div className="demo-section k-content">
                          <label>{Lang.to_date}</label>
                          <DatetimePickerWrapper className="servicesPicker" options={datePicker}
                                                 name={'order_to'} placeholder={Lang.to_date_placeholder} value={search.order_to} onChange={handleChange}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group md-form">
                      <label className="d-block">{Lang.order_type}</label>
                      <Select2Wrapper onChange={handleChange} value={search.type}
                          className={'form-control'} id={'type'} name={'type'}
                          data={{
                            placeholder: Lang.vt_trans_lang_62_selecttype,
                            data: translationTypes
                          }} >
                        <option value={''}>{Lang.select_target_lang}</option>
                      </Select2Wrapper>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-8 col-lg-12 mobiCenter formSectionSearchButton">
                    <div className={'float-right'}>
                    <Submit className={'btn btn-green hvr-rectangle-out btnMove mobiNone mr-3'} label={Lang.search}/>
                      <button className=" btn btn-default hvr-rectangle-out btnMove mobiNone" onClick={formDataReset} type="button">{Lang.reset}
                      </button>
                    </div>
                  </div>
                </div>
              </FormLayout>
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-12">
                  <div className="quickLinkBox equalHeight boxShadow">
                    <QuickLinks/>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-12">
                  <div className="boxWhite equalHeight boxShadow">
                  <div className="table-responsive">
                    <table id="orderDataListing" className="display table masterTable" style={{width:'100%'}}>
                      <thead>
                      <tr>
                        <th>{Lang.order_no}</th>
                        <th>{Lang.company_name}</th>
                        <th>{Lang.language}</th>
                        <th>{Lang.date}</th>
                        <th>{Lang.action}</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      <tr>
                        <th>{Lang.order_no}</th>
                        <th>{Lang.company_name}</th>
                        <th>{Lang.language}</th>
                        <th>{Lang.date}</th>
                        <th>{Lang.action}</th>
                      </tr>
                      </tfoot>
                    </table>
                    <div className={'no-record-found-in-list hide_me'}>
                      <NoDataFound/>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="modal right fade" id="exampleModal" role="dialog" aria-labelledby="exampleModal"
               aria-hidden="true">
            <div className="modal-dialog" role="document">
              {transOrderDetail['orderData'][0] == undefined &&
                <div className={'dataLoad'}>{Lang.please_wait}</div>
              }
              { transOrderDetail['orderData'][0]!==undefined && transOrderDetail['orderData'][0].language_from.country &&
              <div className="modal-content">
                <div className="sideMenuBarRight" id="orderDetailsBox">
                  <a href="javascript:;" className="btn closeBtn" data-dismiss="modal"><span>×</span></a>

                  <div className="orderPreview">
                    <div className="blockStep">
                      <div className="blockStepList">
                        <h3 className="stripTitleNew">
                          <div className="row">
                            <div className="col-sm-6">
                              <i className="far fa-file-alt"/> {Lang.order_detail} <span className={'orderID'}> #{transOrderDetail['orderData'][0].order_no}</span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <div className="colData"><span>{Lang.vt_trans_lang_56_invationdate} :</span>&nbsp;&nbsp;&nbsp;<span
                                  className="timer"><p><i className="far fa-calendar-alt" aria-hidden="true"/> {moment(transOrderDetail['orderData'][0].created_at).format('dddd')}  {moment(transOrderDetail['orderData'][0].created_at).format('DD-MM-YYYY')}</p><p><i
                                  className="far fa-clock" aria-hidden="true"> </i> {moment(transOrderDetail['orderData'][0].created_at).format('HH:mm')}</p></span></div>
                            </div>
                          </div>
                        </h3>


                        <div className="row mt-4 mb-4">
                          <div className="col-lg-12 col-xl-6">
                            <div className="row">
                              <div className="col-left"><img
                                  src={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.profile_image_small:''}
                                  alt={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} width="78" className="img-fluid rounded-circle"/></div>
                              <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_52_invationtranslator}</h4>
                                <ul>

                                  <li><i className="far fa-user"/>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} <b>{(transOrderDetail['orderData'][0].translate_by!=undefined && transOrderDetail['orderData'][0].translate_by.user_code!==null)?' ('+transOrderDetail['orderData'][0].translate_by.user_code+')':''}</b></li>
                                  <li><i className="far fa-envelope"/><a href={'mailto:'+((transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:'')}>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:''}</a></li>
                                </ul>

                                {(transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT && (transOrderDetail['orderData'][0].status == OrderStatus.COMPLETED || transOrderDetail['orderData'][0].status == OrderStatus.UNDER_QA)) &&
                                <div className={'scrollbar-inner minHeight200'}>
                                  <ul className={'document_upload_list'}>
                                    {
                                      transOrderDetail['orderData'][0].order_files &&
                                      transOrderDetail['orderData'][0].order_files.length > 0 &&
                                      transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                        if (orderFilesTranslator.type == 'complete') {
                                          return <li>

                                            <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                              <i className="far fa-file-alt"/>
                                              <span>{orderFilesTranslator.file_name}</span>
                                              <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                            </Link>
                                          </li>
                                        }
                                      })}
                                  </ul>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6 mobileSpace">
                            <div className="row">
                              <div className="col-left"><img
                                  src={transOrderDetail['orderData'][0].customer.profile_image_small}
                                  alt={transOrderDetail['orderData'][0].customer.name}  width="78"
                                  className="img-fluid rounded-circle"/></div>
                              <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_54_invationCustomer}</h4>
                                <ul>
                                  <li><i className="far fa-user"/>{transOrderDetail['orderData'][0].customer.name}
                                    {transOrderDetail['orderData'][0].customer.user_code !== null &&
                                      <b>{'(' + transOrderDetail['orderData'][0].customer.user_code + ')'}</b>
                                    }
                                  </li>
                                </ul>

                                {transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT &&
                                <div className={'scrollbar-inner minHeight200'}>
                                  <ul className={'document_upload_list'}>
                                    {
                                      transOrderDetail['orderData'][0].order_files &&
                                      transOrderDetail['orderData'][0].order_files.length > 0 &&
                                      transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                        if (orderFilesTranslator.type == 'create') {
                                          return <li>
                                            <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                              <i className="far fa-file-alt"/>
                                              <span>{orderFilesTranslator.file_name}</span>
                                              <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                            </Link>
                                          </li>
                                        }
                                      })}
                                  </ul>
                                </div>
                                }
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="profileDataRow">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.order_no}.</span> #{transOrderDetail['orderData'][0].order_no}</div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.translation_type}</span>
                                <span data-toggle="tooltip" data-placement="left" title={transOrderDetail['orderData'][0].translation_type.title} className="d-inline">
 									<img src={transOrderDetail['orderData'][0].translation_type.small_icon} alt={transOrderDetail['orderData'][0].translation_type.title} width="25"/>
 								</span>
                                {transOrderDetail['orderData'][0].translation_type.title}
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.language}</span>
                                <div className="language-section">
                                  <span>
                            <img src={(transOrderDetail['orderData'][0].language_from.country.iso!==undefined?window.PUBLIC_URL +'/flags/'+transOrderDetail['orderData'][0].language_from.country.iso.toLowerCase()+'.png':'')}/>{transOrderDetail['orderData'][0].language_from.language_name}
                                    <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span> {getToLanguage(transOrderDetail['orderData'][0])}
                          </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.vt_trans_lang_51_invationDate}</span>
                                <span className="timer">
 									<p><i className="far fa-calendar-alt" aria-hidden="true"/>{moment(transOrderDetail['orderData'][0].order_date).format('dddd')} {moment(transOrderDetail['orderData'][0].order_date).format('DD-MM-YYYY')}</p>
 									<p><i className="far fa-clock" aria-hidden="true"> </i>{(transOrderDetail['orderData'][0].translation_type_id != OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id != OrderType.PHONE_MESSAGE) &&
                                    transOrderDetail['orderData'][0].order_from +' - ' } {(transOrderDetail['orderData'][0].status==OrderStatus.COMPLETED)?transOrderDetail['orderData'][0].complete_order_to:transOrderDetail['orderData'][0].order_to}</p>
 									</span>
                              </div>
                            </div>

                            { transOrderDetail['orderData'][0].translation_type_id === OrderType.VIDEO &&
                              <div className="col-xl-6 col-sm-8">
                                <div className="colData"><span>{Lang.call_duration}</span>
                                  <p>
                                    <i className="far fa-clock"/> {total_call_duration(transOrderDetail['orderData'][0])}
                                  </p>
                                </div>
                              </div>
                            }

                            { transOrderDetail['orderData'][0].translation_type_id === OrderType.TELEPHONE &&
                              <div className="col-xl-6 col-sm-8">
                                <div className="colData"><span>{Lang.call_duration}</span>
                                  <p>
                                    <i className="far fa-clock"/> {total_audio_call_duration(transOrderDetail['orderData'][0])}
                                  </p>
                                </div>
                              </div>
                            }

                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.vt_trans_lang_53_Institution}</span>{transOrderDetail['orderData'][0].company.name}</div>
                            </div>

                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.department}</span>{(transOrderDetail['orderData'][0].department!=undefined)?transOrderDetail['orderData'][0].department.name:''}</div>
                            </div>

                            {transOrderDetail['orderData'][0].comment_for_translator && transOrderDetail['orderData'][0].comment_for_translator.length > 0 &&
                            <div className="col-xl-12 col-sm-6">
                              <div className="colData">
                                <span>{Lang.vt_trans_lang_58_comment}</span>{transOrderDetail['orderData'][0].comment_for_translator}
                              </div>
                            </div>
                            }

                            {transOrderDetail['orderData'][0].meeting_address && transOrderDetail['orderData'][0].meeting_address.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                            <div className="col-sm-6">
                              <div className="colData"><span>{Lang.meeting_address}</span> <i
                                  className="fas fa-map-marker-alt"/> {(transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) ? transOrderDetail['orderData'][0].meeting_address : ''}
                              </div>
                            </div>
                            }
                            {transOrderDetail['orderData'][0].addition_info && transOrderDetail['orderData'][0].addition_info.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                            <div className="col-sm-6">
                              <div className="colData"><span>{Lang.addition_info}</span> <i
                                  className="fas fa-map-marker-alt"/> {transOrderDetail['orderData'][0].addition_info}
                              </div>
                            </div>
                            }
                          </div>
                        </div>
                      </div>

                      {!_.isEmpty(transOrderDetail['orderData'][0].tel_message) &&
                      <div className="blockStepList custmorRow">
                        <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.vt_trans_lang_57_telephoneMSG}</h3>
                        <div className="profileDataRow">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="colData"> {transOrderDetail['orderData'][0].tel_message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {transOrderDetail['orderData'][0].order_files!=undefined && !_.isEmpty(transOrderDetail['orderData'][0].order_files) && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE &&
                        <div className="blockStepList custmorRow">
                          <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.telephone_translated_msg}</h3>
                          <div className="profileDataRow">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="colData"> {transOrderDetail['orderData'][0].order_files[0].name}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      <div className="blockStepList custmorRow">
                        <h3 className="stripTitleNew"><i className="far fa-user"/> {Lang.vt_trans_lang_55_invationContact}</h3>
                        <div className="profileDataRow">
                          <div className="row">
                            {
                              transOrderDetail['orderData'][0].contact_persons &&
                              transOrderDetail['orderData'][0].contact_persons.length > 0 &&
                              transOrderDetail['orderData'][0].contact_persons.map((contactPersons: any, index: number) => {
                                return <>

                                  <div className="col-sm-6">
                                    {index==0 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                    <h4 className="Usertype">{Lang.contact_first}</h4>
                                    }
                                    {index==1 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                    <h4 className="Usertype">{Lang.contact_second}</h4>
                                    }
                                    <div className="colData"><i className="far fa-user"/> {contactPersons.name}</div>

                                    {!_.isEmpty(contactPersons.phone_no) &&
                                    <div className="colData"><i className="fas fa-mobile-alt"/> <a
                                        href={'tel:' + (contactPersons.phone_no)}>{contactPersons.phone_no}</a></div>
                                    }
                                  </div>

                                </>
                              })
                            }

                          </div>
                        </div>
                      </div>


                      {transOrderDetail['orderData'][0].feedback && Number(transOrderDetail['orderData'][0].feedback.rating) >0 &&
                      <div className="blockStepList">
                        <h3 className="stripTitleNew"><i className="far fa-comment-alt"/> {Lang.order_feedback}</h3>
                        <div className="profileDataRow">
                          <div className="colData"><span>{Lang.rating}</span>
                            <div className={'rating custom-rating-detail'} data-rating={transOrderDetail['orderData'][0].feedback.rating?transOrderDetail['orderData'][0].feedback.rating:0}/>
                          </div>
                          {transOrderDetail['orderData'][0].feedback.message &&
                          <div className="colData">
                            <span>{Lang.message}</span>{transOrderDetail['orderData'][0].feedback.message}
                          </div>
                          }
                        </div>
                      </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>

          <Footer/>

        </div>
      </div>
  )
}
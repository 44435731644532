import React, {useState, useEffect} from 'react';
import Lang from "../common/lang/Lang";
import {getToLanguage} from "../common/helpers";
import {DateTimeFormat, OrderModel, OrderStatus, OrderType} from "../model/Order";
import {OrderEditCommentModel} from "../model/OrderEditComment";
import {Link} from "react-router-dom";
import {OrderDataState} from "../dashboard/State";
import {OrderInfoService} from "./Schema";
import moment from "moment";
import _ from "lodash";
import { Icons } from '../common/Icon';
declare let window:any;

export function OrderInfo(props: any) {

    const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);
    const [show, setShow] = useState(false);

    function total_call_duration(order: OrderModel){
        if(!_.isEmpty(order.total_call_time)){
            let diff = moment(order.total_call_time[0].total_call_time, 'HH:mm:ss');
            if(diff.hours() > 0){
                return `${diff.hours()} Hour ${diff.minutes()} minutes ${diff.seconds()} seconds`
            } else {
                return `${diff.minutes()} minutes ${diff.seconds()} seconds`
            }
        } else {
            return `0 minutes`
        }
    }

    function total_audio_call_duration(order: OrderModel){
        if(!_.isEmpty(order.complete_order_to) && !_.isEmpty(order.order_from)) {
            let diff = moment.duration(moment(order.complete_order_to, "HH:mm:ss a").diff(moment(order.order_from, "HH:mm:ss a")));
            return `${diff.hours()} Hour ${diff.minutes()} minutes`
        } else {
            return `0 minutes`
        }
    }

    useEffect(() => {

        OrderInfoService.getInfo().subscribe((message: any) => {
            setShow(message.show);
            if (message.show) {
                setTimeout(function () {
                    let mainObj: any = $('.custom-rating-detail');
                    if (mainObj.length > 0) {
                        mainObj.each(function (index: any, item: any) {
                            let obj: any = $(item);
                            let objRating = obj.attr('data-rating');
                            objRating = objRating != 'null' ? objRating : 0;
                            obj.rateYo({
                                starWidth: "20px",
                                spacing: "4px",
                                rating: objRating,
                                readOnly: true
                            });
                        })
                    }

                }, 100);
                setTransOrderDetail({...transOrderDetail, orderData: message.data});
                ($('[data-toggle="tooltip"]') as any).tooltip();
                ($('.scrollbar-inner') as any).scrollbar();
            } else {
                setShow(false);
                setTransOrderDetail(OrderDataState);
            }
        });
    }, []);

    if(!show){
        //return null;
        return <div className={'dataLoad'}>{Lang.please_wait}</div>;
    }

    if(transOrderDetail['orderData'][0]!==undefined && transOrderDetail['orderData'][0].language_from.country && transOrderDetail['orderData'][0].order_no!==undefined ){

       return  <div className="orderPreview">
           <div className="blockStep">
               <div className="blockStepList">
                   <h3 className="stripTitleNew">
                       <div className="row">
                           <div className="col-sm-6">
                                <i className="far fa-file-alt"/> {Lang.order_detail} <span className={'orderID'}> #{transOrderDetail['orderData'][0].order_no}</span>
                           </div>
                           <div className="col-sm-6 text-right">
                               <div className="colData"><span>{Lang.vt_trans_lang_56_invationdate} :</span>&nbsp;&nbsp;&nbsp;<span
                                   className="timer"><p><i className="far fa-calendar-alt" aria-hidden="true"/> {moment(transOrderDetail['orderData'][0].created_at).format('dddd')}  {moment(transOrderDetail['orderData'][0].created_at).format('DD-MM-YYYY')}</p><p><i
                                   className="far fa-clock" aria-hidden="true"> </i> {moment(transOrderDetail['orderData'][0].created_at).format('HH:mm')}</p></span></div>
                           </div>
                       </div>
                   </h3>

                   <div className="row mt-4 mb-4">
                       <div className="col-lg-12 col-xl-6">
                           <div className="row">
                               <div className="col-left"><img
                                   src={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.profile_image_small:''}
                                   alt={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} width="78"
                                   className="img-fluid rounded-circle"/></div>
                               <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_52_invationtranslator}</h4>
                                   <ul>
                                       <li><i className="far fa-user"/>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''}
                                       <b>{(transOrderDetail['orderData'][0].translate_by!=undefined && transOrderDetail['orderData'][0].translate_by.user_code!==null)?' ('+transOrderDetail['orderData'][0].translate_by.user_code+')':''}</b>
                                       </li>
                                       <li><i className="far fa-envelope"/><a href={'mailto:'+((transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:'')}>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:''}</a></li>
                                   </ul>
                                   {(transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT && (transOrderDetail['orderData'][0].status==OrderStatus.COMPLETED || transOrderDetail['orderData'][0].status==OrderStatus.UNDER_QA)) &&
                                       <div className={'scrollbar-inner minHeight200'}>
                                           <ul className={'document_upload_list'}>
                                               {
                                                   transOrderDetail['orderData'][0].order_files &&
                                                   transOrderDetail['orderData'][0].order_files.length > 0 &&
                                                   transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                                       if (orderFilesTranslator.type == 'complete') {
                                                           return <li className={''}>

                                                               <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                                                   <i className="far fa-file-alt"/>
                                                                   <span>{orderFilesTranslator.file_name}</span>
                                                                   <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                                               </Link>
                                                           </li>
                                                       }
                                                   })}
                                           </ul>
                                       </div>
                                   }
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-12 col-xl-6 mobileSpace">
                           <div className="row">
                               <div className="col-left"><img
                                   src={transOrderDetail['orderData'][0].customer.profile_image_small}
                                   alt={transOrderDetail['orderData'][0].customer.name}  width="78"
                                   className="img-fluid rounded-circle"/></div>
                               <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_54_invationCustomer}</h4>
                                   <ul>
                                       <li><i className="far fa-user"/>{transOrderDetail['orderData'][0].customer.name}
                                           {transOrderDetail['orderData'][0].customer.user_code !== null &&
                                               <b>{'(' + transOrderDetail['orderData'][0].customer.user_code + ')'}</b>
                                           }
                                       </li>
                                   </ul>
                                   {transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT &&
                                   <div className={'scrollbar-inner minHeight200'}>
                                       <ul className={'document_upload_list'}>
                                           {
                                               transOrderDetail['orderData'][0].order_files &&
                                               transOrderDetail['orderData'][0].order_files.length > 0 &&
                                               transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                                   if (orderFilesTranslator.type == 'create') {
                                                       return <li className={''}>
                                                           <Link target={'_blank'} to={'/file-view/'+(orderFilesTranslator.id)+'/document/?file_name='+orderFilesTranslator.file_name}>
                                                               <i className="far fa-file-alt"/>
                                                               <span>{orderFilesTranslator.file_name}</span>
                                                               <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                                           </Link>
                                                       </li>
                                                   }
                                               })}
                                       </ul>
                                   </div>
                                   }
                               </div>
                           </div>
                       </div>

                   </div>

                   <div className="profileDataRow">
                       <div className="row">
                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.order_no}.</span> #{transOrderDetail['orderData'][0].order_no}</div>
                           </div>
                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.translation_type}</span>
                                   <span data-toggle="tooltip" data-placement="left" title={transOrderDetail['orderData'][0].translation_type.title}
                                         className="d-inline">
 									<img src={transOrderDetail['orderData'][0].translation_type.small_icon} alt={transOrderDetail['orderData'][0].translation_type.title} width="25"/>
 								</span>
                                   {transOrderDetail['orderData'][0].translation_type.title}
                               </div>
                           </div>
                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.language}</span>
                                   <div className="language-section">
                                  <span>
                            <img src={(transOrderDetail['orderData'][0].language_from.country.iso!==undefined?window.PUBLIC_URL +'/flags/'+transOrderDetail['orderData'][0].language_from.country.iso.toLowerCase()+'.png':'')}/>{transOrderDetail['orderData'][0].language_from.language_name}
                                      <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span> {getToLanguage(transOrderDetail['orderData'][0])}
                          </span>
                                   </div>
                               </div>
                           </div>
                           {
                                transOrderDetail.orderData[0].translation_type_id === OrderType.VIDEO && !_.isEmpty(transOrderDetail.orderData[0].video_meeting_link) && (
                                    <div className="col-sm-6">
                                        <div className="colData">
                                            <span>Møde URL</span>
                                            <a href={transOrderDetail.orderData[0].video_meeting_link} target="_blank" rel="noopener noreferrer">Link <i className={Icons.external_link} /></a>
                                        </div>
                                    </div>
                                )
                            }
                       </div>
                       <div className="row">
                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.vt_trans_lang_51_invationDate}</span>
                                   <span className="timer">
 									<p><i className="far fa-calendar-alt" aria-hidden="true"/>{moment(transOrderDetail['orderData'][0].order_date).format('dddd')}  {moment(transOrderDetail['orderData'][0].order_date).format('DD-MM-YYYY')}</p>
 									<p>
                                        <i className="far fa-clock" aria-hidden="true"> </i>{(transOrderDetail['orderData'][0].translation_type_id != OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id != OrderType.PHONE_MESSAGE) &&
                                    transOrderDetail['orderData'][0].order_from +' - ' } {(transOrderDetail['orderData'][0].status==OrderStatus.COMPLETED)?transOrderDetail['orderData'][0].complete_order_to:transOrderDetail['orderData'][0].order_to}</p>
 									</span>
                               </div>
                           </div>
                           { transOrderDetail['orderData'][0].translation_type_id === OrderType.VIDEO &&
                               <div className="col-xl-6 col-sm-8">
                                   <div className="colData"><span>{Lang.call_duration}</span>
                                       <p>
                                           <i className="far fa-clock"/> {total_call_duration(transOrderDetail['orderData'][0])}
                                       </p>
                                   </div>
                               </div>
                           }

                           { transOrderDetail['orderData'][0].translation_type_id === OrderType.TELEPHONE &&
                               <div className="col-xl-6 col-sm-8">
                                   <div className="colData"><span>{Lang.call_duration}</span>
                                       <p>
                                            <i className="far fa-clock"/> {total_audio_call_duration(transOrderDetail['orderData'][0])}
                                       </p>
                                   </div>
                               </div>
                           }

                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.vt_trans_lang_53_Institution}</span>
                                   {!_.isEmpty(transOrderDetail['orderData'][0].company)&&!_.isEmpty(transOrderDetail['orderData'][0].company.name)&&
                                   <>
                                       {transOrderDetail['orderData'][0].company.name}
                                   </>
                                   }
                               </div>
                           </div>

                           <div className="col-xl-4 col-sm-6">
                               <div className="colData"><span>{Lang.department}</span>{(transOrderDetail['orderData'][0].department!=undefined)?transOrderDetail['orderData'][0].department.name:''}</div>
                           </div>

                           {transOrderDetail['orderData'][0].comment_for_translator && transOrderDetail['orderData'][0].comment_for_translator.length > 0 &&
                               <div className="col-xl-12 col-sm-6">
                                   <div className="colData">
                                       <span>{Lang.vt_trans_lang_58_comment}</span>{transOrderDetail['orderData'][0].comment_for_translator}
                                   </div>
                               </div>
                           }

                           {transOrderDetail['orderData'][0].meeting_address && transOrderDetail['orderData'][0].meeting_address.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                               <div className="col-sm-6">
                                   <div className="colData"><span>{Lang.meeting_address}</span> <i
                                       className="fas fa-map-marker-alt"/> {(transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) ? transOrderDetail['orderData'][0].meeting_address+', '+transOrderDetail['orderData'][0].meeting_zip_code+' '+transOrderDetail['orderData'][0].meeting_city : ''}
                                   </div>
                               </div>
                           }
                           {transOrderDetail['orderData'][0].addition_info && transOrderDetail['orderData'][0].addition_info.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                           <div className="col-sm-6">
                               <div className="colData"><span>{Lang.addition_info}</span> <i
                                   className="fas fa-map-marker-alt"/> {transOrderDetail['orderData'][0].addition_info}
                               </div>
                           </div>
                           }
                       </div>
                   </div>
               </div>

               {!_.isEmpty(transOrderDetail['orderData'][0].tel_message) &&
                   <div className="blockStepList custmorRow">
                       <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.vt_trans_lang_57_telephoneMSG}</h3>
                       <div className="profileDataRow">
                           <div className="row">
                               <div className="col-sm-12">
                                   <div className="colData"> {transOrderDetail['orderData'][0].tel_message}</div>
                               </div>
                           </div>
                       </div>
                   </div>
               }

               {transOrderDetail['orderData'][0].order_files!=undefined && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE && !_.isEmpty(transOrderDetail['orderData'][0].order_files) &&
               <div className="blockStepList custmorRow">
                   <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.telephone_translated_msg}</h3>
                   <div className="profileDataRow">
                       <div className="row">
                           <div className="col-sm-12">
                               <div className="colData"> {transOrderDetail['orderData'][0].order_files[0].name}</div>
                           </div>
                       </div>
                   </div>
               </div>
               }

               <div className="blockStepList custmorRow">
                   <h3 className="stripTitleNew"><i className="far fa-user"/> {Lang.vt_trans_lang_55_invationContact}</h3>
                   <div className="profileDataRow">
                       <div className="row">
                       {
                           transOrderDetail['orderData'][0].contact_persons &&
                           transOrderDetail['orderData'][0].contact_persons.length > 0 &&
                           transOrderDetail['orderData'][0].contact_persons.map((contactPersons: any, index: number) => {
                               return <>

                                       <div className="col-sm-6">
                                           {index==0 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                            <h4 className="Usertype">{Lang.contact_first}</h4>
                                           }
                                           {index==1 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                           <h4 className="Usertype">{Lang.contact_second}</h4>
                                           }
                                           <div className="colData"><i className="far fa-user"/> {contactPersons.name}</div>
                                           {/*{ !_.isEmpty(contactPersons.email) &&
                                               <div className="colData"><i className="far fa-envelope"/> <a href={'mailto:'+(contactPersons.email)}>{contactPersons.email}</a>
                                               </div>
                                           }*/}
                                           {!_.isEmpty(contactPersons.phone_no) &&
                                               <div className="colData"><i className="fas fa-mobile-alt"/> <a
                                                   href={'tel:' + (contactPersons.phone_no)}>{contactPersons.phone_no}</a>
                                               </div>
                                           }
                                       </div>
                                   </>
                           })
                       }
                   </div>
                   </div>
               </div>

               {transOrderDetail['orderData'][0].feedback && Number(transOrderDetail['orderData'][0].feedback.rating) >0 &&
                   <div className="blockStepList">
                       <h3 className="stripTitleNew"><i className="far fa-comment-alt"/> {Lang.order_feedback}</h3>
                       <div className="profileDataRow">
                           <div className="colData"><span>{Lang.rating}</span>
                               <div className={'rating custom-rating-detail'} data-rating={transOrderDetail['orderData'][0].feedback.rating?transOrderDetail['orderData'][0].feedback.rating:0}/>
                           </div>
                           {transOrderDetail['orderData'][0].feedback.message &&
                           <div className="colData">
                               <span>{Lang.message}</span>{transOrderDetail['orderData'][0].feedback.message}
                           </div>
                           }
                       </div>
                   </div>
               }

               {
               ( transOrderDetail['orderData'][0].translation_type_id !== OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id !== OrderType.PHONE_MESSAGE && transOrderDetail['orderData'][0].status != OrderStatus.COMPLETED && transOrderDetail['orderData'][0].status != OrderStatus.CANCELLED && transOrderDetail['orderData'][0].status != OrderStatus.PAID && transOrderDetail['orderData'][0].status != OrderStatus.UNDER_QA && transOrderDetail['orderData'][0].status != OrderStatus.INVITATION_SENT && transOrderDetail['orderData'][0].order_date!=undefined && moment(transOrderDetail['orderData'][0].order_date).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") && moment(transOrderDetail['orderData'][0].order_date+' '+transOrderDetail['orderData'][0].order_from) <= moment()) &&

                   <div className="blockStepList button text-center">
                       <Link className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail" to={`complete-order/${transOrderDetail['orderData'][0].id}`}>{Lang.vt_trans_lang_59_submit}</Link>
                   </div>
               }
               {
                   ((transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT || transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE) && transOrderDetail['orderData'][0].status != OrderStatus.COMPLETED && transOrderDetail['orderData'][0].status != OrderStatus.CANCELLED && transOrderDetail['orderData'][0].status != OrderStatus.PAID && transOrderDetail['orderData'][0].status != OrderStatus.INVITATION_SENT && transOrderDetail['orderData'][0].status != OrderStatus.UNDER_QA ) &&

                   <div className="blockStepList button text-center">
                       <Link className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail" to={`complete-order/${transOrderDetail['orderData'][0].id}`}>{Lang.vt_trans_lang_59_submit}</Link>
                   </div>
               }

           </div>
       </div>
    } else {
        return <div className={'dataLoad'}>{Lang.please_wait}</div>
    }
}
import Lang from "../../common/lang/Lang";
import { translateThisKey } from "../../common/helpers";

export const RegistrationValidations = function () {
  $(document).ready(function () {

    $.validator.addMethod("name_valid",  (value: any, element: HTMLElement, params: any) =>{
          let flag = true;
          var name_regex = /^[a-zA-Z][a-zA-Z\s]*$/;
          if(!value.match(name_regex) || value.length == 0){
            flag = false;
          }
          return flag;
        },Lang.valid_name);

    $.validator.addMethod("email_valid",  (value: any, element: HTMLElement, params: any) =>{
      let flag = true;
      let email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if(value.length != 0){
        flag = email_regex.test(value);
      }
      return flag;
    },Lang.valid_email);

    $.validator.addMethod("fromlang_valid",  (value: any, element: HTMLElement, params: any) =>{
      let flag = true;
      if(value === $('#to_language_id').val()){
        flag = false;
      }
      return flag;
    },Lang.fromlang_valid);

    // let strongRegex:any = new RegExp("^.*(?=.{8,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]){1})(?=.*\\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$");
    // $.validator.addMethod("pwcheck", function(value:any) {
    //   $.validator.messages.pwcheck = Lang.strong_password_text;
    //   return strongRegex.test(value);
    // });

    $( "#registration_form" ).validate({
      rules: {
        firstName: {
          required: true,
          name_valid : true,
          minlength: 2,
          maxlength: 50,
        },
        lastName: {
          required: true,
          name_valid : true,
          minlength: 2,
          maxlength: 50,
        },
        email: {
          required: true,
          email: true,
          email_valid: true,
          minlength: 8,
          maxlength: 50,
        },
        address: {
          required: true,
          minlength: 5,
          
        },
        city: {
          required: true,
        },
        /*zip_code: {
          required: true,
          minlength: 3,
          maxlength: 20,
        },*/
        country_code: {
          required: true
        },
        mobile_no: {
          required: true,
          //number: true,
          minlength: 8,
          maxlength: 20,
        },
        from_language_id: {
          required: true,
          fromlang_valid: true,
        },
        to_language_id: {
          required: true
        },
        about: {
          maxlength: 500,
        },
        // password1: {
        //   required: true,
        // },
        // confirm_password: {
        //   required: true,
        //   equalTo: "#password1",
        // },
        terms_and_condition: "required"
      },
      messages: {
        terms_and_condition: Lang.vt_trans_lang_15_termcondition_errormsg,
        firstName: Lang.requiredValidator,
        lastName: Lang.requiredValidator
      }
    });
  });
}


export const Progress = function () {

  setTimeout(function () {
    ($('#password1') as any).pwstrength({
      common: {
        // minChar:8,
        // maxChar:20,
        // onScore: function (options:any, word:any, totalScoreCalculated:any) {
        //   ($('#password1')as any).parents('.password').find('.progressBar_message ').show();
        //   let scoreData:number = 0;
        //   if (word.length<8) {
        //     scoreData += -(2.9)*word.length;
        //   }

        //   if (word.match(/[!@#$%^&*()\-_=+{};:,<.>]/)) {
        //     scoreData += (1.9)*(word.length/2);
        //   }else{
        //     scoreData += -(4.98)*(word.length/2);
        //   }

        //   if (word.match(/[0-9]/)) {
        //     scoreData += (1.9)*(word.length/2);
        //   }else{
        //     scoreData += -(4.98)*(word.length/2);
        //   }

        //   if (word.match(/[a-z]/)) {
        //     scoreData += (1.9)*word.length;
        //   }else{
        //     scoreData += -(4.98)*word.length;
        //   }

        //   if (word.match(/[A-Z]/)) {
        //     scoreData += (1.9)*word.length;
        //   } else {
        //     scoreData += -(4.98)*word.length;
        //   }

        //   if(scoreData > 100){
        //     scoreData = (scoreData-(2.38)*word.length)
        //   } else if(scoreData < 0){
        //     scoreData = (2.38)*word.length;
        //   }
        //   return scoreData;
        // },
      },
      rules: {
        scores: {
          wordInvalidChar: -100,
          wordIsACommonPassword: -100,
          wordLetterNumberCharCombo: -2,
          wordLetterNumberCombo: -2,
          wordLowercase: -1,
          wordMaxLength: 2,
          wordMinLength: 1,
          wordNotEmail: -100,
          wordOneNumber: -3,
          wordOneSpecialChar: -3,
          wordRepetitions: -25,
          wordSequences: -20,
          wordSimilarToUsername: -100,
          wordThreeNumbers: -5,
          wordTwoCharacterClasses: -2,
          wordTwoSpecialChar: -5,
          wordUpperLowerCombo: -2,
          wordUppercase: -3,
        }
      },
      i18n:{
        t: function (key:string) {
            let result:string = translateThisKey(key);
            return result === key ? '' : result;
        }
      },
      ui:{
        container: "#pwd-container",
        viewports: {
          progress: "#passwordStrengthProgress",
          verdict: "#passwordStrengthVerdict"
        },
      }
    });
  },500);
}

setTimeout(function(){
  ($('#password1')as any).parents('.password').find('.progressBar_message ').hide();
},1000);

export const LoginValidations = function () {
  $(document).ready(function () {
    $( "#login_form" ).validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: "required",
      },
    });
  });
}

export const BasicInfoValidations = function () {
  $(document).ready(function () {
    $( "#basicinfo_form" ).validate({
      rules: {
        social_no: {
          required: true
        },
        origin_country: {
          required: true
        },
        driving_license: "required",
        own_vehicle: {
          required: {
            depends: function(element:any){
              if($("#customRadio").val()==1){
                return true;
              } else {
                return false;
              }
            }
          }
        },
        police_verified: "required",
        verification_number: {
          required: {
              depends: function(element:any){
                  if($("#customRadio7").val()==1){
                      return true;
                  } else {
                      return false;
                  }
              }
          }
        },
      },
    });
  });
}

export const BankInfoValidations = function () {
  $(document).ready(function () {
    $( "#bankinfo_form" ).validate({
      rules: {
        bank_registration_no: {
          required: true
        },
        account_no: {
          required: true
        },
        bank_name: {
          required: true
        },
        account_holder_name: {
          required: true
        },
        /*iban: {
          required: true
        },*/
      },
    });
  });
}

export const DocumentValidations = function () {
  $(document).ready(function () {
    $( "#document_form" ).validate({
      rules: {
        resume: {
          required: true
        },
        /*children_certificate: {
          required: true
        },*/
        penalty_certificate: {
          required: true
        },
      },
      messages: {
        resume: "Resume is required",
        //children_certificate: "Children Certificate is required",
        penalty_certificate: "Penalty Certificate is required",
      },
    });
  });
}
import API from "../common/AxoisClient";

export const getCountryList = async() => {
  const countries = await API.get(`country-list`);
  return countries.data.countryCodeArr;
}

export const getCountries = async() => {
  const countries = await API.get(`country-list`);
  return countries.data.countriesArr;
}

export const getDefaultCountry = async() => {
  const countries = await API.get(`country-list`);
  return countries.data.country_code;
}
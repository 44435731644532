import React from 'react';
import {Link} from "react-router-dom";
import Lang from "../common/lang/Lang";

export function QuickPayoutLinks (props:any) {
  return(
    <>
      <h2 className="title"><i className="far fa-money-bill-alt"></i> {Lang.payout}</h2>
      <ul>
          <li className={(window.location.pathname==='/pending-payment'?'active':'')}>
              <Link className="navbar-brand" to={'pending-payment'}><i className="far fa-file-alt"></i>{Lang.pending}</Link>
          </li>
          <li className={(window.location.pathname==='/complete-payment'?'active':'')}>
          <Link className="navbar-brand" to={'complete-payment'}><i className="fas fa-file-alt"></i>{Lang.completed}</Link>
        </li>
          <li className={(window.location.pathname==='/paid-payment'?'active':'')}>
          <Link className="navbar-brand" to={'paid-payment'}><i className="fas fa-money-bill-alt"></i>{Lang.paid}</Link>
        </li>
      </ul>
    </>
  )
}

import _ from 'lodash';
// connecty cube info
import {getUserInfo} from "../Auth/Auth";

export const ConnectyInfo = [
  {
    appId: 1306,
    authKey: 'j8APkVBRS2ZhmQ-',
    authSecret: 'zVQgQrecLMJYT3q'
  },
  {
    debug: {
      mode: 1
    }
  }
];

// logged in user info
export function getLoginUserInfo() {
  let login_user:any = {};
  const userInfo:any  = getUserInfo();
  if(!_.isEmpty(userInfo.connecty_cube))
    login_user = {id: userInfo.connecty_cube.id, login:  userInfo.connecty_cube.login, password:  userInfo.connecty_cube.password};
    //login_user = {id: 232544, login:  "abhishek.singh@semidotinfo.com", password:  "semidot@123"};
  return login_user;
}

 export const CallStatus = {
   RINGING:   1,
   INCOMING:  2,
   CONNECT:   3,
   REJECT:    4,
   END:       5,
   NOT_ANSWERED:       6,
   NONE:       7,
 };

export const CallType = {
  VIDEO: 1,
  AUDIO: 2
};
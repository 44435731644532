import {AxiosError} from "axios";
import Lang from "./lang/Lang";
import {Auth} from "../Auth/Auth";
import {ErrorNotification} from "./helpers";
declare var window: any;

export const HandleServerError = (error : AxiosError) => {
  if(error.response !== undefined){
    switch (error.response.status) {
      case 401:
        Error401();
        break;

      case 403:
        Error403();
        break;

      case 404:
        //window.location.href = '/404'
        break;

      case 419:
        Error419();
        break;

      case 422:
        Error422(error.response.data);
        break;

      case 429:
        Error422(error.response.data);
        break;

      default:
        ErrorNotification(Lang.error_response);
    }
  } else {
    ErrorNotification(Lang.no_internet_access);
  }


};

export const Error401 = () => {
  ErrorNotification(Lang.error_401_message);
  Auth.signout();
  window.location.href = '/';
};

export const Error403 = () => {
  ErrorNotification(Lang.error_403_message);
};

export const Error404 = () => {
  ErrorNotification(Lang.error_404_message);
};

export const Error419 = () => {
  ErrorNotification(Lang.error_419_message);
};

export const Error422 = ($errors : any) => {
  $.each( $errors.errors, function( key, value ) {
    $.each( value, function( key2, error ) {
      ErrorNotification( error);
    });
  });
};


export const Error422Custom = ($errors : any) => {
  $.each( $errors.errors, function( key, value ) {
    $.each( value, function( key2, error ) {
      error = error.replace(/document.\d+/g, 'document');
      ErrorNotification( error);
    });
  });
};
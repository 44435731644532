import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import API from './../../common/AxoisClient'
import {AxiosResponse} from "axios";
import Lang from "../../common/lang/Lang";
import {BasicInfoFormState} from "./State";
import {successNotification} from "../../common/helpers";
import {CountryFlags, Select2Wrapper} from "../../common/Select2";
import {getCountries} from "../../services";
import {BasicInfoValidations} from "./Validations";
import {FormLayout, Submit} from "../../common";
import {Auth} from "../../Auth/Auth";
import Cookies from "js-cookie";
const moment =  require ("moment");
declare let window:any;

export function BasicInfo(props: RouteComponentProps) {

    const [basicInfo, setBasicInfo] = useState(BasicInfoFormState);
    const [countries,  setCountry] = useState();

    useEffect(() => {
        document.title = Lang.basic_info_page_title+' - '+Lang.vitolker_translator;;

        /*($('[data-toggle="popover"]') as any).popover();*/
        API.get(`register/${Auth.userInfo.id}`).then((response: AxiosResponse) => {
            setBasicInfo({...basicInfo, user_id: localStorage.getItem("login_user_id"), social_no: response.data.social_no, origin_country: response.data.origin_country, driving_license: response.data.driving_license, own_vehicle: response.data.own_vehicle, police_verified: response.data.police_verified, verification_number: response.data.verification_number});

        });
        ($('[data-toggle="tooltip"]') as any).tooltip();
        getCountries().then(countries => {
            setCountry(countries);
        });
        //setBasicInfo({...basicInfo, user_id: localStorage.getItem("login_user_id")})
        BasicInfoValidations();
        ($('.scrollbar-inner') as any).scrollbar();
        $(window).on('scroll', function() {
            let navHeight:number;
            navHeight = ($(window) as any).height() - 400;
            if (($(window) as any).scrollTop() > navHeight) {
                ($('.mainHeader') as any).addClass('navShadow');
            } else {
                ($('.mainHeader') as any).removeClass('navShadow');
            }
        });


    }, []);

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
        event.preventDefault();
        if($("#basicinfo_form").valid()) {
            API.post(`basicinfo`, basicInfo).then((res: AxiosResponse) => {
                successNotification(Lang.basic_info_success);
                localStorage.setItem('registration_step', '2');
                props.history.push('/bank-info');
            })
        }
    }

    function handleChange(e:any) {
        $("#"+e.target.name+"-error").css({"display": "none"});
        const {name, value} = e.target;
        setBasicInfo(prevSate => ({...prevSate, [name]: value}));
    }

    function Logout() {
        Cookies.set('remember', '', { expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        Cookies.set('remember_token', '', {expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
        ($('.bs-tooltip-bottom') as any).removeClass('show');
        ($('.tooltip') as any).addClass('hide');
        Auth.signout();
        //window.location.href = '/login';
    }

    return(
        /*<div  className="bgCR">*/
        <div  className="bgMain">
        <div className="wrapper">
            <header className="mainHeader">
                <div className="container">
                    <nav className="navbar">
                        <div className="col-6">
                            <Link to={'#'}><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/> </Link>
                        </div>
                        <div className="col-6 text-right">
                            <ul>
                                <li>
                                    <a href={'#'} ><span data-toggle='tooltip' data-placement='bottom' data-original-title="Logout" onClick={() => Logout()}><i className="fas fa-power-off"/></span></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <section className="mainContent processBarWOutHead">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                            <div className="FormSite basicInfoBox userinfoStep">
                                <ul id="progressbar" className="stepListNav userTNav">
                                    <li className="active"/>
                                    <li>
                                        <div className="step">
                                            <div className="step1"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="step">
                                            <div className="step2"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="step">
                                            <div className="step3"><i className="iconCheck fas fa-check-circle"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className={"boxWhite boxShadow"}>
                                <h1 className={'title'}><i className="fas fa-user-edit"/>{Lang.basic_info_page_title}</h1>
                                {/*<form className="centerForm forgotForm" onSubmit={handleSubmit} id="basicinfo_form" autoComplete="off">*/}
                                    <FormLayout id="basicinfo_form" onSubmit={handleSubmit}  autoComplete="off">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="email">{Lang.social_security_no} <span className="required">*</span></label>
                                                <input id="social_no" name="social_no" type="number" className="form-control validate" value={basicInfo.social_no} onChange={(e) => {setBasicInfo({...basicInfo, social_no: e.target.value})}} placeholder={Lang.social_placeholder} />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group group validation-posi">
                                                <label>{Lang.country_of_origin} <span className={'required'}>*</span></label>
                                                <Select2Wrapper value={basicInfo.origin_country} onChange={handleChange}
                                                                id={'origin_country'} name={'origin_country'}
                                                                data={{
                                                                    placeholder: Lang.country_of_origin_placeholder,
                                                                    data: countries,
                                                                    templateResult: CountryFlags,
                                                                    templateSelection: CountryFlags,
                                                                }} >
                                                    <option value={''}>{Lang.country_placeholder}</option>
                                                </Select2Wrapper>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group group validation-posi">
                                                <label htmlFor="email">{Lang.driver_license} <span className="required">*</span></label>

                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" className="custom-control-input"
                                                           id={'customRadio'} name={'driving_license'} onChange={(e) => {setBasicInfo({...basicInfo, driving_license: 1})}} checked={basicInfo.driving_license===1} value={1}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio">{Lang.yes}</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" className="custom-control-input"
                                                           id={'customRadio2'} name={'driving_license'} onChange={(e) => {setBasicInfo({...basicInfo, driving_license: 0, own_vehicle: '', police_verified: '', verification_number: ''})}} value={0} checked={basicInfo.driving_license===0}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio2">{Lang.no}</label>
                                                </div>

                                            </div>
                                        </div>
                                        { basicInfo.driving_license==1 &&
                                            <div className="col-sm-6">
                                                <div className="form-group group validation-posi">
                                                    <label>{Lang.vehicle} <span className={'required'}>*</span></label>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio3'} name={'own_vehicle'} onChange={(e) => {setBasicInfo({...basicInfo, own_vehicle: 1})}} checked={basicInfo.own_vehicle===1} value={1}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio3">{Lang.yes}</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input"
                                                               id={'customRadio4'} name={'own_vehicle'} onChange={(e) => {setBasicInfo({...basicInfo, own_vehicle: 0, police_verified: '', verification_number: ''})}} value={0} checked={basicInfo.own_vehicle===0}/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customRadio4">{Lang.no}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <div className="form-group group validation-posi">
                                                <label>{Lang.police_confirmed} <span className={'required'}>*</span></label>

                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" className="custom-control-input"
                                                           id={'customRadio7'} name={'police_verified'} onChange={(e) => {setBasicInfo({...basicInfo, police_verified: 1})}} checked={basicInfo.police_verified===1} value={1}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio7">{Lang.yes}</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" className="custom-control-input"
                                                           id={'customRadio8'} name={'police_verified'} onChange={(e) => {setBasicInfo({...basicInfo, police_verified: 0, verification_number: ''})}} value={0} checked={basicInfo.police_verified===0}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio8">{Lang.no}</label>
                                                </div>
                                            </div>
                                        </div>

                                        { basicInfo.police_verified==1 &&
                                        <div className="col-sm-6">
                                            <div className="form-group group validation-posi">
                                                <label htmlFor="verification_number">{Lang.vt_trans_lang_87_verification} <span className="required">*</span></label>
                                                <input id="verification_number" name="verification_number" type="number" className="form-control validate" value={basicInfo.verification_number} onChange={(e) => {setBasicInfo({...basicInfo, verification_number: e.target.value})}} placeholder={Lang.verification_number_placeholder} />
                                            </div>
                                        </div>
                                        }

                                    </div>
                                    <div className="mt-5 text-center">
                                        <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.next_button}/>
                                    </div>
                                </FormLayout>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="mainFooter">
                <div className="footer_section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <ul className="footer_right footer_left">
                                    <li>{Lang.we_love_data_regulation}</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="storeMobLink">
                                    <span className="findOnUsText mr-2 text-center">{Lang.find_us_on}</span>
                                    <ul className="BtnApp">
                                        <li>
                                            <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/googlePlay.png`} alt="" title=""/></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${window.PUBLIC_URL}/theme/images/appStore.png`} alt="" title=""/></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="footer_right">
                                    <p>{Lang.all_rights_reserved} &copy; {moment().year()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </div>

    )
}
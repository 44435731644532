import React, {useState} from 'react';
import {SubmitButtonService} from "../services";
import Lang from "./lang/Lang";

export interface SubmitButton extends React.HTMLAttributes<HTMLButtonElement> {
  label: string
}

export function Submit(props:  SubmitButton ) {

  const [active, setActive] = useState(true);

  SubmitButtonService.getLoading().subscribe((message:any) => {
    setActive(message)
  });

  if(active){

    return (
      <button type={"submit"} {...props}>{props.label}</button>
    );
  } else {
    return (
      <button type={"button"} {...props}>{Lang.please_wait}</button>
    );
  }
}
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import Lang from "../common/lang/Lang";
import API, {SwitchLoader} from '../common/AxoisClient';
import {AxiosResponse} from "axios";
import {OrderDataState} from "../dashboard/State";
import {ErrorNotification, getToLanguage, successNotification} from "../common/helpers";
import {DateTimeFormat, OrderModel, OrderStatus, OrderType} from "../model/Order";
import {QuickLinks} from "../quick-links/QuickLinks";
import {NoDataFound} from "../no-data-found/NoDataFound";
import moment from "moment";
import Swal from "sweetalert2";
import _ from "lodash";
import {OrderEditCommentModel} from "../model/OrderEditComment";
import {Link} from "react-router-dom";
import {LoaderType} from "../common/Loader";
declare let window:any;

export function Invitation (props: any) {

  const [transInvitationOrder, setNewTransInvitationOrder] =   useState(OrderDataState);
  const [transOrderDetail, setTransOrderDetail]            =   useState(OrderDataState);

  function performAction(assignment_action: any) {
    API.post(`orders/order-action`, assignment_action).then((res: AxiosResponse) => {
      if (res.data.status === 1) {
        successNotification(Lang.accept_assignment);
      } else if (res.data.status === 2) {
        successNotification(Lang.reject_assignment);
      } else if (res.data.status === 3) {
        ErrorNotification(Lang.already_accepted_someone);
      } else if (res.data.status === 4 || res.data.status === 5) {
        ErrorNotification(res.data.message);
      }
      ($('#exampleModal') as any).modal('hide');
      getOrderList();
    })
  }

  function assignmentAction(event:any, assignment_data:any, action:string) {
    let assignment_action = {'order_id': assignment_data.id, 'action': action}
    if (action === 'reject') {
      let confirm_text = Lang.reject_assignment_confirmation;
      Swal.fire({
        title: Lang.is_sure,
        text: confirm_text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: Lang.vt_trans_lang_49_okbutton,
        cancelButtonText: Lang.vt_trans_lang_50_cancelbutton
      }).then((result: any) => {
        if (result.value) {
          performAction(assignment_action);
        }
      })
    } else {
      performAction(assignment_action);
    }
  }

  function getOrderList(){
    SwitchLoader(LoaderType.NONE);
      API.get(`orders/order-list`, { params: { status:2 }}).then((res: AxiosResponse) => {
        const translatorInvitationOrder = res.data.orderData;
        setNewTransInvitationOrder({...transInvitationOrder, ordersInvitation: translatorInvitationOrder});
      });
  }

  useEffect(() => {
    document.title = Lang.new_invitation+' - '+Lang.vitolker_translator;
    getOrderList();
    setInterval(async () => {
      getOrderList();
    }, 20000);
  }, []);

  function getOrderDetail(event:any, order_id:any) {
    setTransOrderDetail(OrderDataState);
    API.post(`orders/order-detail`, {'order_id': order_id}).then((res: AxiosResponse) => {
      const translatorOrderDetail = res.data.orderData;
      setTransOrderDetail({...transOrderDetail, orderData: translatorOrderDetail});
    });
  }

  return(

      <div className="bgMain">
        <div className="wrapper">
          <Header {...props}/>
          <section className="mainContent">
            <div className="container">
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-12">
                  <div className="quickLinkBox equalHeight boxShadow">
                    <QuickLinks/>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-12">
                  <div className="Custmor_form boxShadow content equalHeight">

                    <div className="dashbox">
                      <div className="invitation">
                        {
                          transInvitationOrder.ordersInvitation &&
                          transInvitationOrder.ordersInvitation.length > 0 &&
                          transInvitationOrder.ordersInvitation[0]!==undefined &&
                          transInvitationOrder.ordersInvitation.map((invitationOrders: OrderModel, index: number) => {
                            let fromFlag = (invitationOrders.language_from!==null && invitationOrders.language_from.country!==undefined)?invitationOrders.language_from.country.iso:'';
                            return <>
                              <div className="invitationItem">
                                <div className="row align-items-center">
                                  <div className="col-lg-6 col-sm-12">
                                    <div className="serviceType float-left">
                                      <span className="SSType"><img src={invitationOrders.translation_type.small_icon} alt="" title={invitationOrders.translation_type.title} className="img-fluid"/></span>
                                      <div className="serviceName">{invitationOrders.translation_type.title}</div>
                                    </div>

                                    <div className="InvitationInfo float-left">
                                      <a href="#" className="orderId" data-toggle="modal" data-target="#exampleModal" onClick={(e) => getOrderDetail(e, invitationOrders.id)}>#{invitationOrders.order_no}</a>
                                      <div className="timer">
                                        <p><i className="far fa-calendar-alt"/> {moment(invitationOrders.order_date).format('dddd')} {moment(invitationOrders.order_date).format('DD-MM-YYYY')}</p>
                                        <p><i className="far fa-clock"/> {(invitationOrders.translation_type_id != OrderType.DOCUMENT && invitationOrders.translation_type_id != OrderType.PHONE_MESSAGE) &&
                                        invitationOrders.order_from +' - '
                                        } {invitationOrders.order_to}</p>
                                      </div>

                                      <div className="Lang">
                                        <span className="lang-from-to"><span><img src={(fromFlag!==undefined)?window.PUBLIC_URL +'/flags/'+fromFlag.toLowerCase()+'.png':''} alt="vt-img"/>{invitationOrders.language_from.language_name}</span><span
                                            className="exchangeIcon"><i className="fas fa-exchange-alt"/></span><span>{getToLanguage(invitationOrders)}</span></span>
                                      </div>
                                      { invitationOrders.translation_type_id===OrderType.PHYSICAL &&
                                        <div className="addressBar">
                                          <i className="fas fa-map-marker-alt"/> {invitationOrders.meeting_address}
                                        </div>
                                      }
                                      { (!_.isEmpty(invitationOrders.company.name) && _.isEmpty(invitationOrders.department) ) &&
                                        <div className="addressBar">
                                          {invitationOrders.company.name}
                                        </div>
                                      }
                                      { (_.isEmpty(invitationOrders.company.name) && !_.isEmpty(invitationOrders.department) && invitationOrders.department !== undefined && !_.isEmpty(invitationOrders.department.name)) &&
                                      <div className="addressBar">
                                        {invitationOrders.department.name}
                                      </div>
                                      }
                                      {(!_.isEmpty(invitationOrders.company.name) && !_.isEmpty(invitationOrders.department) && invitationOrders.department !== undefined && !_.isEmpty(invitationOrders.department.name)) &&
                                        <div className="addressBar">
                                          {invitationOrders.company.name} , {invitationOrders.department.name}
                                        </div>
                                      }

                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-sm-12 mobileSpace20">
                                    <div className="btn_invitation">
                                      <a className="btnStatusPrimary" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal" onClick={(e) => getOrderDetail(e, invitationOrders.id)}>{Lang.see_more}</a>
                                      <a className="btnStatusSuccess" href="javascript:void(0)"
                                         onClick={(e) => assignmentAction(e, invitationOrders, 'accept')}>{Lang.accept}</a>
                                      <a className="btnStatusDanger" href="javascript:void(0)"
                                         onClick={(e) => assignmentAction(e, invitationOrders, 'reject')}>{Lang.reject}</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          })
                        }
                        {(transInvitationOrder.ordersInvitation &&
                            transInvitationOrder.ordersInvitation.length === 0) &&
                              <NoDataFound/>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="modal right fade" id="exampleModal" role="dialog" aria-labelledby="exampleModal"
               aria-hidden="true">
            <div className="modal-dialog" role="document">
              {transOrderDetail['orderData'][0] == undefined &&
                <div className={'dataLoad'}>{Lang.please_wait}</div>
              }
              { transOrderDetail['orderData'][0]!==undefined &&  transOrderDetail['orderData'][0].language_from.country &&
              <div className="modal-content">
                <div className="sideMenuBarRight" id="orderDetailsBox">
                  <a href="javascript:;" className="btn closeBtn" data-dismiss="modal"><span>×</span></a>
                  <div className="orderPreview">
                    <div className="blockStep">
                      <div className="blockStepList">
                        <h3 className="stripTitleNew">
                          <div className="row">
                            <div className="col-sm-6">
                              <i className="far fa-file-alt"/> {Lang.order_detail} <span className={'orderID'}> #{transOrderDetail['orderData'][0].order_no}</span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <div className="colData"><span>{Lang.vt_trans_lang_56_invationdate} :</span>&nbsp;&nbsp;&nbsp;<span
                                  className="timer"><p><i className="far fa-calendar-alt" aria-hidden="true"/> {moment(transOrderDetail['orderData'][0].created_at).format('dddd')}  {moment(transOrderDetail['orderData'][0].created_at).format('DD-MM-YYYY')}</p><p><i
                                  className="far fa-clock" aria-hidden="true"> </i> {moment(transOrderDetail['orderData'][0].created_at).format('HH:mm')}</p></span></div>
                            </div>
                          </div>
                        </h3>

                        <div className="row mt-4 mb-4">
                          <div className="col-lg-12 col-xl-6">
                            <div className="row">
                              <div className="col-left">
                                <img src={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.profile_image_small:'/theme/images/user-placeholder.jpg'} alt={(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} width="78" className="img-fluid rounded-circle"/>
                              </div>
                              <div className="col-right infoUserP">
                                <h4 className="Usertype">{Lang.vt_trans_lang_52_invationtranslator}</h4>
                                {transOrderDetail['orderData'][0].translate_by!=null &&
                                <ul>
                                  <li><i className="far fa-user"/>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.name:''} <b>{(transOrderDetail['orderData'][0].translate_by!=undefined && transOrderDetail['orderData'][0].translate_by.user_code!=null)?'('+transOrderDetail['orderData'][0].translate_by.user_code+')':''}</b></li>
                                  <li><i className="far fa-envelope"/>{(transOrderDetail['orderData'][0].translate_by!=undefined)?transOrderDetail['orderData'][0].translate_by.email:''}</li>
                                </ul>
                                }
                                {transOrderDetail['orderData'][0].translate_by==null &&
                                <ul>
                                  {/*<li><i className="fas fa-sad-tear"/>
                                    {Lang.not_assigned}</li>*/}
                                </ul>
                                }
                                {(transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT && (transOrderDetail['orderData'][0].status==OrderStatus.COMPLETED || transOrderDetail['orderData'][0].status==OrderStatus.UNDER_QA)) &&
                                <div className={'scrollbar-inner minHeight200'}>
                                  <ul className={'document_upload_list'}>
                                    {
                                      transOrderDetail['orderData'][0].order_files &&
                                      transOrderDetail['orderData'][0].order_files.length > 0 &&
                                      transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                        if (orderFilesTranslator.type == 'complete') {
                                          return <li>
                                            <a target="_blank" href={orderFilesTranslator.name}>
                                              <i className="far fa-file-alt"/>
                                              <span>{orderFilesTranslator.file_name}</span>
                                              <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                            </a>
                                          </li>
                                        }
                                      })}
                                  </ul>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6 mobileSpace">
                            <div className="row">
                              <div className="col-left"><img
                                  src={transOrderDetail['orderData'][0].customer.profile_image_small}
                                  alt={transOrderDetail['orderData'][0].customer.name}  width="78"
                                  className="img-fluid rounded-circle"/></div>
                              <div className="col-right infoUserP"><h4 className="Usertype">{Lang.vt_trans_lang_54_invationCustomer}</h4>
                                <ul>
                                    <li><i className="far fa-user"/>{transOrderDetail['orderData'][0].customer.name}                                                        {transOrderDetail['orderData'][0].customer.user_code !== null &&
                                        <b>{'(' + transOrderDetail['orderData'][0].customer.user_code + ')'}</b>
                                      }
                                    </li>
                                </ul>
                                {transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT &&
                                <div className={'scrollbar-inner minHeight200'}>
                                  <ul className={'document_upload_list'}>
                                    {
                                      transOrderDetail['orderData'][0].order_files &&
                                      transOrderDetail['orderData'][0].order_files.length > 0 &&
                                      transOrderDetail['orderData'][0].order_files.map((orderFilesTranslator: any, index: number) => {
                                        if (orderFilesTranslator.type == 'create') {
                                          return <li>
                                            <a target="_blank" href={orderFilesTranslator.name}>
                                              <i className="far fa-file-alt"/>
                                              <span>{orderFilesTranslator.file_name}</span>
                                              <i className="fas fa-cloud-download-alt downloadIcoSet"/>
                                            </a>
                                          </li>
                                        }
                                      })}
                                  </ul>
                                </div>
                                }
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="profileDataRow">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.order_no}.</span> #{transOrderDetail['orderData'][0].order_no}</div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.translation_type}</span>
                                <span data-toggle="tooltip" data-placement="left" title={transOrderDetail['orderData'][0].translation_type.title}
                                      className="d-inline">
 									<img src={transOrderDetail['orderData'][0].translation_type.small_icon} alt={transOrderDetail['orderData'][0].translation_type.title} width="25"/>
 								</span>
                                {transOrderDetail['orderData'][0].translation_type.title}
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.language}</span>
                                <div className="language-section">
                                  <span>
                            <img src={(transOrderDetail['orderData'][0].language_from.country.iso!==undefined?window.PUBLIC_URL +'/flags/'+transOrderDetail['orderData'][0].language_from.country.iso.toLowerCase()+'.png':'')}/>{transOrderDetail['orderData'][0].language_from.language_name}
                                    <span className="exchangeIcon"><i className="fas fa-exchange-alt"/></span> {getToLanguage(transOrderDetail['orderData'][0])}
                          </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.vt_trans_lang_51_invationDate}</span>
                                <span className="timer">
 									<p><i className="far fa-calendar-alt" aria-hidden="true"/>{moment(transOrderDetail['orderData'][0].order_date).format('dddd')} {moment(transOrderDetail['orderData'][0].order_date).format('DD-MM-YYYY')}</p>
 									<p><i className="far fa-clock" aria-hidden="true"> </i>{(transOrderDetail['orderData'][0].translation_type_id != OrderType.DOCUMENT && transOrderDetail['orderData'][0].translation_type_id != OrderType.PHONE_MESSAGE) &&
                                    transOrderDetail['orderData'][0].order_from +' - ' } {transOrderDetail['orderData'][0].order_to}</p>
 									</span>
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.vt_trans_lang_53_Institution}</span>{transOrderDetail['orderData'][0].company.name}</div>
                            </div>

                            <div className="col-xl-4 col-sm-6">
                              <div className="colData"><span>{Lang.department}</span>{(transOrderDetail['orderData'][0].department!=undefined)?transOrderDetail['orderData'][0].department.name:''}</div>
                            </div>

                            {transOrderDetail['orderData'][0].comment_for_translator && transOrderDetail['orderData'][0].comment_for_translator.length > 0 &&
                            <div className="col-xl-4 col-sm-6">
                              <div className="colData">
                                <span>{Lang.vt_trans_lang_58_comment}</span>{transOrderDetail['orderData'][0].comment_for_translator}
                              </div>
                            </div>
                            }
                            {transOrderDetail['orderData'][0].meeting_address && transOrderDetail['orderData'][0].meeting_address.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                            <div className="col-sm-6">
                              <div className="colData"><span>{Lang.meeting_address}</span> <i
                                  className="fas fa-map-marker-alt"/> {(transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL) ? transOrderDetail['orderData'][0].meeting_address : ''}
                              </div>
                            </div>
                            }
                            {transOrderDetail['orderData'][0].addition_info && transOrderDetail['orderData'][0].addition_info.length > 0 && transOrderDetail['orderData'][0].translation_type_id === OrderType.PHYSICAL &&
                            <div className="col-sm-6">
                              <div className="colData"><span>{Lang.addition_info}</span> <i
                                  className="fas fa-map-marker-alt"/> {transOrderDetail['orderData'][0].addition_info}
                              </div>
                            </div>
                            }
                          </div>
                        </div>
                      </div>

                      {!_.isEmpty(transOrderDetail['orderData'][0].tel_message) &&
                      <div className="blockStepList custmorRow">
                        <h3 className="stripTitleNew"><i className="far fa-envelope"/> {Lang.vt_trans_lang_57_telephoneMSG}</h3>
                        <div className="profileDataRow">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="colData"> {transOrderDetail['orderData'][0].tel_message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      }

                      <div className="blockStepList custmorRow">
                        <h3 className="stripTitleNew"><i className="far fa-user"/> {Lang.vt_trans_lang_55_invationContact}</h3>
                        <div className="profileDataRow">
                          <div className="row">
                            {
                              transOrderDetail['orderData'][0].contact_persons &&
                              transOrderDetail['orderData'][0].contact_persons.length > 0 &&
                              transOrderDetail['orderData'][0].contact_persons.map((contactPersons: any, index: number) => {
                                return <>

                                  <div className="col-sm-6">
                                    {index==0 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                    <h4 className="Usertype">{Lang.contact_first}</h4>
                                    }
                                    {index==1 && transOrderDetail['orderData'][0].contact_persons.length>1 &&
                                    <h4 className="Usertype">{Lang.contact_second}</h4>
                                    }
                                    <div className="colData"><i className="far fa-user"/> {contactPersons.name}</div>
                                    {/*{ !_.isEmpty(contactPersons.email) &&
                                    <div className="colData"><i className="far fa-envelope"/> <a href={'mailto:'+(contactPersons.email)}>{contactPersons.email}</a>
                                    </div>
                                    }
                                    { !_.isEmpty(contactPersons.phone_no) &&
                                    <div className="colData"><i className="fas fa-mobile-alt"/> <a href={'tel:'+(contactPersons.phone_no)}>{contactPersons.phone_no}</a></div>
                                    }*/}
                                  </div>

                                </>
                              })
                            }
                          </div>
                        </div>
                      </div>

                      {/*{transOrderDetail['orderData'][0].edit_request &&
                      transOrderDetail['orderData'][0].edit_request.length > 0 &&
                      <div className="blockStepList">
                        <h3 className="stripTitleNew">
                          <i className="fas fa-pencil-alt"/> {Lang.order_correction}
                        </h3>
                        <div className="kt-history">
                          <div className="historybox">
                            {
                              transOrderDetail['orderData'][0].edit_request &&
                              transOrderDetail['orderData'][0].edit_request.length > 0 &&
                              transOrderDetail['orderData'][0].edit_request.map((editcomments: OrderEditCommentModel, index: number) => {
                                return <div className="kt-timeline-v2__item">
                                  <div className="kt-timeline-v2__item-cricle"><i
                                      className="fa fa-genderless kt-font-danger"/></div>
                                  <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                    <div className="colData"><span
                                        className="badge badge-info">{Lang.request_for_correction}</span></div>
                                    <div className="colData"><i className="far fa-calendar-alt"
                                                                aria-hidden="true"/> {moment(editcomments.created_at).format(DateTimeFormat.DATE)}&nbsp; <i
                                        className="far fa-clock" aria-hidden="true"> </i> {moment(editcomments.created_at).format(DateTimeFormat.TIME)}
                                    </div>
                                    <div className="colData">{editcomments.edit_order_message}</div>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                      }*/}

                      {
                        ((transOrderDetail['orderData'][0].translation_type_id === OrderType.DOCUMENT || transOrderDetail['orderData'][0].translation_type_id === OrderType.PHONE_MESSAGE) && transOrderDetail['orderData'][0].status != OrderStatus.COMPLETED && transOrderDetail['orderData'][0].status != OrderStatus.CANCELLED && transOrderDetail['orderData'][0].status != OrderStatus.PAID && transOrderDetail['orderData'][0].status != OrderStatus.INVITATION_SENT && transOrderDetail['orderData'][0].status != OrderStatus.UNDER_QA ) &&

                        <div className="blockStepList button text-center">
                          <Link className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail" to={`complete-order/${transOrderDetail['orderData'][0].id}`}>{Lang.vt_trans_lang_59_submit}</Link>
                        </div>
                      }
                      {transOrderDetail['orderData'][0].status == OrderStatus.INVITATION_SENT &&
                      <div className="col-lg-7 col-md-5 col-sm-12 mobileSpace20">
                        <div className="btn_invitation">

                          <a className="btnStatusSuccess" href="javascript:void(0)"
                             onClick={(e) => assignmentAction(e, transOrderDetail['orderData'][0], 'accept')}>{Lang.accept}</a>
                          <a className="btnStatusDanger" href="javascript:void(0)"
                             onClick={(e) => assignmentAction(e, transOrderDetail['orderData'][0], 'reject')}>{Lang.reject}</a>
                        </div>
                      </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>

          <Footer/>
        </div>
      </div>
  )
}
import React, {useEffect, useState} from 'react';
import Lang from "../../../common/lang/Lang";
import API from "../../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {showNotification} from "../../../common/helpers";
import {ResetPasswordFormState} from "./State";
import {FormLayout, Submit} from "../../../common";
import {PropsSchema} from "./Schema";
import { ResetPasswordValidations} from "../../../password/Validations";
import * as _ from "lodash";
import {Link} from "react-router-dom";

export function ResetPasswordForm (props: PropsSchema) {

  const {token} = props.match.params;
  const path = props.location.pathname.split('/')[1];

    useEffect(() => {
        document.title = Lang.reset_password_title+' - '+Lang.vitolker_translator;
        ResetPasswordValidations();
        setTimeout(function () {
            //($('#password1')as any).parents('.password').find('.progress ').hide();
            ($('#password1')as any).parents('.password').find('.progressBar_message ').hide();
        },500);
    }, []);

    const [form, setForm] = useState({...ResetPasswordFormState, token: token});
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [PasswordSpace, setPasswordSpace] = useState('0');
    // this state is for indicating if this is the first password reset the user will do, which is the process of email verification
    const [emailVerification, setEmailVerification] = useState(false); 

  function ChangePassword(event: React.ChangeEvent<HTMLFormElement>){
    event.preventDefault();
      if($("#reset_password").valid()) {
          const verifyEmail = (path === "confirm-password"); // First time the translator sets his password
          const pushURL = verifyEmail ? '/account-active' : '/login';
          API.post(`reset-password`, {...form, "verify-email": verifyEmail}).then((res: AxiosResponse) => {
              showNotification('success', res.data);
              props.history.push(pushURL);
          })
      }
  }

    function handleChangePassword(event:any) {
        event.preventDefault();
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name == 'password1' && !_.isEmpty(value)) {
            setPasswordSpace('1');
            //($('#password')as any).parents('.password').find('.progress ').hide();
            ($('#password1')as any).parents('.password').find('.progressBar_message ').hide();
        }
        setForm({...form, password1: event.target.value});
    }
  return(
      <section className={`mainContent pageVerticalCenter ${(PasswordSpace == '1')?'showPasswordSpace':''}`}>
          <div className="container" id="reset-password-container">
              <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                      <div className="FormSite">
                        <FormLayout onSubmit={ChangePassword} id={'reset_password'} className="centerForm forgotForm" autoComplete="off">
                            <h1>{Lang.vt_trans_lang_45_Resetpw_title}</h1>
                            <div className="row">
                                <div className="col-sm-8 offset-sm-2">
                                    <div className="form-group group mt-3 password">
                                        <label htmlFor="email">{Lang.password} <span className="required">*</span></label>
                                        <input type={showPassword  === true ? 'text' : 'password'} name={'password1'} id={'password1'} className="form-control validate" placeholder={Lang.vt_trans_lang_46_Resetpw_placeholder} required minLength={8} maxLength={20} value={form.password1} onChange={(e)=>{handleChangePassword(e)}} />
                                        <i className={`fa ${showPassword === true ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowPassword(!showPassword)}}/>
                                        {/* <div className={'progressBar_message'}>
                                            <label>{Lang.passwordStrength}:</label>
                                            <div id={'passwordStrengthProgress'}/>
                                            <div id={'passwordStrengthVerdict'}/>
                                        </div> */}
                                    </div>

                                    <div className="form-group group mt-3 password">
                                        <label htmlFor="email">{Lang.cn_password} <span className="required">*</span></label>
                                    <input type={showConPassword  === true ? 'text' : 'password'} name={'cn_password'} id={'cn_password'} className="form-control validate" placeholder={Lang.vt_trans_lang_47_Resetpw_placeholder1} required value={form.cn_password} onChange={(e) => {setForm({...form, cn_password: e.target.value})}} />
                                        <i className={`fa ${showConPassword === true ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowConPassword(!showConPassword)}}/>
                                  </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                <Submit className={'btn btn-md btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_68_complete}/>
                            </div>
                            <p className="msgText2">{Lang.do_you_remember_password} <Link to="/login">{Lang.try_logging}</Link></p>
                        </FormLayout>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}
import React, {useEffect, useState} from 'react';
import API, {base_url} from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {RouteComponentProps} from "react-router-dom";
import Lang from "../common/lang/Lang";
import _ from 'lodash';
import {formState} from "./State";
import {FormLayout, Submit} from "../common";
import {getWeTranslateSetting} from "../services/TranslatorService";
import {ErrorNotification, successNotification} from "../common/helpers";
import {Footer, Header} from "../partial";
import {getCountryList, getLanguageList} from "../services";
import {RegistrationValidations} from "../form/signup/Validations";
import {DefaultJQueryFun} from "../dashboard/DefaultJQueryFile";
let moment = require('moment');

declare let window: any;

export function EnquiryCreatePage (props: RouteComponentProps) {

    useEffect(() => {
        document.title = Lang.contact_us+' - '+Lang.vitolker_translator;
        //DefaultJQueryFun(); commented 03-Jan-2020
        //Note: Removing class add here.
        $('body').removeClass('blankVector');
    },[]);

    $('body').addClass('blankVector');

    const [enquiryForm,setEnquiryForm] = useState(formState);

    const [weTranslateSetting,setWeTranslateSetting] = useState<any>();

    function SendEnquiry(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if($( "#contactForm" ).valid()) {
            API.post(`${base_url}api/enquiry`, enquiryForm).then((res: AxiosResponse) => {
                if(!_.isEmpty(res.data) && res.data.status === true) {
                    successNotification(res.data.message);
                    setEnquiryForm(formState);
                } else {
                    ErrorNotification(res.data.message);
                }
            })
        }
    }

    function getSettingData() {
        getWeTranslateSetting().then(res => {
            if (!_.isEmpty(res)) {
                setWeTranslateSetting(res);
            }
        })
    }

    useEffect(()=>{
        getSettingData();
        $( "#contactForm" ).validate({
            rules: {
                subject: {
                    required: true
                },
                message: {
                    required: true
                },
            },
        });
    },[]);

    function resetForm(event:any) {
        event.preventDefault(); 
        setEnquiryForm({...enquiryForm, subject:'',message:''});
    }


    return (
        <div className="bgMain">
            <div className="wrapper">
                <Header {...props}/>
                <section className="mainContent">
                    <div className="container">
                        <div className="contactPage">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12">
                                    <div className="talkTitle">
                                        <h1>{Lang.vt_trans_lang_72_enquiry_title}</h1>
                                        
                                    </div>
                                    <img src="theme/images/contact.png" className="img-fluid"/>
                                </div>
                                <div className="col-xl-6 offset-xl-1 col-lg-7 col-md-12">
                                    {!_.isEmpty(weTranslateSetting) && !_.isUndefined(weTranslateSetting) &&
                                        <div className="row">
                                            {!_.isUndefined(weTranslateSetting.vitolker_address) && !_.isEmpty(weTranslateSetting.vitolker_address) &&
                                                <div className="col-md-4 col-sm-4">
                                                    <div className="infoBox">
                                                        <img src="theme/images/location.png" alt="" title=""/>
                                                        <span>{weTranslateSetting.vitolker_address.setting_value}</span>
                                                    </div>
                                                </div>
                                            }

                                            {!_.isUndefined(weTranslateSetting.vitolker_phone) && !_.isEmpty(weTranslateSetting.vitolker_phone) &&
                                                <div className="col-md-4 col-sm-4">
                                                    <div className="infoBox">
                                                        <img src="theme/images/help.png" alt="" title=""/>
                                                        <span>{weTranslateSetting.vitolker_phone.setting_value}</span>
                                                    </div>
                                                </div>
                                            }

                                            {!_.isUndefined(weTranslateSetting.vitolker_email) && !_.isEmpty(weTranslateSetting.vitolker_email) &&
                                                <div className="col-md-4 col-sm-4">
                                                    <div className="infoBox">
                                                        <img src="theme/images/email.png" alt="" title=""/>
                                                        <span><a href={'mailto:'+weTranslateSetting.vitolker_email.setting_value}>{weTranslateSetting.vitolker_email.setting_value}</a></span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <FormLayout className="contactForm" id={"contactForm"} onSubmit={SendEnquiry}>
                                        <div className="group form-group">
                                            <label htmlFor="subject" className="label">{Lang.subject}</label>
                                            <input value={enquiryForm.subject}  id="subject" name={'subject'} onChange={(e:any)=>setEnquiryForm({...enquiryForm,subject:e.target.value})} type="text" className="form-control" placeholder={Lang.vt_trans_lang_89_enquiry_email}/>
                                        </div>
                                        <div className="group form-group password">
                                            <label htmlFor="message" className="label">{Lang.message}</label>
                                            <textarea value={enquiryForm.message} className="form-control form-control2"  name={'message'} onChange={(e:any)=>setEnquiryForm({...enquiryForm,message:e.target.value})} placeholder={Lang.vt_trans_lang_82_enquiry_msg} id="message"></textarea>
                                        </div>
                                        <div className="group submit_btn text-right mobiCenter">
                                        <button className={'btn btn-default hvr-rectangle-out'} data-skin={'dark'} title={Lang.reset} onClick={(e) => {resetForm(e)}}>{Lang.reset}</button> &nbsp;&nbsp;
                                            <Submit className="btn btn-lg btn-green hvr-rectangle-out" label={Lang.vt_trans_lang_83_send}/>
                                        </div>
                                    </FormLayout>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
    );
}

import * as React from 'react';
import {OrderModel} from "../../model/Order";
import Lang from "../lang/Lang";

let moment = require("moment");
declare var jQuery: any;
declare var window: any;

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'danger',
  WARNING: 'warning',
  INFO:   'info',
};


export const showNotification = (type: string, message: string) => {
  $.notify(
    {message: message},
    {
      type: type,
      placement: {
        from: 'bottom',
        align: 'right'
      },
      newest_on_top: true
    });
}



export const successNotification = (message: string) => {
  showNotification(NOTIFICATION_TYPE.SUCCESS, message);
}

export const ErrorNotification = (message: string) => {
  showNotification(NOTIFICATION_TYPE.ERROR, message);
}

export const getToLanguage = (orderData: OrderModel) => {
    if(orderData.is_dumb===1){
        return  <><img src={window.PUBLIC_URL +'/theme/images/sign-lang.png'} width="18"/> {Lang.sign_language_translation}</>;
    } else {
        let toFlag = (orderData.language_to!==null && orderData.language_to.country!==undefined)?orderData.language_to.country.iso:'';
        return <><img src={(toFlag!==undefined)?window.PUBLIC_URL +'/flags/'+toFlag.toLowerCase()+'.png':''} alt="vt-img"/> {orderData.language_to.language_name}</>;
    }
}


export function translateThisKey(key:string) {
  return Lang.getString(key);
}
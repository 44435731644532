import React, { useEffect, useState } from "react";
import API from "../common/AxoisClient";
import { AxiosResponse } from "axios";
import Lang from "../common/lang/Lang";
import { FormLayout, Submit } from "../common";

export function TermsAndConditions(props: any) {
  const [termsAndCondition, setTermsAndCondition] = useState({ html: "" });

  useEffect(() => {
    API.get(`get-terms-and-conditions`).then((res: AxiosResponse) => {
      const terms_and_conditions = res.data.terms_and_conditions;
      setTermsAndCondition({
        ...termsAndCondition,
        html: terms_and_conditions.setting_value,
      });
    });

    setTimeout(function () {
      ($(".scrollbar-inner") as any).scrollbar();
      $("#PrivacyPolicyForm").validate({
        rules: {
          is_accept_privacy_and_policy: "required",
        },
      });
    }, 100);
  }, []);

  function updateIsAcceptPrivacyAndPolicy(event: any) {
    event.preventDefault();
    if ($("#PrivacyPolicyForm").valid()) {
      API.post("accept-terms-and-conditions", {
        is_accept_privacy_and_policy: 1,
      }).then((res: AxiosResponse) => {
        if (res.data.message) {
          //successNotification(res.data.message);
        }
        ($("#term-and-condition") as any).modal("hide");
      });
    }
  }

  return (
    <div className="modal customModal fade" id="term-and-condition">
      <div className="modal-dialog  modal-dialog-centered modal-bigger2">
        <div className="modal-content policy-popup-modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              style={{ textAlign: "right" }}
            >
              ×
            </button>
          </div>
          <div className="row policy-popup-row">
            {props.aftre_login == 1 && (
              <FormLayout
                id="PrivacyPolicyForm"
                autoComplete={"off"}
                onSubmit={updateIsAcceptPrivacyAndPolicy}
              >
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-12 policy-popup-form">
                  <div className="modal-body policy-popup-modal-body">
                    <div className="headArea text-center">
                      {/*<img src="theme/images/vectorPrivacy.png" alt="" title=""/>*/}
                      <div className="NewTitle">
                        <h4>
                          <span> {Lang.vt_trans_lang_99}</span>
                        </h4>
                      </div>
                    </div>
                    <div className="scrollbar-inner termsScroll policy-popup-termsscroll">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: termsAndCondition.html,
                        }}
                      />
                    </div>

                    <div className="custom-control form-group custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="acceptTermAndCondition"
                        name="is_accept_privacy_and_policy"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="acceptTermAndCondition"
                      >
                        {Lang.vt_trans_lang_93_condition_text}
                      </label>
                    </div>
                  </div>
                  <div className="button mt-5 text-center sign_up_form_page">
                    <Submit
                      className="btn btn-md btn-green hvr-rectangle-out"
                      label={Lang.vt_trans_lang_92_accept}
                    />
                  </div>
                </div>
              </FormLayout>
            )}
            {props.aftre_login == 0 && (
              <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-12 policy-popup-form">
                <div className="modal-body policy-popup-modal-body">
                  <div className="headArea text-center">
                    {/*<img src="theme/images/vectorPrivacy.png" alt="" title=""/>*/}
                    <div className="NewTitle">
                      <h4>
                        <span>{Lang.term_and_condition}</span>
                      </h4>
                    </div>
                  </div>
                  {/*<div className="scrollbar-inner termsScroll">*/}
                  <div className="scrollbar-inner policy-popup-termsscroll">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: termsAndCondition.html,
                      }}
                    />
                  </div>
                </div>
                <div className="button text-center sign_up_form_page accept-policy-button">
                  <Submit
                    className="btn btn-md btn-green hvr-rectangle-out"
                    data-dismiss="modal"
                    label={Lang.accept_ok}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

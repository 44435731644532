import React, { useEffect, useRef } from "react";
import { Options } from "select2";
import _ from "lodash";
declare let window: any;

export interface Select2Props
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  data: Options;
}

export function Select2Wrapper(props: Select2Props) {
  const ele = useRef(null);
  useEffect(() => {
    $("select").on("select2:open", function (e) {
      ($(".select2-results__options") as any)
        .scrollbar()
        .parent()
        .addClass("scrollbar-inner");
    });
    let $el = ele.current;
    if ($el !== null) {
      let $els = $($el);
      $els
        .select2(props.data !== undefined ? props.data : {})
        .on("select2:select", (event: any) => {
          if (props.onChange !== undefined) props.onChange(event);
        });
      if (props.value !== undefined) {
        $els.val(props.value).trigger("change.select2");
      }
    }
  }, [props.data.data, props.value]);

  return (
    <div>
      <select
        {...props}
        ref={ele}
        {..._.omit(props, ["data", "ref", "onChange"])}
      >
        {props.children}
      </select>
    </div>
  );
}

export function LanguageFlags(state: any) {
  if (!state.id || state.country === null) {
    return state.text;
  }
  return $(
    '<span><img src="' +
      window.PUBLIC_URL +
      "/flags/" +
      state.country.iso.toLowerCase() +
      '.png" class="img-flag" /> ' +
      state.text +
      "</span>"
  );
}

export function CountryFlags(state: any) {
  if (!state.id) {
    return state.text;
  }
  return $(
    '<span><img src="' +
      window.PUBLIC_URL +
      "/flags/" +
      state.iso.toLowerCase() +
      '.png" class="img-flag" /> ' +
      state.text +
      "</span>"
  );
}

import React, {useRef, useEffect} from 'react';
import _ from 'lodash';
import {isMobile} from "react-device-detect";
import {isEmpty} from "rxjs/operators";
require("bootstrap-timepicker/js/bootstrap-timepicker.min.js");
require("bootstrap-timepicker/css/bootstrap-timepicker.min.css");


export const timePicker = { minuteStep: 5,maxHours: 24,showMeridian: false, defaultTime: 'current', icons: {up: 'fa fa-chevron-up',down: 'fa fa-chevron-down'}};

export interface TimePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: any
}


export function TimePickerWrapper (props: TimePickerProps) {

  const datetime_picker = useRef(null);

  let newOptions = {...timePicker, ...props.options};

  useEffect(() => {
    ($('.bootstrap-timepicker-hour')as any).prop('readonly',true);
    ($('.bootstrap-timepicker-minute')as any).prop('readonly',true);
    const $el = datetime_picker.current;
    if($el !== null ) {
      ($($el) as any).off('changeTime.timepicker').timepicker(newOptions).on('changeTime.timepicker', (e: any) => {
        if(props.onChange !== undefined) {
          let valOfMinute = $('.bootstrap-timepicker-minute').val();
          if(_.isEmpty(e.time.minutes) && _.isEmpty(valOfMinute)) {
            $('.bootstrap-timepicker-minute').val('00');
          }

          let valOfHours = $('.bootstrap-timepicker-hour').val();
          if(_.isEmpty(e.time.hours) && _.isEmpty(valOfHours)) {
            $('.bootstrap-timepicker-hour').val('00');
          }
          $('.bootstrap-timepicker-minute').val();
          props.onChange(e);
        }
      });
    }

    //Add JQuery to prevent to type user.
    $('body').on('keyup', '.bootstrap-timepicker-hour,.bootstrap-timepicker-minute',function (keyUpEvent:any) {
      if(isNaN(keyUpEvent.target.value)){
        $(this).val('');
      }
    });
  });

  return(
    <input readOnly={true} type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref', 'onChange','onClick'])} />
  )
}
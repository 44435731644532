import React from 'react';
import {Link} from "react-router-dom";
import { RouteComponentProps} from "react-router";
import {LoginForm} from "./form";
import Lang from "./common/lang/Lang";
import {WebAppDownloadModal} from "./partial/WebAppDownloadModal";
import {isIPad13, isMobile} from "react-device-detect";
import {AppDownloadModal} from "./partial/AppDownloadModal";
import {FormLayout} from "./common";
declare let window: any;

export function LoginPage (props: RouteComponentProps) {
  return (
    <div className="login_page">
      <WebAppDownloadModal/>
      { (isMobile || isIPad13)  &&
      <AppDownloadModal/>
      }
      <div className="container-fluid">
        <div className="row FormSite">
          <div className="col-lg-6 nopadding ">
            <div id="tile_1">
              <div className="leftbox front hidden-xs">
                {props.location.pathname === '/apply' &&
                  <div className="logo"> <Link to={'#'}><img src={`${window.PUBLIC_URL}/theme/images/login_logo.png`} alt={'logo'}/> </Link></div>
                }
                <div className="centerbox">
                  <div className="boxin">
                    <h2>{Lang.vt_trans_lang_19}</h2>
                    <p>{Lang.vt_trans_lang_36_login_entertext} <br/>{Lang.vt_trans_lang_37_login_des}<br/>{Lang.vt_trans_lang_38_login_des1}</p>
                    <button className="hvr-sweep-to-right flip_button btn btn-lg btn-white hvr-rectangle-out flip_button" type="button" onClick={() => {props.history.push('apply')}}>{Lang.vt_trans_lang_20_signup}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 nopadding">
            <div id="tile_2">
              <div className="rightbox front" id="tile_login">
                <div className="logo_mobile hidden-sm hidden-md hidden-lg"> <Link to="#"><img src={`${window.PUBLIC_URL}/theme/images/logo.png`} alt={'logo'}/></Link> </div>
                <div className="centerbox">
                  <div className="boxin">
                    <h1>{Lang.vt_trans_lang_21_signin}</h1>
                    <LoginForm {...props}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from "react";

export const En = {
  info: "Info",
  success: "Success",
  warning: "Warning",
  error: "Error",
  subject: "Subject",
  message: "Message",
  Lets_talk_about: "Let’s talk about",
  everything: "everything",
  the_phone_is_open: "The phone is open 24-7",
  submit: "Submit",
  search: "Search",
  reset: "Reset",
  finish: "Finish",
  active_confirm: "Are you sure that you want to active records ?",
  inactive_confirm: "Are you sure that you want to inactive records ?",
  delete_confirm: "Are you sure that you want to delete record ?",
  online_test_confirm: "Are you sure that you want to submit your test ?",
  online_test_confirm_text_first:
    "By checking this box, I confirm the above statement about my language skills.",
  online_test_confirm_text_second:
    "I do not possess or have any doubt that I possess the above language skills in Danish and / or the foreign language.",
  online_test_confirm_error:
    "You need to select one of the two buttons that best describes your language skills.",
  edit: "Edit",
  no_record_found: "No record found",
  page_not_found: "Page not found",
  error_response: "Ooops... Something went wrong. Please try again.",
  no_internet_access: "No internet access",
  please_wait: "Please Wait...",
  is_sure: "Are you sure ?",
  yes: "Yes",
  no: "No",
  add_language: "Flere sprog",

  excel: "Excel",
  csv: "CSV",
  export: "Export",
  active: "Activate",
  deactivate: "Deactivate",
  select: "Select",
  inactive: "Inactive",
  choose_fields: "Choose Fields",
  Fields: "Fields",
  admin_users: "Admin users",
  add_new: "New Record",
  customers: "Customers",
  create: "Create",
  users: "Users",
  add_customer: "Add New Customer",
  name: "Name",
  email: "Email",
  email_address: "Email address",
  iban: "IBAN",
  swift: "SWIFT",
  iban_placeholder: "Enter IBAN",
  bank_name: "Bank Name",
  bank_name_placeholder: "Enter Bank Name",
  account_holder_name: "Account Holder Name",
  account_holder_name_placeholder: "Enter Account Holder Name",
  password: "Password",
  email_placeholder: "Enter Email",
  country_of_origin: "Origin Country",
  country_of_origin_placeholder: "Select Origin Country",
  country_placeholder: "Select Country",
  name_placeholder: "Enter Name",
  current_password: "Current Password",
  change_password: "Current Password",
  new_password: "New Password",
  password_placeholder: "Enter Password",
  cn_password: "Confirm Password",
  cn_password_placeholder: "Enter Password Again",
  success_change_password: "Password has been changed successfully.",
  cpassword_not_match: "Current password does not match with our record.",
  address: "Address",
  address_placeholder: "Enter Address",
  zip_code: "Post Number",
  zip_code_placeholder: "Enter Post Number",
  city: "City",
  city_placeholder: "Enter City",
  mobile: "Mobile No.",
  mobile_placeholder: "Enter Mobile No.",
  contact_person: "Contact Person",
  contact_person_placeholder: "Enter Contact Person Name",
  contact_phone: "Contact Person Mobile No.",
  contact_phone_placeholder: "Enter Contact Person Mobile No.",
  ean_number: "EAN No.",
  ean_number_placeholder: "Enter EAN No.",
  cpr_number: "Social ID.",
  cpr_number_placeholder: "Enter Social ID",
  identification_code: "Employee ID",
  identification_code_placeholder: "Enter Employee ID",

  //customer
  department: "Department",
  department_unit: "Department Unit",
  department_id_placeholder: "Enter Department",
  department_unit_id_placeholder: "Enter Department Unit",
  company_id_placeholder: "Enter Company Name",
  select_dept: "Select Department",
  select_dept_unit: "Select Department Unit",
  customer_info: "Customer Info",
  send_login_d_btn: "Send Login Details",
  total_order: "Total Orders",
  total_earning: "Total Earning",
  active_order: "Active Orders",
  pending_order: "Pending Orders",
  create_order: "Create New Order",
  edit_customer: "Edit Customer Info",

  //orders
  order: "Orders",
  job_information: "Order Information",
  distance: "Distance",
  minutes: "Minutes",
  travel_time: "Travel Time",
  note_on_map: "Time and Distance shown one sided only",
  translation_type: "Translation Type",
  translation_type_placeholder: "Select Translation Type",
  attach_document: "Attach Document",
  tel_message: "Message",
  tel_message_placeholder:
    "Please enter your message in the box below and end with the recipient's name and telephone no",
  from_language: "Language From",
  to_language: "Language To",
  language_placeholder: "Choose Language",
  date: "Date",
  date_placeholder: "Choose Date",
  order_from: "Order From",
  order_from_placeholder: "Choose Order From Time",
  order_to: "Order To",
  order_to_placeholder: "Choose Order To Time",
  call_time_should_be_greater: "Call time should be greater then from time",
  client_name: "Client Name",
  client_name_placeholder: "Enter Client Name",
  client_cpr: "Client CPR No.",
  client_cpr_placeholder: "Enter Client CPR No.",
  employee_id: "Employee ID.",
  employee_id_placeholder: "Enter Employee ID",
  comment_for_translator: "Comment For Translator",
  comment_for_translator_placeholder: "Enter Comment For Translator",
  billing_information: "Billing Information",
  choose_file: "Choose file",
  contact_email: "Contact Person Email",
  contact_email_2: "Second Contact Person Email",
  contact_name_2: "Second Contact Person Name",
  contact_phone_2: "Second Contact Person Phone",
  contact_email_placeholder: "Enter Contact Person Email",
  contact_email_2_placeholder: "Enter Second Contact Person Email",
  second_contact_person: "Second Contact Person",
  forgot_password_content:
    "Please enter the email address associated with your account so we can send you a magic link to reset your password by typing a new one.",
  do_you_remember_password: "Do you remember your password?",
  try_logging: "Try logging in",
  sign_language_translation: "Sign language",
  order_detail_in_rejectcase: "Sorry, you have already rejected this order.",
  doc_upload_title: "Attach the translated documents",

  //activity logs
  activity_logs: "Activity Logs",

  //email templates
  email_templates: "Email Templates",

  //Order Section
  orders: "Orders",
  order_name: "Order",
  not_assigned: "Not assigned",
  firm_institution: "Firm/Institution",
  signature: "Signature",
  clear: "Clear",
  pending: "Pending",
  completed: "Completed",
  order_history: "Order History",
  paid: "Paid",
  under_qa: "Under QA",
  complete: "Complete",
  time_request: "Time Requested",
  activity_type: "Activity type",
  you_dont_have_permision: "You don't have permission to access this page.",
  comment: "Comment",
  new_invitation: "New Invitation",
  browse: "Browse",
  translated_document: "Translated Document",
  translation_of_phone_msg: "Translation of phone message",
  order_detail: "Order Detail",
  preference: "Preference",
  download_document: "Download Document",
  translator_download_document: "Translated Document",
  customer_download_document: "Customer Document",
  translator: "Translator",
  vitolker_translator: "ViTolker Translator",
  customer: "Customer",
  contact_first: "Contact First",
  contact_second: "Contact Second",
  create_at: "Create at",
  addition_info: "Additional Address Info",
  order_feedback: "Order Feedback",
  rating: "Rating",
  e_signature: "e-Signature",
  call_duration: "Total call duration",

  //Claim Section
  claims: "Claims",
  claim_created: "Claim has been successfully created.",
  claim_rejected: "Claim has been successfully rejected.",
  claim_resolved: "Claim has been successfully resolved.",
  add_new_claim: "Add New Claim",
  claim_subject: "Subject",
  claim_reason: "Reason",
  claim_status_resolved: "Resolved",
  claim_status_new: "New",
  claim_status_reject: "Reject",
  claim_status_admin_replaed: "Admin Replied",
  claim_creator_type_admin: "Admin",
  claim_creator_type_customer: "Customer",
  claim_creator_type_translator: "Translator",
  claim_detail: "Detail",
  is_claim_reject: "Are you sure want to reject this claim ?",
  is_claim_resolve: "Are you sure claim has resolve ?",

  //Registration Section
  requiredValidator: "This field is required.",
  remoteValidator: "Please fix this field.",
  emailValidator: "Please enter a valid email address.",
  urlValidator: "Please enter a valid URL.",
  dateValidator: "Please enter a valid date.",
  dateISOValidator: "Please enter a valid date (ISO).",
  numberValidator: "Please enter a valid number.",
  digitsValidator: "Please enter only digits.",
  creditcardValidator: "Please enter a valid credit card number.",
  equalToValidator: "Please enter the same value again.",
  acceptValidator: "Please enter a value with a valid extension.",
  maxlengthValidator: "Please enter no more than {0} characters.",
  minlengthValidator: "Please enter at least {0} characters.",
  rangelengthValidator: "Please enter a value between {0} and {1} characters long.",
  rangeValidator: "Please enter a value between {0} and {1}.",
  maxValidator: "Please enter a value less than or equal to {0}.",
  minValidator: "Please enter a value greater than or equal to {0}.",
  companyTypeValidator: "Company type is required.",
  lettersAndSpaceValidator: "Please use only letters & space",
  public: "Public",
  private: "Private",
  company_type: "Company Type",
  create_account: "Create Account",
  source_lang: "Source Language",
  target_lang: "Target Language",
  select_source_lang: "Select Source Language",
  select_target_lang: "Select Target Language",
  next_button: "Next",
  back_button: "Back",
  previous_button: "Previous",
  term_and_condition: "Terms & Conditions",
  term_and_condition_text: "I have read and accept the Terms of Use and Privacy and Policy",
  click_to_accept: "Click To Accept",
  get_it_for_free: "Get it for free",
  choose_your_native: "Choose your native platform and",
  download_app_free: "download the app free!",
  app_store: "App Store",
  google_play: "Google Play",
  vitolker_with_love: "Vitolker - with love for language.",
  privacy_policy: "Privacy Policy",
  we_love_data_regulation: "We love the Personal Data Regulation",
  find_us_on: "Find us on",
  all_rights_reserved: "All rights reserved ViTolker",
  try_our_app: "For better video call experience, please use our mobile application",
  download_now: "Download Now",
  gender_heading: "Preferably the interpreter gender",
  gender_title: "Gender",
  male: "Male",
  female: "Female",
  no_sig: "No significance",
  registration_success: "Your account has been created successfully.",
  verification_number: "Police verification number",
  verification_number_placeholder: "Enter Verification Number",
  driver_license: "Do you have driving license?",
  driver_license_placeholder: "Enter driver's license",
  social_security_no: "Social security no.",
  social_placeholder: "Enter social security no.",
  vehicle: "Do you have your own vehicle?",
  vehicle_placeholder: "Enter vehicle",
  certified: "Certified",
  certified_placeholder: "Enter certified",
  police_confirmed: "Are you verified by police?",
  police_conf_placeholder: "Enter police confirmed",
  push_agreement: "Push Agreement",
  basic_info_page_title: "Basic Info",
  bank_info_page_title: "Bank Info",
  availability_page_title: "Availability Settings",
  document_page_title: "Document Upload",
  basic_info_success: "Your basic info has been saved successfully.",
  bank_info_success: "Your bank info has been saved successfully.",
  bank_reg_no: "Bank reg. no.",
  bank_reg_placeholder: "Enter bank reg. no.",
  bank_account_no: "Bank account no",
  bank_accno_placeholder: "Enter bank account no",
  resume: "Resume",
  document_upload_success: "Your documents has been uploaded successfully.",
  profile_image_success: "Profile image has been uploaded successfully.",
  children_certificate: "Children Certificate",
  penalty_certificate: "Penalty Certificate",
  contract_sign_success: "Contract has been signed successfully.",
  contract_sign_error: "Please sign above",
  updt_bank_info_title: "Fill your bank information",
  updt_basic_info_title: "Fill your basic information",
  otp_sent_msg: "Please check your mobile phone.",
  otp_expire: "Sorry, your OTP has been expired.",
  verify_translator_phone: "Verify Translator Phone Number",
  email_already_register: "This email address already exists in our database.",
  invalid_address: "This is not a valid address, try being more specific.",
  mobile_already_register: "This mobile no is already exists in our database.",
  valid_name: "Please enter a valid name.",
  valid_mobile_no: "Please enter a valid mobile number",
  valid_email: "Please enter a valid email address.",
  strong_password_text: "Please use with lowercase & uppercase & digit & Special Char.",
  fromlang_valid: "Source & Target language can’t be same.",
  welcome_back: "Welcome Back",
  welcome_back_desc1: "To Keep Connected With us Please",
  welcome_back_desc2: "Login With Your Personal Info",
  welcome_translator_portal: "Welcome to we-translate Translator Portal",
  check_your_mail:
    "You are one step away to be a verified translator at ViTolker, please check you mailbox to complete the registration process.",
  thanks_email_verified_text: "Thank you, your email address has been verified successfully.",
  already_email_verified: "Your email address has been already verified.",
  email_link_expire: "Sorry, your email address link has been expired.",
  first_verify_email: "Sorry, first you have to verify your email address.",
  login: "Login",
  login_vitolker: "Login - Vitolker Translator",
  sign_in: "Sign In",
  sign_up: "Sign Up",
  sign_up_vitolker: "Sign Up - Vitolker Translator",
  hello_friend: "Hello Friend",
  enter_your_detail_text: "Enter Your Personal Details",
  start_journey: "and Start Journey With Us",
  sign_in_vitolker: "Sign in to ViTolker",
  forgot_password_title: "Forgot Password",
  reset_password_title: "Reset Password",
  reset_password_heading: "Please reset your old password by typing a new one.",
  dont_have_account: "Don't have an account?",
  remember_me: "Remember me",
  email_does_not_match: "Email does not exists in our records.",
  verify: "Verify",
  push_agreement_info:
    "By enabling this feature you are giving permission to ViTolker to assign translation jobs directly to you.",
  choose_a_file: "Choose a file",
  //source_target_not_same: "Source and Target language can’t be same.",

  // Translator Profile
  profile: "Profile",
  profile_under_review: "Your profile is under review.",
  profile_under_review_content:
    "What would be next: Our admin executive will be reviewing your profile and may contact for more information. If neccessary, administrator would be asking for online test or/and can ask to sign contrat with ViTolker in some cases.",
  contract_send: "Contract has been send to you.",
  contract_content:
    "Welcome to ViTolker. It is with pleasure that you are now part of ViTolker, this contract is signed with digital signature.",
  contract: "Contract",
  online_test_assigned: "An online test has been assigned to you",
  online_test_assigned_content: "Please complete the online test to share your knowledge with us.",
  click_here: "Click Here",
  already_email_change_req: "You have already submitted a request with this email address.",
  language_title_on_profile: "Language Settings",
  translation_service_title: "Select a translation services?",
  do_you_sign_translation: "Do you do sign language translation?",
  document_title: "Documents",
  unverified: "Unverified",
  review_title: "Review",
  rejected: "Rejected",
  current_location: "Current Location",
  jobs_completed: "Jobs Completed",
  hrs: "hrs",
  certificate: "Certificate",
  working_hours: "Working hours",
  verifying_email_address: "Please check your email for verifying your email address.",
  profile_pic: "Profile Pic",
  upload: "Upload",
  add_date: "Add Date",
  enter_hours_placeholder: "Enter hours",

  //Translator Dashboard
  new_task_invitations: "New Invitations",
  my_task: "All Orders",
  complete_task: "Completed Orders",
  awaiting_invoice_amount: "Awaiting Invoice Amount",
  complete_invoice_amount: "Completed Invoice Amount",
  order_statistics: "Order Statistics",
  view_all: "View All",
  physical_translation: "Physical Translation",
  physical_translation_description: "Physical Translation",
  video_translation: "Video Translation",
  video_translation_description: "Video Translation",
  telephone_translation: "Telephone Translation",
  telephone_translation_description: "Telephone Translation",
  telephone_translation_msg: "Telephone Translation Message",
  telephone_besked_description: "Telefonbesked tolkning",
  telephone_translated_msg: "Telephone Translated Message",
  sign_translation: "Sign Language Translation",
  document_translation: "Document Translation",
  document_translation_description: "Document Translation",
  new_assignment: "New Assignment",
  my_assignment: "My Assignment",
  latest_assignment: "Latest Assignment",
  my_account: "My Account",
  order_list: "Order list",
  order_no: "Order No",
  assigned: "Assigned",
  cancelled: "Cancelled",
  ok: "OK",
  accept_ok: "Acceptere",
  cancel: "Cancel",
  overdue: "Overdue",
  order_type: "Type of Order",
  company_name: "Company Name",
  language: "Language",
  action: "Action",
  view: "View document",
  route: "Route",
  time: "Time",
  customer_type: "Customer Type",
  employee_id_number: "Employee ID Number",
  identification_code_translator: "Identification Code",
  type_interpretation: "Type Interpretation",
  company_institution: "Company / Institution",
  preferably_the_gender: "Preferably the gender of the interpreter",
  meeting_address: "Meeting Address",
  comments_interpreter: "Comments on the interpreter",
  comments_interpreter_toll_booth: "Comments to the toll booth",
  notes_office: "Notes for the office",
  history: "History",
  order_correction: "Order Correction",
  dashboard: "Kontrolpanel",
  accept_assignment_confirmation: "Are you sure you want to accept this assignment ?",
  accept_assignment: "You have successfully accepted this assignment.",
  compelete_assignment_confirmation: "Are you sure you want to complete this assignment ?",
  reject_assignment_confirmation: "Are you sure you want to reject this assignment ?",
  reject_assignment: "You have successfully rejected this assignment.",
  translation_service_update: "your translation services has been successfully updated.",
  translation_service_confirm: "Are you sure you want to update translation services?",
  atleastOneTranslation: "Please select atleast one translation type.",
  language_delete_confirm: "Are you sure you want to delete this language ?",
  language_delete_success: "Language has been deleted successfully.",
  language_add_success: "Language has been added successfully.",
  order_complete_success: "Order has been completed successfully.",
  already_accepted_someone: "Sorry, someone has accepted this assignment.",
  order_invitation_title: "Order Invitation",
  accept: "Accept",
  reject: "Reject",
  see_more: "See More",
  request_for_correction: "Request For Correction",
  order_cancel: "Order Cancel",
  cancel_date_and_time: "Cancel date and time",
  cancel_message: "Message",
  cancel_reason: "Reason",
  amount: "Amount",
  order_status: "Order Status",
  payout: "Payout",
  my_orders: "My Orders",
  all_orders: "All Orders",
  passwordStrength: "Password strength",
  pwcheck: "Please use with lowercase & uppercase & digit & Special Characters.",
  pwHint: "Password Hint: David@123",
  status: "Status",

  // add language
  add_language_headline: "Would you like to add more languages?",
  add_language_title: "Add Language",
  from_accent: "From Accent Language",
  from: "From",
  to: "To",
  to_accent: "To Accent Language",
  from_accent_placeholder: "Enter From Accent Language",
  to_accent_placeholder: "Enter To Accent Language",
  upload_certificate: "Upload Certificate",
  level: "Level",
  select_level: "Select Level",
  certification: "Certification",
  how_many_hours: "How many hours of experience you have in this language?",
  source_mother_tongue: "Is this mother tongue?",
  target_mother_tongue: "Is this mother tongue?",
  mother_tongue: "Mother tongue",
  notifications: "Notifications",
  ago: "ago",
  no_notification_found: "No notification found",

  // add leave
  add_leave_title: "Let us know your availability by adding the date when you are not servicing.",
  leave_type: "Leave Type",
  full_day: "Full Day",
  half_day: "Half Day",
  multiple: "Multiple",
  reason: "Reason",
  type_of_half_day: "Type Of Half Day",
  first_half: "First Half",
  second_half: "Second Half",
  reason_placeholder: "Reason for leave",
  from_date: "From Date",
  from_date_placeholder: "Enter From Date",
  to_date: "To Date",
  to_date_placeholder: "Enter To Date",
  no_data_found: "No record found",
  leave_added_success: "Unavailability setting has been added successfully.",

  // online test
  online_test_heading: "Online Test",
  online_quiz: "Online Quiz",
  quiz_start_text: "Quiz start nu",
  subjective: "Subjective",
  OBJECTIVE: "Objective",
  MULTI_CHOICE: "Multi Choice",
  thank_you_test: "Thank you",
  thanku_submit_online_test: "You have successfully submitted your test.",
  already_submit_online_test: "You have already submitted your test.",
  answer_question_validation: "Please answer this question.",
  question: "Question",
  your_answer: "Your Answers",
  // online_test_lang_heading: 'Jeg tilkendegiver, at jeg kan tolke på dansk samt følgende fremmedsprog,',
  online_test_lang_heading: "Jeg tilkendegiver, at jeg kan tolke på følgende sprogkombinationer:",
  online_test_lang_heading_end: "på et niveau, hvor jeg kan:",
  online_test_bullet_one:
    "udtrykke mig på en klar, præcis, letforståelig og modersmålslignende måde",
  online_test_bullet_second: "udtrykke mig spontant uden bemærkelsesværdig søgen efter ordene",
  online_test_bullet_third:
    "læse og forstå selv abstrakte tekster, der indeholder svære ord og sætningsstrukturer, såsom manualer og fagtekster.",
  error_401_message:
    "Whoops! Session has been expired due to, you have recently logged in, in another device.",
  error_403_message: "You don't have permission for this action.",
  error_404_message: "Page not found.",
  error_419_message: "Invalid request",
  // menu text
  we_learn: "We Learn",
  complete_order: "Complete Order",
  invitation: "Invitations",
  payment: "Payment",
  my_profile: "My Profile",
  contact_us: "Contact Us",
  logout: "Logout",
  we_learn_heading_first: "Our Great Translators Provide",
  we_learn_heading_second: "For",
  we_learn_heading_third: "Services",
  read_more: "Read More",
  weak_password_text:
    "Please use both letters and numbers, and make sure to enter at least 8 characters",

  // edit phone number
  edit_phone_number_title: "Edit Translator Phone Number",
  enter_otp_number: "Enter OTP Number",

  // notifications
  notificationPageTitle: "Notifications",
  viewMore: "View More",
  notification: "Notification",
  na: "N/A",
  invalid_file: "Invalid file",

  vt_trans_lang_1_full_name: "Enter Name",
  vt_trans_lang_2_email: "E-mail",
  vt_trans_lang_3_mobile_number: "Enter Mobile No.",
  vt_trans_lang_4_verify_phone_number: "Verify Translator Phone Number",
  vt_trans_lang_5_enter_otp_number: "Enter OTP Number",
  vt_trans_lang_6_send_otp: "Send OTP",
  vt_trans_lang_7_resend_otp: "Resend OTP",
  vt_trans_lang_8_to_language: "Select Target Language",
  vt_trans_lang_9_gender_heading: "Preferably the interpreter gender",
  vt_trans_lang_10_password: "Enter Password",
  vt_trans_lang_11_cn_password: "Gentag adgangskode",
  vt_trans_lang_12_address: "Enter adresse",
  vt_trans_lang_13_backbutton: "Back",
  vt_trans_lang_14_sendbutton: "Send",
  vt_trans_lang_15_termcondition_errormsg:
    "Please check this box if you accept our terms and conditions",
  vt_trans_lang_16_welcome_text: "Welcome to we-translate Translator Portal",
  vt_trans_lang_17_checkmail_text:
    "Please check your email inbox to complete the registration process.",
  vt_trans_lang_17_checkmail_text_2:
    "You have received an email with a link where you need to click to verify your email.",
  vt_trans_lang_18_thanks_emailverified:
    "Welcome to ViTolker`s translation and interpreting portal",
  vt_trans_lang_19: "Become a part of ViTolker",
  vt_trans_lang_20_signup: "APPLY HERE",
  vt_trans_lang_21_signin: "Log in as a language consultant",
  vt_trans_lang_22_email: "E-mail",
  vt_trans_lang_23_password: "Password",
  vt_trans_lang_24_forgot_password: "Forgot password?",
  vt_trans_lang_25_remember_me: "Stay signed in",
  vt_trans_lang_26_loginbutton: "sign in",
  vt_trans_lang_27_account: "Don't have access to ViTolker's language consulting platform yet?",
  vt_trans_lang_28_button: "Apply here",
  vt_trans_lang_29_welcome: "Already a part of ViTolker?",
  vt_trans_lang_30_welcome_des1: "Log ind på din sprogkonsulent platform, og få",
  vt_trans_lang_31_welcome_des2: "adgang til ViTolkers spændende sprogopgaver.",
  vt_trans_lang_32_create_account: "Become an interpreter",
  vt_trans_lang_33_pwcheck:
    "Please use both lowercase (abcde) and upper case letters (ABCDE), numbers (123456) and special characters (@! / $ / =? +)",
  vt_trans_lang_34_popup_heading: "DOWNLOAD OUR FREE APP NOW",
  vt_trans_lang_35_popup_des1: "Access a world of language opportunities.",
  vt_trans_lang_36_login_entertext: "We are always looking for new talented colleagues there",
  vt_trans_lang_37_login_des: "can help us deliver quality conscious",
  vt_trans_lang_38_login_des1: "language services worldwide.",
  vt_trans_lang_39_policyText: "Terms and Conditions",
  vt_trans_lang_40_findUson: "Download our app!",
  vt_trans_lang_41_forgotpw_title: "Reset your password",
  vt_trans_lang_42_forgotpw_des:
    "Enter the email address associated with your account. You will then receive a link where you can reset the password.",
  vt_trans_lang_43_example: "For example. abc@vitolker.dk",
  vt_trans_lang_44_forgotbutton: "Send",
  vt_trans_lang_45_Resetpw_title: "Reset password",
  vt_trans_lang_46_Resetpw_placeholder: "********",
  vt_trans_lang_47_Resetpw_placeholder1: "********",
  vt_trans_lang_48_documentBackbutton: "Back",
  vt_trans_lang_49_okbutton: "Yes",
  vt_trans_lang_50_cancelbutton: "Not afterall",
  vt_trans_lang_51_invationDate: "Meeting date and time",
  vt_trans_lang_52_invationtranslator: "language Consultant",
  vt_trans_lang_53_Institution: "Institution",
  vt_trans_lang_54_invationCustomer: "Contact person",
  vt_trans_lang_55_invationContact: "Customer contact information",
  vt_trans_lang_56_invationdate: "created",
  vt_trans_lang_57_telephoneMSG: "The message to be translated to the recipient",
  vt_trans_lang_58_comment: "Comments",
  vt_trans_lang_59_submit: "Finish the task",
  vt_trans_lang_60_Completesubmit: "Quit",
  vt_trans_lang_61_order_history: "History",
  vt_trans_lang_62_selecttype: "Choose",
  vt_trans_lang_63_option: "See options",
  vt_trans_lang_64_registrer: "Registrer",
  vt_trans_lang_65_complete_task: "Completed tasks",
  vt_trans_lang_66_all_task: "All tasks",
  vt_trans_lang_67_new_task: "New tasks",
  vt_trans_lang_68_complete: "Complete",
  vt_trans_lang_69_passwordtitle: "For security reasons, change your password",
  vt_trans_lang_70_document_title: "Please attach the documents below (can be done later)",
  vt_trans_lang_71_termCondition_title: "Privacy and data protection policy",
  vt_trans_lang_72_enquiry_title: "You are always welcome to contact us!",
  vt_trans_lang_73_browse: "attach",
  vt_trans_lang_74_browse_tit: "Documents for ViTolker",
  vt_trans_lang_75_service_title: "What do you offer?",
  vt_trans_lang_76_title_onprofile: "Linguistic and professional competences",
  vt_trans_lang_77_addmore_language:
    "Do you have interpreting and / or translation skills in several languages?",
  vt_trans_lang_78_job_completed: "Completed tasks",
  vt_trans_lang_79_overview: "Overview",
  vt_trans_lang_80_latest_assigment: "Recent assignments",
  vt_trans_lang_81_view_All: "See all",
  vt_trans_lang_82_enquiry_msg: "Enter your inquiry here",
  vt_trans_lang_83_send: "Send",
  vt_trans_lang_84_current_pw: "Current password",
  vt_trans_lang_85_new_pw: "New password",
  vt_trans_lang_86_register: "Registrer",
  vt_trans_lang_87_verification: "What is your former state police interpreter number?",
  vt_trans_lang_88_back: "Back",
  vt_trans_lang_89_enquiry_email: "Enter the subject of your inquiry",
  vt_trans_lang_90_save: "Save",
  vt_trans_lang_91_document_success:
    "The enter information is successfully updated in your profile.",
  vt_trans_lang_92_accept: "Approve",
  vt_trans_lang_93_condition_text:
    "I have read and agree to the Terms of Use and Privacy and Privacy Policy",
  vt_trans_lang_94: "I have read and accept ViTolkers",
  vt_trans_lang_95: "Conditions",
  vt_trans_lang_96: "and",
  vt_trans_lang_97: "Privacy and Privacy Policy",
  vt_trans_lang_98: "Check the box below is mandatory",
  vt_trans_lang_99: "Privacy and Privacy Policy",
  vt_trans_lang_100_cnpw: "The password is not the same",
  vt_trans_lang_101_thank_you_registration: "Thank you very much for your application.",
  vt_trans_lang_102_spam_folder: "Check your spam folder to see if the email might be there.",

  vt_translator_lang_121_today: "Today",
  vt_translator_lang_122_month: "Month",
  vt_translator_lang_123_week: "Week",
  vt_translator_lang_124_day: "Day",

  wordMinLength: "Your password is too short",
  wordMaxLength: "Your password is too long",
  wordInvalidChar: "Your password contains an invalid character",
  wordNotEmail: "Do not use your email as your password",
  wordSimilarToUsername: "Your password cannot contain your username",
  wordTwoCharacterClasses: "Use different character classes",
  wordRepetitions: "Too many repetitions",
  wordSequences: "Your password contains sequences",
  errorList: "Errors:",
  veryWeak: "Very Weak",
  weak: "Weak",
  normal: "Normal",
  medium: "Medium",
  strong: "Strong",
  veryStrong: "Very Strong",

  wordIsACommonPassword: "",
  wordLetterNumberCharCombo: "",
  wordLetterNumberCombo: "",
  wordLowercase: "",
  wordOneNumber: "",
  wordOneSpecialChar: "",
  wordThreeNumbers: "",
  wordTwoSpecialChar: "",
  wordUpperLowerCombo: "",
  wordUppercase: "",
  account_active_h1_title: "Din e-mailadresse er nu bekræftet, og din profil er klar.",
  account_active_next_steps_1:
    "ViTolker vil derefter kontakte dig med henblik på en indledende samtale.",
  account_active_next_steps_2:
    "Du vil derefter blive bedt om at bestå en online-test for god tolkning og etisk praksis inden den endelige samtale.",
  account_active_next_steps_3:
    "Når du er færdig med din endelige samtale, sender vi dig din kontrakt til underskrift, og du bliver officielt en del af vores tolketeam.",

  vt_customer_lang_121_today: "Today",
  vt_customer_lang_122_month: "Month",
  vt_customer_lang_123_week: "Week",
  vt_customer_lang_124_day: "Day",
  select_type: "Vælg Type",
  about_yourself: "Beskrivelse af dig selv",
};

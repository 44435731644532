import {UserModel} from "../model";
import API, {base_url} from "../common/AxoisClient";
import {CallingServices} from "../services";
import { TranslatorModel } from "../model/Translator";

export interface UserInfo extends UserModel{
  scopes: Array<string>
  _token: string|null;
}

interface AuthSchema {
  isAuthenticated: boolean;
  userInfo: UserInfo;
  registrationStep: string| number;
  login_user_id: string| number;
  token: string| null;
  userData: UserAndTranslatorData;
  reAuthenticate: (token: any) =>  any;
  authenticate: (data: any, userInfo?: UserAndTranslatorData) => void;
  signout: () => void;
}

type UserAndTranslatorData = Partial<{ translatorData: TranslatorModel, userData: UserInfo }>

export const Auth : AuthSchema = {

  isAuthenticated: false,
  userInfo: {} as UserInfo,
  registrationStep: '',
  login_user_id: '',
  token: null,
  userData: {},
  authenticate( data: UserInfo, userInfo?: UserAndTranslatorData) {
    this.isAuthenticated = true;
    //localStorage.setItem('userInfo', JSON.stringify(data));
    let transStr = localStorageGetItem("registration_step");
    this.userInfo = data;
    this.registrationStep = transStr;
    this.login_user_id = localStorageGetItem("login_user_id");
    this.token = data._token;
    if (userInfo) this.userData = userInfo
    CallingServices.init();
  },

  async reAuthenticate(token: string) {
    this.token = token;
    const res = await API.get(`${base_url}re-authenticate`);
    const userInfo: UserAndTranslatorData = res.data ? (await API.get(`translator-data/${res.data.id}`)).data : {}
    if(res.data){
      Auth.authenticate(res.data, userInfo);
      this.token = token;
    }
    return res.data;
  },

  signout() {
    this.isAuthenticated = false;
    localStorage.setItem('userInfo', '');
    localStorage.setItem('translatorInfo', '');
    this.userInfo = {} as UserInfo;
    this.registrationStep = '';
    this.login_user_id = '';
    this.token = null;
  },

};

function localStorageHasItem(key: string): boolean{
  return localStorage.getItem(key) !== null
}
function localStorageGetItem(key: string): any{
  if(localStorageHasItem(key)){
    return localStorage.getItem(key);
  }
}

export const getUserInfo = (): UserInfo| boolean  => {
  /*let userStr: string = localStorageGetItem("userInfo");
  try {
    return JSON.parse(userStr);
  } catch (er) {
    return false;
  }*/
  return Auth.userInfo;

};
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {WeLearnModel} from "../model/WeLearn";
import {Link} from "react-router-dom";


export function WeLearnDetail (props:any) {

    const [weLearnDetail,  setWeLearnDetail] = useState({} as WeLearnModel);
    const [quizData,  setQuizData] = useState({quiz_id:0});
    const [getWeLearnStatus, setWeLearnStatus] = useState({we_learn_status: 0});

    useEffect(() => {
        document.title = Lang.we_learn+' - '+Lang.vitolker_translator;;
        API.post(`we-learn/we-learn-detail`, {we_learn_id:props.match.params.id}).then((res: AxiosResponse) => {
            setWeLearnDetail(res.data);
        });
        API.post(`we-learn/get-quiz-data`, {we_learn_id:props.match.params.id}).then((res: AxiosResponse) => {
            setQuizData({...quizData, quiz_id: res.data.quiz_data.id});
            setWeLearnStatus({...getWeLearnStatus, we_learn_status: res.data.answer_count});
        });
    },[]);

  return(

      <div className="bgMain">
        <div className="wrapper">
            <Header {...props}/>
            <section className="mainContent">
                <div className="container">
                    <div className="statisticsPage">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                <div className="boxShadow hover-vd hvr-grow-shadow boxwhiteShadow">
                                    <div className="profile_box for-vd">
                                        <video controls src={weLearnDetail.video} poster={weLearnDetail.video_thumbnail} width="100%" height='auto'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                <div className="info-vd">
                                    <h5>{weLearnDetail.title}</h5>
                                    <p>{weLearnDetail.description}</p>
                                </div>
                            </div>
                            { getWeLearnStatus.we_learn_status===0 &&
                                <div className="col-md-12 col-sm-12 text-center">
                                    <div className="online-test_btn">
                                        <Link to={`/quiz-start/${quizData.quiz_id}`}
                                              className="btn btn-green hvr-rectangle-out">{Lang.quiz_start_text} <i
                                            className="fas fa-angle-right"/></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
          <Footer/>
        </div>
      </div>
  )
}
import React, {useEffect, useState} from 'react';
import {Footer, Header} from "../partial";
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {Link} from "react-router-dom";
import {QuizModel} from "../model";


export function OnlineQuizDetail (props:any) {

    const [onlineQuizDetail,  setOnlineQuizDetail] = useState({} as QuizModel);
    const [onlineQuizData,  setOnlineQuizData] = useState({quiz_id:0});
    const [getOnlineQuizStatus, setOnlineQuizStatus] = useState({online_quiz_status: 0});


    useEffect(() => {
        document.title = Lang.online_quiz+' - '+Lang.vitolker_translator;;
        API.post(`online/online-quiz-detail`, {online_quiz_id:props.match.params.id}).then((res: AxiosResponse) => {
            setOnlineQuizDetail(res.data);
        });
        API.post(`online/get-online-quiz-data`, {online_quiz_id:props.match.params.id}).then((res: AxiosResponse) => {
            setOnlineQuizData({...onlineQuizData, quiz_id: res.data.quiz_data[0].id});
            setOnlineQuizStatus({...getOnlineQuizStatus, online_quiz_status: res.data.answer_count});
        });
    },[]);

  return(

      <div className="bgMain">
        <div className="wrapper">
          <Header {...props}/>
            <section className="mainContent">
                <div className="container">
                    <div className="statisticsPage">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                <div className="boxShadow hover-vd hvr-grow-shadow boxwhiteShadow">
                                    <div className="profile_box for-vd">
                                        <img src={onlineQuizDetail.image} style={{width:'515px',height:'343px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-6">
                                <div className="info-vd">
                                    <h5>{onlineQuizDetail.title}</h5>
                                    <p>{onlineQuizDetail.description}</p>
                                </div>
                            </div>
                            { getOnlineQuizStatus.online_quiz_status===0 &&
                                <div className="col-md-12 col-sm-12 text-center">
                                    <div className="online-test_btn">
                                        <Link to={`/online-quiz-start/${onlineQuizData.quiz_id}`}
                                              className="btn btn-green hvr-rectangle-out">{Lang.quiz_start_text} <i
                                            className="fas fa-angle-right"></i></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
          <Footer/>
        </div>
      </div>
  )
}
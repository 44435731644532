import React, {useEffect, useState, useRef} from 'react';
import API, {base_url, SwitchLoader} from "../common/AxoisClient";
import _ from "lodash";
import {OrderDataState, OrderSearchState} from "../dashboard/State";
import Lang from "../common/lang/Lang";
import {datePicker, DatetimePickerWrapper} from "../common/DatetimePicker";
import {getTranslationTypeList} from "../services";
import {Auth} from "../Auth/Auth";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {FormLayout, Icons, Submit} from "../common";
import {OrderStatus, OrderType} from "../model/Order";
import {MapInfoService, OrderInfoService} from "./Schema";
import {QuickLinks} from "../quick-links/QuickLinks";
import {NoDataFound} from "../no-data-found/NoDataFound";
import {LoaderType} from "../common/Loader";
import {Select2Wrapper} from "../common/Select2";
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import { renderToStaticMarkup } from 'react-dom/server';
import VideoLink from '../orders/components/VideoLink/VideoLink';
declare let window:any;
let DataTableObj:any;
let currentLangName:string = 'Danish'; //'Danish';
var v = 0;
//let moment = require('moment');

export function TranslatorFullCalender (props: any) {


  const [transOrderDetail, setTransOrderDetail]   =   useState(OrderDataState);
  const [first, setFirst]                         =   useState(false);
  const [search, setSearch]                       =   useState(OrderSearchState);
  const [translationTypes, setTranslationTypes]   =   useState();
  const [loader, setLoader]                       =   useState({ 'isLoader': 0 });

  function getOrderList(status:any,order_from:any,order_to:any,type:any){

    ($('#orderDataListings') as any).DataTable().destroy();

    ($.fn as any).dataTableExt.sErrMode = 'throw';

    DataTableObj = ($('#orderDataListings') as any).DataTable( {
      "processing": true,
      "serverSide": true,
      'responsive': true,
      "order": [[ 0, "desc" ]],
      lengthMenu: [
        [ 10, 15, 20, -1 ],
        [ '10', '15', '20', 'All' ]
      ],
      "ajax": {
        url: base_url+'api/translator/v1/orders/order-list',
        type: 'GET',
        beforeSend: function (request:any) {
          $('#orderDataListings').find('td:first').attr('colspan',0);
          request.setRequestHeader("Authorization", "Bearer "+Auth.token);
          request.setRequestHeader("RequestFrom", "web");
        },
        "data": {
          "status":status.toString(),
          "order_from":order_from,
          "order_to":order_to,
          "type":type
        },
        "complete": function() {
          let mainObj:any = $('.custom-rating');
          if(mainObj.length>0){
            mainObj.each(function (index:any,item:any) {
              let obj:any = $(item);
              let objRating = obj.attr('data-rating');
              obj.rateYo({
                rating:objRating,
                readOnly: true
              });
            })
          }
          $('#orderDataListings').off( 'click', '.showDetailsInfo').on('click', '.showDetailsInfo', function (event) {
            let data = DataTableObj.row( $(this).parents('tr') ).data();
            OrderInfoService.hide();
            OrderInfoService.showDetails(data.id);
          });
          $('#orderDataListings').off( 'click', '.hvr-push-map').on('click', '.hvr-push-map', function () {
            let data = DataTableObj.row( $(this).parents('tr') ).data();
            if(data.id != undefined){
              MapInfoService.showDetails(data.id);
            }
          });
          ($('[data-toggle="tooltip"]') as any).tooltip();
        }
      },
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/"+currentLangName+".json"
      },
      "columns": [
        {
          data: null, name: 'order_no', orderable: true, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            let content_order_type:string = '';
            if(!_.isEmpty(row.translation_type.title)) {
              content_order_type = row.translation_type.title;
            }

            if(!_.isEmpty(row.order_no)) {
              content += renderToStaticMarkup(
                <>
                  <span className="ServiceIconSat" data-toggle="tooltip" data-placement="bottom" data-original-title={content_order_type.replace(" ", "-")}><img src='+row.translation_type.small_icon+' style={{width:'18px'}}/></span> <a className="showDetailsInfo" href="#" data-toggle="modal" data-target="#exampleModal">#{row.order_no}</a><p style={{margin: 0}}>{content_order_type.replace(" ", "-")}</p>
                  { row.schedule_team_meeting ? <span data-toggle="tooltip" data-placement="bottom" title={!row.video_meeting_link ? "Der oprettes et link til et videomøde, når der er tildelt en oversætter" : "videolink genereret"}>(Microsoft meeting <i className={row.video_meeting_link ? Icons.link : Icons.clock}/>)</span> : null}
                </>
              )
            }
            return content;
          }
        },
        {
          data: null, name: 'company_name', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.company) && !_.isEmpty(row.company.name)) {
              content = row.company.name;
            }
            return content;
          }
        },
        {
          data: null, name: 'language', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.language_from) && !_.isEmpty(row.language_to) && row.is_dumb!=='1') {

              content = '<img src='+window.PUBLIC_URL +'/flags/'+row.language_from.country.iso.toLowerCase()+'.png /> ' +row.language_from.language_name+ '<span class="exchangeIcon"><i class="fas fa-exchange-alt"></i></span> <img src='+window.PUBLIC_URL +'/flags/'+row.language_to.country.iso.toLowerCase()+'.png /> '+row.language_to.language_name;

            } else if(row.is_dumb==='1'){

              content = '<img src='+window.PUBLIC_URL +'/flags/'+row.language_from.country.iso.toLowerCase()+'.png /> '+row.language_from.language_name+' <span class="exchangeIcon"><i class="fas fa-exchange-alt"></i></span> <img src='+window.PUBLIC_URL +'theme/images/sign-lang.png width="16"/> '+Lang.sign_language_translation;

            }
            return content;
          }
        },
        {
          data: null, name: 'status', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            let overdue:boolean = false;
            if(!_.isEmpty(row.status)) {
              status = row.status;
            }
            if(status==='3'){
              //overdue = moment(row.order_date).format('YYYY-MM-DD') < moment().format("YYYY-MM-DD");
              overdue = moment(row.order_date+' '+row.order_to) <= moment();
            }
            if(overdue === false){
              if(status==='3'){
                type = Lang.assigned;
                content = '<span class="btnStatusPrimary">'+type+'</span>';
              } else if(status==='4'){
                type = Lang.cancelled;
                content = '<span class="btnStatusDanger">'+type+'</span>';
              } else if(status==='5'){
                type = Lang.completed;
                content = '<span class="btnStatusSuccess">'+type+'</span>';
              } else if(status==='6'){
                //type = Lang.paid;
                type = 'Afsluttet';
                content = '<span class="btnStatusSuccess">'+type+'</span>';
              } else if(status==='7'){
                type = Lang.under_qa;
                content = '<span class="btnStatusWarning">'+type+'</span>';
              }
            } else {
              type = Lang.overdue;
              content = '<span class="btnStatusDanger">'+type+'</span>';
            }
            return content;
          }
        },
        {
          data: null, name: 'date', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            if(!_.isEmpty(row.order_date)) {
              let toTime = (row.status==OrderStatus.COMPLETED)?row.complete_order_to:row.order_to;
              if(row.translation_type_id == OrderType.DOCUMENT || row.translation_type_id == OrderType.PHONE_MESSAGE){

                content = moment(row.order_date).format('dddd');
                content +=  '<br/><span class="order-day-with-time">';
                content += moment(row.order_date).format('DD-MM-YYYY')+'<br/>';
                content +=  ' '+toTime+' ';

                content +=  '</span>';
              } else {
                content = moment(row.order_date).format('dddd');
                content +=  '<br/><span class="order-day-with-time">';
                content += moment(row.order_date).format('DD-MM-YYYY')+'<br/>';
                content +=  ' '+row.order_from;
                content +=  ' - '+toTime+' ';
                content +=  '</span>';
              }
            }
            return content;
          }
        },
        {
          data: null, name: 'view', orderable: false, searchable: false, "width": "2%", "render": function (data: any, type: any, row: any) {
            let content:string = '';
            let viewButton: string = '';
            let mapButton: string = '';
            if(!_.isEmpty(row.translation_type.title)) {
              content = row.translation_type.title;
            }
            return renderToStaticMarkup(
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {row.video_meeting_link && <VideoLink video_meeting_link={row.video_meeting_link!} />}
                <a className="btnStatusPrimary hvr-push showDetailsInfo" href="#" data-toggle="modal" data-target="#exampleModal"><i className="fas fa-eye"></i> {Lang.view}</a>
                { row.translation_type_id == 2 && (
                  <a className="btnStatusPrimary hvr-push-map" href="#" data-toggle="modal" data-target="#exampleModalMap"><i className="fas fa-map-marker-alt"></i> {Lang.route}</a>
                )}
              </div>
            )
            type = Lang.view;
            viewButton = '<a class="btnStatusPrimary hvr-push showDetailsInfo" href="#" data-toggle="modal" data-target="#exampleModal"><i class="fas fa-eye"></i> '+type+'</a>';
            if(row.translation_type_id==2){
              type = Lang.route;
              mapButton = '<a class="btnStatusPrimary hvr-push-map" href="#" data-toggle="modal" data-target="#exampleModalMap"><i class="fas fa-map-marker-alt"></i> '+type+'</a>';
            }
            return viewButton+mapButton;

          }
        },

      ],
      "initComplete": function(settings:any, json:any) {
        setLoader({...loader, isLoader: 1 });
        if($('#orderDataListings_wrapper').find('.dataTables_empty').is(':visible')){
          $('.no-record-found-in-list').removeClass('hide_me');
          $('#orderDataListings_wrapper').hide();
        } else {
          $('.no-record-found-in-list').addClass('hide_me');
        }
      }
    });
  }

  function handleCalClicks (info:any) {
    ($('#exampleModal') as any).modal({
      //backdrop: 'static',
      keyboard: true,
      show:true
    });
    //OrderInfoService.showDetails(info.event._def.publicId);
    OrderInfoService.hide();
    OrderInfoService.showDetails(info.event.id);

    /*setSearch(OrderSearchState); todo : 22-Jan-2019 order detail popup on click calender order no
    let date = moment.utc(info.event.start).local().format("Y-M-D");
    getOrderList([3, 4, 5, 6, 7],date,date,'');*/
  }

  function handleBlankDateClicks (info:any)  {
    setSearch(OrderSearchState);
    let date = moment.utc(info.date).local().format("Y-M-D");
    getOrderList([3, 4, 5, 6, 7],date,date,'');
  }

   function getMyAssignmentList (info:any, successCallback:any, failureCallback:any) {
     SwitchLoader(LoaderType.NONE);
     setTimeout(() => {
       $('.fc-prev-button, .fc-next-button, .fc-today-button').on('click', function () {
         setFirst(true);
       });

       if (first) {
         getOrderList([3, 4, 5, 6, 7], moment(info.start.valueOf()).format('Y-M-D'), moment(info.end.valueOf()).format('Y-M-D'), '');
         setFirst(false);
       }
     }, first ? 500 : 0);
     v++;
     //if(first == true || (search.type == '' && search.order_to == '' && search.order_from == '')){
     if(v%5 === 0){
       setFirst(false);
       return API.get(`orders/my-assignments-for-calender`,
           {
             params: {
               start: moment(info.start.valueOf()).format('Y-M-D'),
               end: moment(info.end.valueOf()).format('Y-M-D')
             }
           }).then(res => {
         return successCallback(res.data);
       });
     }
  }

  useEffect(() => {
    var v = 0;
    getOrderList([3, 4, 5, 6, 7],'','','');

    getTranslationTypeList().then(translationTypesData => {
      setTranslationTypes(translationTypesData);
    });
  },[props.assignment_data]);

  function formDataReset(event: any) {
    event.preventDefault();
    getOrderList([3, 4, 5, 6, 7],'','','');
    setSearch(OrderSearchState);
  }

  function handleChange(e:any) {
    SwitchLoader(LoaderType.NONE);
    const {name, value} = e.target;
    setSearch(prevSate => ({...prevSate, [name]: value}));
  }

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
    event.preventDefault();
      getOrderList([3, 4, 5, 6, 7], search.order_from, search.order_to, search.type);
  }


  return(
      <><div className="myOrderPage">
        {loader.isLoader == 0 &&
          <><div className="bg_load"/>
            <div className="wrap"/></>
        }
        <div id='calendar' className="boxWhite boxShadow">
          <FullCalendar defaultView="dayGridMonth"
                        plugins={[ dayGridPlugin, interactionPlugin  ]}
                        eventSources={[{
                          events: getMyAssignmentList,
                        }
                        ]}
                        buttonText={{
                          today: Lang.vt_translator_lang_121_today,
                          month: Lang.vt_translator_lang_122_month,
                          week: Lang.vt_translator_lang_123_week,
                          day: Lang.vt_translator_lang_124_day,
                        }}
                        locale={'da'}
                        firstDay={1}
                        editable={false}
                        /*buttonText={{
                          today: Lang.vt_customer_lang_121_today,
                          month: Lang.vt_customer_lang_122_month,
                          week: Lang.vt_customer_lang_123_week,
                          day: Lang.vt_customer_lang_124_day,
                        }}
                        locale={'da'}*/
                        eventLimit={2}
                        dateClick={(info:any) => handleBlankDateClicks(info)}
                        eventClick={(info:any) => handleCalClicks(info)}
          />
        </div>
      </div>
      <FormLayout id="search_order" onSubmit={handleSubmit}  autoComplete="off">
          <div className="row align-items-center mb-20">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="form-group md-form">
                <div className="example">
                  <div className="demo-section k-content">
                    <label>{Lang.from_date}</label>
                    <DatetimePickerWrapper className="servicesPicker" options={datePicker}
                                           name={'order_from'} placeholder={Lang.from_date_placeholder} value={search.order_from} onChange={handleChange}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="form-group md-form">
                <div className="example" >
                  <div className="demo-section k-content">
                    <label>{Lang.to_date}</label>
                    <DatetimePickerWrapper className="servicesPicker" options={datePicker}
                                           name={'order_to'} placeholder={Lang.to_date_placeholder} value={search.order_to} onChange={handleChange}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="form-group md-form">
                <label className="d-block">{Lang.order_type}</label>
                <Select2Wrapper  style={{width:'100%'}} onChange={handleChange} value={search.type}
                                 className={'form-control'} id={'type'} name={'type'}
                                 data={{
                                   placeholder: Lang.vt_trans_lang_62_selecttype,
                                   data: translationTypes
                                 }}>
                  <option value={''}>{Lang.select_target_lang}</option>
                </Select2Wrapper>
              </div>
            </div>
            <div className="col-xl-3 col-md-8 col-lg-12 mobiCenter formSectionSearchButton">
              <div className={'float-right'}>
                <Submit className={'btn btn-green hvr-rectangle-out btnMove mobiNone mr-3'} label={Lang.search}/>
                <button className=" btn btn-default hvr-rectangle-out btnMove mobiNone" onClick={formDataReset} type="button">{Lang.reset}
                </button>
              </div>
            </div>
      </div>
    </FormLayout>
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div className="quickLinkBox equalHeight boxShadow">
              <QuickLinks/>
            </div>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-12">
            <div className="boxWhite equalHeight boxShadow">
              <div className="table-responsive ">
                <table id="orderDataListings" className="display table masterTable" style={{width:'100%'}}>
                  <thead>
                  <tr>
                    <th>{Lang.order_no}</th>
                    <th>{Lang.company_name}</th>
                    <th>{Lang.language}</th>
                    <th>{Lang.status}</th>
                    <th>{Lang.date}</th>
                    <th>{Lang.action}</th>
                  </tr>
                  </thead>
                  <tbody>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th>{Lang.order_no}</th>
                    <th>{Lang.company_name}</th>
                    <th>{Lang.language}</th>
                    <th>{Lang.status}</th>
                    <th>{Lang.date}</th>
                    <th>{Lang.action}</th>
                  </tr>
                  </tfoot>
                </table>
                <div className={'no-record-found-in-list hide_me'}>
                  <NoDataFound/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
  )
}
import React from 'react';
import _ from 'lodash';
import {CallType} from "../Config";

export function Incoming (props:any){
  return (
    <div className="accpetCall_popup ">
      <div className="profileIn profileEnimation"><img src="/theme/images/john.png" alt="" title="" className="img-fluid" /></div>
      <div className="nameTitle">
        <span> {_.capitalize(props.user.full_name)} is calling you . . .</span>
      </div>
      <div className="buttonBoth">
        <div className="decline">
          <a onClick={props.rejectCall} style={{cursor: 'pointer'}}><img src="/theme/images/callClose.png"/></a>
          <span>Decline</span>
        </div>
        <div className="decline accept">
          <a onClick={props.acceptCall} style={{cursor: 'pointer'}} >
            {props.call_type === CallType.VIDEO &&  <i className="fas fa-video"/> }
            {props.call_type === CallType.AUDIO &&  <i className="fas fa-phone-volume"/>}
          </a>
          <span>Accept</span>

        </div>
      </div>
    </div>
  )
}
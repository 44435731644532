import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {QuizStart} from "../online-test/QuizStart";
import {Footer, Header} from "../partial";
export function OnlineQuizStartTabs (props:any) {
  
  // useEffect(() => {
  //   if (localStorage.getItem('send_online_test') === '2') {
  //     <Redirect to='/online-test'  />
  //   }
  // })

  return(

      // <QuizStart quiz_id={props.match.params.id} from={'Online Quiz Start'}/>
      /*
       The route above is the old version of the route below, since there is only one online-quiz, we are using
        the route below to obtain a clearer url 
      */
     <>
        {localStorage.getItem('send_online_test') === '1' &&
          <QuizStart quiz_id={"1"} from={'Online Quiz Start'}/>
        }
        {localStorage.getItem('send_online_test') === '2' &&
          <Redirect to="/online-test"/>
        }
        {/* {localStorage.getItem('send_online_test') === '2' &&
          <div className="bgMain">
            <div className="wrapper">
                <Header {...props}/>
                <section className="mainContent">
                    <div className="container">
                        <div className="statisticsPage">
                            <div className="row align-items-center mb-20">
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                                    <h1 className="title"><i className="fas fa-file-alt"/>{Lang.online_test_heading}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
              {loader.isLoader == 0 &&
              <><div className="bg_load"/>
                  <div className="wrap"/></>
              }
            </div>
          </div>
        } */}
     </>
        
      
  )
}
import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";
import {getAppDownloadList} from "../services/AppDownloadService";
import Cookies from "js-cookie";
import _ from "lodash";
import {isAndroid, isIOS, isMobile} from "react-device-detect";

declare let window: any;

export function AppDownloadModal (props: any) {

    let vitolker_app_model:any = Cookies.get('vitolker_app_header_section');
    const [appDownloadData, setAppDownloadData] = useState<any>();
    const [days, setDays] = useState<any>();

    useEffect(() => {
        ($('section') as any).addClass('custom-height-header');
        getAppDownloadList().then(appDownloadData => {
            setAppDownloadData(appDownloadData.data);
            if(!_.isNil(appDownloadData.data.pop_again_shows_in_day) && !_.isUndefined(appDownloadData.data.pop_again_shows_in_day)) {
                setDays(Number(appDownloadData.data.pop_again_shows_in_day));
            }
        });
    },[]);

    function closeAppPopup(){
        ($('.appHeader') as any).hide();
        ($('section') as any).removeClass('custom-height-header');
        if((vitolker_app_model=='undefined' || vitolker_app_model==undefined) && isMobile){
            Cookies.set('vitolker_app_header_section', '1',{ expires :  days });
        }
    }

  return (
      <>
          {(!_.isEmpty(appDownloadData) && (vitolker_app_model=='undefined' || vitolker_app_model==undefined)) &&
          <div className="appHeader">
              <div className="container">
                  <div className="row no-gutters align-items-center">
                      <div className="col-12">
                          <button type="button" className="closeBtn" onClick={closeAppPopup}>
                              <svg viewBox="0 0 16 16" fill="currentcolor" focusable="false" aria-hidden="true"
                                   role="img" className="sc-1tke4oj-0 hVvAHW">
                                  <path
                                      d="M13.4 14a.51.51 0 01-.36-.15L8 8.76l-5.1 5.09a.5.5 0 01-.71 0 .5.5 0 010-.7l5.1-5.1L2 2.85a.5.5 0 010-.7.5.5 0 01.71 0L8 7.34l5.19-5.19a.5.5 0 01.71 0 .5.5 0 010 .7l-5.2 5.2 5.1 5.1a.5.5 0 01-.35.85z"></path>
                              </svg>
                          </button>
                          <div className="contentImageAppData">
                              <img src="theme/images/vitolkerIcon.png" alt="" title="" className="img-fluid" width="30"/>
                              {!_.isEmpty(appDownloadData) &&
                              <div className="BtnAppData">
                                  {isAndroid && appDownloadData.google_play_store_link &&
                                  <a href={appDownloadData.google_play_store_link} target={'_blank'}
                                     className="btn btn-sm btn-green hvr-rectangle-out">{Lang.download_now}</a>
                                  }
                                  {isIOS && appDownloadData.ios_app_store_link &&
                                  <a href={appDownloadData.ios_app_store_link} target={'_blank'}
                                     className="btn btn-sm btn-green hvr-rectangle-out">{Lang.download_now}</a>
                                  }
                              </div>
                              }
                          </div>
                          <div className="contentAppData">
                              <div className="labelApp">{Lang.try_our_app}</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          }
          {/*<div className="appHeader">
              <div className="container">
                  <div className="row no-gutters align-items-center">
                      <div className="col-12 mt-2">
                          <button type="button" className="closeBtn" onClick={closeAppPopup}>
                              <svg viewBox="0 0 16 16" fill="currentcolor" focusable="false" aria-hidden="true"
                                   role="img" className="sc-1tke4oj-0 hVvAHW">
                                  <path d="M13.4 14a.51.51 0 01-.36-.15L8 8.76l-5.1 5.09a.5.5 0 01-.71 0 .5.5 0 010-.7l5.1-5.1L2 2.85a.5.5 0 010-.7.5.5 0 01.71 0L8 7.34l5.19-5.19a.5.5 0 01.71 0 .5.5 0 010 .7l-5.2 5.2 5.1 5.1a.5.5 0 01-.35.85z"></path>
                              </svg>
                          </button>
                          <div className="contentImageAppData">
                              <img src="theme/images/vitolkerIcon.png" alt="" title="" className="img-fluid" width="30"/>
                          </div>
                          <div className="contentAppData">
                              <div className="labelApp">{Lang.try_our_app}</div>
                          </div>
                          <div className="BtnAppData">
                              <a href="#" className="btn btn-sm btn-green hvr-rectangle-out">{Lang.download_now}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>*/}
      </>
  )
}
import {AxiosResponse} from "axios";
import API, {SwitchLoader} from "../common/AxoisClient";
import {LoaderType} from "../common/Loader";

declare var window: any;
export const DefaultJQueryFun = function () {
    $(document).ready(function(){
        //$( ".overally" ).remove();
        ($(".smobitrigger") as any).smplmnu();
        ($('.scrollbar-inner') as any).scrollbar();

        $('.notification_hide_show').on('click',function () {
            SwitchLoader(LoaderType.NONE);
            API.get('mark-viewed-notification').then((res: AxiosResponse) => {
                if(res.data.status == true) {
                    $('.notification').html('0').hide();
                }
            });
        });

        /*
        commented - 17-Mar-2019
        $('.notification_hide_show').on('click',function () {
            SwitchLoader(LoaderType.NONE);
            if(!$('.notification-at-header .show-notification').is(':visible')) {
                $('.notification-at-header .show-notification').fadeIn();
                API.get('mark-viewed-notification').then((res: AxiosResponse) => {
                    if(res.data.status == true) {
                        $('.notification').html('0').hide();
                    }
                });
            } else {
                if($('.notification-at-header .show-notification').is(':visible')) {
                     $('.notification-at-header .show-notification').fadeOut();
                }
            }
        });

        $('body').on('click',function (e) {
            if (!$(e.target).parents('.notification_full').length && !$(e.target).parents('.notification-view').length) {
                $('.notification-at-header .show-notification').fadeOut();
            }
        });*/

        $(window).on('scroll', function() {
            let navHeight:number;
            navHeight = ($(window) as any).height() - 700;
            if (($(window) as any).scrollTop() > navHeight) {
                ($('.mainHeader') as any).addClass('navShadow');
            } else {
                ($('.mainHeader') as any).removeClass('navShadow');
            }
        });
        ($('[data-toggle="tooltip"]') as any).tooltip();

    });



}
import React, {useState, useRef, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import Lang from "../../common/lang/Lang";
import API from "../../common/AxoisClient";
import {Auth} from "../../Auth/Auth";
import {AxiosResponse} from "axios";
import {successNotification} from "../../common/helpers";
import {FormLayout, Submit} from "../../common";
import _ from 'lodash';

export const TContractDisplay = (props: any) => {
    const form_ref = useRef<HTMLFormElement>(null);
    const [signature, setSignature] = useState<any>();
    let dataImage: any;

    $(document).ready(function () {
        setTimeout(function () {
            dataImage = ($(".sigPad") as any).signaturePad({drawOnly: true, lineWidth: 0});
        }, 500);
    });

    function createMarkup(content: string) {
        return {__html: content};
    }

    function handleSign() {
        if (dataImage !== undefined && dataImage!='') {
            $('#errorSign').hide();
            $('#checkboxError').hide();
            let imageBase64 = dataImage.getSignatureImage("image/png", 100);
            setSignature({...signature, signatureImage: imageBase64, 'user_id': Auth.userInfo.id});
        }
    }

    function clearSign() {
        $('#errorSign').show();
        $('#checkboxError').show();
        setSignature({...signature, signatureImage: '', 'user_id': '', 'is_accept':false});
        $('#is_accept').prop('checked', false);

    }


    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        handleSign();
        console.log('signature',signature);
        if (signature !== undefined) {
            if(signature.is_accept){
                $('#submit_button').hide();
                $('#please_wait_button').show();
                API.post(`contract-signature-save`, signature).then((res: AxiosResponse) => {
                    successNotification(Lang.contract_sign_success);
                    props.onHide();
                });
            }else{
                $('#checkboxError').show();
            }
        } else {
            $('#errorSign').show();
        }
    }

    function onToggle(ev: React.ChangeEvent<HTMLInputElement>){
        setSignature({...signature,'is_accept':ev.target.checked});
    }

    return (

        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <form
                style={{
                    padding: '2vw 5vw',
                }}
                ref={form_ref}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Lang.contract}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className={'row'}>
                            <div className={'col-md-12'} style={{marginLeft: 10}}>
                                <div className={'form-group'}>
                                    <span
                                        dangerouslySetInnerHTML={createMarkup(props.translator_contract.translatorContractContent)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sigPad">
                        <FormLayout
                            className="centerForm forgotForm sendContractForm"
                            onSubmit={handleSubmit}
                            id="basicinfo_form"
                            autoComplete="off"
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '0 0 1rem'
                            }}>
                                <canvas width={!_.isNull(form_ref.current)? form_ref.current.clientWidth! * 0.72 : '1500'} height="130" onClick={handleSign} id="canvas-sign-draw"/>
                            </div>
                            <span style={{display: 'none', color: 'red', float: 'left', width: '100%'}}
                                  id={'errorSign'}>{Lang.contract_sign_error}</span>                            
                            <div className='accept-radiobox'>
                            <span style={{display: 'none', color: 'red', float: 'left', width: '100%'}}
                                  id={'checkboxError'}>{Lang.vt_trans_lang_98}</span>
                                <input type="checkbox" id="is_accept" name="is_accept" onChange={ev =>onToggle(ev)}/>
                                <label htmlFor="acceptTermAndCondition">{Lang.vt_trans_lang_93_condition_text}</label>
                                                            
                            </div>

                            <div className='contract-button-section'>
                                <input type="submit" className="clearButton btn btn-lg" onClick={clearSign} value="Clear"
                                   style={{float: 'right'}}/>
                                <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} onClick={handleSign} id={'submit_button'}
                                    label={Lang.submit}/>
                            </div>
                            <span className={'btn btn-lg btn-green hvr-rectangle-out'} style={{display: 'none'}} id={'please_wait_button'}>{Lang.please_wait}</span>
                        </FormLayout>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </form>
        </Modal>
    )
};
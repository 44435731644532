import React from 'react';
import _ from 'lodash';

export function NotificationsIcon (props: any) {

    return (
        <>
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'assign_translator') &&
                <i className="fas fa-hands-helping"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'translator_send_invitation') &&
                <i className="fas fa-envelope-open-text"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'ONLINE_TEST') &&
                <i className="fas fa-desktop"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'contract_send') &&
                <i className="fas fa-file-import"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'ORDER_UPDATE') &&
                <i className="fas fa-clipboard-check"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'order_complete') &&
                <i className="fas fa-clipboard-check"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'UNDER_QA') &&
                <i className="fas fa-clipboard-check"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'order_complete_on_feed_back') &&
                <i className="fa fa-star" aria-hidden="true"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'LANG_APPROVE') &&
                <i className="fas fa-language"/>
            }
            {(!_.isEmpty(props.notification_type.type) && props.notification_type.type == 'CALL_NOT_ANSWERD') &&
                <i className="fas fa-phone"/>
            }
            {(_.isEmpty(props.notification_type.type)) &&
                <i className="fas fa-bell "/>
            }
    </>
    );
}

import {Subject} from "rxjs";
import API, {SwitchLoader} from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {LoaderType} from "../common/Loader";

export interface FormSchema{
    order_date:string,
    translation_type_id:string,
    from_language:string,
    to_language:string
};

export interface ordersPropSchema{
    ordersData:any,
    formData:FormSchema,
};


const subject = new Subject();
export const OrderInfoService = {
    showDetails: (order_id: number) => {
        API.post(`orders/order-detail`, {'order_id': order_id}).then((res: AxiosResponse) => {
            subject.next( {show: true, data: res.data.orderData} )
        });
    },
    hide: () => subject.next({show: false}),
    getInfo: () => subject.asObservable()
};

const mapsubject = new Subject();
export const MapInfoService = {
    showDetails: (order_id: number) => {
        API.post(`orders/order-detail`, {'order_id': order_id}).then((res: AxiosResponse) => {
            mapsubject.next( {show: true, data: res.data.orderData} )
        });
    },
    hide: () => mapsubject.next({show: false}),
    getInfo: () => mapsubject.asObservable()
};
import React from 'react';
import {CallStatus} from "../Config";
import {Connect} from "./Connect";
import {Incoming} from "./Incoming";
import {ErrorNotification, successNotification} from "../../common/helpers";


export function CallingUI(props: any) {
  console.log(props.status);
  switch (props.status){

    case CallStatus.INCOMING:
      return <Incoming {...props}/>;

    case CallStatus.RINGING:
    case CallStatus.CONNECT:
      return <Connect {...props}/>;

    case CallStatus.REJECT:
      ErrorNotification("Call Disconnected");
      return null;

    case CallStatus.END:
      successNotification("Call Ended");
      return null;

    case CallStatus.NOT_ANSWERED:
      ErrorNotification("Not answered");
      return null;

    default:
      return null;

  }
}
import React, {useEffect, useState} from 'react';
import {PropsSchema} from "../form/password";
import {Auth} from "../Auth/Auth";
import {Redirect} from "react-router";
import Lang from "../common/lang/Lang";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import {ErrorNotification, showNotification, successNotification} from "../common/helpers";
import * as _ from "lodash";
import {FormLayout, Submit} from "../common";
import {ChangePasswordValidations} from "../form/profile/Validations";
import {ChangeOldPasswordState, ChangePasswordState} from "../form/signup/State";
import {PasswordLayout} from "../layout";
import {Link} from "react-router-dom";

export function CreatePasswordWithOldPassword (props: PropsSchema) {

    let params:any = (new URL((document.location)as any)).searchParams;
    let token:any = decodeURI(params.get("token"));

    const [tchangepassword, setTChangePassword] = useState({...ChangeOldPasswordState});
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showCurPassword, setShowCurPassword] = useState(false);

    /*function sendEmail(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if( $( "#resetPassword").valid()) {
            API.post(`profile/change-password`, form,{headers:{Authorization:`Bearer ${token}`}}).then((res: AxiosResponse) => {
                if(res.data.status) {
                    successNotification(res.data.message);
                    setForm(changePasswordState);
                    ($('#PasswordTwo')as any).parents('.password').find('.progressBar_message ').hide();
                    window.location.href='/login'
                } else {
                    ErrorNotification(res.data.message);
                }
            });
        }
    }*/

    useEffect(() => {
        ChangePasswordValidations();
        setTimeout(function () {
            ($('#new_password')as any).parents('.password').find('.progressBar_message ').hide();
        },500);
    }, []);

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
        event.preventDefault();
        if($("#change_password").valid()) {
            API.post(`profile/change-password`, tchangepassword,{headers:{Authorization:`Bearer ${token}`}}).then((res: AxiosResponse) => {
                if(res.data.status === false){
                    ErrorNotification(Lang.cpassword_not_match);
                } else if(res.data.status === true){
                    successNotification(Lang.success_change_password);
                    window.location.href='/login'
                }
            })
        }
    }

    function handleChangePassword(event:any) {
        event.preventDefault();
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name === 'new_password' && _.isEmpty(value)) {
            ($('#new_password')as any).parents('.password').find('.progressBar_message ').hide();
        }
        setTChangePassword({...tchangepassword, new_password: event.target.value});
    }

    return(
        <PasswordLayout>
            <div className="forgot_password_page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="boxin">
                                <section className={`mainContent pageVerticalCenter`}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                                                <div className="FormSite">
                                                    <FormLayout id="change_password" className="changePassword" onSubmit={handleSubmit}  autoComplete="off">
                                                        <h1 style={{textAlign: 'center'}}>{Lang.vt_trans_lang_69_passwordtitle}</h1>
                                                        <div className="row">
                                                            <div className="col-sm-8 offset-sm-2">
                                                                <div className="form-group group mt-3 password">
                                                                    <label htmlFor="email">{Lang.vt_trans_lang_84_current_pw} <span className="required">*</span></label>
                                                                    <input id="current_password" type={showCurPassword ? 'text' : 'password'} value={tchangepassword.current_password}  onChange={(e) => {setTChangePassword({...tchangepassword, current_password: e.target.value})}} className="form-control" name={'current_password'} placeholder="*******"/>
                                                                    <i className={`fa ${showCurPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowCurPassword(!showCurPassword)}}/>
                                                                </div>

                                                                <div className="form-group group mt-3 password">
                                                                    <label htmlFor="PasswordTwo">{Lang.vt_trans_lang_85_new_pw}</label>
                                                                    <input id="new_password" type={showPassword ? 'text' : 'password'} value={tchangepassword.new_password} onChange={(e)=>{handleChangePassword(e)}}
                                                                           name={'new_password'} className="form-control" minLength={8} maxLength={20} placeholder="*******"/>
                                                                    <i className={`fa ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowPassword(!showPassword)}}/>
                                                                    <div className={'progressBar_message'}>
                                                                        <label>{Lang.passwordStrength}:</label>
                                                                        <div id={'passwordStrengthProgress'}/>
                                                                        <div id={'passwordStrengthVerdict'}/>
                                                                        <small>{Lang.vt_trans_lang_33_pwcheck}</small>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="form-group group mt-3 password">
                                                                    <label htmlFor="PasswordThree">{Lang.cn_password}</label>
                                                                    <input id="confirm_password" type={showConPassword ? 'text' : 'password'} value={tchangepassword.confirm_password}  onChange={(e) => {setTChangePassword({...tchangepassword, confirm_password: e.target.value})}}
                                                                           className="form-control" name={'confirm_password'}
                                                                           placeholder="*******"/>
                                                                    <i className={`fa ${showConPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowConPassword(!showConPassword)}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 text-center">
                                                            <Submit className={'btn btn-md btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_86_register}/>
                                                        </div>
														{/* <p className="msgText2">{Lang.do_you_remember_password} <Link to="/login">{Lang.try_logging}</Link></p> */}
                                                    </FormLayout>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PasswordLayout>
    )
}
import React, {useEffect, useState} from 'react'
import {LoginFormState} from "./State";
import Lang from "../../common/lang/Lang";
import API, {base_url} from './../../common/AxoisClient'
import {Auth} from "../../Auth/Auth";
import {AxiosResponse} from "axios";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import {FormLayout, Submit} from "../../common";
import {LoginValidations} from "../signup/Validations";
import Cookies from 'js-cookie';
import {getRememberMeData} from "../../services/AppDownloadService";
import {WebAppDownloadModal} from "../../partial/WebAppDownloadModal";
import {isIPad13, isMobile} from "react-device-detect";
import {AppDownloadModal} from "../../partial/AppDownloadModal";

export function LoginForm (props: RouteComponentProps) {

  const [login, setLogin] = useState(LoginFormState);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        document.title = Lang.login_vitolker;
        LoginValidations();
    }, []);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setLogin({...login, [name]: value});
    }

  function SendLogin(event: React.ChangeEvent<HTMLFormElement>) {
      event.preventDefault();
      if($("#login_form").valid()) {
          API.post(`${base_url}login`, login).then((res: AxiosResponse) => {
              localStorage.setItem('login_user_id', res.data.id);
              if( Number(res.data.is_change_password) > 0 ) {
                  props.history.push('/create-new-password-with-old-password?token=' + encodeURI(res.data._token));
              } else {
                  if (res.data.email_verified_at !== null || res.data.phone_verified_at !== null) {
                      var TranslatorHeaderData = {"profile_image": res.data.profile_image, "profile_image_small": res.data.profile_image_small, "name": res.data.name, "uniqueid" : res.data.id};
                      localStorage.setItem('TanslatorHeaderData', JSON.stringify(TranslatorHeaderData));
                      Auth.authenticate(res.data);
                      if(login.remember_me) {
                          getRememberMeData().then(rememberMeData => {
                              let days: number = Number(rememberMeData.data.login_remember_me_time);
                              Cookies.set('remember_token', res.data._token, {expires: days});
                          });
                      } else {
                          Cookies.set('remember_token', res.data._token);
                      }
                      API.get(`register/${Auth.userInfo.id}`).then((response: AxiosResponse) => {
                          localStorage.setItem('registration_step', response.data.registration_step);
                          localStorage.setItem('send_online_test', response.data.send_online_test);
                          if (response.data.registration_step === 1) {
                              props.history.push('/basic-info');
                          } else if (response.data.registration_step === 2) {
                              props.history.push('/bank-info');
                          } else if (response.data.registration_step === 3) {
                              props.history.push('/document-upload');
                          } else if (response.data.registration_step === 4 || response.data.registration_step === 7) {
                              if (response.data.email_verified_at !== null || response.data.phone_verified_at !== null ) {
                                  props.history.push('/dashboard');
                              } else {
                                  props.history.push('/first-verify-email-address');
                              }
                          }
                      })
                  } else {
                      props.history.push('/first-verify-email-address');
                  }
              }
          })
      }
  }

  return (
      <FormLayout className="loginBoxForm_waste" id={'login_form'} onSubmit={SendLogin}  autoComplete="off">
        <div className="group form-group">
          <input id="user_name" placeholder={Lang.vt_trans_lang_22_email} type="email" value={login.email}  onChange={(e) => {setLogin({...login, email: e.target.value})}} className="form-control input" name="email" autoComplete="user_name" autoFocus={true}/>
        </div>
        <div className="group form-group password">
          <input id="password" placeholder={Lang.vt_trans_lang_23_password} type={showPassword  === true ? 'text' : 'password'} value={login.password} className="form-control input" name="password" autoComplete="current-password" onChange={(e) => {setLogin({...login, password: e.target.value})}}/>
            <i className={`fa ${showPassword === true ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowPassword(!showPassword)}}/>
        </div>

          <div className="rowSet clr">
              <div className="float-left">
                  <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="remember_me" name="remember_me" value={login.remember_me} onChange={handleChange}/>
                          <label className="custom-control-label" htmlFor="remember_me">{Lang.vt_trans_lang_25_remember_me}</label>
                  </div>

              </div>
              <div className="float-right">
                  <div className="foot-lnk text-right">
                      <Link to="/forgot-password">{Lang.vt_trans_lang_24_forgot_password}</Link>
                  </div>
              </div>
          </div>

          <div className="group submit_btn mobiCenter">
            <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.vt_trans_lang_26_loginbutton}/>
          </div>

        <div className="onsignuppage hidden-sm hidden-md hidden-lg new_ac  text-center">{Lang.vt_trans_lang_27_account}
            <Link className="flip_button" to="/apply"><strong>{Lang.vt_trans_lang_28_button}</strong></Link>
        </div>

    </FormLayout>

  )
}
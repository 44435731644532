import React from 'react';
import {setFormSubmition} from "./AxoisClient";

export function FormLayout (props: any) {

  function OnSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    setFormSubmition(true);
    props.onSubmit(event);
  } 

  return(
    <form {...props} onSubmit={OnSubmit} >
      {props.children}
    </form>
  )
}
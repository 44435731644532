import React, {useEffect, useState} from 'react';
import Lang from "../common/lang/Lang";
import CanvasJSReact from "../lib/canvasjs.react";
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import _, { isEmpty } from 'lodash';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export function Graph (){


  const graphOptionsValue = {
    "animationEnabled":true,
    "data":[{
      "type":"pie",
      "startAngle":280,
      "yValueFormatString":"##0\"%\"",
      "indexLabel":"{} {y}",
      "indexLabelFontSize":18,
      "indexLabelFontColor":"white",
      "indexLabelPlacement":"inside",
      "dataPoints":[
        {"y":0,"label":"Physical Translation","color":'#72AEFF'},
        {"y":0,"label":"Video Translation","color":"#B19DFF"},
        {"y":0,"label":"Telephone Translation","color":"#FF9661"},
        {"y":0,"label":"Telephone Message Translation","color":"#FF8A7E"},
        {"y":0,"label":"Document Translation","color":"#B19DFF"}
      ]
    }]
  };

  const [graphOptions, setGraphOptions] = useState(graphOptionsValue);
  useEffect(() => {
    API.get('/translator-dashboard/order-statistics').then((res: AxiosResponse) => {
      setGraphOptions(res.data);
    });
  },[]);
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <div className="innerSpace">
          <div className="fav_tranlator order_statistics_box">
            <div className="Custmor_form boxShadow content equalHeight" style={{overflow:'hidden'}}>
              <div className="row dashBoxtitle">
                <div className="col-8">
                  <h4>{Lang.order_statistics}</h4>
                </div>
              </div>
              <ul className="labelDesign">
                { !isEmpty(graphOptions.data[0]) && Array.isArray(graphOptions.data[0].dataPoints) && graphOptions.data[0].dataPoints.map((value, index) => {
                  return (
                      <li className={'label'+(index+1)}><span></span> {!_.isEmpty(value.label)?value.label+'\xa0':''}</li>
                  );
                })}
              </ul>
              <div id="chartContainer" style={{height:'280px', 'width': '100%'}}>
                <CanvasJSChart options = {graphOptions}/>
              </div>
            </div>
          </div>
        </div>
      </div>
     </>
  )
}
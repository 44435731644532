import React, {useEffect} from 'react';
import {CallStatus} from "../Config";
import _ from "lodash";

export function Ringing (props:any){

  useEffect(() => {
    //$("#audio_ringing").draggable();
  })

  return (
    <div className="accpetCall_popup " id={'audio_ringing'}>
      <div className="profileIn profileEnimation"><img src="theme/images/john.png" alt="" title="" className="img-fluid" /></div>
      <div className="nameTitle" >

        {props.status === CallStatus.CONNECT &&
        <>
          <div className="nameTitle">
            <span> <strong>{_.capitalize(props.user.full_name)}</strong></span>
          </div>
        </>
        }
        {props.status === CallStatus.RINGING &&
        <span> <strong>{_.capitalize(props.user.full_name)} </strong><br/> Ringing</span>
        }
      </div>
      <div className="buttonBoth">
        <div className="decline">
          <a onClick={props.status === CallStatus.RINGING ? props.rejectCall : props.stopCall} style={{cursor: 'pointer'}}><img src="theme/images/callClose.png"/></a>
        </div>
      </div>
      {props.status === CallStatus.CONNECT &&
      <>
        <audio style={{marginTop: 10}}  controls id={'remoteOpponentVideoElementId' } loop autoPlay={true}>
          Your browser does not support the video tag.
        </audio>

      </>
      }
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { Footer, Header } from "../partial";
import API, { SwitchLoader } from "../common/AxoisClient";
import { AxiosResponse } from "axios";
import { OrderDataState } from "./State";
import { OrderModel, OrderStatus, OrderType } from "../model/Order";
import Lang from "../common/lang/Lang";
import { ErrorNotification, getToLanguage, successNotification } from "../common/helpers";
import { NoDataFound } from "../no-data-found/NoDataFound";
// import { Graph } from "./Graph";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
import { LoaderType } from "../common/Loader";
import { Auth } from "../Auth/Auth";
import { Modal } from "react-bootstrap";
import { Icons, Submit } from "../common";
import Axios from "../common/AxoisClient";
import { Graph } from "./Graph";
declare let window: any;

export function DashboardPage(props: any) {
  const [transOrderData, setTransOrderData] = useState(OrderDataState);
  const [transInvitationOrder, setTransInvitationOrder] = useState(OrderDataState);
  const [transRatingOrder, setTransRatingOrder] = useState(OrderDataState);
  const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);
  const [dashboardCount, setDashboardCount] = useState({
    newTasks: 0,
    myTasks: 0,
    completeTasks: 0,
    completedInvoice: 0,
    awaitingInvoice: 0,
  });
  const [loader, setLoader] = useState({ isLoader: 0 });
  const [ShowCheckboxModal, setShowCheckboxModal] = useState<boolean>(
    localStorage.getItem("CheckboxModalShown") !== "true" &&
      (Auth.userData.translatorData
        ? !Auth.userData.translatorData!.worked_with_ministry &&
          new Date(Auth.userData.translatorData!.created_at) < new Date("2022-06-29")
        : true)
  );
  // const [ShowSwiftIbanModal, setShowSwiftIbanModal] = useState(
  //   (isEmpty(localStorage.getItem("SwiftIbanModalShown"))
  //     ? true
  //     : new Date(localStorage.getItem("SwiftIbanModalShown") || "now") <=
  //         new Date(new Date().setHours(new Date().getHours() - 12)) && true) &&
  //     isEmpty((Auth.userData.translatorData || {}).swift) &&
  //     isEmpty((Auth.userData.translatorData || {}).iban)
  // );
  console.log("this is: ", Auth.userData.translatorData);

  function getNewInvitation() {
    SwitchLoader(LoaderType.NONE);
    API.get(`orders/order-list`, { params: { status: 2 } }).then((res: AxiosResponse) => {
      // new assignments
      const translatorInvitationOrder = res.data.orderData;
      setTransInvitationOrder({
        ...transInvitationOrder,
        ordersInvitation: translatorInvitationOrder,
      });
    });
  }

  function hideShowCheckboxModal() {
    localStorage.setItem("CheckboxModalShown", "true");
    setShowCheckboxModal(false);
  }

  function getDashboardData() {
    API.get(`orders/order-list`, { params: { status: 3, rating: "high" } }).then(
      (res: AxiosResponse) => {
        // orders list
        const translatorOrderData = res.data.orderData;
        setTransOrderData({ ...transOrderData, orderData: translatorOrderData });
        ($('[data-toggle="tooltip"]') as any).tooltip();
      }
    );

    setTimeout(() => {
      API.get(`orders/order-list`, { params: { status: 5, rating: "high" } }).then(
        (res: AxiosResponse) => {
          // new assignments
          const translatorHighRatingOrder = res.data.orderData;
          setTransRatingOrder({ ...transRatingOrder, highRatedOrders: translatorHighRatingOrder });
          for (var i = 0; i < translatorHighRatingOrder.length; i++) {
            let mainObj: any = $(".order_rating" + i);
            if (mainObj.length > 0) {
              mainObj.each(function (index: any, item: any) {
                let obj: any = $(item);
                let objRating = obj.attr("data-rating");
                objRating = objRating != "null" ? objRating : 0;
                obj.rateYo({
                  starWidth: "13px",
                  spacing: "3px",
                  rating: translatorHighRatingOrder[i].feedback.rating,
                  readOnly: true,
                });
              });
            }
          }
        }
      );
    }, 2000);
    ($('[data-toggle="tooltip"]') as any).tooltip();
  }

  function orderStatistics() {
    API.get("/translator-dashboard/order-statistics").then((res: AxiosResponse) => {
      setDashboardCount({
        ...dashboardCount,
        newTasks: res.data.newTasks,
        myTasks: res.data.myTasks,
        completeTasks: res.data.completeTasks,
        completedInvoice: res.data.completedInvoice,
        awaitingInvoice: res.data.awaitingInvoice,
      });
      setLoader({ ...loader, isLoader: 1 });
    });
  }

  useEffect(() => {
    document.title = Lang.dashboard + " - " + Lang.vitolker_translator;
    orderStatistics();
    getDashboardData();
    getNewInvitation();
    setInterval(async () => {
      getNewInvitation();
    }, 20000);
  }, []);

  function total_call_duration(order: OrderModel) {
    if (!_.isEmpty(order.total_call_time)) {
      let diff = moment(order.total_call_time[0].total_call_time, "HH:mm:ss");
      if (diff.hours() > 0) {
        return `${diff.hours()} Hour ${diff.minutes()} minutes ${diff.seconds()} seconds`;
      } else {
        return `${diff.minutes()} minutes ${diff.seconds()} seconds`;
      }
    } else {
      return `0 minutes`;
    }
  }

  function total_audio_call_duration(order: OrderModel) {
    if (!_.isEmpty(order.complete_order_to) && !_.isEmpty(order.order_from)) {
      let diff = moment.duration(
        moment(order.complete_order_to, "HH:mm:ss a").diff(moment(order.order_from, "HH:mm:ss a"))
      );
      return `${diff.hours()} Hour ${diff.minutes()} minutes`;
    } else {
      return `0 minutes`;
    }
  }

  function getOrderDetail(event: any, order_id: any) {
    setTransOrderDetail(OrderDataState);
    API.post(`orders/order-detail`, { order_id: order_id }).then((res: AxiosResponse) => {
      const translatorOrderDetail = res.data.orderData;
      setTimeout(function () {
        let mainObj: any = $(".custom-rating-detail");
        if (mainObj.length > 0) {
          mainObj.each(function (index: any, item: any) {
            let obj: any = $(item);
            let objRating = obj.attr("data-rating");
            objRating = objRating != "null" ? objRating : 0;
            obj.rateYo({
              starWidth: "20px",
              spacing: "4px",
              rating: objRating,
              readOnly: true,
            });
          });
        }
      }, 100);
      setTransOrderDetail({ ...transOrderDetail, orderData: translatorOrderDetail });
      ($('[data-toggle="tooltip"]') as any).tooltip();
      ($(".scrollbar-inner") as any).scrollbar();
    });
  }

  function performAction(assignment_action: any) {
    API.post(`orders/order-action`, assignment_action).then((res: AxiosResponse) => {
      if (res.data.status === 1) {
        successNotification(Lang.accept_assignment);
      } else if (res.data.status === 2) {
        successNotification(Lang.reject_assignment);
      } else if (res.data.status === 3) {
        ErrorNotification(Lang.already_accepted_someone);
      } else if (res.data.status === 4 || res.data.status === 5) {
        ErrorNotification(res.data.message);
      }
      orderStatistics();
      getDashboardData();
      ($("#exampleModal") as any).modal("hide");
    });
  }

  function assignmentAction(event: any, assignment_data: any, action: string) {
    let assignment_action = { order_id: assignment_data.id, action: action };
    if (action === "reject") {
      let confirm_text = Lang.reject_assignment_confirmation;
      Swal.fire({
        title: Lang.is_sure,
        text: confirm_text,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: Lang.ok,
        cancelButtonText: Lang.cancel,
      }).then((result: any) => {
        if (result.value) {
          performAction(assignment_action);
        }
      });
    } else {
      performAction(assignment_action);
    }
  }

  const orderData = transOrderDetail["orderData"][0];

  return (
    <div className="bgMain">
      <div className="wrapper">
        <Header {...props} />
        <section className="mainContent">
          <CheckboxModal show={ShowCheckboxModal} closeModal={hideShowCheckboxModal} />
          {/* <SwiftIbanModal show={ShowSwiftIbanModal} close={() => setShowSwiftIbanModal(false)} /> */}
          <div className="container">
            <h1 className="title mb-20">
              <i className="fas fa-tachometer-alt" /> {Lang.dashboard}
            </h1>
            {loader.isLoader == 0 && (
              <>
                <div className="bg_load" />
                <div className="wrap" />
              </>
            )}
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="innerSpace boxShadow">
                  <div className="dashbox">
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <Link to={"invitation"}>
                          <div className="box box1 hvr-grow-shadow">
                            <div className="col-md-7 col-xs-6">
                              <div className="imagebox part1">
                                <span className="count">{dashboardCount.newTasks}</span>
                              </div>
                            </div>
                            <p className="shiva">
                              <i className="far fa-envelope" />
                            </p>
                            <div className="col-md-12 col-xs-12">
                              <h5>{Lang.vt_trans_lang_67_new_task}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <Link to={"my-assignment"}>
                          <div className="box box2 hvr-grow-shadow">
                            <div className="col-md-6 col-xs-12">
                              <div className="imagebox part2">
                                <span className="count">{dashboardCount.myTasks}</span>
                              </div>
                            </div>
                            <p className="shiva">
                              <i className="far fa-copy" />
                            </p>
                            <div className="col-md-12 col-xs-12">
                              <h5>{Lang.vt_trans_lang_66_all_task}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <Link to={"order-list"}>
                          <div className="box box3 hvr-grow-shadow">
                            <div className="col-md-6 col-xs-12">
                              <div className="imagebox part3">
                                <span className="count">{dashboardCount.completeTasks}</span>
                              </div>
                            </div>
                            <p className="shiva">
                              <i className="fas fa-list" />
                            </p>
                            <div className="col-md-12 col-xs-12">
                              <h5>{Lang.vt_trans_lang_65_complete_task}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Graph />

              <div className="col-sm-12 col-md-12 col-lg-8 ">
                <div className="fav_tranlator">
                  <div className="Custmor_form assignmentTable boxShadow content equalHeight">
                    <div className="row dashBoxtitle">
                      <div className="col-8">
                        <h4>{Lang.new_assignment}</h4>
                      </div>
                      <div className="col-4 text-right">
                        <a href="javascript:void(0)" className="linkView">
                          <i className="fas fa-sync" onClick={(e) => getDashboardData()} />
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <Link className="linkView" to={"invitation"}>
                          {Lang.vt_trans_lang_81_view_All}
                        </Link>
                      </div>
                    </div>

                    <div className="dashbox">
                      <div className="scrollbar-inner">
                        <div className="invitation">
                          {transInvitationOrder.ordersInvitation &&
                            transInvitationOrder.ordersInvitation.length > 0 &&
                            transInvitationOrder.ordersInvitation[0] !== undefined &&
                            transInvitationOrder.ordersInvitation.map(
                              (invitationOrders: OrderModel, index: number) => {
                                let fromFlag =
                                  invitationOrders.language_from !== null &&
                                  invitationOrders.language_from.country !== undefined
                                    ? invitationOrders.language_from.country.iso
                                    : "";
                                return (
                                  <>
                                    <div className="invitationItem">
                                      <div className="row align-items-center">
                                        <div className="col-lg-7 col-md-7 col-sm-12">
                                          <div className="serviceType float-left">
                                            <span className="SSType">
                                              <img
                                                src={invitationOrders.translation_type.small_icon}
                                                alt=""
                                                title={invitationOrders.translation_type.title}
                                                className="img-fluid"
                                              />
                                            </span>
                                            <div className="serviceName">
                                              {invitationOrders.translation_type.title}
                                            </div>
                                          </div>

                                          <div className="InvitationInfo float-left">
                                            <a
                                              href="#"
                                              className="orderId"
                                              data-toggle="modal"
                                              data-target="#exampleModal"
                                              onClick={(e) =>
                                                getOrderDetail(e, invitationOrders.id)
                                              }
                                            >
                                              #{invitationOrders.order_no}
                                            </a>
                                            <div className="timer">
                                              <p>
                                                <i className="far fa-calendar-alt" />{" "}
                                                {moment(invitationOrders.order_date).format("dddd")}{" "}
                                                {moment(invitationOrders.order_date).format(
                                                  "DD-MM-YYYY"
                                                )}
                                              </p>
                                              <p>
                                                <i className="far fa-clock" />{" "}
                                                {invitationOrders.translation_type_id !==
                                                  OrderType.DOCUMENT &&
                                                  invitationOrders.translation_type_id !==
                                                    OrderType.PHONE_MESSAGE &&
                                                  invitationOrders.order_from + " - "}{" "}
                                                {invitationOrders.order_to}
                                              </p>
                                            </div>

                                            <div className="Lang">
                                              <span className="lang-from-to">
                                                <span>
                                                  <img
                                                    src={
                                                      fromFlag !== undefined
                                                        ? window.PUBLIC_URL +
                                                          "/flags/" +
                                                          fromFlag.toLowerCase() +
                                                          ".png"
                                                        : ""
                                                    }
                                                    alt="vt-img"
                                                  />
                                                  {invitationOrders.language_from.language_name}
                                                </span>
                                                <span className="exchangeIcon">
                                                  <i className="fas fa-exchange-alt" />
                                                </span>
                                                <span>{getToLanguage(invitationOrders)}</span>
                                              </span>
                                            </div>
                                            {invitationOrders.translation_type_id ===
                                              OrderType.PHYSICAL && (
                                              <div className="addressBar">
                                                <i className="fas fa-map-marker-alt" />{" "}
                                                {invitationOrders.meeting_address}
                                              </div>
                                            )}
                                            {!_.isEmpty(invitationOrders.company.name) &&
                                              _.isEmpty(invitationOrders.department) && (
                                                <div className="addressBar">
                                                  {invitationOrders.company.name}
                                                </div>
                                              )}
                                            {_.isEmpty(invitationOrders.company.name) &&
                                              !_.isEmpty(invitationOrders.department) &&
                                              invitationOrders.department !== undefined &&
                                              !_.isEmpty(invitationOrders.department.name) && (
                                                <div className="addressBar">
                                                  {invitationOrders.department.name}
                                                </div>
                                              )}
                                            {!_.isEmpty(invitationOrders.company.name) &&
                                              !_.isEmpty(invitationOrders.department) &&
                                              invitationOrders.department !== undefined &&
                                              !_.isEmpty(invitationOrders.department.name) && (
                                                <div className="addressBar">
                                                  {invitationOrders.company.name} ,{" "}
                                                  {invitationOrders.department.name}
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div className="col-lg-5 col-md-5 col-sm-12 mobileSpace20">
                                          <div className="btn_invitation">
                                            <a
                                              className="btnStatusSuccess"
                                              href="javascript:void(0)"
                                              onClick={(e) =>
                                                assignmentAction(e, invitationOrders, "accept")
                                              }
                                            >
                                              {Lang.accept}
                                            </a>
                                            <a
                                              className="btnStatusDanger"
                                              href="javascript:void(0)"
                                              onClick={(e) =>
                                                assignmentAction(e, invitationOrders, "reject")
                                              }
                                            >
                                              {Lang.reject}
                                            </a>
                                          </div>
                                        </div>

                                        {/*<br/>
                                                          <div className="col-lg-7 col-md-7 col-sm-12" style={{
    marginTop: '15px'}}><a className="btnStatusPrimary" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">See More</a>
                                                          </div>*/}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          {transInvitationOrder.ordersInvitation &&
                            transInvitationOrder.ordersInvitation.length === 0 && <NoDataFound />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="col-md-6 mt-20">
                <div className="Custmor_form boxShadow content equalHeight2 limitedOrder">
                  <div className="row dashBoxtitle">
                    <div className="col-8">
                      <h4>{Lang.my_assignment}</h4>
                    </div>
                    <div className="col-4 text-right">
                      <a href="javascript:void(0)" className="linkView">
                        <i className="fas fa-sync" onClick={(e) => getDashboardData()} />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <Link className="linkView" to={"my-assignment"}>
                        {Lang.view_all}
                      </Link>
                    </div>
                  </div>
                  <div className="">
                    <div className="table-responsive for-mg">
                      {transOrderData.orderData && transOrderData.orderData.length > 0 && (
                        <table className="table masterTable">
                          <tr>
                            <th>{Lang.order_no}</th>
                            <th>{Lang.language}</th>
                            <th>{Lang.status}</th>
                            <th>{Lang.date}</th>
                          </tr>
                          {transOrderData.orderData &&
                            transOrderData.orderData.length > 0 &&
                            transOrderData.orderData.map((orders: OrderModel, index: number) => {
                              let fromFlag =
                                orders.language_from !== null &&
                                orders.language_from.country !== undefined &&
                                orders.language_from.country !== null
                                  ? orders.language_from.country.iso
                                  : "";
                              return (
                                <tr>
                                  <td key={orders.id}>
                                    <span
                                      className={"translation_type_icon ServiceIconSat"}
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title={orders.translation_type.title}
                                    >
                                      <img
                                        src={orders.translation_type.small_icon}
                                        width="18"
                                        alt="vt-img"
                                      />{" "}
                                    </span>
                                    <a
                                      href="#"
                                      className={"showDetailsInfo"}
                                      data-toggle="modal"
                                      data-target="#exampleModal"
                                      onClick={(e) => getOrderDetail(e, orders.id)}
                                    >
                                      #{orders.order_no}
                                    </a>
                                    <p>{orders.translation_type.title}</p>
                                  </td>

                                  <td>
                                    <span className="lang-from-to">
                                      <span className="">
                                        <img
                                          src={
                                            fromFlag !== undefined
                                              ? window.PUBLIC_URL +
                                                "/flags/" +
                                                fromFlag.toLowerCase() +
                                                ".png"
                                              : ""
                                          }
                                          alt="vt-img"
                                        />{" "}
                                        {orders.language_from.language_name}
                                      </span>
                                      <span className="exchangeIcon">
                                        <i className="fas fa-exchange-alt" />
                                      </span>{" "}
                                      <span>{getToLanguage(orders)}</span>
                                    </span>
                                  </td>
                                  <td>
                                    {orders.status == 3 &&
                                    moment(orders.order_date).format("YYYY-MM-DD") <=
                                      moment().format("YYYY-MM-DD") ===
                                      false ? (
                                      <span className="btnStatusPrimary">{Lang.assigned}</span>
                                    ) : (
                                      ""
                                    )}
                                    {orders.status == 4 ? (
                                      <span className="btnStatusDanger">{Lang.cancelled}</span>
                                    ) : (
                                      ""
                                    )}
                                    {orders.status == 5 ? (
                                      <span className="btnStatusSuccess">{Lang.completed}</span>
                                    ) : (
                                      ""
                                    )}
                                    {orders.status == 3 &&
                                    moment(orders.order_date).format("YYYY-MM-DD") <=
                                      moment().format("YYYY-MM-DD") ? (
                                      <span className="btnStatusDanger">{Lang.overdue}</span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {moment(orders.order_date).format("dddd")}
                                    <br />
                                    <span className="order-day-with-time">
                                      {moment(orders.order_date).format("DD-MM-YYYY")}
                                      <br />
                                    </span>
                                    {orders.translation_type_id !== OrderType.DOCUMENT &&
                                      orders.translation_type_id !== OrderType.PHONE_MESSAGE &&
                                      " " + orders.order_from + " - "}{" "}
                                    {orders.order_to}
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      )}
                    </div>

                    {transOrderData.orderData && transOrderData.orderData.length === 0 && (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-20">
                <div className="Custmor_form boxShadow content equalHeight2 limitedOrder">
                  <div className="row dashBoxtitle">
                    <div className="col-8">
                      <h4>{Lang.vt_trans_lang_80_latest_assigment}</h4>
                    </div>
                  </div>
                  <div className="">
                    <div className="table-responsive for-mg">
                      {transRatingOrder.highRatedOrders &&
                        transRatingOrder.highRatedOrders.length > 0 && (
                          <table className="table masterTable">
                            <tr>
                              <th>{Lang.order_no}</th>
                              <th>{Lang.language}</th>
                              <th>{Lang.rating}</th>
                              <th>{Lang.date}</th>
                              {/*<th>{Lang.action}</th>*/}
                            </tr>
                            {transRatingOrder.highRatedOrders &&
                              transRatingOrder.highRatedOrders.length > 0 &&
                              transRatingOrder.highRatedOrders.map(
                                (orders: OrderModel, index: number) => {
                                  let fromFlag =
                                    orders.language_from !== null &&
                                    orders.language_from.country !== undefined &&
                                    orders.language_from.country !== null
                                      ? orders.language_from.country.iso
                                      : "";
                                  return (
                                    <tr>
                                      <td key={orders.id}>
                                        <span
                                          className={"translation_type_icon ServiceIconSat"}
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title={orders.translation_type.title}
                                        >
                                          <img
                                            src={orders.translation_type.small_icon}
                                            width="18"
                                            alt="vt-img"
                                          />
                                        </span>
                                        <a
                                          href="#"
                                          className={"showDetailsInfo"}
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                          onClick={(e) => getOrderDetail(e, orders.id)}
                                        >
                                          #{orders.order_no}
                                        </a>
                                        <p>{orders.translation_type.title}</p>
                                      </td>
                                      <td>
                                        <span className="lang-from-to">
                                          {/*<span className="Language_icon">*/}
                                          <span className="">
                                            <img
                                              src={
                                                fromFlag !== undefined
                                                  ? window.PUBLIC_URL +
                                                    "/flags/" +
                                                    fromFlag.toLowerCase() +
                                                    ".png"
                                                  : ""
                                              }
                                              alt="vt-img"
                                            />{" "}
                                            {orders.language_from.language_name}
                                          </span>
                                          <span className="exchangeIcon">
                                            <i className="fas fa-exchange-alt" />
                                          </span>{" "}
                                          <span>{getToLanguage(orders)}</span>
                                        </span>
                                      </td>
                                      <td>
                                        <div className={"rating order_rating" + index} />
                                      </td>
                                      <td>
                                        {moment(orders.order_date).format("dddd")}
                                        <br />
                                        <span className="order-day-with-time">
                                          {moment(orders.order_date).format("DD-MM-YYYY")}
                                          <br />
                                        </span>
                                        {orders.translation_type_id !== OrderType.DOCUMENT &&
                                          orders.translation_type_id !== OrderType.PHONE_MESSAGE &&
                                          " " + orders.order_from + " - "}{" "}
                                        {orders.status === OrderStatus.COMPLETED
                                          ? orders.complete_order_to
                                          : orders.order_to}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </table>
                        )}
                    </div>

                    {transRatingOrder.highRatedOrders &&
                      transRatingOrder.highRatedOrders.length === 0 && <NoDataFound />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal right fade"
          id="exampleModal"
          role="dialog"
          aria-labelledby="exampleModal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            {transOrderDetail["orderData"][0] == undefined && (
              <div className={"dataLoad"}>{Lang.please_wait}</div>
            )}
            {transOrderDetail["orderData"][0] !== undefined &&
              transOrderDetail["orderData"][0].language_from.country && (
                <div className="modal-content">
                  <div className="sideMenuBarRight" id="orderDetailsBox">
                    <a href="javascript:;" className="btn closeBtn" data-dismiss="modal">
                      <span>×</span>
                    </a>
                    <div className="orderPreview">
                      <div className="blockStep">
                        <div className="blockStepList">
                          <h3 className="stripTitleNew">
                            <div className="row">
                              <div className="col-sm-6">
                                <i className="far fa-file-alt" /> {Lang.order_detail}{" "}
                                <span className={"orderID"}>
                                  {" "}
                                  #{transOrderDetail["orderData"][0].order_no}
                                </span>
                              </div>
                              <div className="col-sm-6 text-right">
                                <div className="colData">
                                  <span>{Lang.vt_trans_lang_56_invationdate} :</span>
                                  &nbsp;&nbsp;&nbsp;
                                  <span className="timer">
                                    <p>
                                      <i className="far fa-calendar-alt" aria-hidden="true" />{" "}
                                      {moment(transOrderDetail["orderData"][0].created_at).format(
                                        "dddd"
                                      )}{" "}
                                      {moment(transOrderDetail["orderData"][0].created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                    <p>
                                      <i className="far fa-clock" aria-hidden="true">
                                        {" "}
                                      </i>{" "}
                                      {moment(transOrderDetail["orderData"][0].created_at).format(
                                        "HH:mm"
                                      )}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </h3>

                          <div className="row mt-4 mb-4">
                            <div className="col-lg-12 col-xl-6">
                              <div className="row">
                                <div className="col-left">
                                  <img
                                    src={
                                      transOrderDetail["orderData"][0].translate_by != undefined
                                        ? transOrderDetail["orderData"][0].translate_by
                                            .profile_image_small
                                        : "/theme/images/user-placeholder.jpg"
                                    }
                                    alt={
                                      transOrderDetail["orderData"][0].translate_by != undefined
                                        ? transOrderDetail["orderData"][0].translate_by.name
                                        : ""
                                    }
                                    width="78"
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                                <div className="col-right infoUserP">
                                  <h4 className="Usertype">
                                    {Lang.vt_trans_lang_52_invationtranslator}
                                  </h4>
                                  {transOrderDetail["orderData"][0].translate_by != null && (
                                    <ul>
                                      <li>
                                        <i className="far fa-user" />
                                        {transOrderDetail["orderData"][0].translate_by != undefined
                                          ? transOrderDetail["orderData"][0].translate_by.name
                                          : ""}{" "}
                                        <b>
                                          {transOrderDetail["orderData"][0].translate_by !=
                                            undefined &&
                                          transOrderDetail["orderData"][0].translate_by.user_code !=
                                            null
                                            ? "(" +
                                              transOrderDetail["orderData"][0].translate_by
                                                .user_code +
                                              ")"
                                            : ""}
                                        </b>
                                      </li>
                                      <li>
                                        <i className="far fa-envelope" />
                                        {transOrderDetail["orderData"][0].translate_by != undefined
                                          ? transOrderDetail["orderData"][0].translate_by.email
                                          : ""}
                                      </li>
                                    </ul>
                                  )}
                                  {transOrderDetail["orderData"][0].translate_by == null && (
                                    <ul>
                                      {/*<li><i className="fas fa-sad-tear"/>
                                                                  {Lang.not_assigned}</li>*/}
                                    </ul>
                                  )}
                                  {transOrderDetail["orderData"][0].translation_type_id ===
                                    OrderType.DOCUMENT &&
                                    (transOrderDetail["orderData"][0].status ==
                                      OrderStatus.COMPLETED ||
                                      transOrderDetail["orderData"][0].status ==
                                        OrderStatus.UNDER_QA) && (
                                      <div className={"scrollbar-inner minHeight200"}>
                                        <ul className={"document_upload_list"}>
                                          {transOrderDetail["orderData"][0].order_files &&
                                            transOrderDetail["orderData"][0].order_files.length >
                                              0 &&
                                            transOrderDetail["orderData"][0].order_files.map(
                                              (orderFilesTranslator: any, index: number) => {
                                                if (orderFilesTranslator.type == "complete") {
                                                  return (
                                                    <li>
                                                      <Link
                                                        target={"_blank"}
                                                        to={
                                                          "/file-view/" +
                                                          orderFilesTranslator.id +
                                                          "/document/?file_name=" +
                                                          orderFilesTranslator.file_name
                                                        }
                                                      >
                                                        <i className="far fa-file-alt" />
                                                        <span>
                                                          {orderFilesTranslator.file_name}
                                                        </span>
                                                        <i className="fas fa-cloud-download-alt downloadIcoSet" />
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              }
                                            )}
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 mobileSpace">
                              <div className="row">
                                <div className="col-left">
                                  <img
                                    src={
                                      transOrderDetail["orderData"][0].customer.profile_image_small
                                    }
                                    alt={transOrderDetail["orderData"][0].customer.name}
                                    width="78"
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                                <div className="col-right infoUserP">
                                  <h4 className="Usertype">
                                    {Lang.vt_trans_lang_54_invationCustomer}
                                  </h4>
                                  <ul>
                                    <li>
                                      <i className="far fa-user" />
                                      {transOrderDetail["orderData"][0].customer.name}{" "}
                                      {transOrderDetail["orderData"][0].customer.user_code !==
                                        null && (
                                        <b>
                                          {"(" +
                                            transOrderDetail["orderData"][0].customer.user_code +
                                            ")"}
                                        </b>
                                      )}
                                    </li>
                                  </ul>
                                  {transOrderDetail["orderData"][0].translation_type_id ===
                                    OrderType.DOCUMENT && (
                                    <div className={"scrollbar-inner minHeight200"}>
                                      <ul className={"document_upload_list"}>
                                        {transOrderDetail["orderData"][0].order_files &&
                                          transOrderDetail["orderData"][0].order_files.length > 0 &&
                                          transOrderDetail["orderData"][0].order_files.map(
                                            (orderFilesTranslator: any, index: number) => {
                                              if (orderFilesTranslator.type == "create") {
                                                return (
                                                  <li>
                                                    <Link
                                                      target={"_blank"}
                                                      to={
                                                        "/file-view/" +
                                                        orderFilesTranslator.id +
                                                        "/document/?file_name=" +
                                                        orderFilesTranslator.file_name
                                                      }
                                                    >
                                                      <i className="far fa-file-alt" />
                                                      <span>{orderFilesTranslator.file_name}</span>
                                                      <i className="fas fa-cloud-download-alt downloadIcoSet" />
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="profileDataRow">
                            <div className="row">
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.order_no}.</span> #
                                  {transOrderDetail["orderData"][0].order_no}
                                </div>
                              </div>
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.translation_type}</span>
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title={transOrderDetail["orderData"][0].translation_type.title}
                                    className="d-inline"
                                  >
                                    <img
                                      src={
                                        transOrderDetail["orderData"][0].translation_type.small_icon
                                      }
                                      alt={transOrderDetail["orderData"][0].translation_type.title}
                                      width="25"
                                    />
                                  </span>
                                  {transOrderDetail["orderData"][0].translation_type.title}
                                </div>
                              </div>
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.language}</span>
                                  <div className="language-section">
                                    <span>
                                      <img
                                        src={
                                          transOrderDetail["orderData"][0].language_from.country
                                            .iso !== undefined
                                            ? window.PUBLIC_URL +
                                              "/flags/" +
                                              transOrderDetail[
                                                "orderData"
                                              ][0].language_from.country.iso.toLowerCase() +
                                              ".png"
                                            : ""
                                        }
                                      />
                                      {transOrderDetail["orderData"][0].language_from.language_name}
                                      <span className="exchangeIcon">
                                        <i className="fas fa-exchange-alt" />
                                      </span>{" "}
                                      {getToLanguage(transOrderDetail["orderData"][0])}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.vt_trans_lang_51_invationDate}</span>
                                  <span className="timer">
                                    <p>
                                      <i className="far fa-calendar-alt" aria-hidden="true" />
                                      {moment(transOrderDetail["orderData"][0].order_date).format(
                                        "dddd"
                                      )}{" "}
                                      {moment(transOrderDetail["orderData"][0].order_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                    <p>
                                      <i className="far fa-clock" aria-hidden="true">
                                        {" "}
                                      </i>
                                      {transOrderDetail["orderData"][0].translation_type_id !=
                                        OrderType.DOCUMENT &&
                                        transOrderDetail["orderData"][0].translation_type_id !=
                                          OrderType.PHONE_MESSAGE &&
                                        transOrderDetail["orderData"][0].order_from + " - "}{" "}
                                      {transOrderDetail["orderData"][0].status ==
                                      OrderStatus.COMPLETED
                                        ? transOrderDetail["orderData"][0].complete_order_to
                                        : transOrderDetail["orderData"][0].order_to}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {transOrderDetail["orderData"][0].translation_type_id ===
                                OrderType.VIDEO && (
                                <div className="col-xl-6 col-sm-8">
                                  <div className="colData">
                                    <span>{Lang.call_duration}</span>
                                    <p>
                                      <i className="far fa-clock" />{" "}
                                      {total_call_duration(transOrderDetail["orderData"][0])}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {transOrderDetail["orderData"][0].translation_type_id ===
                                OrderType.TELEPHONE && (
                                <div className="col-xl-6 col-sm-8">
                                  <div className="colData">
                                    <span>{Lang.call_duration}</span>
                                    <p>
                                      <i className="far fa-clock" />{" "}
                                      {total_audio_call_duration(transOrderDetail["orderData"][0])}
                                    </p>
                                  </div>
                                </div>
                              )}

                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.vt_trans_lang_53_Institution}</span>
                                  {transOrderDetail["orderData"][0].company.name}
                                </div>
                              </div>

                              <div className="col-xl-4 col-sm-6">
                                <div className="colData">
                                  <span>{Lang.department}</span>
                                  {transOrderDetail["orderData"][0].department != undefined
                                    ? transOrderDetail["orderData"][0].department.name
                                    : ""}
                                </div>
                              </div>

                              {transOrderDetail["orderData"][0].comment_for_translator &&
                                transOrderDetail["orderData"][0].comment_for_translator.length >
                                  0 && (
                                  <div className="col-xl-12 col-sm-6">
                                    <div className="colData">
                                      <span>{Lang.comments_interpreter}</span>
                                      {transOrderDetail["orderData"][0].comment_for_translator}
                                    </div>
                                  </div>
                                )}
                              {transOrderDetail["orderData"][0].meeting_address &&
                                transOrderDetail["orderData"][0].meeting_address.length > 0 &&
                                transOrderDetail["orderData"][0].translation_type_id ===
                                  OrderType.PHYSICAL && (
                                  <div className="col-sm-6">
                                    <div className="colData">
                                      <span>{Lang.meeting_address}</span>{" "}
                                      <i className="fas fa-map-marker-alt" />{" "}
                                      {transOrderDetail["orderData"][0].translation_type_id ===
                                      OrderType.PHYSICAL
                                        ? transOrderDetail["orderData"][0].meeting_address
                                        : ""}
                                    </div>
                                  </div>
                                )}
                              {transOrderDetail["orderData"][0].addition_info &&
                                transOrderDetail["orderData"][0].addition_info.length > 0 &&
                                transOrderDetail["orderData"][0].translation_type_id ===
                                  OrderType.PHYSICAL && (
                                  <div className="col-sm-6">
                                    <div className="colData">
                                      <span>{Lang.addition_info}</span>{" "}
                                      <i className="fas fa-map-marker-alt" />{" "}
                                      {transOrderDetail["orderData"][0].addition_info}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        {!_.isEmpty(transOrderDetail["orderData"][0].tel_message) && (
                          <div className="blockStepList custmorRow">
                            <h3 className="stripTitleNew">
                              <i className="far fa-envelope" /> {Lang.vt_trans_lang_57_telephoneMSG}
                            </h3>
                            <div className="profileDataRow">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="colData">
                                    {" "}
                                    {transOrderDetail["orderData"][0].tel_message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="blockStepList custmorRow">
                          <h3 className="stripTitleNew">
                            <i className="far fa-user" /> {Lang.vt_trans_lang_55_invationContact}
                          </h3>
                          <div className="profileDataRow">
                            <div className="row">
                              {transOrderDetail["orderData"][0].contact_persons &&
                                transOrderDetail["orderData"][0].contact_persons.length > 0 &&
                                transOrderDetail["orderData"][0].contact_persons.map(
                                  (contactPersons: any, index: number) => {
                                    return (
                                      <>
                                        <div className="col-sm-6">
                                          {index == 0 &&
                                            transOrderDetail["orderData"][0].contact_persons
                                              .length > 1 && (
                                              <h4 className="Usertype">{Lang.contact_first}</h4>
                                            )}
                                          {index == 1 &&
                                            transOrderDetail["orderData"][0].contact_persons
                                              .length > 1 && (
                                              <h4 className="Usertype">{Lang.contact_second}</h4>
                                            )}
                                          <div className="colData">
                                            <i className="far fa-user" /> {contactPersons.name}
                                          </div>

                                          {!_.isEmpty(contactPersons.phone_no) &&
                                            transOrderDetail["orderData"][0].status !==
                                              OrderStatus.INVITATION_SENT && (
                                              <div className="colData">
                                                <i className="fas fa-mobile-alt" />{" "}
                                                <a href={"tel:" + contactPersons.phone_no}>
                                                  {contactPersons.phone_no}
                                                </a>
                                              </div>
                                            )}
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                        {transOrderDetail["orderData"][0].feedback &&
                          Number(transOrderDetail["orderData"][0].feedback.rating) > 0 && (
                            <div className="blockStepList">
                              <h3 className="stripTitleNew">
                                <i className="far fa-comment-alt" /> {Lang.order_feedback}
                              </h3>
                              <div className="profileDataRow">
                                <div className="colData">
                                  <span>{Lang.rating}</span>
                                  <div
                                    className={"rating custom-rating-detail"}
                                    data-rating={
                                      transOrderDetail["orderData"][0].feedback.rating
                                        ? transOrderDetail["orderData"][0].feedback.rating
                                        : 0
                                    }
                                  />
                                </div>
                                {transOrderDetail["orderData"][0].feedback.message && (
                                  <div className="colData">
                                    <span>{Lang.message}</span>
                                    {transOrderDetail["orderData"][0].feedback.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        {transOrderDetail["orderData"][0].status != OrderStatus.COMPLETED &&
                          transOrderDetail["orderData"][0].status != OrderStatus.CANCELLED &&
                          transOrderDetail["orderData"][0].status != OrderStatus.PAID &&
                          transOrderDetail["orderData"][0].status != OrderStatus.UNDER_QA &&
                          transOrderDetail["orderData"][0].status != OrderStatus.INVITATION_SENT &&
                          transOrderDetail["orderData"][0].order_date != undefined &&
                          moment(transOrderDetail["orderData"][0].order_date).format(
                            "YYYY-MM-DD"
                          ) <= moment().format("YYYY-MM-DD") &&
                          moment(
                            transOrderDetail["orderData"][0].order_date +
                              " " +
                              transOrderDetail["orderData"][0].order_from
                          ) <= moment() &&
                          transOrderDetail["orderData"][0].translation_type_id !==
                            OrderType.PHONE_MESSAGE && (
                            <div className="blockStepList button text-center">
                              <Link
                                className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail"
                                to={`complete-order/${transOrderDetail["orderData"][0].id}`}
                              >
                                {Lang.vt_trans_lang_59_submit}
                              </Link>
                            </div>
                          )}
                        {(transOrderDetail["orderData"][0].translation_type_id ===
                          OrderType.DOCUMENT ||
                          transOrderDetail["orderData"][0].translation_type_id ===
                            OrderType.PHONE_MESSAGE) &&
                          transOrderDetail["orderData"][0].status != OrderStatus.COMPLETED &&
                          transOrderDetail["orderData"][0].status != OrderStatus.CANCELLED &&
                          transOrderDetail["orderData"][0].status != OrderStatus.PAID &&
                          transOrderDetail["orderData"][0].status != OrderStatus.INVITATION_SENT &&
                          transOrderDetail["orderData"][0].status != OrderStatus.UNDER_QA && (
                            <div className="blockStepList button text-center">
                              <Link
                                className="btn btn-md btn-green hvr-rectangle-out editOrderActionDetail"
                                to={`complete-order/${transOrderDetail["orderData"][0].id}`}
                              >
                                {Lang.vt_trans_lang_59_submit}
                              </Link>
                            </div>
                          )}
                        {transOrderDetail["orderData"][0].status == OrderStatus.INVITATION_SENT && (
                          <div className="col-lg-7 col-md-5 col-sm-12 mobileSpace20">
                            <div className="btn_invitation">
                              <a
                                className="btnStatusSuccess"
                                href="javascript:void(0)"
                                onClick={(e) =>
                                  assignmentAction(e, transOrderDetail["orderData"][0], "accept")
                                }
                              >
                                {Lang.accept}
                              </a>
                              <a
                                className="btnStatusDanger"
                                href="javascript:void(0)"
                                onClick={(e) =>
                                  assignmentAction(e, transOrderDetail["orderData"][0], "reject")
                                }
                              >
                                {Lang.reject}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export const CheckboxModal = ({ show, closeModal }: { show: boolean; closeModal: () => void }) => {
  const [FormValues, setFormValues] = useState<{ worked_with_ministry: boolean }>({
    worked_with_ministry: Auth.userData.translatorData
      ? Auth.userData.translatorData.worked_with_ministry
      : false,
  });
  const handleChange = (val: boolean) =>
    setFormValues({ ...FormValues, worked_with_ministry: val });
  const handleSubmit = async () => {
    const response = await Axios.post("profile/update-ministry-work", FormValues);
    closeModal();
  };
  return (
    <Modal show={show} className="customModal" centered>
      <Modal.Header>
        <h1 className="title">
          <i className={Icons.bell} />
          Notice
        </h1>
        <button type="button" className="close" data-dismiss="modal" onClick={(e) => {}}>
          &times;
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {/* Worked with ministry in the past field */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              margin: "1rem 0",
            }}
          >
            <label style={{ marginBottom: "1rem", textAlign: "start", fontSize: "1rem" }}>
              Har du erfaring med at tolke for Udlændinge- og Integrationsministeriet, herunder
              bl.a. Flygtningenævnet, Hjemrejsestyrelsen, Udlændingestyrelsen, SIRI og
              Udlændingenævnet?
            </label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="worked_with_ministry"
                  id="worked_with_ministry_true"
                  value="true"
                  style={{ cursor: "pointer" }}
                  checked={FormValues.worked_with_ministry}
                  onChange={() => handleChange(true)}
                />
                <label
                  className="form-check-label checkboxLabel"
                  htmlFor="worked_with_ministry_true"
                  style={{ cursor: "pointer" }}
                >
                  {Lang.yes}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="worked_with_ministry"
                  id="worked_with_ministry_false"
                  style={{ cursor: "pointer" }}
                  value="false"
                  checked={!FormValues.worked_with_ministry}
                  onChange={() => handleChange(false)}
                />
                <label
                  className="form-check-label checkboxLabel"
                  htmlFor="worked_with_ministry_false"
                  style={{ cursor: "pointer" }}
                >
                  {Lang.no}
                </label>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Submit
          onClick={() => handleSubmit()}
          className={"btn btn-lg btn-green hvr-rectangle-out"}
          label={Lang.submit}
        />
      </Modal.Footer>
    </Modal>
  );
};

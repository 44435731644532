import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import Lang from "../../common/lang/Lang";
import {getUserInfo} from "../../Auth/Auth";
import {ChangePasswordState, RegisterFormState} from "../signup/State";
import API from "../../common/AxoisClient";
import {AxiosResponse} from "axios";
import {ErrorNotification, successNotification} from "../../common/helpers";
import {ChangePasswordValidations} from "./Validations";
import {FormLayout, Submit} from "../../common";
import * as _ from "lodash";
declare let window:any;

    export const TChangePassword = (props : any) => {
    const user:any = getUserInfo();

    const [tchangepassword, setTChangePassword] = useState(ChangePasswordState);
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showCurPassword, setShowCurPassword] = useState(false);

    useEffect(() => {
        ChangePasswordValidations();
        setTimeout(function () {
            ($('#new_password')as any).parents('.password').find('.progressBar_message ').hide();
        },500);
    }, []);

    function resetForm(event:any) {
        event.preventDefault(); 
        setTChangePassword({...tchangepassword, new_password: '', confirm_password: '', current_password: ''});
        ($('#new_password')as any).parents('.password').find('.progressBar_message ').hide();
    }

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>){
        event.preventDefault();
        if($("#change_password").valid()) {
            API.post(`profile/change-password`, tchangepassword).then((res: AxiosResponse) => {
                if(res.data.status===false){
                    ErrorNotification(Lang.cpassword_not_match);
                } else if(res.data.status===true){
                    successNotification(Lang.success_change_password);
                }
            })
        }
    }

    function handleChangePassword(event:any) {
        event.preventDefault();
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(name === 'new_password' && _.isEmpty(value)) {
            ($('#new_password')as any).parents('.password').find('.progressBar_message ').hide();
        }
        setTChangePassword({...tchangepassword, new_password: event.target.value});
    }

    return (
        <div id="profileTab4" className="tab-pane">
            <FormLayout id="change_password" className="changePassword" onSubmit={handleSubmit}  autoComplete="off">
                <div className="row FormSite">
                    <div className="col-xl-6 col-lg-12">
                        <div className="form-group group mt-3 password">
                            <label htmlFor="PasswordOne">{Lang.current_password}</label>
                            <input id="current_password" type={showCurPassword ? 'text' : 'password'} value={tchangepassword.current_password}  onChange={(e) => {setTChangePassword({...tchangepassword, current_password: e.target.value})}}
                                   className="form-control" name={'current_password'} placeholder="*******"/>
                            <i className={`fa ${showCurPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowCurPassword(!showCurPassword)}}/>
                        </div>
                        <div className="form-group group mt-3 password">
                            <label htmlFor="PasswordTwo">{Lang.new_password}</label>
                            <input id="new_password" type={showPassword ? 'text' : 'password'} value={tchangepassword.new_password} onChange={(e)=>{handleChangePassword(e)}}
                                   name={'new_password'} className="form-control" minLength={8} maxLength={20} placeholder="*******"/>
                            <i className={`fa ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowPassword(!showPassword)}}/>
                            <div className={'progressBar_message'}>
                                <label>{Lang.passwordStrength}:</label>
                                <div id={'passwordStrengthProgress'}/>
                                <div id={'passwordStrengthVerdict'}/>
                                {/* <small>{Lang.vt_trans_lang_33_pwcheck}</small> */}
                               
                            </div>
                        </div>
                        <div className="form-group group mt-3 password">
                            <label htmlFor="PasswordThree">{Lang.cn_password}</label>
                            <input id="confirm_password" type={showConPassword ? 'text' : 'password'} value={tchangepassword.confirm_password}  onChange={(e) => {setTChangePassword({...tchangepassword, confirm_password: e.target.value})}}
                                   className="form-control" name={'confirm_password'}
                                   placeholder="*******"/>
                            <i className={`fa ${showConPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}` } aria-hidden="true" onClick={() => {setShowConPassword(!showConPassword)}}/>
                        </div>
                        <div className="mt-5">
                            <button className={'btn btn-default hvr-rectangle-out'} data-skin={'dark'} title={Lang.reset} onClick={(e) => {resetForm(e)}}>{Lang.reset}</button> &nbsp;&nbsp;
                            <Submit className={'btn btn-lg btn-green hvr-rectangle-out'} label={Lang.submit}/>
                        </div>
                    </div>
                </div>
            </FormLayout>
        </div>

    )
}
import React, {useState, useEffect} from 'react';
import {OrderDataState} from "../dashboard/State";
import {MapInfoService} from "./Schema";
import {Auth} from "../Auth/Auth";
import _ from "lodash";
import Lang from "../common/lang/Lang";
declare let window:any;

export function MapInfo(props: any) {

    const [transOrderDetail, setTransOrderDetail] = useState(OrderDataState);
    const [show, setShow] = useState(false);

    useEffect(() => {

        MapInfoService.getInfo().subscribe((message:any) => {
           setShow(message.show);
           if(message.show){
               setTimeout(() => {
                   setTransOrderDetail({...transOrderDetail, orderData: message.data});
                   getMap(message.data);
               },500);
           }
        });

    }, []);

    function getMap(data: any) {
        $('#dvMap').show();
        let markers: any = [
            {
                "title": data[0].meeting_city,
                "lat": parseFloat(data[0].meeting_lat),
                "lng": parseFloat(data[0].meeting_long),
                "description": data[0].meeting_address
            },
            {
                "title": Auth.userInfo.city,
                "lat": (Auth.userInfo.lat != undefined) ? parseFloat(Auth.userInfo.lat) : '',
                "lng": (Auth.userInfo.lng != undefined) ? parseFloat(Auth.userInfo.lng) : '',
                "description": Auth.userInfo.address
            },
        ];
        var mapOptions = {
            center: new google.maps.LatLng(parseInt(markers[0].lat), parseInt(markers[0].lng)),
            zoom: 15,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
                style: google.maps.ZoomControlStyle.DEFAULT
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid']
            },
        };

        var map = new google.maps.Map((document.getElementById("dvMap") as any), mapOptions);


        if(map!=undefined){
            var infoWindow = new google.maps.InfoWindow();
            var lat_lng = new Array();
            var latlngbounds = new google.maps.LatLngBounds();
            for (i = 0; i < markers.length; i++) {
                var data = markers[i]
                var myLatlng = new google.maps.LatLng(data.lat, data.lng);
                lat_lng.push(myLatlng);
                let marker: any = new google.maps.Marker({
                    position: myLatlng,
                    map: map,
                    title: data.title
                });
                latlngbounds.extend(marker.position);
                (function (marker, data) {
                    google.maps.event.addListener(marker, "click", function (e) {
                        infoWindow.setContent(data.description);
                        infoWindow.open(map, marker);
                    });
                })(marker, data);
            }
            map.setCenter(latlngbounds.getCenter());
            map.fitBounds(latlngbounds);

            var path: any = new google.maps.MVCArray();
            var service: any = new google.maps.DirectionsService();
            var poly = new google.maps.Polyline({map: map, strokeColor: '#4986E7', strokeWeight: 2});
            for (var i = 0; i < lat_lng.length; i++) {
                if ((i + 1) < lat_lng.length) {
                    var src = lat_lng[i];
                    var des = lat_lng[i + 1];
                    path.push(src);
                    poly.setPath(path);
                    service.route({
                        origin: src,
                        destination: des,
                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                        travelMode: 'DRIVING',
                    }, function (result: any, status: any) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            for (var i = 0, len = result.routes[0].overview_path.length; i < len; i++) {
                                path.push(result.routes[0].overview_path[i]);
                            }
                        }
                    });
                }
            }
        }
    }
    if(!show){
        return null;
    }
    if(!_.isEmpty(transOrderDetail['orderData'][0])){
       return  <>
           <div id={'dvMap'}/>
           <div className={'distanceTimeOnMap'}>
               <ul>
                   {transOrderDetail['orderData'][0].total_distance !== undefined &&
                   <li><span>{Lang.distance}</span>
                       <label>-</label> {(transOrderDetail['orderData'][0].total_distance / 2).toFixed(2)} <h6>Km</h6>
                   </li>
                   }
                   {transOrderDetail['orderData'][0].travel_time !== undefined &&
                   <li><span>{Lang.travel_time}</span>
                       <label>-</label> {(transOrderDetail['orderData'][0].travel_time / 2).toFixed(2)}
                       <h6> {Lang.minutes}</h6></li>
                   }
               </ul>
               <span style={{float: 'right',fontSize: 'xx-small'}}>{Lang.note_on_map}</span>
           </div>
       </>
    } else {
        return null;
    }
}
import {
  TranslatorLanguageInterface,
  RegisterFormSchema,
  BasicInfoFormSchema,
  BankInfoFormSchema,
  DocumentInfoFormSchema,
  ChangePasswordSchema,
  ViewBasicInfoFormSchema,
  TranslationTypeSchema,
  translationTypesModel,
  SignTranslationSchema,
  TimerCountSchema,
  OTPExpirySchema,
  PhoneNoForPopupSchema,
  ChangeOldPasswordSchema,
} from "./Schema";

export const RegisterFormState: RegisterFormSchema = {
  language: [
    {
      from_language_id: 96,
      to_language_id: "",
    },
  ] as TranslatorLanguageInterface[],
  name: "",
  email: "",
  email_verified_at: "",
  country_code: "",
  mobile_no: "",
  from_language_id: "",
  to_language_id: "",
  company_type: "",
  gender: "",
  password1: "",
  confirm_password: "",
  address: "",
  zip_code: "",
  city: "",
  lat: "",
  lng: "",
  profile_image: "",
  profile_image_small: "",
  profile_image_medium: "",
  default_img_placeholder: "",
  terms_and_condition: "",
  translator_otp: "",
  translator_phone_number: "",
  final_phone_number: "",
  verified_phone_number: "",
  about: "",
  send_otp_text: "Send OTP",
  worked_with_ministry: false,
  transTypes: [],
};

export const BasicInfoFormState: BasicInfoFormSchema = {
  social_no: "",
  driving_license: "",
  verification_number: "",
  own_vehicle: "",
  is_certified: "",
  police_verified: "",
  push_agreement: 0,
  user_id: localStorage.getItem("login_user_id"),
  origin_country: "",
  country_name: "",
  about: "",
};

export const ViewBasicInfoFormState: ViewBasicInfoFormSchema = {
  social_no: "",
  driving_license: "",
  own_vehicle: "",
  is_certified: "",
  police_verified: "",
  push_agreement: 0,
  verification_number: "",
  user_id: localStorage.getItem("login_user_id"),
  origin_country: "",
  country_name: "",
  resume: "",
  criminal_record: "",
  children_certificate: "",
  translation_types: [],
};

export const BankInfoFormState: BankInfoFormSchema = {
  bank_registration_no: "",
  account_no: "",
  iban: "",
  swift: "",
  bank_name: "",
  account_holder_name: "",
  user_id: localStorage.getItem("login_user_id"),
};

export const ChangePasswordState: ChangePasswordSchema = {
  current_password: "",
  new_password: "",
  confirm_password: "",
  user_id: localStorage.getItem("login_user_id"),
};

export const ChangeOldPasswordState: ChangeOldPasswordSchema = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

export const DocumentInfoFormState: DocumentInfoFormSchema = {
  resume_file_name: "",
  child_certificate_filename: "",
  penalty_certificate_filename: "",
  resume_display_filename: "",
  child_display_filename: "",
  penalty_display_filename: "",
  user_id: localStorage.getItem("login_user_id"),
};

export const TranslationTypeState: TranslationTypeSchema = {
  title: "",
  small_icon: "",
  icon: "",
};

export const TimerCountState: TimerCountSchema = {
  timer_count: "1:00",
};

export const OTPExpiryState: OTPExpirySchema = {
  start_time: "",
};

export const PhoneNoForPopupState: PhoneNoForPopupSchema = {
  country_code: "",
  mobile_no: "",
  translator_otp: "",
};

export const SignTranslationState: SignTranslationSchema = {
  is_sign_lang_translation: 0,
  total_hours: 0,
  total_compelete_jobs: 0,
  tempEmailExists: 0,
};

import API from "../common/AxoisClient";

export const getAppDownloadList = async() => {
  const appDownload = await API.get(`app-download-data`);
  return appDownload;
}

export const getRememberMeData = async() => {
  const rememberMe = await API.get(`remember-me-data`);
  return rememberMe;
}